<div class="dialog-container">
    <h1 class="condensed dialog-title">Exporteer delen</h1>
    <div class="dialog-content">
        <div class="w-100">
            <div *ngIf="!exporting" class="pb-3">
                <p>Exporteer alle delen naar een Excel document.</p>
                <p>Alle delen worden geëxporteerd, uitgezonderd delen die zijn vervallen of gearchiveerd/gefactureerd zijn.</p>
                <mat-checkbox [(ngModel)]="includeArchived">Inclusief gearchiveerde en gefactureerde delen</mat-checkbox>
                <mat-checkbox [(ngModel)]="includeCancelled">Inclusief vervallen delen</mat-checkbox>
            </div>
            <div *ngIf="exporting" class="w-100">
                Bezig met exporteren...
                <br><br>
                <mat-progress-bar class="w-100" mode="indeterminate"></mat-progress-bar>
                <br><br>
            </div>
        </div>
    </div>
    <div class="d-flex flex-wrap">
        <button (click)="onNoClick()" mat-button>Annuleren</button>
        <div class="spacer"></div>
        <button (click)="export()" cdkFocusInitial color="primary" mat-raised-button>Download Excel bestand</button>
    </div>
</div>
