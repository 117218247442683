import {Component, HostListener, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FileItem} from './FileItem';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';
import {Platform} from '@angular/cdk/platform';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FileAddItemComponent} from '../file-add-item-dialog/file-add-item.component';
import {ApiService} from '../../services/api/api.service';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {FileService} from '../../services/file.service';

declare var cordova;
declare var window;

@Component({
    selector: 'app-file-selection-dialog',
    templateUrl: './file-selection-dialog.component.html',
    styleUrls: ['./file-selection-dialog.component.scss']
})
export class FileSelectionDialogComponent implements OnInit, OnDestroy {

    secure = '';
    dragOver = false;
    isCordova = false;
    loaded = false;
    uploading = false;
    showContextMenu: boolean;
    multiple = false;
    onlyImages = false;
    direct = false;
    currentDir: FileItem[];
    currentPath: string;
    breadcrumb: Crumb[];

    public Settings = Settings;
    public Storage = LocalStorage;

    public icons = {
        'bmp': 'fa-file-image',
        'gif': 'fa-file-image',
        'jpg': 'fa-file-image',
        'png': 'fa-file-image',
        'image/png': 'fa-file-image',
        'image/jpeg': 'fa-file-image',
        'ods': 'fa-file-excel',
        'xls': 'fa-file-excel',
        'xlsx': 'fa-file-excel',
        'doc': 'fa-file-word',
        'docx': 'fa-file-word',
        'zip': 'fa-file-archive',
        '': 'fa-file',
        null: 'fa-file',
        'undefined': 'fa-file',
        'pdf': 'fa-file-pdf',
        'application/pdf': 'fa-file-pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word',
        'application/x-zip-compressed': 'fa-file-archive'
    };
    public extHasThumb: Map<string, boolean> = new Map<string, boolean>([
        ['jpg', true],
        ['jpeg', true],
        ['png', true],
        ['JPG', true],
        ['JPEG', true],
        ['PNG', true]
    ]);

    public selectedFolders: Map<FileItem, FileItem> = new Map<FileItem, FileItem>();

    constructor(public dialogRef: MatDialogRef<FileSelectionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) {secure, multiple, images, files, currentPath, directCamera, photoLib},
                public platform: Platform,
                private ngZone: NgZone,
                private dialog: MatDialog,
                private confirmDialogService: ConfirmDialogService,
                private apiService: ApiService,
                private fileService: FileService) {
        if (currentPath) {
            this.currentPath = `/${currentPath}`;
        }
        this.secure = secure;
        this.onlyImages = images;
        this.multiple = multiple;
        if (files) {
            this.direct = true;
            if (!this.currentPath) {
                this.currentPath = '';
            }
            this.uploadFiles(files);
        }
        if (directCamera) {
            this.direct = true;
            this.camera(photoLib);
        }
    }

    ngOnInit(): void {
        if (!this.direct) {
            this.readDir();
        }
    }


    @HostListener('dragover', ['$event'])
    public ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.srcElement.classList.contains('dialog-container') || evt.srcElement.classList.contains('overlay')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        this.uploadFiles(files);
    }

    public uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    public uploadFiles(files: File[]) {
        if (files.length > 0) {
            const uploadedFiles = [];
            for (let i = 0; i < files.length; i++) {
                this.uploading = true;

                if (!files[i].size || files[i].size < 110664300) { // ~100MB
                    this.apiService.makeFileRequest$<FileItem>(
                        'file/upload',
                        files[i],
                        {path: this.currentPath, root: (this.direct && !this.currentPath) ? 'true' : '', secure: this.secure})
                        .subscribe(data => {
                            if (data['success'] && files.length === 1) {
                                this.uploading = false;
                                if (this.direct) {
                                    this.returnUploadedFile(data.data);
                                } else {
                                    this.reload();
                                }
                            } else if (data['success'] && files.length > 1) {
                                if (this.direct) {
                                    uploadedFiles.push(data.data);
                                } else {
                                    this.reload();
                                }
                            } else {
                                this.uploading = false;
                                this.uploadErrorMessage(data['error']['upload'][0]);
                            }
                            if (uploadedFiles.length === files.length) {
                                this.open(uploadedFiles);
                            }
                        }, error => {
                            this.uploading = false;
                            this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage'] ? error['errormessage'] : 'Je kunt dit niet uploaden!'}`);
                        });
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    reload() {
        this.readDir(this.currentPath);
    }

    returnUploadedFile(file: FileItem) {
        this.open(file);
    }

    open(file?: FileItem | FileItem[]) {
        if (!file && this.selectedFolders.size === 1) {
            file = Array.from(this.selectedFolders.keys())[0];
        } else if (this.selectedFolders.size > 1 && this.multiple) {
            const files = Array.from(this.selectedFolders.keys()).filter(f => f.type === 'file');
            this.dialogRef.close(files);
        }
        if (!file) {
            this.readDir();
        } else {
            this.showContextMenu = false;
            if (Array.isArray(file)) {
                this.dialogRef.close(file);
            } else {
                if (file.type === 'file') {
                    this.dialogRef.close([file]);
                } else {
                    this.readDir(file.path);
                }
            }
        }
        this.selectedFolders.clear();
    }

    createBreadcrumb() {
        this.breadcrumb = [];
        const path = this.currentPath.split('/');
        path.forEach((p, i) => {
            const c = new Crumb();
            c.name = p;
            c.path = path.slice(0, i + 1).join('/');
            this.breadcrumb.push(c);
        });
    }

    download(file: FileItem = null) {
        if (!file && this.selectedFolders.size === 1) {
            file = Array.from(this.selectedFolders.keys())[0];
        }
        window.open(`${Settings.API_ENDPOINT}file/download/download?path=${file.path}&access_token=${this.Storage.getUserToken()}`);
    }

    rotateImage(file: FileItem = null) {
        if (!file && this.selectedFolders.size === 1) {
            file = Array.from(this.selectedFolders.keys())[0];
        }
        this.showContextMenu = false;
        this.fileService.rotateImage(file.path).subscribe(() => {
            this.reload();
        });
    }

    deleteItem(event) {
        this.showContextMenu = false;
        let file: FileItem;
        if (this.selectedFolders.size === 1) {
            file = Array.from(this.selectedFolders.keys())[0];
            this.fileService.delete(file.path).subscribe(() => {
                this.reload();
            }, error => {
                this.confirmDialogService.confirm(
                    'Fout',
                    error.message,
                    'Oké',
                    null);
            });
        }
    }

    select(event, doc: FileItem) {
        if ((this.platform.IOS || this.platform.ANDROID) && event.type !== 'contextmenu' && event.type !== 'press') {
            this.open(doc);
        } else {
            if (event.ctrlKey || event.shiftKey) {
                this.selectedFolders.has(doc) ? this.selectedFolders.delete(doc) : this.selectedFolders.set(doc, doc);
            } else if (event.type === 'contextmenu' || event.type === 'press') {
                if (this.selectedFolders.size === 0) {
                    this.selectedFolders.set(doc, doc);
                } else {
                    if (!this.selectedFolders.has(doc)) {
                        this.selectedFolders.clear();
                        this.selectedFolders.set(doc, doc);
                    }
                }
            } else {
                this.showContextMenu = false;
                this.selectedFolders.clear();
                this.selectedFolders.set(doc, doc);
            }
        }

    }

    up() {
        let path = this.currentPath.split('/');
        if (path.length > 1) {
            path = path.slice(0, path.length - 1);
            this.readDir(path.join('/'));
        } else {
            this.readDir();
        }
    }

    contextMenuItem(event, contextMenu) {
        this.showContextMenu = true;
        event.preventDefault();
        if (event.type === 'press') {
            contextMenu.setAttribute('style', `top: ${event.center.y}px; left: ${event.center.x}px`);
        } else {
            contextMenu.setAttribute('style', `top: ${event.clientY}px; left: ${event.clientX}px`);
        }
    }

    ngOnDestroy(): void {
    }

    camera(photoLib?: boolean) {
        if (typeof cordova !== 'undefined') {
            navigator['camera'].getPicture((data) => {
                window.resolveLocalFileSystemURL(data,
                    (fileEntry) => {
                        fileEntry.file(file => {
                                const reader = new FileReader();
                                reader.onloadend = (result) => {
                                    const blob = new Blob([reader.result], {type: 'image/jpg'});
                                    blob['lastModifiedDate'] = new Date();
                                    blob['name'] = Math.floor(new Date().getTime() * Math.random()) + '.jpg';
                                    blob['fileName'] = blob['name'];
                                    const file = blob as File;
                                    this.ngZone.run(() => {
                                        this.uploadFiles([file]);
                                    });
                                };
                                reader.readAsArrayBuffer(file);
                            }, err => {

                            }
                        );
                    }, () => {
                        // error
                    }
                );
            }, (data) => {

            }, {
                quality: 50,
                targetWidth: 1600,
                targetHeight: 1600,
                destinationType: 1,
                sourceType: photoLib ? 0 : 1,
                correctOrientation: true
            });
        }
    }

    outsideClick(event) {
        if (['folder-view', 'details-container', 'folders'].indexOf(event.target.className) !== -1) {
            this.showContextMenu = false;
            this.selectedFolders.clear();
        }
    }

    addNewItem(event, isFolder = false) {
        event.stopPropagation();
        this.addNewItemDialog(null, isFolder);
    }

    public readDir(dir = '') {
        this.loaded = false;
        this.fileService.read(dir).subscribe(dirContent => {
            this.currentPath = dir;
            this.currentDir = dirContent.data.filter(f => {
                return this.extHasThumb.has(f.extension) || f.type === 'dir' || !this.onlyImages;
            }).sort((a, b) => {

                if (a.type < b.type) {
                    return -1;
                }
                if (a.type > b.type) {
                    return 1;
                }
                return 0;
            });
            this.loaded = true;
            this.createBreadcrumb();
        });
    }

    close() {
        this.dialogRef.close();
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Er is iets foutgegaan bij het uploaden',
            `${message}`,
            'Oké', null);
    }

    private addNewItemDialog(files: File[] = null, isFolder = false) {
        const dialogRef = this.dialog.open(FileAddItemComponent, {
            width: '500px',
            maxHeight: '600px',
            data: {
                path: this.currentPath,
                files,
                isFolder
            }
        });
        const subs = dialogRef.afterClosed().subscribe(() => {
            this.reload();
        });
    }
}

export class Crumb {
    name: string;
    path: string;
}
