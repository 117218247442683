import {NativeDateAdapter} from '@angular/material/core';
import {formatDate} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable()
export class DateFullMonthNoDayAdapter extends NativeDateAdapter {

    format(date: Date, displayFormat: any): string {
        return formatDate(date, 'MMMM yyy', 'nl');
    }


}
