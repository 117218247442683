<div class="controls-bottom">
    <div (click)="dialogRef.close()" class="fab big"
         matTooltip="Aanpassingen annuleren"
         matTooltipPosition="before"
         id="btn-AddImage">
        <i class="fas fa-undo"></i>
    </div>
    <div (click)="save()" *ngIf="showSave" [class.disabled]="saving" class="fab big primary" id="btn-Save"
         matTooltip="Opslaan"
         matTooltipPosition="before">
        <i *ngIf="!saving" class="fas fa-check"></i>
        <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
    </div>
</div>
<app-draw-on-image #draw
                   *ngIf="editImage"
                   [height]="height"
                   [width]="width"
                   [image]="editImage">
</app-draw-on-image>
