import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {LocationService} from '../../pushpin-detail/location-selector-dialog/location.service';
import {GpsService} from '../../gps.service';
import {ChecklistService} from '../../services/checklist.service';
import {CodaltComponent} from '../../codalt.component';
import {ChecklistCompleted} from '../../classes/checklist-completed';
import {ChecklistAnswer} from '../../classes/checklist-answer';
import {ChecklistPointType} from '../../classes/checklist-point';
import {ChecklistQuestion} from '../../classes/checklist-question';
import {LocalStorage} from '../../storage.class';

@Component({
    selector: 'app-checklist-pdf',
    templateUrl: './checklist-pdf.component.html',
    styleUrls: ['./checklist-pdf.component.scss']
})
export class ChecklistPDFComponent extends CodaltComponent implements OnInit {

    @Input()
    completed: ChecklistCompleted;
    questionAnswerMap = new Map<number, ChecklistAnswer>();
    questions = new Map<number, ChecklistQuestion>();
    ChecklistPointType = ChecklistPointType;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private locationService: LocationService,
                private gpsService: GpsService,
                private checklistService: ChecklistService) {
        super();
    }

    ngOnInit(): void {
        if (!this.completed) {
            this.subscriptions.add(this.route.params.subscribe(params => {
                if (params['token']) {
                    LocalStorage.setUserToken(params['token']);
                }
                this.getData(params['completedId']);
            }));
        } else {
            this.processData();
        }
    }

    private getData(id) {
        this.checklistService.getAnswers(id).subscribe(completed => {
            this.completed = completed.data;
            this.processData();
        });
    }

    private processData() {
        this.completed.answers.forEach(answer => {
            this.questionAnswerMap.set(answer.question_id, answer);
        });
        this.completed.checklist.questions.forEach(question => {
            this.questions.set(question.id, question);
            question.sub_items.forEach(subQuestion => {
                this.questions.set(subQuestion.id, subQuestion);
            });
        });
        setTimeout(() => {
            document.body.appendChild(document.createElement('readyforpuppeteer'));
        }, 1000);
    }
}
