import {Component, OnInit} from '@angular/core';
import {LibraryService} from '../services/library/library.service';
import {MatDialog} from '@angular/material/dialog';
import {Library} from '../classes/library.class';
import {Platform} from '@angular/cdk/platform';
import {LocalStorage} from '../storage.class';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {CordovaService} from '../cordova.service';
import {Router} from '@angular/router';
import {CodaltComponent} from '../codalt.component';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

declare var cordova;

@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.scss']
})
export class LibraryComponent extends CodaltComponent implements OnInit {

    isRead = false;
    editMode = false;
    touchDevice = false;

    libraryChapters: Library[];
    selectedChapter: Library[];

    constructor(public platform: Platform,
                private confirmDialogService: ConfirmDialogService,
                private libraryService: LibraryService,
                private dialog: MatDialog,
                private router: Router,
                private firebase: FirebaseX,
                private cordovaService: CordovaService) {
        super();
    }

    ngOnInit() {
        this.touchDevice = this.platform.ANDROID || this.platform.IOS;
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        this.isRead = !this.AuthorisationService.hasAnyFeature(['customerAreaGet', 'lrma', 'priceList', 'customerAreaSave', 'extraCostSave', 'decorationSave', 'userGet', 'managementDashboard']);
        this.libraryService.getLibrary().then((lib) => {
            this.libraryChapters = lib;
        }, error => {

        });
    }

    drop(event: CdkDragDrop<string[]>, array) {
        if (this.editMode) {
            moveItemInArray(array, event.previousIndex, event.currentIndex);
            array.forEach((item, index) => {
                item.order = index;
            });
            this.libraryService.changeOrder(array).then(result => {

            }, error => {

            });
        }
    }

    selectChapter(chapter?: Library[]) {
        this.selectedChapter = chapter;
        if (this.selectedChapter) {
            this.cordovaService.setBackbuttonAction(() => {
                this.selectedChapter = null;
            });
        } else {
            this.cordovaService.setBackbuttonAction(() => {
                this.router.navigate(['/']);
            });
        }
    }

    logout() {
        this.confirmDialogService.confirm(
            'Uitloggen',
            `Wil je echt uitloggen?`,
            'Ja', 'Nee').then(() => {
            LocalStorage.logoutUser();
            this.firebase.unregister().then(() => {
                this.firebase.subscribe('alldevices');
            });
            this.router.navigate([`/login`]);
        }, () => {

        });
    }
}
