
export class Library {
  public id: number;

  public name: string;

  public order: number;

  public parent: any;

  public file: string;

  public created_at: Date;

  public updated_at: Date;

  public deleted_at: any;

  public sub_items: Library[] = [];

}
