import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PushpinPart} from '../../classes/pushpinpart.class';

@Component({
    selector: 'app-crowindicator',
    templateUrl: './crowindicator.component.html',
    styleUrls: ['./crowindicator.component.scss']
})
export class CROWIndicatorComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<CROWIndicatorComponent>,
                @Inject(MAT_DIALOG_DATA) public pushpinPart: PushpinPart) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
