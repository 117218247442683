import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LocalStorage} from './storage.class';
import {Settings} from './settings.class';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';

@Injectable()
export class CodaltInterceptor implements HttpInterceptor {

    public static loadingCount = 0;

    private skipUrlsForError = ['dev.virtualearth.net/REST/v1/Routes/SnapToRoad'];

    constructor(private confirmDialogService: ConfirmDialogService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.indexOf(Settings.API_ENDPOINT) !== -1) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + LocalStorage.getUserToken(),
                    Accept: 'application/json'
                }
            });
        }
        return next.handle(request).pipe(tap((response) => {
            if (response.type === HttpEventType.Sent) {
                setTimeout(() => {
                    CodaltInterceptor.loadingCount++;
                });
            }
            if (response.type === HttpEventType.Response) {
                setTimeout(() => {
                    CodaltInterceptor.loadingCount--;
                });
            }
        }, (error) => {
            console.log(error);
            if (error.status === 500 && !this.skipUrlsForError.some(skip => error.url.indexOf(skip))) {
                let errorMessage = '';
                if (error.error && error.error.message) {
                    errorMessage = error.error.message;
                }
                this.confirmDialogService.confirm('Er is een fout opgetreden', error.message + '<br><br>' + errorMessage, 'Sluiten', null);
            }
            setTimeout(() => {
                CodaltInterceptor.loadingCount--;
            });
        }));
    }
}



