import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';


@Pipe({
    name: 'safeSrc'
})
export class SafeSrcPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }

    transform(url) {
        if (url) {
            return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
        }
        return null;
    }
}