import {Injectable} from '@angular/core';
import {CordovaService} from './cordova.service';
import {ApiService} from './services/api/api.service';
import {Settings} from './settings.class';

@Injectable()
export class CameraService {

    constructor(private cordovaService: CordovaService, private apiService: ApiService) {
    }

    getPicture(): Promise<string> {
        return new Promise((resolve, reject) => {
            navigator['camera'].getPicture((data) => {
                this.apiService.postCall(`images`, {base64: data})
                    .then((imageUrl: object) => {
                        resolve(`${Settings.API_ENDPOINT}${imageUrl['imageUrl']}`);
                    }, error => {
                        reject(error);
                    });
            }, (data) => {
                reject(data);
            }, {
                quality: 50,
                targetWidth: 1600,
                targetHeight: 1600,
                destinationType: 0,
                correctOrientation: true
            });
        });
    }

}
