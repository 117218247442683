import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {PpeEquipment} from '../ppe-equipment';
import {PpeRequest} from '../ppe-request';
import {PpeStatus} from '../ppe-status';
import {User} from '../classes/user.class';

@Injectable({
    providedIn: 'root'
})
export class PpeService {

    constructor(private apiService: ApiService) {
    }

    saveEquipment(equipment: PpeEquipment) {
        return this.apiService.postCall$<PpeEquipment>(`ppe/equipment`, equipment);
    }

    removeEquipment(id: number) {
        return this.apiService.deleteCall$(`ppe/equipment/${id}`);
    }

    listEquipment() {
        return this.apiService.getCall$<PpeEquipment[]>(`ppe/equipment`);
    }

    getEquipment(id: number) {
        return this.apiService.getCall$<PpeEquipment>(`ppe/equipment/${id}`);
    }

    saveRequest(request: PpeRequest) {
        return this.apiService.postCall$<PpeRequest>(`ppe/requests`, request);
    }

    listRequests(statusIds: number[]) {
        return this.apiService.getCall$<PpeRequest[]>(`ppe/requests`, {statusIds});
    }

    getRequest(id: number) {
        return this.apiService.getCall$<PpeRequest>(`ppe/requests/${id}`);
    }

    deleteRequest(id: number) {
        return this.apiService.deleteCall$(`ppe/requests/${id}`);
    }

    deleteEquipment(id: number) {
        return this.apiService.deleteCall$(`ppe/equipment/${id}`);
    }

    statusList() {
        return this.apiService.getCall$<PpeStatus[]>(`ppe/status`);
    }

    managerList() {
        return this.apiService.getCall$<User[]>(`ppe/managers`);
    }

}
