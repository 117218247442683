import {Component, OnInit} from '@angular/core';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Decoration} from '../../classes/decoration.class';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-decoration-detail',
    templateUrl: './decoration-detail.component.html',
    styleUrls: ['./decoration-detail.component.scss']
})
export class DecorationDetailComponent implements OnInit {

    decoration: Decoration;
    groups: string[];
    types = ['Figuratie', 'Lengtemarkering', 'Luchthaven'];

    public form = new FormGroup({
        name: new FormControl('', [Validators.min(0), Validators.max(99)]),
        surface: new FormControl('', [Validators.min(0.001), Validators.max(999999.999)]),
        type: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
        decoration_group: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
        specify_length: new FormControl('', []),
        priority_figuration: new FormControl('', []),
        specific_price: new FormControl(false),
        mold: new FormControl(false)
    });

    constructor(private decorationsService: DecorationsService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {

            this.decorationsService.getList().then(decorations => {
                this.groups = [];
                decorations.forEach(decoration => {
                    if (decoration.id === +params['id']) {
                        this.decoration = decoration;
                    }
                    if (this.groups.indexOf(decoration.decoration_group) === -1) {
                        this.groups.push(decoration.decoration_group);
                    }
                });
                if (!this.decoration) {
                    this.decoration = new Decoration();
                }
                Object.entries(this.form.controls).forEach(control => {
                    if (typeof this.decoration[control[0]] !== 'undefined') {
                        this.form.controls[control[0]].setValue(this.decoration[control[0]]);
                    }
                });
            });
        });
    }

    save() {
        if (this.form.valid) {
            Object.assign(this.decoration, this.form.value);
            this.decorationsService.save(this.decoration).then(decoration => {
                this.router.navigate([`decorations`]);
            }, error => {

            });
        } else {
            for (const name of Object.keys(this.form.controls)) {
                this.form.controls[name].markAsTouched();
            }
        }

    }

}
