import {Component, ElementRef, EventEmitter, HostBinding, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {CustomerArea} from '../../classes/customerarea.class';
import {Pushpin} from '../../classes/pushpin.class';
import {StatusService} from '../../services/status/status.service';
import {MaterialService} from '../../services/material/material.service';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {PaintColorService} from '../../services/paintColor/paint-color.service';
import {MaterialPricesGroup, PricesService} from '../../services/prices/prices.service';
import {MaterialPrice} from '../../classes/materialprice.class';
import {DecorationPrice} from '../../classes/decorationprice.class';
import {ExtraCostsService} from '../../services/extraCosts/extra-costs.service';
import {ExtraCost} from '../../classes/extracost.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {ExtraCostsDialogComponent} from './extra-costs-dialog/extra-costs-dialog.component';
import {DocumentService} from '../../services/document/document.service';
import {combineLatest} from 'rxjs';
import {saveAs} from 'file-saver';
import {LocalStorage} from '../../storage.class';
import {DecimalPipe} from '@angular/common';
import {Settings} from '../../settings.class';
import {ApiService} from '../../services/api/api.service';
import {CodaltComponent} from '../../codalt.component';
import {Inspection} from '../../classes/inspection';
import {InspectionStatusLog} from '../../classes/inspectionstatuslog.class';
import {InspectionStatusService} from '../../services/inspection-status.service';
import {InspectionService} from '../../services/inspection.service';
import {PushpinChangesDialogComponent} from './pushpin-changes-dialog/pushpin-changes-dialog.component';
import {TailCharge} from '../../classes/tail-charge';
import {TailChargeService} from '../../services/tail-charge.service';
import {TailChargeDialogComponent} from './tail-charge-dialog/tail-charge-dialog.component';
import {PushpinService} from '../../services/pushpin/pushpin.service';
import {Material} from '../../classes/material.class';
import {PushpinPart} from '../../classes/pushpinpart.class';
import {TotalPricePushpinPartsPipe} from '../../dashboard/total-price-pushpin-parts.pipe';
import {StatusId} from '../../classes/status.class';

@Component({
    selector: 'app-pushpin-selection',
    templateUrl: './pushpin-selection.component.html',
    styleUrls: ['./pushpin-selection.component.scss']
})
export class PushpinSelectionComponent extends CodaltComponent implements OnInit {

    @HostBinding('class')
    classList = '';

    @Output()
    updatePushpinsOnMap = new EventEmitter<boolean>();

    @Output()
    chooseInspection = new EventEmitter<void>();

    linkPriceCalculationToVisibleFilters = false;
    visiblePushpins: Pushpin[];
    selectionVisible = false;
    customerArea: CustomerArea;
    inspection: Inspection;

    inspectionStatusLog: InspectionStatusLog[];
    materialSurfaces = new Map<number, number>();
    specificPriceDecorationLength = new Map<number, number>();
    specificPriceDecoration: DecorationPrice[] = [];
    totalPrice = 0;
    subTotalPrice = 0;
    totalPriceAllParts;
    totalParts = '?';
    selectedParts = '?';
    selectedPushpins = '?';
    inCustomerAreaStatusSince;
    extraCostsList: ExtraCost[];
    tailChargeList: TailCharge[];
    extraCostsPricesMap = {};
    partCount = 0;
    commentBoxValue: string;
    savingComment = false;
    generatingQuotation = false;
    uploading = false;
    addingPoints = false;
    settingPaid = false;
    removingPoints = false;
    @ViewChild('uploadInput') uploadInput: ElementRef;
    materialPrices: MaterialPrice[];

    materialPricesGroups: MaterialPricesGroup[];

    materials: Material[];

    statusIncludedInPdf = new Map<number, string>([
        [1, 'Geschouwd'],
        [2, 'Geschouwd'],
        [3, 'Selectie door klant, Geselecteerd door klant, In uitvoering, Uitvoering gereed'],
        [4, 'Selectie door klant, Geselecteerd door klant, In uitvoering, Uitvoering gereed'],
        [5, 'Geselecteerd door klant, In uitvoering, Uitvoering gereed'],
        [6, 'Geselecteerd door klant, In uitvoering, Uitvoering gereed'],
        [7, 'Geselecteerd door klant, In uitvoering, Uitvoering gereed'],
    ]);

    loadingPrices = false;

    private updateMapTimeout;
    private keyValMap = {};
    private decorationPrices: DecorationPrice[];

    constructor(private inspectionStatusService: InspectionStatusService,
                private inspectionService: InspectionService,
                private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService,
                private pricesService: PricesService,
                private extraCostsService: ExtraCostsService,
                private tailChargeService: TailChargeService,
                private confirmDialogService: ConfirmDialogService,
                private documentService: DocumentService,
                private pushpinService: PushpinService,
                public dialog: MatDialog,
                private elRef: ElementRef,
                private apiService: ApiService,
                private decimalPipe: DecimalPipe) {
        super();
    }

    ngOnInit() {

    }

    showChanges() {
        const dialogRef = this.dialog.open(PushpinChangesDialogComponent, {
            panelClass: 'pushpin-changes-dialog',
            data: this.inspection
        });
    }

    setPaid() {
        this.confirmDialogService.confirm(
            'Goedgekeurd werk als betaald markeren',
            'Hierbij zet u al het werk wat op de status goedgekeurd staat op betaald.',
            'Markeren als betaald',
            'Annuleren').then(() => {
            this.settingPaid = true;
            this.inspectionStatusService.setPaid(this.inspection.id).then(success => {
                this.pushpinService.reset();
                this.updatePushpinsOnMap.emit(true);
                this.settingPaid = false;
            }, () => {
                this.settingPaid = false;
            });
        }, () => {

        });
    }

    acceptWork() {
        this.confirmDialogService.confirm(
            'Uitgevoerd werk goedkeuren',
            'Hierbij wijzigt u de status van alle uitgevoerde delen naar goedgekeurd.',
            'Werk goedkeuren',
            'Niet nu').then(() => {
            this.inspectionStatusService.acceptWork(this.inspection.id).then(success => {
                this.updatePushpinsOnMap.emit(true);
            });
        }, () => {

        });
    }

    inspectionChange() {
        this.clearPrices();
        this.tailChargeList = [];
        this.extraCostsList = [];
    }

    clearPrices() {
        this.materialPricesGroups = [];
        this.specificPriceDecoration = [];
        this.specificPriceDecorationLength.clear();
        this.materialSurfaces.clear();
        this.totalPrice = 0;
        this.totalPriceAllParts = 0;
    }

    linkPricesAndFiltersChange() {
        this.clearPrices();

        if (this.linkPriceCalculationToVisibleFilters) {
            this.calculateCurrentFilteringPrice();
        } else {
            this.getPushpinPartsAndCalculatePrices();
        }
    }

    calculateCurrentFilteringPrice() {
        this.clearPrices();
        this.visiblePushpins.forEach(pushpin => {
            if (Array.isArray(pushpin.pushpin_parts)) {
                pushpin.pushpin_parts.forEach(pushpinPart => {
                    this.calculate(pushpinPart);
                });
            }
        });
        this.specificPriceDecoration.sort((a, b) => {
            if (a.material_id < b.material_id) {
                return -1;
            }
            if (a.material_id > b.material_id) {
                return 1;
            }
            return 0;
        });
        const extraCostsTotalPrice = this.pricesService.calculateTotalPriceExtraCosts(this.extraCostsList, this.extraCostsPricesMap);
        this.totalPrice += extraCostsTotalPrice;
        this.materialPricesGroups = this.pricesService.groupPrices(this.materials, this.materialPrices, this.materialSurfaces, this.specificPriceDecoration);
    }

    toggleSelection() {
        this.selectionVisible = !this.selectionVisible;
        this.pricesService.priceSelectionOpened.emit(this.selectionVisible);

        this.classList = this.selectionVisible ? 'visible' : '';
    }

    public downloadAttachment(log: InspectionStatusLog) {
        window.open(`${Settings.API_ENDPOINT}inspection/attachment?path=${log.comment}&access_token=${LocalStorage.getUserToken()}`);
    }

    public setCustomerArea(customerArea: CustomerArea, inspection: Inspection) {
        this.clearPrices();
        this.customerArea = customerArea;
        this.inspection = inspection;

        if (this.inspection?.id) {
            this.loadingPrices = true;
            this.pricesService.getItemsWithPrices([inspection.id]).subscribe(prices => {
                prices.extraCostsPrices.filter(_ecPrice => {
                    return _ecPrice.customer_area_id === customerArea.id;
                }).forEach(price => {
                    this.extraCostsPricesMap[price.extra_cost_type_id] = price.price;
                });
                this.materialPrices = prices.materialPrices;
                this.decorationPrices = prices.decorationPrices;
                this.getKeyValMapData();
                this.loadingPrices = false;
                if (window.matchMedia('(min-width: 1100px)').matches) {
                    if (!this.selectionVisible) {
                        this.toggleSelection();
                    }
                }
            }, () => {
                this.loadingPrices = false;
            });
        }
    }

    public statusChange(event, customerConfirm = false) {
        this.inspection.inspection_status_id = event;
        if (event === 6 && this.inspection.inspection_status.id !== event) {
            this.inspectionStatusService.checkAllPushpinPartsExecuted(this.inspection.id).then(allAreExecuted => {
                if (allAreExecuted) {
                    this.changeStatus(event);
                } else {
                    this.confirmDialogService.confirm(
                        'Niet alles uitgevoerd',
                        'Niet alle markeringsitems staan op uitgevoerd',
                        'Toch opslaan',
                        'Annuleren'
                    ).then(() => {
                        this.changeStatus(event);
                    }, () => {
                        this.inspection.inspection_status_id = this.inspection.inspection_status.id;
                    });
                }
            }, error => {

            });
        } else if (customerConfirm && event === 4 && this.AuthorisationService.hasFeature('statusGiveOrder')) {
            const price = (this.decimalPipe.transform(this.totalPrice, '1.2-2'));
            this.confirmDialogService.confirm(
                'Bevestiging gunning opdracht',
                `Hiermee gunt u Van Velsen deze opdracht met een totale waarde van €${price}.
                Zodra u op akkoord klikt ontvangt u een offerte in uw e-mail.
                De werkzaamheden worden uitgevoerd na ontvangst van de opdrachtsbevestiging.`,
                'Akkoord',
                'Niet Akkoord').then(() => {
                this.changeStatus(event);
            }, () => {
                this.inspection.inspection_status_id = this.inspection.inspection_status.id;
            });
        } else if (customerConfirm && event === 4 && this.AuthorisationService.hasFeature('statusGiveOrderOverride')) {
            this.confirmDialogService.confirm(
                'Klant actie',
                'Deze actie is bedoeld voor de klant. Weet u zeker dat u wilt doorgaan?',
                'Ja',
                'Nee').then(() => {
                this.changeStatus(event);
            }, () => {
                this.inspection.inspection_status_id = this.inspection.inspection_status.id;
            });
        } else if (event !== 4) {
            this.changeStatus(event);
        }
    }

    public calculatePrices() {
        this.clearPrices();
        this.pricesService.selectedPushpinParts.forEach(pushpinPart => {
            this.calculate(pushpinPart);
        });

        this.specificPriceDecoration.sort((a, b) => {
            if (a.material_id < b.material_id) {
                return -1;
            }
            if (a.material_id > b.material_id) {
                return 1;
            }
            return 0;
        });
        const extraCostsTotalPrice = this.pricesService.calculateTotalPriceExtraCosts(this.extraCostsList, this.extraCostsPricesMap);
        this.subTotalPrice = this.totalPrice + extraCostsTotalPrice;
        const tailChargesTotal = this.pricesService.calculateTotalTailCharges(this.tailChargeList, this.subTotalPrice);
        this.totalPrice += extraCostsTotalPrice + tailChargesTotal;
        this.materialPricesGroups = this.pricesService.groupPrices(this.materials, this.materialPrices, this.materialSurfaces, this.specificPriceDecoration);
    }


    public setVisiblePushpins(visiblePushpins: Pushpin[]) {
        this.visiblePushpins = visiblePushpins;
        if (this.inspection?.id) {
            if (this.materialPrices && this.decorationPrices && this.inspection.inspection_status.selection_allowed && !this.linkPriceCalculationToVisibleFilters) {
                this.pushpinService.allInspectionPushpins(this.inspection.id).subscribe(pppData => {
                    pppData.data.forEach(pushpinPart => {
                        pushpinPart.decoration = this.keyValMap['decoration_id'][pushpinPart.decoration_id];
                        pushpinPart.material = this.keyValMap['material_id'][pushpinPart.material_id];
                        pushpinPart.paint_color = this.keyValMap['paint_color_id'][pushpinPart.paint_color_id];
                        pushpinPart.status = this.keyValMap['status_id'][pushpinPart.status_id];
                        pushpinPart.amount = +pushpinPart.amount;
                        pushpinPart.surface = +pushpinPart.surface;
                        pushpinPart.length = +pushpinPart.length;
                        pushpinPart.stake_out = !!pushpinPart.stake_out;
                        pushpinPart.exclusive_material = !!pushpinPart.exclusive_material;
                        pushpinPart.blast = !!pushpinPart.blast;
                        pushpinPart.night = !!pushpinPart.night;
                    });
                    const extraCostsTotalPrice = this.pricesService.calculateTotalPriceExtraCosts(this.extraCostsList, this.extraCostsPricesMap);
                    const tailChargesTotal = this.pricesService.calculateTotalTailCharges(this.tailChargeList, this.subTotalPrice);
                    this.totalPriceAllParts = (new TotalPricePushpinPartsPipe(this.pricesService).transform(
                        pppData.data,
                        this.materialPrices,
                        this.decorationPrices,
                        [StatusId.Geschouwd, StatusId.VrijVoorUitvoering, StatusId.CalGeschouwd, StatusId.CalGepland, StatusId.CalUitgevoerd]
                    )).total + tailChargesTotal + extraCostsTotalPrice;
                });
            }
            this.pricesService.getTotalPrice(this.inspection.id).then(data => {

                this.totalParts = data['total'];
                this.selectedParts = data['selected'];
                this.selectedPushpins = data['selectedPushpins'];
                this.inCustomerAreaStatusSince = data['statusDate'];
            });
            let statusId = [];
            if (this.inspection?.inspection_status_id === 2) {
                statusId = [1, 8];
            } else if (this.inspection?.inspection_status_id === 3) {
                statusId = [2];
            } else if (this.inspection?.inspection_status_id === 7) {
                statusId = [5];
            }
            this.partCount = this.visiblePushpins.reduce((total, pushpin) => {
                return total + pushpin.pushpin_parts.filter(p => statusId.indexOf(p.status_id) !== -1).length;
            }, 0);
            if (this.linkPriceCalculationToVisibleFilters) {
                this.calculateCurrentFilteringPrice();
            }
        }
    }

    public removeAllPoints() {
        this.removingPoints = true;
        const pushpinParts = [];
        const pushpinPartIds = [];
        let statusId = null;
        if (this.inspection.inspection_status_id === 2) {
            statusId = 1;
        } else if (this.inspection.inspection_status_id === 3) {
            statusId = 2;
        }
        this.visiblePushpins.forEach(pushpin => {
            if (pushpin.pushpin_parts) {
                pushpin.pushpin_parts.forEach(pushpin_part => {
                    pushpinParts.push(pushpin_part);
                    pushpinPartIds.push(pushpin_part.id);
                });
            }
        });

        this.pricesService.selectedPushpinParts.forEach(_pushpinPart => {

            const pushpinPart = pushpinParts.filter(_v_pushpinPart => {
                return _v_pushpinPart.id === _pushpinPart.id;
            })[0];
            if (pushpinPart) {
                pushpinPart.status_id = statusId;
                pushpinPart.status = this.keyValMap['status_id'][statusId];
            }
        });
        this.pricesService.selectedPushpinParts = [];

        if (statusId) {
            this.inspectionStatusService.setPushpinPartsToStatus(pushpinPartIds, statusId).then(result => {
                this.updatePushpinsOnMap.emit(true);
                this.removingPoints = false;
            }, () => {
                this.removingPoints = false;
            });
        } else {
            this.removingPoints = false;
        }
        this.calculatePrices();
    }

    public removePushpinPart(pushpinPart) {
        pushpinPart['loading'] = true;
        if (pushpinPart.id) {
            const pushpinToRemove = this.pricesService.selectedPushpinParts.filter(_pushpinPart => {
                return _pushpinPart.id === pushpinPart.id;
            });
            if (pushpinToRemove && pushpinToRemove.length > 0) {
                if (this.inspection.inspection_status_id === 2) {
                    pushpinPart.status_id = 1;
                    pushpinPart.status = this.keyValMap['status_id'][1];
                }
                if (this.inspection.inspection_status_id === 3) {
                    pushpinPart.status_id = 2;
                    pushpinPart.status = this.keyValMap['status_id'][2];
                }
                this.inspectionStatusService.setPushpinPartsToStatus([pushpinPart.id], pushpinPart.status_id).then(result => {
                    const index = this.pricesService.selectedPushpinParts.indexOf(pushpinToRemove[0]);
                    this.pricesService.selectedPushpinParts.splice(index, 1);
                    this.calculatePrices();
                    clearTimeout(this.updateMapTimeout);
                    this.updateMapTimeout = setTimeout(() => {
                        this.updatePushpinsOnMap.emit(true);
                    }, 800);

                }, () => {
                });

            }
        }
    }

    public addPushpinPart(pushpinPart) {
        pushpinPart['loading'] = true;
        if (pushpinPart.id) {
            const unique = this.pricesService.selectedPushpinParts.filter(_pushpinPart => {
                return _pushpinPart.id === pushpinPart.id;
            }).length < 1;
            if (unique) {
                if (pushpinPart.status_id === 2 && this.inspection.inspection_status_id === 3) {
                    pushpinPart.status_id = 3;
                    pushpinPart.status = this.keyValMap['status_id'][3];
                }
                if ([1, 8].indexOf(pushpinPart.status_id) !== -1 && this.inspection.inspection_status_id === 2) {
                    pushpinPart.status_id = 2;
                    pushpinPart.status = this.keyValMap['status_id'][2];
                }
                this.inspectionStatusService.setPushpinPartsToStatus([pushpinPart.id], pushpinPart.status_id).then(result => {
                    this.pricesService.selectedPushpinParts.push(pushpinPart);
                    this.calculate(pushpinPart);
                    clearTimeout(this.updateMapTimeout);
                    this.updateMapTimeout = setTimeout(() => {
                        this.updatePushpinsOnMap.emit(true);
                    }, 800);
                }, () => {
                });
            }

            this.specificPriceDecoration.sort((a, b) => {
                if (a.material_id < b.material_id) {
                    return -1;
                }
                if (a.material_id > b.material_id) {
                    return 1;
                }
                return 0;
            });
        }
    }

    public addVisiblePoints() {
        this.addingPoints = true;
        const pushpinPartIds = [];
        let statusId = null;
        this.visiblePushpins.forEach(pushpin => {
            if (Array.isArray(pushpin.pushpin_parts)) {
                pushpin.pushpin_parts.forEach(pushpinPart => {
                    const unique = this.pricesService.selectedPushpinParts.filter(_pushpinPart => {
                        return _pushpinPart.id === pushpinPart.id;
                    }).length < 1;
                    if (unique) {
                        this.calculate(pushpinPart);
                        pushpinPart['checked'] = true;
                        if (pushpinPart.status_id === 2 && this.inspection.inspection_status_id === 3) {
                            pushpinPart.status_id = 3;
                            pushpinPart.status = this.keyValMap['status_id'][3];
                            pushpinPartIds.push(pushpinPart.id);
                            statusId = 3;
                        }
                        if ([1, 8].indexOf(pushpinPart.status_id) !== -1 && this.inspection.inspection_status_id === 2) {
                            pushpinPart.status_id = 2;
                            pushpinPart.status = this.keyValMap['status_id'][2];
                            pushpinPartIds.push(pushpinPart.id);
                            statusId = 2;
                        }


                        this.pricesService.selectedPushpinParts.push(pushpinPart);
                    }
                });
            }
        });
        this.specificPriceDecoration.sort((a, b) => {
            if (a.material_id < b.material_id) {
                return -1;
            }
            if (a.material_id > b.material_id) {
                return 1;
            }
            return 0;
        });
        if (statusId) {
            this.inspectionStatusService.setPushpinPartsToStatus(pushpinPartIds, statusId).then(result => {
                this.updatePushpinsOnMap.emit(true);
                this.addingPoints = false;
            }, () => {
                this.addingPoints = false;
            });
        } else {
            this.addingPoints = false;
        }
    }

    public addComment() {
        this.savingComment = true;
        this.inspectionStatusService.addCommentToInspectionLog(this.commentBoxValue, this.inspection.id).then(logItems => {
            this.inspectionStatusLog = logItems;
            this.savingComment = false;
            this.commentBoxValue = '';
            setTimeout(() => {
                this.scrollCommentsToBottom();
            });
        }, err => {
            this.savingComment = false;
        });
    }

    uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    uploadFiles(files: File[]) {
        const url = `inspection/attachment`;
        if (files.length > 0) {
            this.uploading = true;
            for (let i = 0; i < files.length; i++) {
                if (files[i].size < 110664300) { // ~100MB
                    this.apiService.makeFileRequest$<InspectionStatusLog[]>(url, files[i],
                        {'customer_area_id': this.customerArea.id, 'inspection_id': this.inspection.id}).subscribe(data => {
                        this.uploading = false;
                        this.inspectionStatusLog = data.data;
                        setTimeout(() => {
                            this.scrollCommentsToBottom();
                        });
                    }, error => {
                        this.uploading = false;
                        let errorMessage = '';
                        if (typeof error.data === 'object' && Array.isArray(error.data.data)) {
                            errorMessage += error.data.data.reduce((t, e) => t += `<br>${e}`);
                        } else {
                            errorMessage = error.message ? error.message : 'Je kunt dit niet uploaden!';
                        }
                        this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${errorMessage}`);
                    });
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    public editExtraCost(extraCost = null) {
        if (this.AuthorisationService.hasFeature('extraCostSave')) {
            if (!extraCost) {
                extraCost = new ExtraCost();
                extraCost.number = 1;
            }
            extraCost.customer_area_id = this.customerArea.id;

            const dialogRef = this.dialog.open(ExtraCostsDialogComponent, {
                panelClass: 'confirm-dialog',
                disableClose: true,
                data: {
                    extraCost: extraCost,
                    extraCostsPricesMap: this.extraCostsPricesMap,
                    inspection: this.inspection
                }
            });
            const subs = dialogRef.afterClosed().subscribe(remove => {
                if (this.extraCostsList.indexOf(extraCost) === -1) {
                    this.extraCostsList.push(extraCost);
                }
                if (remove) {
                    const index = this.extraCostsList.indexOf(extraCost);
                    this.extraCostsList.splice(index, 1);
                }
                this.calculatePrices();
                subs.unsubscribe();
            });
        }
    }

    public editTailCharge(tailCharge = null) {
        if (this.AuthorisationService.hasFeature('saveTailCharges')) {
            if (!tailCharge) {
                tailCharge = new ExtraCost();
                tailCharge.number = 0.1;
            }
            tailCharge.customer_area_id = this.customerArea.id;

            const dialogRef = this.dialog.open(TailChargeDialogComponent, {
                panelClass: 'confirm-dialog',
                disableClose: true,
                data: {
                    tailCharge,
                    inspection: this.inspection,
                    totalPrice: this.totalPrice
                }
            });
            const subs = dialogRef.afterClosed().subscribe(remove => {
                if (this.tailChargeList.indexOf(tailCharge) === -1) {
                    this.tailChargeList.push(tailCharge);
                }
                if (remove) {
                    const index = this.tailChargeList.indexOf(tailCharge);
                    this.tailChargeList.splice(index, 1);
                }
                this.calculatePrices();
                subs.unsubscribe();
            });
        }
    }

    tabChange(event: MatTabChangeEvent) {
        if (event.index === 1) {
            this.scrollCommentsToBottom();
        }

    }

    getCurrentPushpinParts() {
        return new Promise<PushpinPart[]>((resolve, reject) => {
            if ([3, 4, 5].indexOf(this.inspection?.inspection_status_id) !== -1) {
                this.inspectionStatusService.getReleasedPushpins(this.inspection.id).then(_pushpinParts => {
                    resolve(_pushpinParts);
                });
            } else if (this.inspection?.inspection_status_id === 1) {
                this.inspectionStatusService.getConceptPushpins(this.inspection.id).then(_pushpinParts => {
                    resolve(_pushpinParts);
                });
            } else if (this.inspection?.inspection_status_id === 2) {
                this.inspectionStatusService.getCustomerVisiblePushpins(this.inspection.id).then(_pushpinParts => {
                    resolve(_pushpinParts);
                });
            } else if (this.inspection?.inspection_status_id >= 6) {
                this.inspectionStatusService.getFinishedPushpins(this.inspection.id).then(_pushpinParts => {
                    resolve(_pushpinParts);
                });
            }
        });
    }

    public getPushpinPartsAndCalculatePrices() {
        const visiblePushpinParts = [];
        this.visiblePushpins.forEach(pushpin => {
            if (pushpin.pushpin_parts) {
                pushpin.pushpin_parts.forEach(pushpin_part => {
                    pushpin_part['checked'] = false;
                    visiblePushpinParts.push(pushpin_part);
                });
            }
        });
        this.getCurrentPushpinParts().then(_pushpinParts => {
            if ([3, 4, 5].indexOf(this.inspection?.inspection_status_id) !== -1) {
                _pushpinParts.forEach(pp => {
                    pp['checked'] = true;
                    const pushpinPart = visiblePushpinParts.filter(_v_pushpinPart => {
                        return _v_pushpinPart.id === pp.id;
                    })[0];
                    if (pushpinPart) {
                        pushpinPart['checked'] = true;
                    }
                });
                this.pricesService.selectedPushpinParts = _pushpinParts;
                this.calculatePrices();
            } else if (this.inspection?.inspection_status_id === 1) {
                _pushpinParts.forEach(pp => {
                    pp['checked'] = false;
                });
                this.pricesService.selectedPushpinParts = _pushpinParts;
                this.calculatePrices();
            } else if (this.inspection?.inspection_status_id === 2) {
                _pushpinParts.forEach(pp => {
                    pp['checked'] = true;
                    const pushpinPart = visiblePushpinParts.filter(_v_pushpinPart => {
                        return _v_pushpinPart.id === pp.id;
                    })[0];
                    if (pushpinPart) {
                        pushpinPart['checked'] = true;
                    }
                });
                this.pricesService.selectedPushpinParts = _pushpinParts;
                this.calculatePrices();
            } else if (this.inspection?.inspection_status_id >= 6) {
                this.inspectionStatusService.getFinishedPushpins(this.inspection.id).then(_pushpinParts => {
                    this.pricesService.selectedPushpinParts = _pushpinParts;
                    this.calculatePrices();
                });
            }
        }, () => {

        });
    }

    public downloadQuotation() {
        this.generatingQuotation = true;
        this.documentService.getQuotationPdf(this.inspection.id).subscribe(document => {
            this.generatingQuotation = false;
            saveAs(document, `Prijsspecificatie ${this.customerArea.name}.pdf`);
        });
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Fout bij het uploaden',
            `${message}`,
            'Oké', null);
    }

    private scrollCommentsToBottom() {
        const commentLine = this.elRef.nativeElement.querySelector('#comment-line');
        if (commentLine) {
            commentLine.scrollBy(0, commentLine.scrollHeight);
        }
    }

    private changeStatus(event) {
        this.inspectionStatusService.updateStatus(this.inspection.id, event).then(resultStatus => {
            if (this.commentBoxValue) {
                this.addComment();
            }
            this.pricesService.priceSelectionOpened.emit(true);
            this.inspection.inspection_status_id = resultStatus.id;
            Object.assign(this.inspection.inspection_status, resultStatus);
            this.setCustomerArea(this.customerArea, this.inspection);
            if (event === 4 || event === 7) {
                this.documentService.getQuotation(this.inspection.id);
            }
        });
    }

    private calculate(pushpinPart) {
        pushpinPart.decoration = this.keyValMap['decoration_id'][pushpinPart.decoration_id];
        pushpinPart.material = this.keyValMap['material_id'][pushpinPart.material_id];
        pushpinPart.paint_color = this.keyValMap['paint_color_id'][pushpinPart.paint_color_id];
        pushpinPart.status = this.keyValMap['status_id'][pushpinPart.status_id];
        this.totalPrice += this.pricesService.calculatePushpinPartPrice(
            pushpinPart,
            this.materialPrices,
            this.decorationPrices,
            this.materialSurfaces,
            this.specificPriceDecorationLength,
            this.specificPriceDecoration
        );
    }

    private getKeyValMapData() {
        const statusMap$ = this.statusService.getKeyValMap();
        const decorationsMap$ = this.decorationsService.getKeyValMap();
        const materialsMap$ = this.materialService.getMaterialsAsKeyValueMap(true);
        const paintColorMap$ = this.paintColorService.getKeyValMap();
        const materials$ = this.materialService.getMaterials(true);
        combineLatest([statusMap$, decorationsMap$, materialsMap$, paintColorMap$, materials$])
            .subscribe(([status, decoration, material, paintColor, materials]) => {
                this.materials = materials;
                this.keyValMap['status_id'] = status;
                this.keyValMap['decoration_id'] = decoration;
                this.keyValMap['material_id'] = material;
                this.keyValMap['paint_color_id'] = paintColor;
                this.decorationPrices.forEach(_decorationPrice => {
                    _decorationPrice.decoration = this.keyValMap['decoration_id'][_decorationPrice.decoration_id];
                    _decorationPrice.material = this.keyValMap['material_id'][_decorationPrice.material_id];
                    _decorationPrice.paint_color = this.keyValMap['paint_color_id'][_decorationPrice.paint_color_id];
                });
                this.materialPrices.forEach(_materialPrice => {
                    _materialPrice.material = this.keyValMap['material_id'][_materialPrice.material_id];
                    _materialPrice.paint_color = this.keyValMap['paint_color_id'][_materialPrice.paint_color_id];
                });
                this.extraCostsService.getSavedExtraCosts(this.inspection.id).then(extraCosts => {
                    this.linkPricesAndFiltersChange();
                    this.extraCostsList = extraCosts;
                });
                this.tailChargeService.get(this.inspection.id).subscribe(charges => {
                    this.tailChargeList = charges.data;
                });

                this.inspectionStatusService.getInspectionStatusLog(this.inspection.id).then(logItems => {
                    this.inspectionStatusLog = logItems;
                    this.scrollCommentsToBottom();
                });

            });
    }
}
