import {CustomerArea} from './customerarea.class';
import {Role} from "./role.class";

export class User {
    id: number;
    name: string;
    email: string;
    group: string;
    notifications: number;
    customer_areas: CustomerArea[];
    roles: Role[];
    is_codalt: boolean;
    hasOtp: boolean;
}
