import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {PushpinOverviewComponent} from '../pushpin-overview/pushpin-overview.component';
import {Injectable} from '@angular/core';
import {Utils} from '../utils.class';

@Injectable()
export class CanDeactivatePushpinDetail implements CanDeactivate<PushpinOverviewComponent> {
    constructor() {
    }

    canDeactivate(
        component: PushpinOverviewComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.pushpinDetailComponent && !Utils.isIOS()) {
            return component.pushpinDetailComponent.openChangesBackActionCheck();
        }
        return true;
    }
}
