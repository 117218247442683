<table class="report-container">
    <thead class="report-header">
    </thead>
    <tfoot class="report-footer">
    <tr>
        <td class="report-footer-cell">
            <div class="footer-info">

            </div>
        </td>
    </tr>
    </tfoot>
    <tbody class="report-content">
    <tr>
        <td class="report-content-cell">
            <div class="main">
                <div *ngIf="customerArea" class="document">
                    <div class="d-flex pt-3 pb-2" [class.pb-3]="!inspection.reference">
                        {{customerArea.kind === 'Bedrijf' ? '' : customerArea.kind}} {{customerArea.name}}<br>
                        {{customerArea.street}} {{customerArea.number}}<br>
                        {{customerArea.zip}} {{customerArea.city}}
                    </div>
                    <div class="pb-3" *ngIf="inspection.reference">
                        Uw referentie: {{inspection.reference}}
                    </div>
                    <div>
                        <h2 *ngIf="inspection.inspection_status_id <= 4" class="condensed">Offerte</h2>
                        <h2 *ngIf="inspection.inspection_status_id > 4" class="condensed">Factuurspecificatie</h2>
                    </div>
                    <div class="pb-3">
                        Datum: {{date | date:'d MMMM yyyy HH:mm'}}
                    </div>
                    <div *ngIf="materialSurfaces">
                        <table cellpadding="3" class="price-table">
                            <tr class="border-bottom">
                                <th style="width: 106mm;">
                                    Omschrijving
                                </th>
                                <th style="text-align: right; width: 36mm;">
                                    Hoeveelheid
                                </th>
                                <th style="width: 14mm; text-align: right;">
                                    Eh.
                                </th>
                                <th style="width: 30mm;">
                                    Code
                                </th>
                                <th class="text-right" style="width: 30mm;">
                                    Eenheidsprijs
                                </th>
                                <th class="text-right" style="width: 42mm;">
                                    Bedrag
                                </th>
                            </tr>
                            <ng-container *ngFor="let materialPricesGroup of materialPricesGroups">
                                <tr>
                                    <th colspan="5">
                                        {{materialPricesGroup.group}}
                                    </th>
                                </tr>
                                <ng-container *ngFor="let materialPrice of materialPricesGroup.materialPrices">
                                    <tr *ngIf="materialSurfaces.get(materialPrice.id) > 0">
                                        <td>
                                            <div class="ellipsis">
                                                {{materialPrice.material.name}}
                                                {{(materialPrice.paint_color_id < 2) ? '' : materialPrice.paint_color.name}}
                                            </div>
                                        </td>
                                        <td class="price-cell">
                                            {{materialSurfaces.get(materialPrice.id) | number:'1.0-2'}}
                                        </td>
                                        <td class="price-cell">
                                            <span class="gray">m<sup>2</sup></span>
                                        </td>
                                        <td>
                                            <span class="gray">{{materialPrice.code}}</span>
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span> {{materialPrice.price | number:'1.2-2'}}
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span> {{materialSurfaces.get(materialPrice.id) * materialPrice.price | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngFor="let decorationPrice of materialPricesGroup.specificPriceDecoration; let i = index">
                                    <tr>
                                        <td>
                                            <div class="ellipsis" title="{{decorationPrice.decoration.name}}">
                                                {{decorationPrice.material.name}} {{decorationPrice.decoration.name}}
                                                {{(decorationPrice.paint_color_id === 1) ? '' : decorationPrice.paint_color.name}}
                                            </div>
                                        </td>
                                        <td class="price-cell">
                                            {{specificPriceDecorationLength.get(decorationPrice.id)}}
                                        </td>
                                        <td class="price-cell">
                                            <span *ngIf="decorationPrice.material_id !== 5" class="gray">
                                                <ng-container *ngIf="decorationPrice.decoration.specify_length">
                                                    m
                                                </ng-container>
                                                <ng-container *ngIf="!decorationPrice.decoration?.specify_length && decorationPrice.decoration?.surface">
                                                     st
                                                </ng-container>
                                                <ng-container *ngIf="!decorationPrice.decoration?.surface">
                                                     m<sup>2</sup>
                                                </ng-container>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="gray">{{decorationPrice.code}}</span>
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{decorationPrice.price | number:'1.2-2'}}
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{specificPriceDecorationLength.get(decorationPrice.id) * decorationPrice.price | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngFor="let extraCost of extraCostsList; let i = $index">
                                <tr class="extra-cost-item">
                                    <td>
                                        <div class="ellipsis" title="{{extraCost.extra_cost_type.name}}">
                                            {{extraCost.extra_cost_type.name}}
                                        </div>
                                    </td>
                                    <td class="price-cell">
                                        {{extraCost.number}}
                                    </td>
                                    <td class="text-right">
                                        <span class="gray" *ngIf="extraCost.extra_cost_type.unit === 'm2'"> m<sup>2</sup></span>
                                        <span class="gray" *ngIf="extraCost.extra_cost_type.unit !== 'm2'"> {{extraCost.extra_cost_type.unit}}</span>
                                    </td>
                                    <td>
                                        <span class="gray">{{extraCostsCodeMap[extraCost.extra_cost_type_id]}}</span>
                                    </td>
                                    <td class="price-cell">
                                        <span class="eu">€</span>
                                        {{extraCostsPricesMap[extraCost.extra_cost_type_id] | number:'1.2-2'}}
                                    </td>
                                    <td class="price-cell">
                                        <span class="eu">€</span>
                                        {{extraCost.number * extraCostsPricesMap[extraCost.extra_cost_type_id] | number:'1.2-2'}}
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="tailChargeList && tailChargeList.length !== 0">
                                <tr class="total">
                                    <th class="text-right" colspan="5">
                                        Subtotaal:
                                    </th>
                                    <th class="price-cell total" colspan="1">
                                        <span class="eu">€</span> {{subTotalPrice | number:'1.2-2'}}
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        &nbsp;
                                    </td>
                                </tr>
                                <ng-container *ngFor="let tailCharge of tailChargeList; let i = $index">
                                    <tr class="extra-cost-item">
                                        <td>
                                            <div class="ellipsis" title="{{tailCharge.comment}}">
                                                {{tailCharge.comment}}
                                            </div>
                                        </td>
                                        <td class="price-cell">
                                            {{tailCharge.number * 100 | number:'1.3-3'}}
                                        </td>
                                        <td class="text-right">
                                            <span class="gray">%</span>
                                        </td>
                                        <td class="price-cell">

                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{tailCharge.number * subTotalPrice | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <tr class="total">
                                <th class="text-right" colspan="5">
                                    Totaal:
                                </th>
                                <th class="price-cell total" colspan="1">
                                    <span class="eu">€</span> {{totalPrice | number:'1.2-2'}}
                                </th>
                            </tr>
                            <ng-container *ngIf="inspection.inspection_status_id > 4 && changedLines.length > 0">
                                <tr>
                                    <th colspan="5">
                                        <h3 class="condensed mt-5">Wijzigingen t.o.v. offerte</h3>
                                        <i *ngIf="inspection.inspection_status_id === 5">
                                            Let op: de schouw is nog in uitvoering. Nog niet uitgevoerde delen worden hier als wijziging t.o.v. offerte getoond.
                                        </i>
                                    </th>
                                </tr>
                                <ng-container *ngFor="let change of changedLines; let i = index">
                                    <ng-container
                                        *ngIf="i === 0 || change['pushpin_id'] != changedLines[i-1]['pushpin_id']">
                                        <tr>
                                            <td colspan="5">
                                                &nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <i>{{change['location']}}</i>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            <div class="ellipsis">
                                                {{change['name']}}
                                            </div>
                                        </td>
                                        <td class="price-cell">
                                            {{change['negative'] ? '-' : '+'}}
                                            {{change['surface'] | number:'1.0-2'}}
                                        </td>
                                        <td class="text-right">
                                            <span [innerHTML]="change['unit']" class="gray"></span>
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{change['price'] | number:'1.2-2'}}
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            <ng-container *ngIf="change['negative']">-</ng-container>
                                            {{change['priceTotal'] | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="inspection.inspection_status_id > 4 && addedLines?.length > 0">
                                <tr>
                                    <th colspan="5">
                                        <h3 class="condensed mt-5">Meerwerk t.o.v. offerte</h3>
                                    </th>
                                </tr>
                                <ng-container *ngFor="let change of addedLines; let i = index">
                                    <ng-container
                                        *ngIf="i === 0 || change['pushpin_id'] != addedLines[i-1]['pushpin_id']">
                                        <tr>
                                            <td colspan="5">
                                                &nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5">
                                                <i>{{change['location']}}</i>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            <div class="ellipsis">
                                                {{change['name']}}
                                            </div>
                                        </td>
                                        <td class="price-cell">
                                            {{change['negative'] ? '-' : '+'}}
                                            {{change['surface'] | number:'1.0-2'}}
                                        </td>
                                        <td class="text-right">
                                            <span [innerHTML]="change['unit']" class="gray"></span>
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            {{change['price'] | number:'1.2-2'}}
                                        </td>
                                        <td class="price-cell">
                                            <span class="eu">€</span>
                                            <ng-container *ngIf="change['negative']">-</ng-container>
                                            {{change['priceTotal'] | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </table>
                    </div>
                    <div class="text-center">
                        <br>
                        LET OP! De genoemde prijzen zijn ter indicatie conform de prijslijst en zijn exclusief eventuele toeslagen. Alle bedragen zijn exclusief btw.
                    </div>
                </div>
            </div>
        </td>
    </tr>
    </tbody>
</table>
