import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {RepairComment} from '../../classes/repaircomment.class';
import {RepairService} from '../../services/repair.service';

@Component({
    selector: 'app-repair-comment',
    templateUrl: './repair-comment.component.html',
    styleUrls: ['./repair-comment.component.scss']
})
export class RepairCommentComponent implements OnInit {

    @HostBinding('class.logitem') logitem = false;

    @Input() repairComment: RepairComment;

    @Output() uploadFiles = new EventEmitter<File[]>();

    @Output() deleted = new EventEmitter<RepairComment>();

    @Output() placedComment = new EventEmitter<RepairComment>();

    @Output() openFile = new EventEmitter<object>();

    url = Settings.API_ENDPOINT + 'file/';
    token = LocalStorage.getUserToken();

    editing = false;

    mayEdit = false;

    private user: User;

    constructor(private repairService: RepairService,
                private confirmDialog: ConfirmDialogService) {
    }

    ngOnInit(): void {
        this.logitem = this.repairComment.statuslog;
        this.user = LocalStorage.getUser();
        this.editing = !this.repairComment.id;
        this.mayEdit = this.user.id === this.repairComment.user_id || !this.repairComment.user_id;
    }

    placeOrEditComment() {
        this.repairService.saveComment(this.repairComment).then(comment => {
            if (!this.repairComment.id) {
                this.repairComment.id = comment.id;
                this.repairComment.created_at = comment.created_at;
                this.placedComment.emit(this.repairComment);
            }
            this.editing = false;
        });
    }

    edit() {
        this.editing = true;
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles.emit(files);
    }

    deleteComment() {
        this.confirmDialog.confirm(
            'Verwijderen',
            `Weet u zeker dat u deze reactie van ${this.repairComment?.user?.name} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            if (this.repairComment.id) {
                this.repairService.deleteComment(this.repairComment.id).then(success => {
                    if (success) {
                        this.deleted.emit(this.repairComment);
                    }
                });
            } else {
                this.deleted.emit(this.repairComment);
            }
        }, () => {
        });
    }

}
