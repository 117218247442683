<app-vvr-icon [dark]="true" [full]="true" [overlay]="true" [shadow]="false"></app-vvr-icon>
<div class="login-background">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4" class="login-form">
                <h1 class="h2 condensed">Voer de code in uit je authenticator-app</h1>
                <p>
                    Inloggen met tweestapsverificatie is verplicht voor dit account.
                    Voer hieronder de code in die uw authenticator app teruggeeft.
                </p>
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Code</mat-label>
                    <input (keyup.enter)="login()"
                           [(ngModel)]="twoFactorCode"
                           placeholder="000 000"
                           matInput
                           placeholder="Code"
                           appDecimalInput type="text">
                    <mat-error>Dit veld moet 6 tekens bevatten</mat-error>
                </mat-form-field>
                <div class="d-flex justify-content-between">
                    <button routerLink="/logout" color="secondary" mat-raised-button>
                        Uitloggen
                    </button>
                    <button (click)="login()" color="primary" mat-raised-button>
                        {{validating ? 'Valideren...' : 'Inloggen'}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
