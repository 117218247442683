import * as turf from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import lineToPolygon from '@turf/line-to-polygon';
import {MultiPolygon} from '@turf/helpers';


export class Point {
    private x: number;
    private y: number;

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

export class Region {

    private readonly points = [];
    private length: number;

    constructor(points) {
        this.points = points || [];
        this.length = Array.isArray(points) ? points.length : 0;
    }

    public area() {
        let area = 0,
            i,
            j,
            point1,
            point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            area += point1.lng * point2.lat;
            area -= point1.lat * point2.lng;
        }
        area /= 2;

        return area;
    }

    public centroid() {
        let x = 0,
            y = 0,
            i,
            j,
            f,
            point1,
            point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            f = point1.lng * point2.lat - point2.lng * point1.lat;
            x += (point1.lng + point2.lng) * f;
            y += (point1.lat + point2.lat) * f;
        }

        f = this.area() * 6;

        return {
            lng: x / f,
            lat: y / f
        };
    }

    public bestMapView(mapWidth, mapHeight, buffer) {

        const zoomLevel = 0;
        let maxLat = -85;
        let minLat = 85;
        let maxLon = -180;
        let minLon = 180;

        this.points.forEach(point => {

            if (point.lat > maxLat) {
                maxLat = point.lat;
            }

            if (point.lat < minLat) {
                minLat = point.lat;
            }

            if (point.lng > maxLon) {
                maxLon = point.lng;
            }

            if (point.lng < minLon) {
                minLon = point.lng;
            }

        });

        let zoom1 = 0;
        let zoom2 = 0;

        // Determine the best zoom level based on the map scale and bounding coordinate information
        if (maxLon !== minLon && maxLat !== minLat) {

            // best zoom level based on map width
            zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
            // best zoom level based on map height
            zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
        }


        return (zoom1 < zoom2) ? zoom1 : zoom2;


    }


    public insideTown(center) {

        const geoPoints = this.points.map(p => [p.lat, p.lng]);
        if (Array.isArray(geoPoints) && geoPoints.length > 2) {
            const line = turf.lineString(geoPoints);
            const polygon = lineToPolygon(line);

            return booleanPointInPolygon(turf.point([center.lat, center.lng]), polygon as any as MultiPolygon);
        }
        return false;
    }


}

