<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">PBM's</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/management"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-9 col-xl-7">

            <table class="table table-striped table-hover">
                <tr>
                    <th (click)="sortList('name')">
                        Naam <i class="fas"
                                [class.fa-sort]="currentSort !== 'name'"
                                [class.fa-sort-up]="desc && currentSort === 'name'"
                                [class.fa-sort-down]="!desc && currentSort === 'name'"></i>
                    </th>
                    <th class="trash-column" width="20" *ngIf="AuthorisationService.hasFeature('deletePbmEquipment')">

                    </th>
                </tr>
                <tr *ngFor="let equipment of equipments"
                    (click)="edit(equipment)"
                    class="curpoint">
                    <td>
                        {{equipment.name}}
                    </td>
                    <td class="pt-0 pb-0" *ngIf="AuthorisationService.hasFeature('deletePbmEquipment')">
                        <button (click)="delete($event, equipment)"
                                color="warn"
                                mat-icon-button>
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div class="controls-bottom">
    <div id="btn-Mapview" class="fab big primary"
         routerLink="/{{Routenames.ppeEquipment}}/new">
        <i class="fas fa-plus"></i>
    </div>
</div>
