import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {PaintColor} from '../../classes/paintcolor.class';

@Injectable({
    providedIn: 'root'
})
export class PaintColorService {

    private _paintColorList: PaintColor[] = [];

    constructor(private apiService: ApiService) {
    }

    public getList(): Promise<PaintColor[]> {
        return new Promise((resolve, reject) => {
            if (this._paintColorList && this._paintColorList.length > 0) {
                resolve(this._paintColorList);
            } else {
                this.apiService.getCall('paintcolors').then((paintcolors: PaintColor[]) => {
                    this._paintColorList = paintcolors;
                    resolve(paintcolors);
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public getKeyValMap(): Promise<object> {
        return new Promise((resolve, reject) => {
            if (this._paintColorList && this._paintColorList.length > 0) {
                resolve(this.toKeyVal(this._paintColorList));
            } else {
                this.apiService.getCall('paintcolors').then((paintcolors: PaintColor[]) => {
                    this._paintColorList = paintcolors;
                    resolve(this.toKeyVal(paintcolors));
                }, err => {
                    reject(err);
                });
            }
        });
    }

    private toKeyVal(arr: object[]) {
        const keyValMap = {};
        arr.forEach(item => {
            keyValMap[item['id']] = item;
        });
        return keyValMap;
    }
}
