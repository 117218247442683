<div class="image-viewer-container">
    <div class="swiper-container" [swiper]="config" [(index)]="images.viewIndex">
        <div class="swiper-wrapper" *ngIf="images.images">
            <div *ngFor="let image of images.images" class="image swiper-slide">
                <div class="image-box">
                    <pinch-zoom [draggable]="true" overflow="visible" #pinchZoom>
                        <img [src]="image.drawn_image ?? image.image ?? image" class="img" alt="image"/>
                    </pinch-zoom>
                    <pinch-zoom [draggable]="true" *ngIf="!image.image" overflow="visible">
                        <img [src]="image" class="img" alt="image"/>
                    </pinch-zoom>
                </div>
                <div class="text" *ngIf="image.image">
                    {{image.status.name}}<br>
                    <ng-container *ngIf="image.updated_at">
                        {{image.updated_at | date : 'd MMM yyyy, H:mm'}}
                    </ng-container>
                    <ng-container *ngIf="!image.updated_at">
                        Zojuist gemaakt
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
</div>
<div class="controls-bottom">
    <div class="fab big" (click)="drawOnImage($event)" *ngIf="showDrawing">
        <i class="fa-solid fa-pen-swirl"></i>
    </div>
    <div class="fab big primary" (click)="dialogRef.close()">
        <i class="fas fa-times"></i>
    </div>
</div>
