import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CustomerArea} from '../../classes/customerarea.class';
import {PushpinFilterComponent} from '../pushpin-filter/pushpin-filter.component';
import {PushpinService} from '../../services/pushpin/pushpin.service';
import {PushpinFilter} from '../../pushpin-filter';
import {Settings} from '../../settings.class';
import {combineLatest} from 'rxjs';
import {Pushpin} from '../../classes/pushpin.class';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../services/status/status.service';
import {MaterialService} from '../../services/material/material.service';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {PaintColorService} from '../../services/paintColor/paint-color.service';
import {PushpinImage} from '../../classes/pushpin-image.class';
import {PushpinPart} from '../../classes/pushpinpart.class';
import {CordovaService} from '../../cordova.service';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {PushpinSelectionComponent} from '../pushpin-selection/pushpin-selection.component';
import {PricesService} from '../../services/prices/prices.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {MaterialPrice} from '../../classes/materialprice.class';
import {DecorationPrice} from '../../classes/decorationprice.class';
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {MatDialog} from '@angular/material/dialog';
import {PushpinExportComponent} from '../pushpin-export/pushpin-export.component';
import {CodaltComponent} from '../../codalt.component';
import {Inspection} from '../../classes/inspection';
import {saveAs} from 'file-saver';
import {formatDate} from '@angular/common';

declare var cordova;

@Component({
    selector: 'app-pushpin-list',
    templateUrl: './pushpin-list.component.html',
    styleUrls: ['./pushpin-list.component.scss']
})
export class PushpinListComponent extends CodaltComponent implements OnInit {

    showPriceSelectionBar: boolean;

    @Input()
    filterComponent: PushpinFilterComponent;

    @Input()
    selectionComponent: PushpinSelectionComponent;

    @ViewChild('scrollviewport')
    scrollviewport: CdkVirtualScrollViewport;

    zipExporting = false;
    loading: boolean;
    showList = false;
    isCordova = false;
    isSmallDevice = false;

    pushpins: Pushpin[] = [];
    pushpinParts: PushpinPart[];
    searchedPushpinParts: PushpinPart[];
    imagesMap: object;
    mapCenter = null;
    mapZoom;
    customerArea: CustomerArea;
    inspection: Inspection;
    order = 'date';
    searchText = '';
    showName = false;
    showPrice = false;
    pushpinPartPricesMap;
    private keyValMap = {};
    private latestUpdatedAt = -1;
    private filter: PushpinFilter;
    private deviceHasInternet = true;
    private materialPrices: MaterialPrice[];
    private decorationPrices: DecorationPrice[];

    constructor(private pushpinService: PushpinService,
                private pricesService: PricesService,
                private activatedRoute: ActivatedRoute,
                private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService,
                private cordovaService: CordovaService,
                private confirmDialogService: ConfirmDialogService,
                private customerAreaService: CustomerAreaService,
                public dialog: MatDialog,
                private router: Router) {
        super();
        this.filter = Settings.pushpinFilter;
    }

    ngOnInit(): void {
        this.isSmallDevice = window.matchMedia('(max-width: 767px)').matches;
        this.isCordova = typeof cordova !== 'undefined';
        this.pushpinPartPricesMap = this.pricesService.pushpinPartPricesMap;
        if (this.AuthorisationService.hasFeature('listShowNames')) {
            this.showName = true;
        }
        this.showPrice = this.AuthorisationService.hasFeature('priceList');
        this.subscriptions.add(this.cordovaService.deviceHasInternet.subscribe((hasInternet) => {
            this.deviceHasInternet = hasInternet;
        }));
        this.subscriptions.add(this.filterComponent.filterEmitter.subscribe((filter) => {
            this.filter = filter;
            this.latestUpdatedAt = -1;
        }));

        this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
            if (params['zoom']) {
                this.mapZoom = +params['zoom'];
            }

            if (params['lat'] && params['lng']) {
                this.mapCenter = {
                    lat: params['lat'],
                    lng: params['lng']
                };
            }

            if (params['view'].indexOf('list') !== -1) {
                if (this.selectionComponent) {
                    this.selectionComponent.calculatePrices();
                }
                if (params['view'] === 'list') {
                    this.showList = true;
                }
            } else {
                this.showList = false;
            }
        }));
    }

    search() {
        const matchesPrice = this.searchText.match(/^([><]) ?([0-9]+)$/);
        if (this.searchText.match(/^[0-9]+$/)) {
            this.searchedPushpinParts = this.pushpinParts
                .filter(p => (p.pushpin.number + '' === this.searchText.trim()) || p.pushpin.reference + '' === this.searchText.trim());
        } else if (matchesPrice && this.showPrice) {
            if (matchesPrice[1] === '>') {
                this.searchedPushpinParts = this.pushpinParts.filter(p => this.pushpinPartPricesMap[p.id] > (+matchesPrice[2]));
            } else {
                this.searchedPushpinParts = this.pushpinParts.filter(p => this.pushpinPartPricesMap[p.id] < (+matchesPrice[2]));
            }
        } else if (this.searchText) {
            this.searchedPushpinParts = this.pushpinParts
                .filter(p =>
                    ((p.pushpin.street || '').toLowerCase() + '|' +
                        (p.decoration.name || '').toLowerCase() + '|' +
                        (p.pushpin.place || '').toLowerCase() + '|' +
                        (p.description || '').toLowerCase() + '|' +
                        (p.pushpin.description || '').toLowerCase() + '|' +
                        (p.pushpin.reference || '').toLowerCase() + '|' +
                        (p.customer_note || '').toLowerCase()
                    ).indexOf(this.searchText.toLowerCase().trim()) !== -1);
        } else {
            this.searchedPushpinParts = this.pushpinParts;
        }
        this.sortList(this.order);
    }

    zipExport() {
        this.zipExporting = true;
        this.customerAreaService.zip(this.customerArea.id, this.inspection?.id).subscribe(zip => {
            const date = formatDate(new Date(), 'dd-MMM-yyyy', 'nl').replace('.', '');
            saveAs(zip, `Afbeeldingen ${this.customerArea.name} ${date}.zip`);
            this.zipExporting = false;
        });
    }

    excelExport() {
        const dialogRef = this.dialog.open(PushpinExportComponent, {
            data: {
                customerArea: this.customerArea,
                inspection: this.inspection
            },
            panelClass: 'excel-export-dialog'
        });
    }

    orderChanged(event) {
        const sortingOn = event.value;

        this.sortList(sortingOn);
    }

    public setSelection(event, pushpinPart: PushpinPart) {
        event.preventDefault();
        event.stopPropagation();
        pushpinPart['checked'] = !pushpinPart['checked'];
        if (pushpinPart['checked']) {
            this.selectionComponent.addPushpinPart(pushpinPart);
        } else {
            this.selectionComponent.removePushpinPart(pushpinPart);
        }
    }

    public setCustomerArea(customerArea: CustomerArea, inspection: Inspection) {
        this.customerArea = customerArea;
        this.inspection = inspection;
        this.showSelectionBarDecider(inspection);
        this.subscriptions.add(this.filterComponent.filterEmitter.subscribe(filter => {
            if (filter.deleted) {
                this.showPriceSelectionBar = false;
            } else {
                this.showSelectionBarDecider(inspection);
            }
        }));
    }

    public toggleFilter() {
        this.filterComponent.toggleFilter();
    }

    public reloadPushpins(pushpins) {
        if (this.customerArea) {
            this.loading = true;
            this.pushpinParts = [];
            pushpins?.forEach((pushpin: Pushpin) => {
                if (pushpin.pushpin_parts) {
                    pushpin.pushpin_parts.forEach(pushpinPart => {
                        pushpinPart.decoration = this.keyValMap['decoration_id'][pushpinPart.decoration_id];
                        if (pushpinPart.decoration) {
                            pushpinPart.material = this.keyValMap['material_id'][pushpinPart.material_id];
                            pushpinPart.paint_color = this.keyValMap['paint_color_id'][pushpinPart.paint_color_id];
                            pushpinPart.status = this.keyValMap['status_id'][pushpinPart.status_id];
                            pushpinPart.images = [];
                            const image = new PushpinImage();
                            pushpinPart.images.push(image);
                            pushpinPart['pushpin'] = pushpin;
                            pushpinPart.updated_at = new Date(pushpinPart.updated_at);
                            if (pushpinPart.decoration.type === 'Vlak' || pushpinPart.decoration.type === 'Diversen vervangen' || !pushpinPart.decoration.specify_length) {
                                pushpinPart.surface = this.pricesService.calculateSurface(pushpinPart);
                            }

                            this.pushpinParts.push(pushpinPart);
                        }
                    });
                } else {
                    pushpin.pushpin_parts = [];
                }
            });
            this.pushpins = pushpins;
            this.pushpinService.getPushpinPartImagesMap(this.customerArea.id).then(imagesMap => {
                this.imagesMap = imagesMap;
                this.pushpins.forEach(pushpin => {
                    let pushpinImg = null;
                    pushpin.pushpin_parts.forEach(pushpinPart => {
                        if (typeof this.imagesMap[pushpinPart.id] !== 'undefined') {
                            pushpinImg = this.imagesMap[pushpinPart.id];
                            pushpinPart.images[0].image = this.imagesMap[pushpinPart.id];
                        } else if (pushpinImg) {
                            pushpinPart.images[0].image = pushpinImg;
                        } else {
                            pushpin.pushpin_parts.forEach(_pushpinPart => {
                                if (typeof this.imagesMap[_pushpinPart.id] !== 'undefined') {
                                    pushpinImg = this.imagesMap[_pushpinPart.id];
                                }
                            });
                            if (pushpinImg) {
                                pushpinPart.images[0].image = pushpinImg;
                            }
                        }
                    });
                });
            });


            this.setPushpinCustomerSelectionCheckoxes();
            if (this.showPrice && this.inspection) {
                this.pricesService.getItemsWithPrices([this.inspection.id]).subscribe(prices => {
                    this.materialPrices = prices['materialPrices'];
                    this.decorationPrices = prices['decorationPrices'];
                    this.pushpinParts.forEach(pushpinPart => {
                        this.pricesService.calculatePushpinPartPrice(pushpinPart, this.materialPrices, this.decorationPrices);
                    });
                    this.search();
                    this.sortList(this.order);
                });
            } else {
                this.search();
                this.sortList(this.order);
            }
        }
    }

    public initKeyValMapData(pushpins) {
        const status$ = this.statusService.getKeyValMap();
        const decorations$ = this.decorationsService.getKeyValMap();
        const materials$ = this.materialService.getMaterialsAsKeyValueMap(true);
        const paintColor$ = this.paintColorService.getKeyValMap();
        combineLatest([status$, decorations$, materials$, paintColor$])
            .subscribe(([status, decoration, material, paintColor]) => {
                this.keyValMap['status_id'] = status;
                this.keyValMap['decoration_id'] = decoration;
                this.keyValMap['material_id'] = material;
                this.keyValMap['paint_color_id'] = paintColor;
                this.reloadPushpins(pushpins);
            });
    }

    public openPushpin(pushpinId: number, pushpinPartId: number) {
        if (this.deviceHasInternet) {
            this.router.navigate([
                'pushpins/list-detail',
                pushpinId,
                this.mapZoom,
                this.mapCenter.lat,
                this.mapCenter.lng,
                {pushpinPartId: pushpinPartId, inspection: this.inspection?.id}
            ]);
        } else {
            this.confirmDialogService.confirm(
                'Geen Internet',
                'Het apparaat heeft momenteel geen internetverbinding, de punaise kan daarom niet worden geopend.',
                'Oké',
                null).then(() => {

            }, () => {

            });
        }
    }

    back() {
        this.customerAreaService.getList().then(customerAreas => {
            if (customerAreas.length < 2) {
                this.router.navigate(['/']);
            } else {
                this.router.navigate(['/customer-selection']);
            }
        });
    }

    private showSelectionBarDecider(inspection: Inspection) {
        this.showPriceSelectionBar = (
                (this.AuthorisationService.hasFeature('priceList') && inspection?.inspection_status_id > 2)
                || this.AuthorisationService.hasFeature('showPushpinSelectionBarAlways')
            )
            && window.matchMedia('(min-width: 1100px)').matches;
    }

    private sortList(sortingOn) {
        let pushpinParts = this.searchedPushpinParts;
        this.searchedPushpinParts = [];
        if (sortingOn === 'date') {
            pushpinParts = pushpinParts.sort((a, b) =>
                (new Date(a.updated_at)).getTime() - (new Date(b.updated_at)).getTime()
            );
        }
        if (sortingOn === '-date') {
            pushpinParts = pushpinParts.sort((a, b) =>
                (new Date(b.updated_at)).getTime() - (new Date(a.updated_at)).getTime()
            );
        }
        if (sortingOn === 'price') {
            pushpinParts = pushpinParts.sort((a, b) =>
                this.pushpinPartPricesMap[a.id] - this.pushpinPartPricesMap[b.id]
            );
        }
        if (sortingOn === '-price') {
            pushpinParts = pushpinParts.sort((a, b) =>
                this.pushpinPartPricesMap[b.id] - this.pushpinPartPricesMap[a.id]
            );
        }
        if (sortingOn === 'number') {
            pushpinParts = pushpinParts.sort((a, b) =>
                a.pushpin.number - b.pushpin.number
            );
        }
        if (sortingOn === '-number') {
            pushpinParts = pushpinParts.sort((a, b) =>
                b.pushpin.number - a.pushpin.number
            );
        }
        pushpinParts.forEach(pp => {
            this.searchedPushpinParts.push(pp);
        });
    }

    private setPushpinCustomerSelectionCheckoxes() {
        this.pushpinParts.forEach(pushpinParts => {
            pushpinParts['checked'] = this.pricesService.selectedPushpinParts.filter(_pushpinPart => {
                return _pushpinPart.id === pushpinParts.id;
            }).length > 0;
        });
    }

}
