import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ChecklistService} from '../../services/checklist.service';
import {Checklist} from '../../classes/checklist';
import {Subscription} from 'rxjs';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ChecklistQuestion} from '../../classes/checklist-question';
import {AnswerOption, ChecklistAnswer} from '../../classes/checklist-answer';
import {ChecklistCompleted} from '../../classes/checklist-completed';
import {ChecklistPoint, ChecklistPointType} from '../../classes/checklist-point';
import {FormArrayChecklistQuestions, FormGroupChecklistQuestion} from '../checklist-edit/checklist-edit.component';
import {Utils} from '../../utils.class';
import {Routenames} from '../../route-names.enum';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {LocationService} from '../../pushpin-detail/location-selector-dialog/location.service';
import {GpsService} from '../../gps.service';
import {LocalStorage} from '../../storage.class';
import {ChecklistAction} from '../../classes/checklist-action';
import {saveAs} from 'file-saver';

@Component({
    selector: 'app-checklist-fill-in',
    templateUrl: './checklist-fill-in.component.html',
    styleUrls: ['./checklist-fill-in.component.scss']
})
export class ChecklistFillInComponent extends CodaltComponent implements OnInit {

    downloadingPdf = false;
    saving = false;

    AnswerOption = AnswerOption;
    ChecklistPointType = ChecklistPointType;

    form: FormGroupChecklistCompleted;

    checklist: Checklist;

    completed: ChecklistCompleted;

    questionFormMap = new Map<ChecklistQuestion, {
        answer: FormControl,
        comment: FormControl
    }>();

    questions = new Map<number, ChecklistQuestion>();

    inspectorList: User[];
    foremanList: User[];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private locationService: LocationService,
                private gpsService: GpsService,
                private checklistService: ChecklistService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['completedId']) {
                this.checklistService.getAnswers(params['completedId']).subscribe(completed => {
                    this.completed = completed.data;
                    this.checklist = completed.data.checklist;
                    if (!this.completed.final) {
                        this.createForm(this.completed);
                    }
                });
            } else {
                this.completed = new ChecklistCompleted();
                this.completed.checklist_id = +params['checklistId'];
                this.checklistService.get(this.completed.checklist_id).subscribe(checklist => {
                    this.checklist = checklist.data;
                    this.createForm(this.completed);
                });
            }
        }));
    }

    pdf() {
        this.downloadingPdf = true;
        this.checklistService.getPdf(this.completed.id).subscribe(data => {
            saveAs(data, `Inspectielijst ${this.completed.id}.pdf`);
            this.downloadingPdf = false;
        });
    }

    createForm(completed: ChecklistCompleted) {
        this.checklist.questions.forEach(question => {
            this.questions.set(question.id, question);
            this.setAnswer(completed, question);
            question.sub_items.forEach(subQuestion => {
                this.questions.set(subQuestion.id, subQuestion);
                this.setAnswer(completed, subQuestion);
            });
        });
        const points = new FormArray(completed.points.map(point => {
            return this.createPointFormGroup(point);
        })) as FormArrayChecklistQuestions;
        const actions = new FormArray(completed.actions.map(action => {
            return this.createActionFormGroup(action);
        })) as FormArrayChecklistQuestions;
        const finalFc = new FormControl(completed.final);
        finalFc.valueChanges.subscribe(value => {
            if (value && !this.saving) {
                this.confirmDialog.confirm(
                    'Definitief maken?',
                    `Weet je zeker dat je de inspectielijst definitief wilt maken?
                    Er kunnen hierna geen aanpassingen meer gedaan worden.`,
                    'Ja, opslaan',
                    'Annuleren'
                ).then(() => {
                    this.save();
                }, () => {
                    this.form.controls.final.setValue(false);
                });
            }
        });
        this.form = new FormGroup({
            id: new FormControl(completed.id),
            checklist_id: new FormControl(completed.checklist_id),
            worknumber: new FormControl(completed.worknumber, Validators.required),
            description: new FormControl(completed.description, Validators.required),
            location: new FormControl(completed.location, Validators.required),
            lat: new FormControl(completed.lat),
            lng: new FormControl(completed.lng),
            foreman_id: new FormControl(completed.foreman_id, Validators.required),
            inspector_id: new FormControl(completed.inspector_id, Validators.required),
            final: finalFc,
            points: points,
            actions: actions
        }) as FormGroupChecklistCompleted;

        if (!this.form.value.location) {
            this.setLocation();
        }
        this.subscriptions.add(this.checklistService.foremanList().subscribe(list => {
            this.foremanList = list.data;
            if (!this.form.value.foreman_id) {
                const user = this.foremanList.find(u => u.id === LocalStorage.getUser().id);
                this.form.controls.foreman_id.setValue(user?.id);
            }
        }));

        this.subscriptions.add(this.checklistService.inspectorList().subscribe(list => {
            this.inspectorList = list.data;
            if (!this.form.value.inspector_id) {
                const user = this.inspectorList.find(u => u.id === LocalStorage.getUser().id);
                this.form.controls.inspector_id.setValue(user?.id);
            }
        }));

    }

    save() {
        this.saving = true;
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            const answers = [];
            this.questionFormMap.forEach((formGroup, question) => {
                const answer = new ChecklistAnswer();
                answer.question_id = question.id;
                answer.answer = formGroup.answer.value;
                answer.comment = formGroup.comment.value;
                answer.checklist_completed_id = this.completed.id;
                answers.push(answer);
            });
            const completedChecklist = new ChecklistCompleted();
            Object.assign(completedChecklist, this.form.value);
            completedChecklist.answers = answers;
            this.checklistService.saveAnswers(completedChecklist).subscribe(data => {
                this.completed = data.data;
                this.router.navigate([Routenames.checklistFillIn]);
                this.saving = false;
            }, () => {
                this.saving = false;
            });
        } else {
            this.confirmDialog.confirm(
                'Opslaan',
                `Niet alle velden zijn correct ingevuld. Controleer de rood gemarkeerde velden.`,
                'oké',
                null
            ).then(() => {

            }, () => {

            });
            this.form.controls.final.setValue(false);
            this.saving = false;
        }
    }

    addPoint() {
        this.form.controls.points.push(this.createPointFormGroup(new ChecklistPoint()));
    }

    addAction(action = new ChecklistAction()) {
        this.form.controls.actions.push(this.createActionFormGroup(action));
    }

    deletePoint(index: number) {
        this.confirmDialog.confirm(
            'Punt verwijderen?',
            `Weet u zeker dat u dit punt wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.form.controls.points.removeAt(index);
        }, () => {

        });

    }

    deleteAction(index: number) {
        this.confirmDialog.confirm(
            'Actie verwijderen?',
            `Weet u zeker dat u deze actie wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.form.controls.actions.removeAt(index);
        }, () => {

        });
    }

    private createPointFormGroup(point: ChecklistPoint) {
        return new FormGroup({
            id: new FormControl(point.id),
            answer: new FormControl(point.answer, Validators.required),
            comment: new FormControl(point.comment, Validators.required)
        }) as FormGroupChecklistQuestion;
    }

    private createActionFormGroup(action: ChecklistAction) {
        return new FormGroup({
            id: new FormControl(action.id),
            deadline: new FormControl(action.deadline),
            action: new FormControl(action.action, Validators.required),
            user: new FormControl(action.user),
            question_id: new FormControl(action.question_id, Validators.required)
        }) as FormGroupChecklistQuestion;
    }

    private setLocation() {
        this.gpsService.getCurrentPosition().then((geo) => {
            this.form.controls.lat.setValue(geo['latitude']);
            this.form.controls.lng.setValue(geo['longitude']);
            this.locationService.getLocations(geo['latitude'], geo['longitude']).subscribe(response => {
                if (response && response['resourceSets'][0]['resources']) {
                    const addresses = [];
                    const uniqueCheck = [];
                    response['resourceSets'][0]['resources'].forEach(addr => {
                        const flatAddress = addr.address.addressLine + addr.address.postalCode + addr.address.adminDistrict2;
                        if (uniqueCheck.indexOf(flatAddress) === -1) {
                            uniqueCheck.push(flatAddress);
                            addresses.push(addr);
                        }
                    });
                    if (addresses.length > 0) {
                        const address = addresses[0].address;
                        let number: any = '0';
                        let street: any = '-';


                        if (typeof address.addressLine !== 'undefined') {
                            number = address.addressLine.split(' ').reverse()[0];

                            if (isNaN(number[0])) {
                                street = address.addressLine;
                                number = '-';
                            } else {
                                street = address.addressLine.split(' ');

                                delete street[street.indexOf(number)];
                                street = street.join(' ');
                            }
                        }
                        this.form.controls.location.setValue(`${street} ${number}, ${address.postalCode} ${address.locality}`);
                    }
                }

            }, e => {

            });
        });
    }

    private setAnswer(completed: ChecklistCompleted, question: ChecklistQuestion) {
        const answer = completed.answers.find(a => a.question_id === question.id);
        const answerFormControls = {
            answer: new FormControl(answer?.answer),
            comment: new FormControl(answer?.comment)
        };
        this.questionFormMap.set(question, answerFormControls);
        answerFormControls.answer.valueChanges.subscribe(value => {
            if (value === AnswerOption.Nee) {
                if (!this.form.value.actions.find(a => a.question_id === question.id)) {
                    const action = new ChecklistAction();
                    action.question_id = question.id;
                    this.addAction(action);
                }
            }
        });
    }

}

export type FormGroupChecklistCompleted = FormGroup & {
    controls: {
        id: FormControl & { value: number },
        checklist_id: FormControl & { value: number },
        worknumber: FormControl & { value: string },
        description: FormControl & { value: string },
        location: FormControl & { value: string },
        lat: FormControl & { value: number },
        lng: FormControl & { value: number },
        foreman_id: FormControl & { value: number },
        inspector_id: FormControl & { value: number },
        final: FormControl & { value: boolean },
        points: FormArray & { controls: FormArrayPoints },
        actions: FormArray & { controls: FormArrayActions }
    }
};

export type FormArrayPoints = FormArray & { controls: FormGroupPoint[] };

export type FormGroupPoint = FormGroup & {
    controls: {
        id: FormControl & { value: number },
        answer: FormControl & { value: ChecklistPointType },
        comment: FormControl & { value: string }
    }
};

export type FormArrayActions = FormArray & { controls: FormGroupAction[] };

export type FormGroupAction = FormGroup & {
    controls: {
        id: FormControl & { value: number },
        question_id: FormControl & { value: number },
        user: FormControl & { value: string },
        action: FormControl & { value: string }
        deadline: FormControl & { value: Date }
    }
};
