<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed"><span class="d-none d-md-inline">Melding </span> {{incident?.id ? incident.id : 'aanmaken'}}</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar meldingen"
                 matTooltipPosition="below"
                 routerLink="/incident">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <div (click)="openNavi()" class="fab small"
                 matTooltip="Open navigatie"
                 matTooltipPosition="below">
                <i class="fas fa-map-marked"></i>
            </div>
        </div>
    </div>
    <div *ngIf="form">
        <form [formGroup]="form">
            <div class="row" *ngIf="incident.id">
                <div class="col-12 pb-2">
                    <p>
                        <b>Melding nummer:</b> {{incident?.id}}
                    </p>
                    <p>
                        <b>Aangemaakt door:</b> {{incident?.user?.name}}
                    </p>
                    <p>
                        <b>Aangemaakt op:</b> {{incident.created_at | date : 'EEE d MMMM yyyy, H:mm'}}
                    </p>
                </div>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-9">
                    <mat-label>Straat</mat-label>
                    <input formControlName="street" id="street" matInput name="street" placeholder="Straatnaam"
                           type="text">
                    <mat-error>Dit veld mag maximaal 255 tekens bevatten</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Huisnummer</mat-label>
                    <input formControlName="housenumber" id="housenumber" matInput name="housenumber"
                           placeholder="Bijv. 12"
                           type="text">
                    <mat-error>Dit veld mag maximaal 20 tekens bevatten</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Postcode</mat-label>
                    <input formControlName="zipcode" id="zipcode" matInput name="zipcode"
                           placeholder="Bijv. 1234 AB"
                           type="text">
                    <mat-error>Dit veld mag maximaal 7 tekens bevatten</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-md-9">
                    <mat-label>Plaats</mat-label>
                    <input formControlName="place" id="place" matInput name="place" placeholder="Plaatsnaam"
                           type="text">
                    <mat-error>Dit veld mag maximaal 255 tekens bevatten</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-12">
                    <mat-label>Beschrijving</mat-label>
                    <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize formControlName="description"
                              id="description" matInput
                              name="description" placeholder="Voeg hier eventuele notities en opmerkingen toe"
                              type="text"></textarea>
                    <mat-error>Dit veld is verplicht</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field
                    appearance="fill"
                    class="col-6">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status"
                                placeholder="Status">
                        <mat-option *ngFor="let status of statusList"
                                    [value]="status">
                            {{status}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field
                    appearance="fill"
                    class="col-6">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type"
                                placeholder="Type">
                        <mat-option *ngFor="let type of typeList"
                                    [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
        <div class="files">
            <div *ngFor="let file of incident.files" class="file">
                <div (click)="openFile(file, incident.files)"
                     [class.is-img]="['jpg', 'jpeg', 'png'].indexOf(file.ext) !== -1"
                     [style.background-image]="(file.file.substr(0,4) !== 'http' ? url : '') + file.file+'?access_token='+token|safeBgUrl">
                    <div class="icon"><i class="fas fa-file fa-3x"></i></div>
                    <div class="filename">
                        {{file.name}}
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="incident.id" class="comments">
            <div class="title">
                <h1 class="h3 condensed">Reacties</h1>
            </div>
            <app-incident-comment (deleted)="deleteComment($event)"
                                  (openFile)="openFile($event, comment.files)"
                                  (placedComment)="addEmptyComment()"
                                  (uploadFiles)="commentUploadFiles($event, comment)"
                                  *ngFor="let comment of incident.comments" [incidentComment]="comment">
            </app-incident-comment>
        </div>
    </div>
    <div class="controls-bottom">
        <label *ngIf="!isCordova"
               matTooltip="Upload bestand"
               matTooltipPosition="before">
            <input
                (change)="uploadFile($event)" hidden multiple type="file">
            <div class="fab big">
                <i *ngIf="!uploading" class="fas fa-upload"></i>
                <i *ngIf="uploading" class="fas fa-circle-notch fa-spin"></i>
            </div>
        </label>
        <div (click)="takePicture()"
             *ngIf="isCordova" class="fab big"
             id="btn-AddImage"
             matTooltip="Camera"
             matTooltipPosition="before">
            <i class="fas fa-camera"></i>
        </div>
        <div (click)="save(true)" [class.disabled]="saving" class="fab big primary" id="btn-Save"
             matTooltip="Opslaan"
             matTooltipPosition="before">
            <i *ngIf="!saving" class="fas fa-save"></i>
            <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
</div>

