import {Component, Inject, OnInit} from '@angular/core';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TailChargeService} from '../../../services/tail-charge.service';
import {Inspection} from '../../../classes/inspection';
import {TailCharge} from '../../../classes/tail-charge';

export class TailChargeDialogData {
    tailCharge: TailCharge;
    inspection: Inspection;
    totalPrice: number;
}

@Component({
    selector: 'app-tail-charge-dialog',
    templateUrl: './tail-charge-dialog.component.html',
    styleUrls: ['./tail-charge-dialog.component.scss']
})
export class TailChargeDialogComponent implements OnInit {

    public tailCharge: TailCharge;

    percentage;

    constructor(private tailChargeService: TailChargeService,
                private confirmDialogService: ConfirmDialogService,
                public dialogRef: MatDialogRef<TailChargeDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public tailChargeDialogData: TailChargeDialogData) {
    }

    ngOnInit(): void {
        this.tailCharge = this.tailChargeDialogData.tailCharge;
        this.percentage = this.tailCharge.number * 100;
        if (!this.tailCharge) {
            this.tailCharge = new TailCharge();
        }
    }

    save() {
        this.tailCharge.inspection_id = this.tailChargeDialogData.inspection.id;
        this.tailCharge.number = this.percentage / 100;
        this.tailChargeService.save(this.tailCharge).subscribe(tailCharge => {
            this.tailCharge.id = tailCharge.data.id;
            this.dialogRef.close();
        });
    }

    delete() {
        this.confirmDialogService.confirm(
            'Verwijderen',
            'Weet u zeker dat u deze staartkosten wilt verwijderen?',
            'Ja',
            'Nee').then(
            () => {
                if (this.tailCharge.id) {
                    this.tailChargeService.delete(this.tailCharge.id).subscribe(result => {
                        this.dialogRef.close(true);
                    }, () => {

                    });
                } else {
                    this.dialogRef.close(true);
                }
            }, () => {

            }
        );
    }

    close() {
        this.dialogRef.close((!this.tailCharge.id));
    }

}
