import {CustomerArea} from './customerarea.class';
import {User} from './user.class';
import {PushpinPart} from './pushpinpart.class';

export class Pushpin {
    id: number;
    customer_area_id: number;
    lengthmark: boolean;
    user_id: number;
    number: number;
    lat: number;
    lng: number;
    end_lat: number;
    end_lng: number;
    mid_lng: number;
    mid_lat: number;
    description: string;
    reference: string;
    street: string;
    zipcode: string;
    housenumber: string;
    place: string;
    draft: boolean;
    created_at: Date;
    updated_at: Date;
    snappedpoints: any;
    customer_area: CustomerArea;
    user: User;
    pushpin_parts: PushpinPart[];

    // optional for map view
    material_surfaces?: {
        id, material, surface
    }[];
}
