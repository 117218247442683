import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Router} from '@angular/router';
import {Routenames} from '../route-names.enum';
import {MoldMaterial} from '../mold-material';
import {MoldService} from '../services/mold.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-mold-management',
    templateUrl: './mold-management.component.html',
    styleUrls: ['./mold-management.component.scss']
})
export class MoldManagementComponent extends CodaltComponent implements OnInit {

    materials: MoldMaterial[];
    currentSort = null;
    desc = false;

    constructor(private moldService: MoldService,
                private confirmDialog: ConfirmDialogService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.moldService.listMaterial().subscribe(materials => {
            this.materials = materials.data;
        }));
    }

    edit(material: MoldMaterial) {
        this.router.navigate([Routenames.moldMaterial, material.id]);
    }

    sortList(sortingOn) {
        let decorations = this.materials;
        this.materials = [];

        if (sortingOn === this.currentSort) {
            decorations = decorations.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = false;
            decorations = decorations.sort((a, b) => {
                if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                    return -1;
                }
                if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        }

        this.materials = decorations;

        this.currentSort = sortingOn;
    }

    delete(event, material: MoldMaterial) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Materiaal verwijderen',
            `Weet u zeker dat u materiaal ${material.name} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.moldService.deleteMaterial(material.id).subscribe(() => {
                const index = this.materials.indexOf(material);
                this.materials.splice(index, 1);
            });
        }, () => {

        });
    }

}
