import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeclarationService} from '../../services/declaration.service';
import {SortingComponent} from '../../sorting.component';
import {DeclarationReport} from '../../declarationReport';
import {DateAdapter} from '@angular/material/core';
import {DateFullMonthNoDayAdapter} from '../../date-adapters/date-full-month-no-day-adapter';
import {Subscription} from 'rxjs';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {formatCurrency, formatNumber} from '@angular/common';

@Component({
    selector: 'app-declaration-report',
    templateUrl: './declaration-report.component.html',
    styleUrls: ['./declaration-report.component.scss'],
    providers: [{
        provide: DateAdapter, useClass: DateFullMonthNoDayAdapter
    }]
})
export class DeclarationReportComponent extends SortingComponent<DeclarationReport> implements OnInit, OnDestroy {

    date = new Date();

    total: number;

    readonly statusGoedgekeurd = 2;

    constructor(private confirmDialogService: ConfirmDialogService,
                private declarationService: DeclarationService) {
        super();
    }

    ngOnInit(): void {
        this.getReport();
    }


    private getReport() {
        this.subscriptions.add(this.declarationService.report(this.date).subscribe(report => {
            this.list = report.data;
            this.total = this.list.map(d => +d.amount || 0).reduce((p, c) => p + c, 0);
        }));
    }

    statusPayed(item: DeclarationReport) {
        this.confirmDialogService.confirm('Vergoeden',
            `Weet je zeker dat je de status van al deze declaraties met een totaal bedrag van
            ${formatCurrency(item.amount, 'nl', 'EUR')} wilt omzetten naar 'Vergoed'?`,
            'Omzetten naar Vergoed',
            'Nee').then(() => {
            this.subscriptions.add(this.declarationService.pay(item.declarations).subscribe(() => {
                this.getReport();
            }));
        }, () => {

        });
    }

    copyClip(amount) {
        return formatNumber(amount, 'nl', '0.2-2').replace('.', '');
    }

    chosenMonthHandler(date, datepicker) {
        this.date = date;
        this.getReport();
        datepicker.close();
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
