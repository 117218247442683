import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ChecklistService} from '../../services/checklist.service';
import {Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {ChecklistCompleted} from '../../classes/checklist-completed';
import {Routenames} from '../../route-names.enum';
import {ChecklistChooseDialogComponent} from './checklist-choose-dialog/checklist-choose-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SortingComponent} from '../../sorting.component';

@Component({
    selector: 'app-checklist-overview',
    templateUrl: './checklist-overview.component.html',
    styleUrls: ['./checklist-overview.component.scss']
})
export class ChecklistOverviewComponent extends SortingComponent<ChecklistCompleted> implements OnInit {

    showAll = false;
    currentSort = 'created_at';

    constructor(private checklistService: ChecklistService,
                private router: Router,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        this.getAnswers();
    }

    getAnswers() {
        this.subscriptions.add(this.checklistService.listAnswers(this.showAll).subscribe(lists => {
            this.list = lists.data;
        }));
    }

    chooseChecklist() {
        this.dialog.open(ChecklistChooseDialogComponent, {
            width: '500px',
            maxHeight: '600px',
            panelClass: 'part-dialog',
            data: {}
        });
    }

    edit(checklist) {
        this.router.navigate([Routenames.checklistFillIn, checklist.checklist_id, {completedId: checklist.id}]);
    }

    delete(event, checklist: ChecklistCompleted) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Inspectielijst intrekken',
            `Weet u zeker dat u de ingevulde inspectielijst '${checklist?.checklist?.name}' wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.checklistService.deleteAnsweredChecklist(checklist.id).subscribe(() => {
                const index = this.list.indexOf(checklist);
                this.list.splice(index, 1);
            });
        }, () => {

        });
    }

    adTypeSortFunction = (item: ChecklistCompleted) => {
        return item?.checklist?.name;
    };

    inspectorSortFunction = (item: ChecklistCompleted) => {
        return item?.inspector?.name;
    };

    foremanSortFunction = (item: ChecklistCompleted) => {
        return item?.foreman?.name;
    };
}
