import {Component, OnInit} from '@angular/core';
import {LocalStorage} from '../storage.class';
import {Router} from '@angular/router';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit() {
        LocalStorage.logoutUser();
        this.router.navigate(['/login']);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

}
