<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Mal materiaal {{material?.id ? 'aanpassen' : 'toevoegen'}}</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/{{Routenames.moldMaterial}}"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <ng-container *ngIf="form">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <form [formGroup]="form">
                    <mat-form-field class="w-100" id="name">
                        <input matInput type="text" [formControl]="fc.name"
                               placeholder="Naam">
                        <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 col-12">
                <div class="d-flex">
                    <button mat-button routerLink="/{{Routenames.moldMaterial}}">Terug</button>
                    <div class="spacer"></div>
                    <button mat-raised-button color="primary" (click)="save()" [disabled]="form.invalid" id="btn-save">
                        Opslaan
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
