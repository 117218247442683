<app-vvr-icon [dark]="true" [full]="false" [overlay]="true" [shadow]="true"></app-vvr-icon>
<div class="map-container">
    <div class="active-filters">
        <div *ngIf="filterComponent.filter.deleted">
            Verwijderde punten
            <div (click)="filterComponent.resetFilter('deleted')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.filterTypeText">
            {{filterComponent.filter.filterTypeText}}
            <div (click)="filterComponent.resetType()" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.filterMaterialText">
            {{filterComponent.filter.filterMaterialText}}
            <div (click)="filterComponent.resetMaterial()" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.filterPaintColorText">
            {{filterComponent.filter.filterPaintColorText}}
            <div (click)="filterComponent.resetPaintColor()" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.filterCrowText">
            {{filterComponent.filter.filterCrowText}}
            <div (click)="filterComponent.resetCrow()" class="close"></div>
        </div>
        <ng-container *ngIf="selectionComponent && !selectionComponent.selectionVisible">
            <div *ngIf="filterComponent.filter.enabledStatusText || filterComponent.filter.disabledStatusText">
                Status: {{filterComponent.filter.enabledStatusText}}
                <ng-container
                    *ngIf="filterComponent.filter.enabledStatusText && filterComponent.filter.disabledStatusText">
                    ,
                </ng-container>
                <del>{{filterComponent.filter.disabledStatusText}}</del>
                <div (click)="filterComponent.resetStatus()" class="close"></div>
            </div>
        </ng-container>
        <div *ngIf="filterComponent.filter.with_comment">
            Met opmerking
            <div (click)="filterComponent.resetFilter('with_comment')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.priority_figuration">
            Voorrang figuraties
            <div (click)="filterComponent.resetFilter('priority_figuration')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.always_check">
            Altijd controleren
            <div (click)="filterComponent.resetFilter('always_check')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.primer">
            Primer
            <div (click)="filterComponent.resetFilter('primer')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.blast">
            Stralen
            <div (click)="filterComponent.resetFilter('blast')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.stake_out">
            Uitzetten
            <div (click)="filterComponent.resetFilter('stake_out')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.turbo_burn">
            Turbo branden
            <div (click)="filterComponent.resetFilter('turbo_burn')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.manual_burn">
            Handmatig branden
            <div (click)="filterComponent.resetFilter('manual_burn')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.night">
            Nacht
            <div (click)="filterComponent.resetFilter('night')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.exclusive_material">
            Zonder materiaal
            <div (click)="filterComponent.resetFilter('exclusive_material')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.overgrown">
            Overgroeide kanten
            <div (click)="filterComponent.resetFilter('overgrown')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.traffic_controllers">
            Verkeersregelaars
            <div (click)="filterComponent.resetFilter('traffic_controllers')" class="close"></div>
        </div>
        <div *ngIf="filterComponent.filter.no_traffic_controllers">
            Geen verkeersregelaars
            <div (click)="filterComponent.resetFilter('no_traffic_controllers')" class="close"></div>
        </div>
    </div>
    <ng-container *ngIf="showPriceSelectionBar && selectionComponent">
        <div [class.disable]="selectionComponent.selectionVisible"
             [class.opened]="selectionComponent.selectionVisible"
             [class.pl-0]="pushpinMoveComponent?.visible"
             class="controls-top left">
            <div (click)="selectionComponent.toggleSelection()"
                 *ngIf="!manualAreasComponent.manualAreasMode && !pushpinMoveComponent?.visible"
                 [class.active]="selectionComponent.selectionVisible"
                 class="fab small"
                 id="btn-selection-open"
                 matTooltip="Prijs en statusbalk"
                 matTooltipPosition="after">
                <i *ngIf="selectionComponent.selectionVisible" class="fas fa-times"></i>
                <i *ngIf="!selectionComponent.selectionVisible" class="fas fa-shopping-cart"></i>
            </div>
            <div class="fab small" (click)="pushpinMoveComponent.toggle()"
                 *ngIf="!manualAreasComponent.manualAreasMode && !selectionComponent.selectionVisible && AuthorisationService.hasFeature('inspectionSave')"
                 [class.active]="pushpinMoveComponent?.visible"
                 id="btn-move-inspection"
                 matTooltip="Verplaatsen of kopiëren van Schouwronde"
                 matTooltipPosition="after">
                <i class="fas fa-dolly" [class.fa-times]="pushpinMoveComponent?.visible"></i>
            </div>
        </div>
    </ng-container>
    <div (click)="chooseInspection.emit()" *ngIf="!showPriceSelectionBar" [class.map-style-road]="mapStyleRoad" class="controls-top left statustext">
        Status: {{inspection?.inspection_status?.name}}
    </div>

    <div (click)="toggleFilter()" *ngIf="filterComponent.filterVisible" class="filter-overlay"></div>
    <div [class.disable]="filterComponent.filterVisible" class="controls-top">
        <div (click)="toggleFilter()" *ngIf="!manualAreasComponent.manualAreasMode"
             [class.active]="filterComponent.filterVisible"
             [matBadge]="filterComponent.filter.filterCount" class="fab small"
             id="btn-filters"
             matBadgePosition="above before"
             matTooltip="Filters"
             matTooltipPosition="before">
            <i *ngIf="filterComponent.filterVisible" class="fas fa-times"></i>
            <i *ngIf="!filterComponent.filterVisible" class="fas fa-filter"></i>
        </div>
        <div (click)="manualAreasComponent.closeManualAreasDialog()" *ngIf="manualAreasComponent.manualAreasMode"
             class="fab small active"
             id="btn-manualAreas-exit"
             matTooltip="Sluit gemarkeerde gebieden en straten"
             matTooltipPosition="before">
            <i class="fas fa-times"></i>
        </div>
        <div (click)="back()" class="fab small" id="btn-Exit"
             matTooltip="Terug naar overzicht"
             matTooltipPosition="before">
            <i class="fas fa-sign-out-alt"></i>
        </div>
        <div id="btn-searchLocation">
            <div (click)="openAddressSearch($event)" class="fab small"
                 matTooltip="Zoeken op kaart"
                 matTooltipPosition="before">
                <i class="fas fa-search"></i>
            </div>
            <div [class.visible]="isSearching" class="searchbar">
                <mat-form-field>
                    <mat-label>Zoek een adres</mat-label>
                    <input #searchInput="matInput"
                           [formControl]="addressAutocomplete"
                           [matAutocomplete]="auto"
                           matInput
                           type="text">
                    <mat-autocomplete #auto="matAutocomplete"
                                      (optionSelected)="goToAddress($event)"
                                      [displayWith]="showAddress"
                                      autoActiveFirstOption
                                      class="address-list">
                        <mat-option *ngFor="let item of addressSearch | async" [value]="item">
                            {{item.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button mat-icon-button
                        (click)="clearHecto()"
                        matTooltip="Hectometer markeringen op kaart wissen"
                        matTooltipPosition="before">
                    <mat-icon class="fas fa-eraser"></mat-icon>
                </button>
            </div>
        </div>

        <div (click)="manualAreasComponent.openManualAreasDialog()"
             *ngIf="!manualAreasComponent?.manualAreasMode && !filterComponent?.filterVisible && AuthorisationService?.hasFeature('manualAreasSave')"
             class="fab small d-none d-md-block"
             id="btn-manualAreas"
             matTooltip="Gemarkeerde gebieden en straten"
             matTooltipPosition="before">
            <i class="fas fa-draw-polygon"></i>
        </div>
        <div (click)="toggleMapStyle()" class="fab small" id="btn-toggleMapStyle"
             matTooltip="Wissel kaart/satelliet"
             matTooltipPosition="before">
            <i class="fas fa-layer-group"></i>
        </div>
        <div (click)="centerTown()" class="fab small" id="btn-centerTown"
             matTooltip="Centreer op klantgebied"
             matTooltipPosition="before">
            <i class="area-icon"></i>
        </div>
        <div (click)="centerOnGps()" class="fab small" id="btn-currentLocation"
             matTooltip="Centreer op GPS locatie"
             matTooltipPosition="before">
            <i *ngIf="map.followLocation" class="fas fa-location-arrow"></i>
            <i *ngIf="!map.followLocation" class="fa-light fa-location-arrow"></i>
        </div>
    </div>
    <div [class.disable]="filterComponent?.filterVisible" class="controls-bottom">
        <div *ngIf="!manualAreasComponent?.manualAreasMode"
             [routerLink]="['/pushpins/list/', customerArea?.id, mapZoom, mapCenter?.lat, mapCenter?.lng, {inspection: inspection?.id}]"
             class="fab big"
             id="btn-Listview"
             matTooltip="Lijstweergave"
             matTooltipPosition="before">
            <i class="fas fa-list-ul"></i>
        </div>
        <div (click)="deleteDraftPushpin()"
             *ngIf="lengthMarkDraft?.draft"
             class="fab big"
             matTooltip="{{lengthMarkDraft?.end_lat ? 'Annuleer correctie van deze lengtemarkering' : 'Verwijder deze lengtemarkering'}}"
             matTooltipPosition="before">
            <i class="fas fa-trash" *ngIf="!lengthMarkDraft?.end_lat"></i>
            <i class="fa-solid fa-rotate-left" *ngIf="lengthMarkDraft?.end_lat"></i>
        </div>
        <div (click)="navigateDraftPushpin()" *ngIf="lengthMarkDraft" [class.disabled]="!validLength"
             class="fab big secondary" id="btn-finalizePin"
             matTooltip="Item toevoegen"
             matTooltipPosition="before">
            <i class="fas fa-check"></i>
        </div>
        <div (click)="navigateToPushpin()"
             *ngIf="!manualAreasComponent?.manualAreasMode && showAddPinButton && AuthorisationService.hasFeature('pushpinSave')"
             [class.disabled-events]="!inspection || inspection?.lock"
             class="fab big primary"
             id="btn-addPin"
             matTooltip="Item toevoegen"
             matTooltipPosition="before">
            <i class="fas fa-plus"></i>
        </div>
        <div (click)="manualAreasComponent.setPoint()"
             *ngIf="manualAreasComponent.manualAreasMode && manualAreasComponent.manualAreaDrawing && isCordova"
             class="fab big primary"
             id="btn-addMan"
             matTooltip="Punt toevoegen"
             matTooltipPosition="before">
            <i class="fas fa-check"></i>
        </div>
    </div>
    <div [class.roadstyle]="mapStyleRoad" class="crosshair"></div>
    <app-angular-bing-maps #map></app-angular-bing-maps>
</div>
<div *ngIf="selectedPosition && selectedPushpins.length" [style.bottom]="selectedPosition.bottom"
     [style.left]="selectedPosition.left"
     [style.max-height]="selectedPosition.maxHeight"
     [style.right]="selectedPosition.right"
     [style.top]="selectedPosition.top"
     class="pushpin-preview">
    <div class="pin-wrapper">
        <div class="dialog-close">
            <div (click)="selectedPushpins = []" class="fab small active" id="btn-cancel"
                 matTooltip="Sluiten"
                 matTooltipPosition="after">
                <i class="fas fa-times"></i>
            </div>
        </div>
        <div *ngFor="let pushpin of selectedPushpins" class="pin">
            <h1 (click)="openPushpin(pushpin)" class="h4 condensed">Punaise {{pushpin.number}}</h1>
            <div class="materials">
                <div *ngFor="let mat of pushpin.material_surfaces" class="material-surface">
                    {{mat.material}}: {{mat.surface | number:'1.0-0'}} m<sup>{{pushpin.lengthmark ? '1' : '2'}}</sup>
                </div>
            </div>
            <div (click)="openPushpin(pushpin, part.id)" *ngFor="let part of pushpin.pushpin_parts" class="part">
                    <span class="material">
                        {{part.material.name}}
                        {{part.primer ? ' + primer' : ''}}
                    </span>
                <b>
                    {{part.decoration?.decoration_group}}
                </b><br>
                <div [style.color]="'#' + part?.status?.color" class="status">
                    {{part.status?.name}}
                </div>
                <ng-container *ngIf="part.decoration.specify_length">
                    {{part.length | number:'1.0-2'}}m<sup>1</sup> {{part.decoration?.name}}
                </ng-container>
                <ng-container *ngIf="!part.decoration?.specify_length">
                    {{part.amount}} * {{part.decoration?.name}}
                </ng-container>
                <div *ngIf="part.status_id === 9">
                    Uitgevoerd op {{part['executedDate'] | date  : 'd MMM yyyy, H:mm'}}
                </div>
            </div>
        </div>
    </div>
</div>
