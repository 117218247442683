import {Pipe, PipeTransform} from '@angular/core';
import {AuthorisationService} from '../services/auth/authorisation.service';
import {User} from '../classes/user.class';

@Pipe({
    name: 'hasFeature'
})
export class HasFeaturePipe implements PipeTransform {

    transform(value: string | string[], obj?: User): unknown {
        if (typeof value === 'object') {
            return AuthorisationService.hasAnyFeature(value, obj);
        } else {
            return AuthorisationService.hasFeature(value, obj);
        }
    }
}
