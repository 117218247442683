import {User} from './classes/user.class';
import {DeclarationType} from './declaration-type';
import {DeclarationStatusLog} from './declaration-status-log';
import {DeclarationStatus} from './declaration-status';
import {DeclarationDistance} from './declaration-distance';

export class Declaration {
    id: number;
    date: Date;
    type_id: number;
    type: DeclarationType;
    status_id = 1;
    status: DeclarationStatus;
    status_log: DeclarationStatusLog[];
    declaration_distances: DeclarationDistance[];
    user_id: number;
    user: User;
    manager_id: number;
    manager: User;
    images: any[];
    amount: number;
    comment: string;
    reason: string;
    created_at: Date;
}
