import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PushpinService} from '../../services/pushpin/pushpin.service';
import {Pushpin} from '../../classes/pushpin.class';
import {LocationService} from '../location-selector-dialog/location.service';
import {CodaltComponent} from "../../codalt.component";

@Component({
    selector: 'app-create-pushpin-dialog',
    templateUrl: './create-pushpin-dialog.component.html',
    styleUrls: ['./create-pushpin-dialog.component.scss']
})
export class CreatePushpinDialogComponent extends CodaltComponent implements OnInit {

    public checking = true;
    public locationSelected = false;
    public typeSelected = false;

    public addresses;

    public showExtraCostsButton = false;

    constructor(private locationService: LocationService,
                private pushpinService: PushpinService,
                private elRef: ElementRef,
                public dialogRef: MatDialogRef<CreatePushpinDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public pushpin: Pushpin) {
        super();
        this.showExtraCostsButton = window.matchMedia('(min-width: 1100px)').matches;
    }

    ngOnInit() {
        this.pushpinService.checkNoOpenLengthmark(this.pushpin.customer_area_id).then(maycreate => {
            this.checking = false;
            if (!maycreate) {
                this.selectType(false);
            }
        });
        this.locationService.getLocations(this.pushpin.lat, this.pushpin.lng).subscribe(response => {
            if (response && response['resourceSets'][0]['resources']) {
                this.addresses = [];
                const uniqueCheck = [];
                response['resourceSets'][0]['resources'].forEach(addr => {
                    const flatAdress = addr.address.addressLine + addr.address.postalCode + addr.address.adminDistrict2;
                    if (uniqueCheck.indexOf(flatAdress) === -1) {
                        uniqueCheck.push(flatAdress);
                        this.addresses.push(addr);
                    }
                });
                if (this.addresses.length === 1) {
                    this.select(this.addresses[0]);
                    this.locationSelected = true;
                } else if (this.addresses.length < 1) {
                    this.locationSelected = true;
                }
            } else {
                this.locationSelected = true;
            }

            if (this.typeSelected && this.locationSelected) {
                this.createPushpin();
            }
        });
    }

    otherType(type) {
        this.dialogRef.close(type);
    }

    selectType(lengthmark) {
        this.typeSelected = true;
        this.pushpin.lengthmark = lengthmark;
        if (this.locationSelected) {
            this.createPushpin();
        }
    }

    select(address) {
        this.setLocation(address);
        this.locationSelected = true;
        if (this.typeSelected) {
            this.createPushpin();
        }
    }

    createPushpin() {
        if (this.pushpin.lengthmark) {
            this.pushpin.draft = true;
        }
        this.pushpinService.savePushpin(this.pushpin).then(pushpin => {
            this.dialogRef.close(pushpin);
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    setLocation(addressData: any) {
        const address = addressData.address;
        let number: any = '0';
        let street: any = '-';


        if (typeof address.addressLine !== 'undefined') {
            number = address.addressLine.split(' ').reverse()[0];

            if (isNaN(number[0])) {
                street = address.addressLine;
                number = '-';
            } else {
                street = address.addressLine.split(' ');

                delete street[street.indexOf(number)];
                street = street.join(' ');
            }
        }

        this.pushpin.zipcode = address.postalCode;
        this.pushpin.street = street;
        this.pushpin.housenumber = number;
        this.pushpin.place = address.locality;

    }

}
