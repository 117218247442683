<div class="dialog-container">
    <div class="dialog-close">
        <div class="fab small active" id="btn-cancel" mat-dialog-close="">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <h1 class="condensed dialog-title">Indexeren</h1>
    <div class="dialog-content flex-column">
        <div class="d-flex w-100">
            <mat-slider
                thumbLabel
                class="w-100 mt-3 flex-fill"
                color="primary"
                [(ngModel)]="precent"
                [step]="0.01"
                [min]="0.01"
                [max]="10">
            </mat-slider>
            <mat-form-field appearance="fill" class="percent-input">
                <mat-label>Index</mat-label>
                <mat-icon matSuffix class="fas fa-percent"></mat-icon>
                <input matInput type="text"
                       [(ngModel)]="precent"
                       appDecimalInput
                       [decimalInput]="2"
                       placeholder="index %">
                <mat-error>Dit veld is verplicht</mat-error>
            </mat-form-field>
        </div>
        <div class="d-flex align-items-center">
            <i class="fas fa-exclamation-triangle"></i>
            <small class="pl-2">
                Let op, de wijzigingen worden pas opgeslagen als u de prijzenlijst opslaat met de knop rechtsonder.
            </small>
        </div>
    </div>
    <div class="d-flex flex-wrap pt-2 justify-content-between">
        <button mat-button mat-dialog-close>
            Annuleren
        </button>
        <button mat-raised-button color="primary" (click)="apply()">
            Indexatie toepassen
        </button>
    </div>
</div>
