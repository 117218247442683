import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Settings} from '../settings.class';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-codalt-image',
    templateUrl: './codalt-image.component.html',
    styleUrls: ['./codalt-image.component.scss']
})
export class CodaltImageComponent implements OnInit, OnChanges {

    image: string;
    @Input() thumb: string;
    @Input() icon: string;
    @Input() iconBackground: string;
    @Input() private fullUrl: string;
    @Input() private path: string;
    @Input() private secureType = 'article';

    constructor() {
    }

    ngOnInit() {
        this.setImage();
    }

    setImage() {
        if (this.fullUrl) {
            this.image = this.fullUrl;
        } else if (this.path && this.path.substr(-4) !== 'null') {
            this.image = Settings.API_ENDPOINT +
                'file/thumb?secure=' + this.secureType +
                '&path=/' + this.path +
                '&access_token=' + LocalStorage.getUserToken();
        }
    }

    ngOnChanges() {
        this.setImage();
    }
}
