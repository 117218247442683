import {Component, OnInit} from '@angular/core';
import {StatusService} from '../services/status/status.service';
import {Status} from '../classes/status.class';
import {MaterialService} from '../services/material/material.service';
import {Material} from '../classes/material.class';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public statuses: Status[];
    public materials: Material[];

    constructor(
        private statusService: StatusService,
        private materialService: MaterialService
    ) {
    }

    ngOnInit() {
        this.statusService.getStatuses().then(statuses => {
            this.statuses = statuses;
        });
        this.materialService.getMaterials().then(materials => {
            this.materials = materials;
        });
    }

}
