import {Component, OnInit} from '@angular/core';
import {ChecklistService} from '../services/checklist.service';
import {Subscription} from 'rxjs';
import {Checklist} from '../classes/checklist';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Router} from '@angular/router';
import {Routenames} from '../route-names.enum';
import {SortingComponent} from '../sorting.component';

@Component({
    selector: 'app-checklists',
    templateUrl: './checklists.component.html',
    styleUrls: ['./checklists.component.scss']
})
export class ChecklistsComponent extends SortingComponent<Checklist> implements OnInit {

    constructor(private checklistService: ChecklistService,
                private router: Router,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.checklistService.list().subscribe(lists => {
            this.list = lists.data;
            this.sortList('number');
        }));
    }

    edit(checklist) {
        this.router.navigate([Routenames.checklistEdit, checklist.id]);
    }

    copy(event, checklist) {
        event.stopPropagation();
        this.router.navigate([Routenames.checklistEdit, 'new', {copy: checklist.id}]);
    }

    delete(event, checklist: Checklist) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Inspectielijst verwijderen',
            `Weet u zeker dat u Inspectielijst '${checklist.name}' wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.checklistService.delete(checklist.id).subscribe(() => {
                const index = this.list.indexOf(checklist);
                this.list.splice(index, 1);
            });
        }, () => {

        });
    }

}
