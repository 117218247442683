<app-vvr-icon [full]="true" [dark]="true" [overlay]="true" [shadow]="false"></app-vvr-icon>
<div class="login-background">
    <div class="login-block">
        <div id="errorMessage">
            <ng-container *ngIf="requestState === RequestRestorePasswordState.SendedEmail">
                Er is een e-mail verstuurd naar <b>{{form.value.email}}</b>.
                Via de link in deze e-mail kun je een nieuw wachtwoord instellen.
                <br><br>
                <b>E-mail niet ontvangen?</b><br>
                Controleer of deze in spam terecht is gekomen en voeg
                <a href="mailto:app@vvr.nl">app@vvr.nl</a> toe aan de lijst met veilige afzenders.
                <br><br>
                <a routerLink="/login" class="textlink back">Terug naar het inlogscherm</a>
            </ng-container>
            <ng-container *ngIf="requestState === RequestRestorePasswordState.SendEmailFail">
                Het is niet gelukt om een e-mail te versturen. Probeer het nog een keer, want het ligt niet aan jou.
            </ng-container>
        </div>
        <ng-container
                *ngIf="requestState === RequestRestorePasswordState.FillInEmail || requestState === RequestRestorePasswordState.SendingEmail">
            <form [formGroup]="form">
                <div class="title">
                    <h1 class="h4 condensed">Wachtwoord vergeten</h1>
                    <p>Vul hier uw e-mailadres in om uw wachtwoord te resetten.</p>
                </div>
                <mat-form-field appearance="fill" id="email">
                    <mat-label>E-mailadres</mat-label>
                    <input matInput type="text" formControlName="email" name="email"
                            placeholder="Vul uw e-mailadres in">
                    <mat-error>Vul een correct e-mailadres in</mat-error>
                </mat-form-field>
                <div class="login-buttons">
                    <button mat-raised-button color="primary" (click)="requestPasswordEmail()"
                            [disabled]="form.invalid || requestState === RequestRestorePasswordState.SendingEmail" id="btn-request-password">
                        <ng-container *ngIf="requestState === RequestRestorePasswordState.FillInEmail">
                            Wachtwoord opvragen
                        </ng-container>
                        <ng-container *ngIf="requestState === RequestRestorePasswordState.SendingEmail">
                            Link wordt verstuurd...
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
    </div>
</div>
