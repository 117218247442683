import {CodaltComponent} from './codalt.component';

export class SortingComponent<T> extends CodaltComponent {
    list: T[];
    currentSort = null;
    desc = false;

    sortList(sortingOn?: string | Function, forceDesc = false) {
        let sortList = this.list;
        this.list = [];

        if (sortingOn === this.currentSort) {
            sortList = sortList.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = forceDesc || false;
            sortList = sortList.sort((a, b) => {
                let aVal;
                let bVal;
                if (typeof sortingOn === 'function') {
                    aVal = sortingOn(a);
                    bVal = sortingOn(b);
                } else if (typeof sortingOn === 'string') {
                    aVal = a[sortingOn];
                    bVal = b[sortingOn];
                }
                const nonUppercaseTypes = ['boolean', 'number'];
                if (!aVal) {
                    return -1;
                }
                if (!bVal) {
                    return 1;
                }
                if (!isNaN(aVal) && !isNaN(bVal)) {
                    aVal = +aVal;
                    bVal = +bVal;
                }
                if (nonUppercaseTypes.indexOf((typeof aVal)) !== -1) {
                    if (aVal < bVal) {
                        return -1;
                    }
                    if (aVal > bVal) {
                        return 1;
                    }
                } else {
                    if (!aVal) {
                        return 0;
                    }
                    if (!bVal) {
                        return 0;
                    }
                    if (('' + aVal).toUpperCase() < ('' + bVal).toUpperCase()) {
                        return -1;
                    }
                    if (('' + aVal).toUpperCase() > ('' + bVal).toUpperCase()) {
                        return 1;
                    }
                }
                return 0;
            });
            if (this.desc) {
                sortList = sortList.reverse();
            }
        }

        this.list = sortList;

        this.currentSort = sortingOn;
    }


}
