import {Component, EventEmitter, OnInit} from '@angular/core';
import {User} from '../classes/user.class';
import {Router} from '@angular/router';
import {CordovaService} from '../cordova.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {LocalStorage} from '../storage.class';
import {formatDate} from '@angular/common';
import {SortingComponent} from '../sorting.component';
import {Repair} from '../classes/repair.class';
import {RepairService} from '../services/repair.service';
import {debounceTime} from 'rxjs/operators';
import * as XLSX from 'xlsx';
import {Settings} from '../settings.class';

@Component({
    selector: 'app-repairs',
    templateUrl: './repairs.component.html',
    styleUrls: ['./repairs.component.scss']
})
export class RepairsComponent extends SortingComponent<Repair> implements OnInit {

    showClosed: boolean;
    user: User;

    searching = new EventEmitter<any>();
    searchText: string;
    unfilteredRepairs: Repair[];

    mayExport = false;

    constructor(private repairService: RepairService,
                private router: Router,
                private cordovaService: CordovaService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        this.mayExport = this.AuthorisationService.hasFeature('incidentManagement');
        this.searching.pipe(debounceTime(125)).subscribe(() => {
            this.list = this.unfilteredRepairs.filter(u => {
                const text = `${u?.machine_number?.replace(/[^A-Z0-9]/ig, '')}_${u?.user?.name}_${u?.id}`.toLowerCase();
                return text.indexOf(this.searchText.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')) !== -1;
            });
        });
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        this.user = LocalStorage.getUser();
        this.getData();
    }

    openIfOne() {
        if (this.list?.length === 1) {
            this.router.navigateByUrl(`/repairs/${this.list[0].id}`);
        }
    }

    getData() {
        this.repairService.getList(this.showClosed).then(incidents => {
            this.unfilteredRepairs = incidents;
            this.list = this.unfilteredRepairs;
            this.currentSort = 'created_at';
            this.desc = true;
        });
    }

    delete(event, repair: Repair) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Verwijderen',
            `Weet u zeker dat u deze reparatie aangemaakt op ${formatDate(repair.created_at, 'd MMM yyyy, H:mm', 'nl')} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.repairService.delete(repair.id).then(() => {
                const index = this.list.indexOf(repair);
                this.unfilteredRepairs.splice(index, 1);
                this.list.splice(index, 1);
            });
        }, () => {

        });
    }

    export() {
        const sheetArray = [];
        this.list.forEach(repair => {
            sheetArray.push({
                Melding: repair.id,
                'Machine nummer': repair.machine_number,
                Status: repair.status,
                'Aangemaakt door': repair.user?.name,
                Aangemaakt: new Date(repair.created_at),
                Beschrijving: repair.description
            });
        });
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetArray);
        for (let i = 2; i <= sheetArray.length + 1; i++) {
            worksheet[`E${i}`].z = 'd mmmm yy h:mm';
        }
        worksheet['!cols'] = [
            {width: 8},
            {width: 24},
            {width: 15},
            {width: 30},
            {width: 20},
            {width: 40},
        ];
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const filename = `VVR_meldingen_${formatDate(new Date(), 'yyyy-MMM-d_H-mm-ss', 'nl')}`;
        Settings.saveAsExcelFile(excelBuffer, filename.replace('.', ''));
    }
}
