import {Component, OnInit} from '@angular/core';
import {PpeService} from '../services/ppe.service';
import {PpeEquipment} from '../ppe-equipment';
import {Router} from '@angular/router';
import {CodaltComponent} from '../codalt.component';
import {Routenames} from '../route-names.enum';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-ppe-management',
    templateUrl: './ppe-management.component.html',
    styleUrls: ['./ppe-management.component.scss']
})
export class PpeManagementComponent extends CodaltComponent implements OnInit {

    equipments: PpeEquipment[];
    currentSort = null;
    desc = false;

    constructor(private ppeService: PpeService,
                private confirmDialog: ConfirmDialogService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.ppeService.listEquipment().subscribe(equipment => {
            this.equipments = equipment.data;
        });
    }

    edit(equipment: PpeEquipment) {
        this.router.navigate([Routenames.ppeEquipment, equipment.id]);
    }

    sortList(sortingOn) {
        let decorations = this.equipments;
        this.equipments = [];

        if (sortingOn === this.currentSort) {
            decorations = decorations.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = false;
            decorations = decorations.sort((a, b) => {
                if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                    return -1;
                }
                if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        }

        this.equipments = decorations;

        this.currentSort = sortingOn;
    }

    delete(event, equipment: PpeEquipment) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Aanvraag intrekken',
            `Weet u zeker dat u PBM ${equipment.name} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.ppeService.deleteEquipment(equipment.id).subscribe(() => {
                const index = this.equipments.indexOf(equipment);
                this.equipments.splice(index, 1);
            });
        }, () => {

        });
    }

}
