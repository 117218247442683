import {User} from './classes/user.class';
import {SmallMaterial} from './smallmaterial';
import {SmallMaterialStatus} from './smallmaterial-status';
import {SmallMaterialStatusLog} from './smallmaterial-status-log';

export class SmallMaterialRequest {
    id: number;
    smallmaterial_id: number;
    smallmaterial: SmallMaterial;
    status_id = 1;
    status: SmallMaterialStatus;
    status_log: SmallMaterialStatusLog[];
    user_id: number;
    user: User;
    manager_id: number;
    manager: User;
    images: any[];
    size: string;
    comment: string;
    reason: string;
    created_at: Date;
}
