import {Component, OnInit} from '@angular/core';
import {LocalStorage} from '../../storage.class';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginFormState} from './login-form-state.enum';
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {AuthenticationService} from "../../services/auth/authentication.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    LoginFormState = LoginFormState;

    loginFormState: LoginFormState = LoginFormState.FillIn;
    errorMessage = null;

    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required])
    });

    constructor(private authService: AuthenticationService,
                private customerAreaService: CustomerAreaService,
                private router: Router) {
    }

    ngOnInit() {

    }

    login() {
        this.errorMessage = null;
        this.loginFormState = LoginFormState.Login;
        this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
            authResponse => {
                LocalStorage.setUserToken(authResponse.access_token);
                LocalStorage.setUser(authResponse.user);
                this.customerAreaService.reset();
                this.router.navigate(['']);
            }, failReason => {
                this.errorMessage = failReason.message;
                if (!this.errorMessage) {
                    this.errorMessage = 'Kon de server niet bereiken';
                }
                this.loginFormState = LoginFormState.FillIn;
            });
    }

    forgotPassword() {
        this.router.navigate(['request-restore-password']);
        return false;
    }

}
