<div class="dialog-container" [class.dragover]="dragOver" [class.uploading]="uploading">
    <h1 class="condensed dialog-title">
        {{fileRequired ? 'Bestand' : 'Hoofdstuk'}}
        {{!fileRequired && !noFilesAllowed ? 'of bestand' : '' }} toevoegen
    </h1>
    <div class="dialog-content mt-3">
        <form [formGroup]="form">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Naam</mat-label>
                <input matInput type="text" formControlName="name" name="name" id="name"
                       placeholder="Naam">
                <mat-error>Dit veld mag maximaal 255 tekens bevatten</mat-error>
            </mat-form-field>
            <div *ngIf="file">
                <b>Geüpload bestand:</b> {{file}}
                <br>
            </div>
        </form>
        <div *ngIf="!file && !noFilesAllowed">
            Geen bestand toegevoegd
            <button mat-button color="primary" style="padding: 0">
                <label style="margin: 0; padding: 0 16px;">
                    Bestand uploaden <input (change)="uploadFile($event)" type="file" hidden multiple>
                </label>
            </button>
        </div>
        <small *ngIf="!noFilesAllowed && !fileRequired">Upload geen bestand om een hoofdstuk toe te voegen</small>

    </div>
    <div class="d-flex flex-wrap">
        <button mat-button [mat-dialog-close]="false">Annuleren</button>
        <div class="spacer"></div>
        <button mat-raised-button (click)="save()" cdkFocusInitial color="primary" [disabled]="saving || !form.valid">
            Opslaan <i class="fas fa-circle-notch fa-spin" *ngIf="saving"></i>
        </button>
    </div>
    <div class="overlay">
        <div class="overlay-wrapper drag">
            <div class="upload-icon">
                <i class="fas fa-upload"></i>
            </div>
        </div>
        <div class="overlay-wrapper uploading">
            <div class="upload-icon">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
            <div>
                Bezig met uploaden...
            </div>
        </div>
    </div>
</div>
