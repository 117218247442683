import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ApiService} from '../services/api/api.service';

@Component({
    selector: 'app-draw-on-image',
    templateUrl: './draw-on-image.component.html',
    styleUrls: ['./draw-on-image.component.scss']
})
export class DrawOnImageComponent implements AfterViewInit, OnDestroy {

    @ViewChild('canvas', {static: false}) public canvas: ElementRef;
    @ViewChild('canvasImage', {static: false}) public canvasImage: ElementRef;

    @Input() width = 640;
    @Input() height = 400;

    @Input() image: string;

    painted = false;

    private canvasImageEl: HTMLCanvasElement;
    private contextImage: CanvasRenderingContext2D;
    private canvasEl: HTMLCanvasElement;
    private context: CanvasRenderingContext2D;
    private paint: boolean;
    private click: DrawClick[] = [];
    private drawings: DrawClick[][] = [];

    private factor = 1;
    private imageWidth = 0;
    private imageHeight = 0;

    private strokeStyle = 'rgba(255,0,0, 0.6)';
    private lineWidth = 10;
    private lineCap = 'round' as CanvasLineCap;
    private lineJoin = 'round' as CanvasLineJoin;

    constructor(private apiService: ApiService) {

    }

    ngAfterViewInit(): void {
        document.querySelector('body').style.overflow = 'hidden';
        this.canvasImageEl = document.getElementById('canvasImage') as HTMLCanvasElement;
        this.contextImage = (<HTMLCanvasElement>this.canvasImageEl).getContext('2d');
        this.contextImage.canvas.width = this.width;
        this.contextImage.canvas.height = this.height;


        if (this.image) {
            const img = new Image();
            img.src = this.image;
            img.crossOrigin = "anonymous";
            img.onload = () => {
                this.imageWidth = img.naturalWidth;
                this.imageHeight = img.naturalHeight;
                const wFactor = this.width / img.naturalWidth;
                const hFactor = this.height / img.naturalHeight;
                const factor = Math.min(wFactor, hFactor);
                this.width = img.naturalWidth * factor;
                this.height = img.naturalHeight * factor;
                this.factor = factor;
                this.contextImage.canvas.width = this.width;
                this.contextImage.canvas.height = this.height;
                this.context.canvas.width = this.width;
                this.context.canvas.height = this.height;


                const x = (this.width - this.width) / 2;
                const y = (this.height - this.height) / 2;

                const ctx = this.contextImage;
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, this.canvasImageEl.width, this.canvasImageEl.height);

                ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, x, y, this.width, this.height);

                this.contextImage.lineWidth = this.lineWidth;
                this.contextImage.lineCap = this.lineCap;
                this.contextImage.lineJoin = this.lineJoin;
                this.contextImage.strokeStyle = this.strokeStyle;

                this.context.lineWidth = this.lineWidth;
                this.context.lineCap = this.lineCap;
                this.context.lineJoin = this.lineJoin;
                this.context.strokeStyle = this.strokeStyle;
            };
        }
        this.canvasEl = document.getElementById('canvas') as HTMLCanvasElement;
        this.context = (<HTMLCanvasElement>this.canvasEl).getContext('2d');
        this.context.globalCompositeOperation = 'destination-atop';


        this.redraw();
        this.createUserEvents();
    }

    private createUserEvents() {
        let canvas = this.canvasEl;

        const pressEventHandler = (e: MouseEvent | TouchEvent) => {
            let mouseX = (e as TouchEvent).changedTouches
                ? (e as TouchEvent).changedTouches[0].clientX
                : (e as MouseEvent).offsetX;
            let mouseY = (e as TouchEvent).changedTouches
                ? ((e as TouchEvent).changedTouches[0].clientY)
                : (e as MouseEvent).offsetY;
            if ((e as TouchEvent).changedTouches) {
                mouseX -= ((this.canvasEl.offsetParent as any).offsetParent as any).offsetLeft - this.canvasEl.offsetLeft;
                mouseY -= ((this.canvasEl.offsetParent as any).offsetTop);
            }

            this.paint = true;
            this.addClick(mouseX, mouseY, false);
            this.redraw();
        };

        const dragEventHandler = (e: MouseEvent | TouchEvent) => {
            let mouseX = (e as TouchEvent).changedTouches
                ? (e as TouchEvent).changedTouches[0].clientX
                : (e as MouseEvent).offsetX;
            let mouseY = (e as TouchEvent).changedTouches
                ? ((e as TouchEvent).changedTouches[0].clientY)
                : (e as MouseEvent).offsetY;
            if ((e as TouchEvent).changedTouches) {
                mouseX -= ((this.canvasEl.offsetParent as any).offsetParent as any).offsetLeft - this.canvasEl.offsetLeft;
                mouseY -= ((this.canvasEl.offsetParent as any).offsetTop);
            }

            if (this.paint) {
                this.addClick(mouseX, mouseY, true);
                this.redraw();
            }

            e.preventDefault();
        };

        const releaseEventHandler = () => {
            this.paint = false;
            this.context.clearRect(0, 0, this.width, this.height);
            const ctx = this.contextImage;
            ctx.beginPath();
            this.click.forEach((click, index) => {
                if (index === 0) {
                    ctx.moveTo(click.x, click.y);
                } else {
                    ctx.lineTo(click.x, click.y);
                }
            });
            ctx.stroke();
            this.drawings.push(this.click);
            this.click = [];
        };

        canvas.addEventListener('mousedown', pressEventHandler);
        canvas.addEventListener('mousemove', dragEventHandler);
        canvas.addEventListener('mouseup', releaseEventHandler);
        canvas.addEventListener('mouseout', releaseEventHandler);
        canvas.addEventListener('touchstart', pressEventHandler);
        canvas.addEventListener('touchmove', dragEventHandler);
        canvas.addEventListener('touchend', releaseEventHandler);
        canvas.addEventListener('touchcancel', releaseEventHandler);
    }

    private redraw() {
        let context = this.context;
        context.beginPath();
        if (this.click.length > 1) {
            const clickFrom = this.click[this.click.length - 2];
            const clickTo = this.click[this.click.length - 1];
            context.moveTo(clickFrom.x, clickFrom.y);
            context.lineTo(clickTo.x, clickTo.y);
            context.stroke();
        }
        context.closePath();
    }

    private addClick(x: number, y: number, dragging: boolean) {
        this.painted = true;
        this.click.push({drag: dragging, x, y});
    }


    save(): Promise<{ base64: string }> {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = this.imageWidth;
            canvas.height = this.imageHeight;

            const img = new Image();
            img.src = this.image;
            img.crossOrigin = "anonymous";
            img.onload = () => {
                ctx.lineWidth = this.lineWidth / this.factor;
                ctx.lineCap = this.lineCap;
                ctx.lineJoin = this.lineJoin;
                ctx.strokeStyle = this.strokeStyle;
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, this.imageWidth, this.imageHeight);

                this.drawings.forEach(click => {
                    ctx.beginPath();
                    click.forEach((click, index) => {
                        if (index === 0) {
                            ctx.moveTo(click.x / this.factor, click.y / this.factor);
                        } else {
                            ctx.lineTo(click.x / this.factor, click.y / this.factor);
                        }
                    });
                    ctx.stroke();
                });

                const base64 = canvas.toDataURL('image/jpeg').replace("data:image/jpeg;base64,", '');
                resolve({base64});
            };
        });
    }

    ngOnDestroy(): void {
        document.querySelector('body').style.removeProperty('overflow');
    }
}

export class DrawClick {
    x: number;
    y: number;
    drag: boolean;
}
