export enum Routenames {
    articles = 'nieuws',
    ppeRequest = 'ppe/requests',
    ppeEquipment = 'ppe/equipment',
    ppeStatus = 'ppe/status',
    checklists = 'checklists',
    checklistEdit = 'checklists/edit',
    checklistFillIn = 'checklist-fill-in',
    checklistPDF = 'checklist-pdf',
    incident = 'incident',
    repairs = 'repairs',
    declarationRequest = 'declarations/requests',
    declarationTypes = 'declarations/types',
    declarationReport = 'declarations/report',
    moldRequest = 'mold/requests',
    moldMaterial = 'mold/equipment',
    moldStatus = 'mold/status',
    smallMaterialRequest = 'smallmaterial/requests',
    smallMaterial = 'smallmaterial',
    smallMaterialStatus = 'smallmaterial/status',
    dataExport = 'data-export'
}
