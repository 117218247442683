<div class="dialog-container">
    <div class="dialog-close">
        <div id="btn-cancel" class="fab small active" (click)="close()">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <h1 class="condensed dialog-title">Inspectie type</h1>
    <div class="dialog-content">
        <table class="table table-hover curpoint w-100">
            <tr *ngFor="let checklist of checklists" (click)="choose(checklist)">
                <td>
                    {{checklist.name}}
                </td>
            </tr>
        </table>
    </div>
</div>
