<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed"></h1>
        </div>
        <div class="controls">
            <div (click)="logout()" class="fab small" id="btn-Exit"
                 matTooltip="Uitloggen"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>

    <div class="customer">
        <div *ngIf="AuthorisationService.hasFeature('customerAreaGet')">
            <div class="action" routerLink="/customer-selection" matRipple>
                <i class="fas fa-snowplow fa-4x"></i>
                <span>Onderhoud</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('incidentList')">
            <div class="action" routerLink="/incident" matRipple>
                <i class="fas fa-exclamation-triangle fa-4x"></i>
                <span>Meldingen</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('listRepairs')">
            <div class="action" routerLink="/repairs" matRipple>
                <i class="fas fa-hammer fa-4x"></i>
                <span>Reparatie</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('libraryList')">
            <div class="action" routerLink="/library" matRipple>
                <i class="fas fa-book fa-4x"></i>
                <span>Bibliotheek</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('lrma')">
            <div class="action" routerLink="/lmra" matRipple>
                <i class="fas fa-first-aid fa-4x"></i>
                <span>LMRA</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('listArticles')">
            <div class="action" routerLink="/{{Routenames.articles}}" matRipple>
                <i class="fas fa-newspaper fa-4x"></i>
                <span>Nieuws</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('getPbmRequests')">
            <div class="action" routerLink="/{{Routenames.ppeRequest}}" matRipple>
                <i class="fas fa-hard-hat fa-4x"></i>
                <span>PBM</span>
            </div>
        </div>

        <div *ngIf="AuthorisationService.hasFeature('checklistAnswers')">
            <div class="action" routerLink="/{{Routenames.checklistFillIn}}" matRipple>
                <i class="fas fa-clipboard-check fa-4x"></i>
                <span>Inspectielijsten</span>
            </div>
        </div>

        <div *ngIf="AuthorisationService.hasFeature('declarations')">
            <div class="action" routerLink="/{{Routenames.declarationRequest}}" matRipple>
                <i class="fas fa-file-invoice fa-4x"></i>
                <span>Declaraties</span>
            </div>
        </div>

        <div *ngIf="AuthorisationService.hasFeature('getMoldRequests')">
            <div class="action" routerLink="/{{Routenames.moldRequest}}" matRipple>
                <i class="fas fa-do-not-enter fa-4x"></i>
                <span>Mallen</span>
            </div>
        </div>

        <div *ngIf="AuthorisationService.hasFeature('getMoldRequests')">
            <div class="action" routerLink="/{{Routenames.smallMaterialRequest}}" matRipple>
                <i class="fas fa-coffee-pot fa-4x"></i>
                <span>Klein Materiaal</span>
            </div>
        </div>

        <div *ngIf="AuthorisationService.hasFeature('userCurrentSave')">
            <div class="action" routerLink="/profile" matRipple>
                <i class="fas fa-user fa-4x"></i>
                <span>Profiel</span>
            </div>
        </div>
        <div
            *ngIf="AuthorisationService.hasAnyFeature(management)">
            <div class="action" routerLink="/management" matRipple>
                <i class="fas fa-tools fa-4x"></i>
                <span>Beheer</span>
            </div>
        </div>
    </div>
    <div class="version-info">{{cordovaVersion}}</div>
</div>
