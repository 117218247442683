import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Utils} from '../../utils.class';
import {Routenames} from '../../route-names.enum';
import {SmallMaterial} from '../../smallmaterial';
import {SmallMaterialService} from '../../services/small-material.service';

@Component({
    selector: 'app-small-material-edit',
    templateUrl: './small-material-edit.component.html',
    styleUrls: ['./small-material-edit.component.scss']
})
export class SmallMaterialEditComponent extends CodaltComponent implements OnInit {

    fc: {
        name: FormControl
    };

    form: FormGroup;

    smallMaterial: SmallMaterial;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private smallMaterialService: SmallMaterialService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.smallMaterialService.getMaterial(params['id']).subscribe(equipment => {
                this.smallMaterial = equipment.data || new SmallMaterial();
                this.fc = {
                    name: new FormControl(this.smallMaterial.name, Validators.required)
                };
                this.form = new FormGroup(this.fc);
            });
        }));
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.smallMaterial.name = this.fc.name.value;
            this.smallMaterialService.saveMaterial(this.smallMaterial).subscribe(() => {
                this.router.navigate([Routenames.smallMaterial]);
            }, error => {
                this.confirmDialog.confirm(
                    'Fout bij het opslaan',
                    error.message,
                    'Sluiten'
                ).then(() => {

                }, () => {

                });
            });
        }
    }
}
