<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Extra kosten beheren</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/extra-costs"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">
            <form [formGroup]="form">
                <mat-form-field class="w-100" id="name">
                    <input matInput type="text" formControlName="name" name="name"
                           placeholder="Naam">
                    <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100" id="unit">
                    <input matInput type="text" formControlName="unit" name="unit"
                           placeholder="Eenheid">
                    <mat-error>Dit veld moet minimaal 1 teken bevatten</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Aantal/prijs gebaseerd op</mat-label>
                    <mat-select formControlName="count_based_on">
                        <mat-option [value]="ExtraCostCountType.count">Totaal prijs</mat-option>
                        <mat-option [value]="ExtraCostCountType.pushpin">Totaal oppervlak</mat-option>
                        <mat-option [value]="ExtraCostCountType.pushpin_figuration">Totaal oppervlak Figuraties</mat-option>
                        <mat-option [value]="ExtraCostCountType.pushpin_lengthmark">Totaal oppervlak Lengtemarkeringen</mat-option>
                    </mat-select>
                    <mat-error>Dit veld is verplicht</mat-error>
                </mat-form-field>
                <div class="mt-2">
                    <mat-checkbox formControlName="night">Alleen nacht</mat-checkbox>
                </div>
            </form>
        </div>
    </div>
    <div class="row justify-content-center pt-3">
        <div class="col-md-6 col-12">
            <div class="d-flex">
                <button mat-button routerLink="/extra-costs">Terug</button>
                <div class="spacer"></div>
                <button mat-raised-button color="primary" (click)="save()" id="btn-save">
                    Opslaan
                </button>
            </div>
        </div>
    </div>
</div>
