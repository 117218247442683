import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LibraryService} from '../../services/library/library.service';
import {Library} from '../../classes/library.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-rename-chapter',
    templateUrl: './rename-chapter.component.html',
    styleUrls: ['./rename-chapter.component.scss']
})
export class RenameChapterComponent implements OnInit {

    public form: FormGroup;
    public saving = false;

    constructor(public dialogRef: MatDialogRef<RenameChapterComponent>,
                private confirmDialogService: ConfirmDialogService,
                private libraryService: LibraryService,
                @Inject(MAT_DIALOG_DATA) public chapter: Library) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl(this.chapter.name, [Validators.required, Validators.maxLength(255)])
        });
    }

    save() {
        if (this.form.valid) {
            this.saving = true;
            this.chapter.name = this.form.get('name').value;
            this.libraryService.rename(this.chapter).then(chapter => {
                this.saving = false;
                this.dialogRef.close(chapter);
            }, error => {
                this.confirmDialogService.confirm(
                    'Fout bij opslaan',
                    `Er is iets fout gegaan bij het opslaan`,
                    'Oké', null);
            });
        }
    }

}
