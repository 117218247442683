<div class="dialog-container">
    <div class="dialog-close">
        <div id="btn-cancel" class="fab small active" (click)="onNoClick()">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <ng-container *ngIf="!type && !group">
        <h1 class="condensed dialog-title">Type</h1>
        <div class="dialog-content">
            <table class="table table-hover curpoint w-100">
                <tr *ngFor="let type of types" (click)="filterType(type)">
                    <td>
                        {{type}} <span *ngIf="type == 'Lengtemarkering' && !lengthmark">(kort)</span>
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="!pushpinPart.material_id && type">
        <h1 class="condensed dialog-title">Materiaal</h1>
        <div class="dialog-content">
            <table class="table table-hover curpoint w-100">
                <tr (click)="selectMaterial(material)" *ngFor="let material of materials">
                    <td>
                        {{material.name}}
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="pushpinPart.material_id && type && !group">
        <h1 class="condensed dialog-title">Groep</h1>
        <div class="dialog-content">
            <table class="table table-hover curpoint w-100">
                <tr *ngFor="let group of groups" (click)="filterGroup(group)">
                    <td>
                        {{group}}
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="type && group && !pushpinPart.decoration && pushpinPart.material_id">
        <h1 class="condensed dialog-title">{{group}}</h1>
        <div class="dialog-content">
            <table class="table table-hover curpoint w-100">
                <tr *ngFor="let decoration of decorations" (click)="selectDecoration(decoration)">
                    <td>
                        {{decoration.name}}
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="pushpinPart.decoration && pushpinPart.material_id">
        <h1 class="condensed dialog-title">Aantal</h1>
        <div class="dialog-content flex-column">

            <div class="amount-selection">
                <div class="amount">
                    <div>&nbsp;</div>
                </div>
                <div class="amount">
                    <div class="number-input">{{amountInput}}</div>
                </div>
                <div class="amount click" (click)="backspaceAmountInput()">
                    <div>
                        <i class="fas fa-backspace"></i>
                    </div>
                </div>
                <div class="amount click" *ngFor="let amount of amountsList" (click)="setAmountInput(amount)">
                    <div>{{amount}}</div>
                </div>
                <div class="amount click">
                    <div>&nbsp;</div>
                </div>
                <div class="amount click" (click)="setAmountInput(0)">
                    <div>0</div>
                </div>
                <div class="amount confirm" (click)="selectAmount(amountInput)">
                    <!--<div class="font-weight-bold">
                        OK
                    </div>-->
                  <button mat-raised-button color="primary">OK</button>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="d-none d-sm-block">
        <ng-container *ngIf="type && !lengthmark">
            <button mat-button (click)="breadcrumBack(0)">{{type}}</button>
        </ng-container>
        <ng-container *ngIf="pushpinPart.material_id">
            /
            <button mat-button (click)="breadcrumBack(1)">{{pushpinPart.material.name}}</button>
        </ng-container>
        <ng-container *ngIf="group && pushpinPart.material_id && group != 'Lengtemarkering'">
            /
            <button mat-button (click)="breadcrumBack(2)">{{group}}</button>
        </ng-container>
        <ng-container *ngIf="pushpinPart.decoration">
            /
            <button mat-button (click)="breadcrumBack(3)">{{pushpinPart.decoration.name}}</button>
        </ng-container>
    </div>
</div>
