<div class="filters">
    <div class="filter-title">
        <h1 class="h4 condensed">Filters</h1>
        <div (click)="toggleFilter()" class="fab small active" id="btn-filters">
            <i class="fas fa-times"></i>
        </div>
    </div>

    <div *ngIf="this.AuthorisationService.hasFeature('statusFiltersAvailable')" class="filter-group">
        <h2 class="h6 condensed">Status</h2>
        <ng-container *ngFor="let status of statuses; let i = index">
            <ng-container>
                <mat-checkbox
                    (change)="filterChange()"
                    [(ngModel)]="filter['status_' + status.id]"
                    [disabled]="filter['status_' + status.id + '_disable']"
                    [value]="status.id"
                    color="primary">
                    <span [innerHTML]="status['img'] | safeHtml"></span>
                    {{status.name}}
                </mat-checkbox>
            </ng-container>
        </ng-container>
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>In datum range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start datum" [(ngModel)]="date_start" (dateChange)="filterChange()">
                <input matEndDate placeholder="Eind datum" [(ngModel)]="date_end" (dateChange)="filterChange()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <div class="filter-group">
        <h2 class="h6 condensed">Groep</h2>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter['type_lengtemarkering']" color="primary">
            Lengtemarkering
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter['type_figuratie']" color="primary">
            Figuratie
        </mat-checkbox>
    </div>

    <div class="filter-group">
        <h2 class="h6 condensed">Materiaal</h2>
        <ng-container *ngFor="let material of materials; let i = index">
            <mat-checkbox (change)="filterChange()" [(ngModel)]="filter['material_' + material.id]"
                          color="primary">
                {{material.name}}
            </mat-checkbox>
        </ng-container>
    </div>

    <div class="filter-group">
        <h2 class="h6 condensed">Diversen</h2>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.primer"
                      color="primary">
            Primer
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.stake_out"
                      color="primary">
            Uitzetten
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.blast"
                      color="primary">
            Stralen
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.traffic_controllers" [disabled]="filter.no_traffic_controllers"
                      color="primary">
            Verkeersregelaars
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.no_traffic_controllers" [disabled]="filter.traffic_controllers"
                      color="primary">
            Geen verkeersregelaars
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" *ngIf="AuthorisationService.hasFeature('selectionShowAlwaysCheck')" [(ngModel)]="filter.always_check"
                      color="primary">
            Altijd controleren
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.turbo_burn"
                      color="primary">
            Turbo branden
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.manual_burn"
                      color="primary">
            Handmatig branden
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.night"
                      color="primary">
            Nacht
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.overgrown"
                      color="primary">
            Overgroeide kanten
        </mat-checkbox>
    </div>

    <div class="filter-group">
        <h2 class="h6 condensed">Overig</h2>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.with_comment"
                      color="primary">
            Met opmerking
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.exclusive_material"
                      color="primary">
            Zonder materiaal
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" [(ngModel)]="filter.priority_figuration"
                      color="primary">
            Voorrangsfiguraties
        </mat-checkbox>
        <mat-checkbox (change)="filterChange()" *ngIf="this.AuthorisationService.hasFeature('showDeletedPushpins')" [(ngModel)]="filter.deleted"
                      color="primary">
            Verwijderde punten
        </mat-checkbox>
    </div>

    <div class="filter-group">
        <h2 class="h6 condensed">CROW</h2>
        <mat-button-toggle-group (change)="filterChange()" [(ngModel)]="filter['crow']" [multiple]="true">
            <mat-button-toggle value="Aplus">A+</mat-button-toggle>
            <mat-button-toggle value="A">A</mat-button-toggle>
            <mat-button-toggle value="B">B</mat-button-toggle>
            <mat-button-toggle value="C">C</mat-button-toggle>
            <mat-button-toggle value="D">D</mat-button-toggle>
            <mat-button-toggle value="X"><i class="fas fa-question-circle"></i></mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div class="filter-group">
        <h2 class="h6 condensed">Verfkleur</h2>
        <ng-container *ngFor="let paintColor of paintColors; let i = index">
            <mat-checkbox (change)="filterChange()" [(ngModel)]="filter['paintColor_' + paintColor.id]"
                          color="primary">
                {{paintColor.name}}
            </mat-checkbox>
        </ng-container>
    </div>
</div>
