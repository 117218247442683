<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">
                <span class="d-none d-sm-inline">
                    Laatste Minuut Risico Analyse (LMRA)
                </span>
                <span class="d-sm-none">
                    LMRA
                </span>
            </h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Uitloggen"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div>
        Over veilig werken is vooraf van alles af te spreken, maar uiteindelijk kun je op de werkplek zelf pas zien of je écht veilig kunt werken.
        Daarom eist de VCA dat jullie vóór aanvang van het werk een Laatste Minuut Risico Analyse (LMRA) uitvoeren. In een LMRA beoordeel je de veiligheidsrisico's en de
        maatregelen om die risico's zo klein mogelijk te maken.
        De resultaten van zo'n LMRA hoeven niet te worden vastgelegd. Het is een laatste check om je eigen veiligheid en die van je collega's te waarborgen.
    </div>
    <div class="question-list">
        <div class="questions">
            <div class="question">
                <h1 class="h3 condensed">
                    Vragen
                </h1>
            </div>
            <ng-container *ngFor="let question of questions; let i = index;">
                <div *ngIf="!question.previousQuestion || question.previousQuestion.answer" class="question">
                    <div>{{question.question}}</div>
                    <div class="answer">
                        <mat-radio-group (change)="questionChange($event, question)" [(ngModel)]="question.answer" aria-label="{{question.question}}">
                            <mat-radio-button [value]="true">Ja</mat-radio-button>
                            <mat-radio-button [value]="false" color="primary">Nee</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="question.answer === false" class="question">
                    <div class="warn">
                        <p>Ga niet aan de slag!</p>
                        <p>Bel met je uitvoerder.</p>
                        <p>Neem in overleg maatregelen.</p>
                    </div>
                </div>
                <div *ngIf="question.answer && i === (questions.length -1)" class="question">
                    <div class="success">
                        Ga aan de slag.
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
