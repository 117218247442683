import {Component, Input, OnInit} from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import {PriceItemsGroup} from '../price-items-group';
import {PriceLogDialogComponent} from '../price-log-dialog/price-log-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Inspection} from '../../classes/inspection';

@Component({
    selector: 'app-prices-edit',
    templateUrl: './prices-edit.component.html',
    styleUrls: ['./prices-edit.component.scss']
})
export class PricesEditComponent implements OnInit {

    @Input()
    panelIndex: number;

    @Input()
    panel: MatExpansionPanel;

    @Input()
    priceGroup: PriceItemsGroup;

    @Input()
    paintColorKeyVal;

    @Input()
    inspections: Inspection[];

    @Input()
    extraCostNames;

    show = false;

    constructor(public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.panel.afterExpand.subscribe(() => {
            this.show = true;
            setTimeout(() => {
                const elem = document.querySelector(`#panel-${this.panelIndex}-0`) as HTMLInputElement;
                if (elem) {
                    elem.focus();
                }
            });
        });
    }

    openPriceLog(priceId, type, inspectionId) {
        const dialogRef = this.dialog.open(PriceLogDialogComponent, {
            panelClass: 'price-log-dialog',
            data: {priceId, type, inspectionId}
        });
    }

}
