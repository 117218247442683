import {Pipe, PipeTransform} from '@angular/core';
import {PricesService, PriceType} from '../services/prices/prices.service';
import {PushpinPart} from '../classes/pushpinpart.class';
import {TotalSurfacePushpinPartsPipe} from './total-surface-pushpin-parts.pipe';
import {formatNumber} from '@angular/common';

@Pipe({
    name: 'percentSurface'
})
export class PercentSurfacePipe implements PipeTransform {

    constructor(private pricesService: PricesService) {
    }

    transform(pushpinParts: PushpinPart[],
              statusId: number,
              statusIdCompare: number,
              customerAreaId: number,
              inspectionId?: number,
              materialId?: number,
              specific_price = PriceType.all
    ): string {
        const statusPrice = (new TotalSurfacePushpinPartsPipe(this.pricesService
        )).transform(
            pushpinParts,
            statusId,
            customerAreaId,
            inspectionId,
            materialId,
            specific_price);
        const statusPriceCompare = (new TotalSurfacePushpinPartsPipe(this.pricesService
        )).transform(
            pushpinParts,
            statusIdCompare,
            customerAreaId,
            inspectionId,
            materialId,
            specific_price);
        if(!statusPriceCompare){
            return '-';
        }
        return `${formatNumber((statusPriceCompare / (statusPriceCompare + statusPrice)) * 100, 'nl', '1.0-0')}%`;
    }

}
