<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Declaraties</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div>
        <div class="pb-3">
            Gebruik de VVR app voor het invoeren van je declaraties.
        </div>
        <button color="primary" mat-raised-button routerLink="/{{Routenames.declarationRequest}}/new">
            Declaratie indienen
        </button>
        <mat-form-field class="status-filter" *ngIf="showStatusFilter">
            <mat-select [(ngModel)]="statusIds" multiple placeholder="Status filter" (selectionChange)="getData()">
                <mat-option *ngFor="let status of statusList" [value]="status.id">{{status.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button color="primary"
                class="ml-5"
                mat-raised-button
                routerLink="/{{Routenames.declarationReport}}"
                *ngIf="'declarationManagement' | hasFeature">
            Rapportage
        </button>
        <div class="pt-3">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('id')" matRipple>
                        #
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="id"></app-sorting-icon>
                    </th>
                    <th>
                        Declaratie
                    </th>
                    <th>
                        Bedrag
                    </th>
                    <th>
                        Status
                    </th>
                    <th class="d-none d-md-table-cell">
                        Aangemaakt door
                    </th>
                    <th (click)="sortList('created_at')" matRipple class="d-none d-md-table-cell">
                        Aangemaakt
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="created_at"></app-sorting-icon>
                    </th>
                    <th class="trash-column">

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of list" class="curpoint" routerLink="/{{Routenames.declarationRequest}}/{{item.id}}">
                    <td>
                        {{item.id}}
                    </td>
                    <td>
                        {{item.type.name}}
                    </td>
                    <td>
                        {{item.amount | currency : 'EUR'}}
                    </td>
                    <td>
                        {{item.status.name}}
                    </td>
                    <td class="d-none d-md-table-cell">
                        {{item?.user?.name}}
                    </td>
                    <td class="d-none d-md-table-cell">
                        {{item.created_at | date : 'd MMM yyyy, H:mm'}}
                    </td>
                    <td class="pt-0 pb-0">
                        <button (click)="delete($event, item)"
                                *ngIf="item.user_id === user.id && item.status_id === 1"
                                color="warn"
                                mat-icon-button>
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
