<div *ngIf="lengthMarkDraft" class="length-mark">
    <h3 class="h6 condensed">Lengtemarkering invoeren</h3>
    <div class="distance">
        Huidige lengte: {{!loadingSnappedPoints ? (distanceDraftLengthMark | number) + 'm' : 'Wordt berekend...'}}
        <i *ngIf="loadingSnappedPoints" class="fas fa-spinner fa-spin"></i>
    </div>
    <div class="explain">
        <p>Versleep de kaart naar het eindpunt en klik op het gele vinkje. Na het opslaan kun je de lijn corrigeren.</p>
        <p>Je kunt tussendoor gewoon punaises aanmaken en het eindpunt later opslaan.</p>
    </div>

    <div *ngIf="lengthMarkDraft && !distanceDraftLengthMark" class="error">
        Sleep het midden van de kaart op een weg om de afstand te berekenen
    </div>
    <div *ngIf="nonRoadline" class="error">
        Lengtemarkering kon niet op een bestaande weg worden getekend
    </div>
    <div>
        <mat-checkbox [(ngModel)]="lengthMarkUseRoute" (change)="drawDraftLengthmark()" class="d-block">
            Tekenen volgens afgelegde route
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="travelModeWalking" (change)="drawDraftLengthmark()" class="d-block">
            Dit is een fiets/wandel pad
        </mat-checkbox>
    </div>

</div>
<div id="map"></div>
<div class="map-backfix-left"></div>
<div class="map-backfix-right"></div>
<app-loading *ngIf="loading" text='Momentje, de kaart wordt geladen...'></app-loading>
