import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Decoration} from '../../classes/decoration.class';
import {CustomerArea} from '../../classes/customerarea.class';

@Injectable({
    providedIn: 'root'
})
export class DecorationsService {

    constructor(private apiService: ApiService) {
    }

    private _decorations: Decoration[] = [];
    private reload = false;

    public set decorations(decorations) {
        this._decorations = decorations;
    }

    sortDecorations(group: string, decorations: Decoration[]) {
        return this.apiService.postCall$('decoration-sort', {
            group: group,
            decorations: decorations.map(p => p.id)
        });
    }

    public getList(customerArea?: CustomerArea): Promise<Decoration[]> {
        return new Promise((resolve, reject) => {
            if (this._decorations && this._decorations.length > 0 && !this.reload) {
                resolve(this.filterAndSort(this._decorations, customerArea));
            } else {
                this.apiService.getCall('decorations').then((decorations: Decoration[]) => {
                    this._decorations = decorations;
                    this.reload = false;
                    resolve(this.filterAndSort(this._decorations, customerArea));
                }, err => {
                    reject(err);
                });
            }
        });
    }

    private filterAndSort(decorations: Decoration[], customerArea?: CustomerArea) {
        if (customerArea) {
            decorations = decorations.filter(d => customerArea.decoration_types.indexOf(d.type) !== -1);
        }

        return decorations.sort((a, b) => {
            const aa = a.order ?? 99999;
            const bb = b.order ?? 99999;
            if (aa < bb) {
                return -1;
            }
            if (aa > bb) {
                return 1;
            }
            return 0;
        }).sort((a, b) => {
            return a.group_order - b.group_order;
        });
    }

    public setReload() {
        this.reload = true;
    }

    public getKeyValMap(): Promise<object> {
        return new Promise((resolve, reject) => {
            if (this._decorations && this._decorations.length > 0) {
                resolve(this.toKeyVal(this._decorations));
            } else {
                this.apiService.getCall('decorations').then((decorations: Decoration[]) => {
                    this._decorations = decorations;
                    resolve(this.toKeyVal(decorations));
                }, err => {
                    reject(err);
                });
            }
        });
    }

    private toKeyVal(arr: object[]) {
        const keyValMap = {};
        arr.forEach(item => {
            keyValMap[item['id']] = item;
        });
        return keyValMap;
    }

    public save(decoration: Decoration): Promise<Decoration> {
        if (!decoration.id) {
            this.setReload();
        }
        return this.apiService.postCall('decoration', decoration);
    }
}
