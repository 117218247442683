import {User} from './classes/user.class';
import {MoldStatus} from './mold-status';
import {MoldStatusLog} from './mold-status-log';
import {MoldMaterial} from './mold-material';
import {Decoration} from './classes/decoration.class';

export class MoldRequest {
    id: number;
    decoration_id: number;
    decoration: Decoration;
    material_id: number;
    material: MoldMaterial;
    status_id = 1;
    status: MoldStatus;
    status_log: MoldStatusLog[];
    user_id: number;
    user: User;
    manager_id: number;
    manager: User;
    images: any[];
    reason: string;
    comment: string;
    created_at: Date;
}
