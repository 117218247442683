import {Component, OnInit} from '@angular/core';
import {SortingComponent} from '../sorting.component';
import {User} from '../classes/user.class';
import {Router} from '@angular/router';
import {CordovaService} from '../cordova.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {AuthorisationService} from '../services/auth/authorisation.service';
import {LocalStorage} from '../storage.class';
import {formatDate} from '@angular/common';
import {MoldService} from '../services/mold.service';
import {MoldRequest} from '../mold-request';
import {MoldStatus} from '../mold-status';

@Component({
    selector: 'app-mold-request',
    templateUrl: './mold-request.component.html',
    styleUrls: ['./mold-request.component.scss']
})
export class MoldRequestComponent extends SortingComponent<MoldRequest> implements OnInit {

    user: User;
    statusIds = [1, 2, 4, 8];
    statusList: MoldStatus[];
    currentSort = 'created_at';
    desc = true;
    showStatusFilter = true;

    constructor(private router: Router,
                private cordovaService: CordovaService,
                private confirmDialog: ConfirmDialogService,
                private moldService: MoldService) {
        super();
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        if (!AuthorisationService.hasFeature('moldManagement')) {
            this.statusIds = [1, 2, 3, 4, 5, 6, 7, 8];
            this.showStatusFilter = false;
        }
        this.getData();
        this.user = LocalStorage.getUser();
        this.moldService.statusList().subscribe(status => {
            this.statusList = status.data;
        });
    }

    public getData() {
        this.moldService.listRequests(this.statusIds).subscribe(requests => {
            this.list = requests.data;
        });
    }

    delete(event, moldRequest: MoldRequest) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Aanvraag intrekken',
            `Weet u zeker dat u deze aanvraag aangemaakt op ${formatDate(moldRequest.created_at, 'd MMM yyyy, H:mm', 'nl')} wilt intrekken?`,
            'Intrekken',
            'Behouden'
        ).then(() => {
            this.moldService.deleteRequest(moldRequest.id).subscribe(() => {
                const index = this.list.indexOf(moldRequest);
                this.list.splice(index, 1);
            });
        }, () => {

        });
    }

}
