import {IncidentComment} from './incidentcomment.class';
import {User} from './user.class';

export class Incident {
    id: number;
    lat: number;
    lng: number;
    street: string;
    zipcode: string;
    housenumber: string;
    place: string;
    user_id: number;
    type: string;
    status: string;
    description: string;
    files: Array<any>;
    created_at: Date;
    comments: IncidentComment[];
    user: User;
}