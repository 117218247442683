<app-vvr-icon [dark]="true" [full]="true" [overlay]="true" [shadow]="false"></app-vvr-icon>
<div class="login-background">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4 login-form">
                <h1 class="h2 condensed">Je bent uitgelogd</h1>
                <p>
                    We sturen je nu terug naar het inlogscherm.
                </p>
            </div>
        </div>
    </div>
</div>
