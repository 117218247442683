import {Pipe, PipeTransform} from '@angular/core';
import {PushpinPart} from '../classes/pushpinpart.class';
import {MaterialPrice} from '../classes/materialprice.class';
import {DecorationPrice} from '../classes/decorationprice.class';
import {PricesService, PriceType, PushpinPartPrice} from '../services/prices/prices.service';
import {StatusId} from '../classes/status.class';

@Pipe({
    name: 'totalPricePushpinParts'
})
export class TotalPricePushpinPartsPipe implements PipeTransform {

    constructor(private pricesService: PricesService) {
    }

    transform(pushpinParts: PushpinPart[],
              materialPrices: MaterialPrice[],
              decorationPrices: DecorationPrice[],
              statusId: number|number[],
              customerAreaId?: number,
              inspectionId?: number,
              materialId?: number,
              specific_price = PriceType.all
    ): PushpinPartPrice {

        let ppp = pushpinParts
            .filter(p => (Array.isArray(statusId) ? statusId.includes(+p.status_id) : +p.status_id === +statusId) && (!customerAreaId || +p.customer_area_id === +customerAreaId) && (!inspectionId || inspectionId === p.inspection_id));
        if (statusId === StatusId.Concept) {
            ppp.push(...pushpinParts.filter(p => +p.status_id === 8 && (!customerAreaId || +p.customer_area_id === +customerAreaId) && (!inspectionId || inspectionId === p.inspection_id)));
        }


        if (materialId) {
            ppp = ppp.filter(p => p.material_id === materialId);
        }
        if (specific_price === PriceType.specific) {
            ppp = ppp.filter(p => p?.decoration?.specific_price);
        }
        if (specific_price === PriceType.notSpecific) {
            ppp = ppp.filter(p => !p?.decoration?.specific_price);
        }

        const priceData = new PushpinPartPrice();
        ppp.forEach(pushpinPart => {
            const price = this.pricesService
                .calculatePushpinPartPriceDetail(
                    pushpinPart,
                    materialPrices.filter(mp => mp.inspection_id === pushpinPart.inspection_id),
                    decorationPrices.filter(mp => mp.inspection_id === pushpinPart.inspection_id));
            priceData.total += price.total;
            priceData.material += price.material;
            priceData.primer += price.primer;
            priceData.blast += price.blast;
            priceData.stake_out += price.stake_out;
        });

        return priceData;
    }

}
