import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InspectionService} from '../services/inspection.service';
import {Inspection} from '../classes/inspection';
import {CustomerArea} from '../classes/customerarea.class';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {NewInspectionDialogComponent} from './new-inspection-dialog/new-inspection-dialog.component';
import {formatDate} from '@angular/common';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PushpinService} from '../services/pushpin/pushpin.service';
import {CodaltComponent} from '../codalt.component';
import {PushpinFilter} from '../pushpin-filter';
import {StatusService} from '../services/status/status.service';
import {Settings} from '../settings.class';

@Component({
    selector: 'app-choose-inspection',
    templateUrl: './choose-inspection.component.html',
    styleUrls: ['./choose-inspection.component.scss']
})
export class ChooseInspectionComponent extends CodaltComponent implements OnInit {

    @Input()
    customerArea!: CustomerArea;
    @Input()
    inspection: Inspection;

    inspections: Inspection[];

    @Output()
    inspectionChanged = new EventEmitter();

    statusMap: any;

    inspectionLock = false;

    constructor(private inspectionService: InspectionService,
                private pushpinService: PushpinService,
                private statusService: StatusService,
                private confirmDialog: ConfirmDialogService,
                private router: Router,
                private dialog: MatDialog,
                private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.statusService.getKeyValMap().then(statusMap => {
            this.statusMap = statusMap;
        });
        if (this.customerArea) {
            this.getInspections();
        }
        this.inspectionLock = this.AuthorisationService.hasFeature('unlockInspection');
    }

    lockInspection() {
        this.confirmDialog.confirm('Schouwronde vergrendelen',
            `Weet je zeker dat je deze schouwronde wilt vergrendelen?`, 'Ja', 'Nee').then(() => {

            const filter = new PushpinFilter();
            filter.status_4 = false;
            filter.status_5 = false;
            filter.status_6 = false;
            filter.status_7 = false;
            filter.status_9 = false;
            filter.status_10 = false;
            filter.status_13 = false;
            filter.status_14 = false;
            filter.status_15 = false;
            filter.status_16 = false;
            filter.extraInfo = false;
            filter.inspection_id = this.inspection.id;

            this.pushpinService.getPushpins(this.customerArea.id, filter).then(pushpins => {
                if (pushpins?.length) {
                    const pushpinParts = (Array.from(Object.values(pushpins.map(p => p.pushpin_parts))) as []).reduce((accumulator, value) => accumulator.concat(value), []).filter(pp => !!pp);
                    const statusIds = [...new Set(pushpinParts.map(pp => pp.status_id))].map(statusId => this.statusMap[statusId].name);
                    console.log(pushpinParts);
                    this.confirmDialog.confirm('Openstaande punaises',
                        `Er zijn nog ${pushpins.length} punaises met ${pushpinParts.length} delen met de status; ${statusIds}`, 'Ja', 'Nee').then(() => {
                        this.inspectionService.lockInspection(this.inspection.id).subscribe(inspection => {
                            Object.assign(this.inspection, inspection.data);
                        });
                    }, () => {
                    });
                } else {
                    this.inspectionService.lockInspection(this.inspection.id).subscribe(inspection => {
                        Object.assign(this.inspection, inspection.data);
                    });
                }
            });
        }, () => {
        });
    }

    unlockInspection() {
        this.confirmDialog.confirm('Schouwronde ontgrendelen',
            `Weet je zeker dat je deze schouwronde wilt ontgrendelen?`, 'Ja', 'Nee').then(() => {
            this.inspectionService.unlockInspection(this.inspection.id).subscribe(inspection => {
                Object.assign(this.inspection, inspection.data);
            });
        }, () => {
        });
    }

    openInspection() {
        this.inspectionChanged.emit();
        setTimeout(() => {
            const params = this.activatedRoute.snapshot.params;
            this.router.navigate(['/',
                'pushpins', params['view'],
                this.customerArea.id, params['zoom'], params?.lat, params?.lng, {inspection: this.inspection?.id}]);
        });
    }

    createInspection() {
        const newInspection = new Inspection();
        newInspection.customer_area_id = this.customerArea.id;
        newInspection.name = `${this.customerArea.name} ${formatDate(new Date(), 'MMMM yyyy', 'nl')}`;
        newInspection.inspection_type_id = 1;
        const dialogRef = this.dialog.open(NewInspectionDialogComponent, {
            panelClass: 'pushpin-dialog',
            data: newInspection
        });
        dialogRef.afterClosed().subscribe(inspection => {
            if (inspection) {
                this.customerArea.inspections.push(inspection);
                this.inspection = inspection;
                this.getInspections();
                this.openInspection();
            }
        });
    }

    editInspection() {
        const editInspection = this.inspection;
        const dialogRef = this.dialog.open(NewInspectionDialogComponent, {
            panelClass: 'pushpin-dialog',
            data: editInspection
        });
        dialogRef.afterClosed().subscribe(inspection => {
            if (inspection) {
                Object.assign(editInspection, inspection);
            } else {
                this.inspection = null;
                this.openInspection();
                this.getInspections();
            }
        });
    }

    private getInspections() {

        this.subscriptions.add(this.inspectionService.getInspections(this.customerArea.id).subscribe(inspections => {
            const today = new Date();
            this.inspections = inspections.data.filter(i => {
                const diffInHours = (today.getTime() - new Date(i.updated_at).getTime()) / 36e5;
                return this.inspectionLock || !i.lock || diffInHours < Settings.inspectionLockGrace;
            });
        }));
    }
}
