<div class="d-flex align-items-center w-100">
    <div class="flex-fill mr-1 overflow-hidden">
        <div>
            <ng-select (change)="openInspection()"
                       *ngIf="inspections"
                       [(ngModel)]="inspection"
                       appendTo="body"
                       bindLabel="name"
                       [items]="inspections"
                       [clearable]="inspections?.length > 1"
                       placeholder="{{inspection ? 'Schouwronde' : 'Selecteer een schouwronde'}}">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <i class="fa-duotone fa-lock" *ngIf="item.lock"></i> {{item.name}}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div>
        <button mat-icon-button [matMenuTriggerFor]="menu"><i class="menu-item fa-regular fa-ellipsis-stroke-vertical"></i></button>
        <mat-menu #menu="matMenu">
            <button (click)="editInspection()" mat-menu-item>
                <mat-icon class="fas fa-edit d-inline-flex align-items-center mr-1"></mat-icon>
                <span>Schouwronde aanpassen</span>
            </button>
            <button (click)="createInspection()" mat-menu-item>
                <mat-icon class="fas fa-plus d-inline-flex align-items-center mr-1"></mat-icon>
                <span>Schouwronde toevoegen</span>
            </button>
            <button mat-menu-item (click)="lockInspection()" *ngIf="!inspection?.lock && inspectionLock">
                <mat-icon class="fa-duotone fa-lock d-inline-flex align-items-center mr-1"></mat-icon>
                <span>Schouwronde vergrendelen</span>
            </button>
            <button mat-menu-item (click)="unlockInspection()" *ngIf="inspection?.lock && inspectionLock">
                <mat-icon class="fa-solid fa-unlock d-inline-flex align-items-center mr-1"></mat-icon>
                <span>Schouwronde ontgrendelen</span>
            </button>
        </mat-menu>
    </div>
</div>
