import {Injectable} from '@angular/core';
import {Settings} from '../settings.class';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiUrlService {

    constructor(private http: HttpClient) {
    }

    Init() {
        return new Promise<void>((resolve, reject) => {
            this.http.get(`${Settings.API_ENDPOINT.replace('api/', '')}url.php`).subscribe(url => {
                Settings.API_ENDPOINT = url['url'];
                resolve();
            }, () => {
                resolve();
            });
        });
    }
}
