import {Injectable} from '@angular/core';
import {ManualArea} from '../classes/manualarea.class';
import {ApiService} from './api/api.service';
import {Inspection} from '../classes/inspection';
import {InspectionType} from '../classes/inspection-type';

@Injectable({
    providedIn: 'root'
})
export class InspectionService {


    constructor(private apiService: ApiService) {
    }

    lockInspection(inspectionId) {
        return this.apiService.getCall$<Inspection>(`inspection/lock/${inspectionId}`);
    }

    unlockInspection(inspectionId) {
        return this.apiService.getCall$<Inspection>(`inspection/unlock/${inspectionId}`);
    }

    getInspectionTypes() {
        return this.apiService.getCall$<InspectionType[]>('inspection/types');
    }

    saveInspection(inspection: Inspection) {
        return this.apiService.postCall$<Inspection>('inspection', inspection);
    }

    deleteInspection(inspectionId) {
        return this.apiService.deleteCall$<Inspection>(`inspection/${inspectionId}`);
    }

    getInspection(inspectionId) {
        return this.apiService.getCall$<Inspection>(`inspection/${inspectionId}`);
    }

    getInspections(customerAreaId) {
        return this.apiService.getCall$<Inspection[]>('inspection', {customer_area_id: customerAreaId});
    }

    saveManualAreas(areas: ManualArea[]) {
        return this.apiService.postCall$<ManualArea[]>(`inspection/manual-areas`, areas);
    }

    deleteManualArea(id: number) {
        return this.apiService.deleteCall$(`inspection/manual-areas/${id}`);
    }

    changeInspectionOfParts(inspection_id, pushpin_part_ids) {
        return this.apiService.postCall$('inspection/move', {inspection_id, pushpin_part_ids});
    }

    copyPartToInspection(inspection_id, pushpin_part_ids) {
        return this.apiService.postCall$('inspection/copy', {inspection_id, pushpin_part_ids});
    }
}
