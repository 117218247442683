import {Component, ElementRef, HostBinding, OnInit} from '@angular/core';
import {AngularBingMapsComponent, MapLocation} from '../angular-bing-maps/angular-bing-maps.component';
import {ManualArea} from '../../../classes/manualarea.class';
import {CustomerArea} from '../../../classes/customerarea.class';
import {FormGroup} from '@angular/forms';
import {CordovaService} from '../../../cordova.service';
import {Router} from '@angular/router';
import {Region} from '../angular-bing-maps/region.class';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {LocationService} from '../../../pushpin-detail/location-selector-dialog/location.service';
import * as turf from '@turf/helpers';
import lineOffset from '@turf/line-offset';
import simplify from '@turf/simplify';
import lineToPolygon from '@turf/line-to-polygon';
import {InspectionService} from '../../../services/inspection.service';
import {Inspection} from '../../../classes/inspection';
import {MatCheckboxChange} from '@angular/material/checkbox';

declare var cordova;
declare var Microsoft;

@Component({
    selector: 'app-manual-areas',
    templateUrl: './manual-areas.component.html',
    styleUrls: ['./manual-areas.component.scss']
})
export class ManualAreasComponent implements OnInit {

    @HostBinding('class')
    classList = '';

    isCordova = false;
    isStreet = false;

    customerArea: CustomerArea;
    inspection: Inspection;

    mapComponent: AngularBingMapsComponent;
    manualAreasMode = false;
    manualAreaDrawing = false;

    areas = [];
    areaColors = [
        {
            name: 'Rood',
            text: 'Gebied niet schouwen',
            notif: true,
            color: 'rgba(255, 0, 0, 0.6)'
        },
        {
            name: 'Groen',
            text: 'Gebied wel schouwen',
            notif: false,
            color: 'rgba(0, 255, 0, 0.6)'
        },
        {
            name: 'Geel',
            text: 'Alleen voorrangsregels schouwen',
            notif: false,
            color: 'rgba(255, 221, 0, 0.6)'
        },
        {
            name: 'Blauw',
            notif: true,
            color: 'rgba(0, 0, 255, 0.6)'
        }
    ];

    form: FormGroup;

    constructor(private inspectionService: InspectionService,
                private cordovaService: CordovaService,
                private router: Router,
                private elRef: ElementRef,
                private locationService: LocationService,
                private confirmDialogService: ConfirmDialogService) {
    }

    public colorChange() {
        this.mapComponent.updateManualPolygons(this.areas);
    }

    ngOnInit() {
        this.isCordova = typeof cordova !== 'undefined';
    }

    public openManualAreasDialog() {
        this.manualAreasMode = true;
        this.classList = 'visible';
        this.cordovaService.setBackbuttonAction(() => {
            this.closeManualAreasDialog();
        });
    }

    public closeManualAreasDialog() {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        this.classList = '';
        setTimeout(() => {
            this.manualAreasMode = false;
            this.manualAreaDrawing = false;
            this.areas.filter(a => {
                return a.drawing === true;
            }).forEach(a => {
                a.drawing = false;
            });
        }, 250);

        this.mapComponent.updateManualPolygons(this.areas);
        this.saveAreas();
    }

    editArea(area: ManualArea) {
        this.isStreet = area.isStreet;
        this.manualAreaDrawing = false;
        this.areas.filter(a => {
            return a.drawing === true;
        }).forEach(a => {
            a.drawing = false;
        });
        this.mapComponent.updateManualPolygons(this.areas);
        this.drawArea(area);
        this.mapComponent.updateManualPolygons(this.areas);
        if (area.exteriorRing.length > 0) {
            const cntr = new MapLocation();
            cntr.lat = area.exteriorRing[area.exteriorRing.length - 1].lat;
            cntr.lng = area.exteriorRing[area.exteriorRing.length - 1].lng;
            this.mapComponent.setCenter(cntr);
            const areaRegion = new Region(area.exteriorRing);
            this.mapComponent.setZoom(
                areaRegion.bestMapView(window.innerWidth, window.innerHeight, 100)
            );
        }

    }

    changeColor(area, color) {
        area.fillColor = color.color;
        area.name = color.text;
        area.show_notification = color.notif;
        this.mapComponent.updateManualPolygons(this.areas);
    }

    addArea(street = false) {

        const manualArea = new ManualArea();
        manualArea.customer_area_id = this.customerArea.id;
        manualArea.inspection_id = this.inspection?.id;
        manualArea.fillColor = this.areaColors[0].color;
        manualArea.name = this.areaColors[0].text;
        manualArea.strokeColor = '#000000';
        manualArea.show_notification = true;
        manualArea.exteriorRing = [];
        manualArea['drawing'] = true;
        manualArea.isStreet = street;
        if (street) {
            manualArea.width = 20;
        }
        this.isStreet = street;
        this.areas.push(manualArea);

        this.manualAreaDrawing = true;
        if (this.isCordova) {
            this.setPoint();
        }
        setTimeout(() => {
            this.scrollAreasToBottom();
        });
    }

    drawArea(area) {
        if (!this.manualAreaDrawing) {
            this.manualAreaDrawing = true;
            area.drawing = true;
        }
    }

    removeArea(area) {
        this.confirmDialogService.confirm(
            'Gemarkeerd gebied verwijderen',
            `Wil je het gemarkeerde gebied ${area.name || ''} verwijderen?`,
            'ja', 'nee').then(() => {
            area.drawing = false;
            this.manualAreaDrawing = false;
            if (area.id) {
                this.inspectionService.deleteManualArea(area.id).subscribe(() => {
                    this.areas.splice(this.areas.indexOf(area), 1);
                    this.mapComponent.updateManualPolygons(this.areas);
                });
            } else {
                this.areas.splice(this.areas.indexOf(area), 1);
                this.mapComponent.updateManualPolygons(this.areas);
            }
        }, () => {
        });

    }

    setPoint(e?: object) {
        const area = this.areas.find(a => {
            return a.drawing === true;
        });
        area.exteriorRing.push({
            lat: e ? e['lat'] : this.mapComponent.mapCenter.lat,
            lng: e ? e['lng'] : this.mapComponent.mapCenter.lng
        });
        this.drawPolygon(area);
    }

    public streetWithChange() {
        this.redrawCurrentEditingArea();
    }

    public removePoint(area, index) {
        area.exteriorRing.splice(index, 1);
        this.drawPolygon(area);
    }

    public finishArea(area) {
        area.drawing = false;
        this.manualAreaDrawing = false;
        this.mapComponent.updateManualPolygons(this.areas);
        this.saveAreas();
    }

    public redrawCurrentEditingArea() {
        const area = this.areas.find(a => a.drawing === true);

        this.drawPolygon(area);
    }

    commonArea(event: MatCheckboxChange, area) {
        if (event.checked) {
            area.inspection_id = null;
        } else {
            area.inspection_id = this.inspection.id;
        }
    }

    private scrollAreasToBottom() {
        const commentLine = this.elRef.nativeElement.querySelector('#area-list');
        if (commentLine) {
            commentLine.scrollBy(0, commentLine.scrollHeight);
        }
    }

    private drawPolygon(area) {
        if (area.isStreet && area.exteriorRing.length > 1) {
            const pointList = [];
            area.exteriorRing.forEach(point => {
                pointList.push(new Microsoft.Maps.Location(
                    point['lat'],
                    point['lng']
                ));
            });
            this.locationService.getSnappedPoints(pointList).subscribe(result => {
                let invalidGeo = false;
                const options = {tolerance: 0.000001, highQuality: true};
                const geoJson = [];
                area['snappedPoints'] = [];
                result['snappedPoints'].forEach(point => {
                    geoJson.push([+point.latitude, +point.longitude]);
                });
                if (geoJson.length > 1) {
                    const orgLine = turf.lineString(geoJson);
                    const dl = lineOffset(orgLine, (area.width / 2), {units: 'meters'});
                    const dl2 = lineOffset(orgLine, -(area.width / 2), {units: 'meters'});
                    const linePoints = [];

                    dl['geometry']['coordinates'].forEach((p, i) => {
                        if (isNaN(p[0]) || Math.abs(p[0]) > 100 ||
                            (i > 0 && (Math.abs(p[0]) - Math.abs(dl['geometry']['coordinates'][i - 1][0])) > 0.2) ||
                            (i > 0 && (Math.abs(p[1]) - Math.abs(dl['geometry']['coordinates'][i - 1][1])) > 0.2)) {
                            invalidGeo = true;
                        }
                        linePoints.push([+p[0], +p[1]]);
                    });
                    dl2['geometry']['coordinates'].reverse().forEach(p => {
                        linePoints.push([+p[0], +p[1]]);
                    });
                    const line = turf.lineString(linePoints);
                    const tpoly = simplify(lineToPolygon(line), options);

                    tpoly['geometry']['coordinates'][0].forEach(p => {
                        area.snappedPoints.push({
                            lat: p[0],
                            lng: p[1]
                        });
                    });

                }
                if (invalidGeo) {
                    area.exteriorRing.splice(area.exteriorRing.length - 1, 1);
                    this.drawPolygon(area);
                } else {
                    this.mapComponent.updateManualPolygons(this.areas);
                }

            });
        } else {
            area.snappedPoints = [];
            this.mapComponent.updateManualPolygons(this.areas);
        }
    }

    private saveAreas() {
        this.inspectionService.saveManualAreas(this.areas).subscribe(areas => {
            this.customerArea.inspections.forEach(inspection => {
                inspection.manual_areas = areas.data.filter(a => a.inspection_id === inspection.id || !a.inspection_id);
            });
            if (this.inspection) {
                this.areas = this.inspection?.manual_areas;
            } else {
                this.areas = this.areas.filter(m => !m.inspection_id);
            }
        });
    }
}
