import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CodaltComponent} from '../codalt.component';
import {Routenames} from '../route-names.enum';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {DeclarationType} from '../declaration-type';
import {DeclarationService} from '../services/declaration.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-declaration-management',
    templateUrl: './declaration-management.component.html',
    styleUrls: ['./declaration-management.component.scss']
})
export class DeclarationManagementComponent extends CodaltComponent implements OnInit, OnDestroy {

    types: DeclarationType[];
    currentSort = null;
    desc = false;

    constructor(private declarationService: DeclarationService,
                private confirmDialog: ConfirmDialogService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.declarationService.listType().subscribe(equipment => {
            this.types = equipment.data;
        }));
    }

    edit(type: DeclarationType) {
        this.router.navigate([Routenames.declarationTypes, type.id]);
    }

    sortList(sortingOn) {
        let decorations = this.types;
        this.types = [];

        if (sortingOn === this.currentSort) {
            decorations = decorations.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = false;
            decorations = decorations.sort((a, b) => {
                if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                    return -1;
                }
                if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        }

        this.types = decorations;

        this.currentSort = sortingOn;
    }

    delete(event, type: DeclarationType) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Aanvraag intrekken',
            `Weet u zeker dat u declaratie type ${type.name} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.subscriptions.add(this.declarationService.deleteType(type.id).subscribe(() => {
                const index = this.types.indexOf(type);
                this.types.splice(index, 1);
            }));
        }, () => {

        });
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
