<div class="list-container">
    <div class="list-controls">
        <div *ngIf="!showPriceSelectionBar"
             class="controls left">

        </div>
        <div *ngIf="showPriceSelectionBar && selectionComponent"
             [class.active]="selectionComponent.selectionVisible"
             class="controls left">
            <div (click)="selectionComponent.toggleSelection()"
                 [class.active]="selectionComponent.selectionVisible" class="fab small"
                 id="btn-selection"
                 matTooltip="Prijs en statusbalk"
                 matTooltipPosition="after">
                <i *ngIf="selectionComponent.selectionVisible" class="fas fa-times"></i>
                <i *ngIf="!selectionComponent.selectionVisible" class="fas fa-shopping-cart"></i>
            </div>
        </div>
        <div class="search-container text-right mr-2">
            <mat-form-field *ngIf="!isSmallDevice" class="mr-2">
                <mat-label>
                    Zoeken
                </mat-label>
                <input (keyup)="search()"
                       [(ngModel)]="searchText"
                       autocomplete="off"
                       matInput
                       matTooltip="Opties: {{showPrice ? '>100 (meer/minder dan 100 euro), ': ''}} 100 (punaise 100) of straat/plaats/figuratie/omschrijving"
                       matTooltipPosition="below">
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    Sortering
                </mat-label>
                <mat-select (selectionChange)="orderChanged($event)" [(value)]="order">
                    <mat-option value="date">
                        Datum oplopend
                    </mat-option>
                    <mat-option value="-date">
                        Datum aflopend
                    </mat-option>
                    <mat-option *ngIf="showPrice" value="price">
                        Prijs oplopend
                    </mat-option>
                    <mat-option *ngIf="showPrice" value="-price">
                        Prijs aflopend
                    </mat-option>
                    <mat-option value="number">
                        Nummer oplopend
                    </mat-option>
                    <mat-option value="-number">
                        Nummer aflopend
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="controls">
            <div (click)="back()" class="fab small" id="btn-Exit"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <div (click)="excelExport()" *ngIf="AuthorisationService.hasFeature('pushpinPartExcelExport') && !isCordova" class="fab small" id="btn-exportexcel"
                 matTooltip="Exporteer delen als Excel bestand"
                 matTooltipPosition="below">
                <i class="fas fa-file-excel"></i>
            </div>
            <div (click)="zipExport()" *ngIf="AuthorisationService.hasFeature('pushpinPartZipExport') && !isCordova" class="fab small" id="btn-exportzip"
                 matTooltip="Exporteer fotos in delen als ZIP bestand"
                 matTooltipPosition="below">
                <i *ngIf="!zipExporting" class="fas fa-file-archive"></i>
                <i *ngIf="zipExporting" class="fas fa-spinner fa-spin"></i>
            </div>
            <div class="fab small" id="btn-decorations" matTooltip="Profiel aanpassen"
                 matTooltipPosition="below"
                 routerLink="/profile">
                <i class="fas fa-user"></i>
            </div>
            <div (click)="toggleFilter()" [class.active]="filterComponent.filterVisible"
                 [class.ml-3]="filterComponent.filter.filterCount" [matBadge]="filterComponent.filter.filterCount"
                 class="fab small" id="btn-filters"
                 matBadgePosition="above before"
                 matTooltip="Filters"
                 matTooltipPosition="below">
                <i *ngIf="filterComponent.filterVisible" class="fas fa-times"></i>
                <i *ngIf="!filterComponent.filterVisible" class="fas fa-filter"></i>
            </div>

        </div>
    </div>

    <div [class.disable]="filterComponent.filterVisible" class="controls-bottom">
        <div
            [routerLink]="['/pushpins/map/', customerArea?.id, mapZoom, mapCenter?.lat, mapCenter?.lng, {inspection: inspection?.id}]"
            class="fab big"
            id="btn-Mapview"
            matTooltip="Kaartweergave"
            matTooltipPosition="before">
            <i class="fas fa-map"></i>
        </div>
    </div>
    <div *ngIf="showList" class="viewport-container">
        <cdk-virtual-scroll-viewport #scrollviewport class="viewport" itemSize="207">
            <div class="list">
                <div *ngIf="(searchedPushpinParts && searchedPushpinParts.length === 0) || !searchedPushpinParts">
                    Er zijn geen markeringsitems gevonden
                </div>
                <ng-container *cdkVirtualFor="let pushpinPart of searchedPushpinParts; let i = index">
                    <div (click)="openPushpin(pushpinPart.pushpin['id'], pushpinPart.id)"
                         class="list-item curpoint item{{i}}">
                        <div [class.filter-visible]="filterComponent.filterVisible"
                             [style.background-image]="pushpinPart.images[0].image|safeBgUrl"
                             class="image">
                            <div class="date">
                                {{pushpinPart.updated_at | date : 'd MMM yyyy, H:mm'}}
                            </div>
                            <div *ngIf="showName || pushpinPart.pushpin['consumer_created']" class="username">
                                {{pushpinPart.pushpin['user_name']}}
                            </div>
                        </div>
                        <div class="info">
                            <div class="main">
                                <div class="address">
                                    <div class="h4">
                                        {{pushpinPart.pushpin.street}} {{pushpinPart.pushpin.housenumber}}
                                    </div>
                                    <div class="place">
                                        {{pushpinPart.pushpin.place}}
                                    </div>
                                </div>
                                <div class="number">
                                    <div>
                                        <div *ngIf="pushpinPartPricesMap[pushpinPart.id] && showPrice" class="price">
                                            <span
                                                class="eu">€</span> {{pushpinPartPricesMap[pushpinPart.id] | number:'1.2-2'}}
                                        </div>
                                        <div class="nr">
                                            {{pushpinPart.pushpin.number}}
                                        </div>
                                        <div *ngIf="pushpinPart['loading']">
                                            <i class="fas fa-circle-notch fa-spin"></i>
                                        </div>
                                        <i (click)="setSelection($event, pushpinPart)"
                                           *ngIf="!pushpinPart['loading'] && inspection?.inspection_type_id === 1 && !inspection?.lock &&
                                           ((inspection?.inspection_status.selection_allowed && this.AuthorisationService.hasFeature('priceList'))
                                                || (inspection?.inspection_status_id === 2 && AuthorisationService.hasFeature('pushpinPartReleaseConsumer')))"
                                           [class.fa-check-square]="pushpinPart['checked'] && !pushpinPart['loading']"
                                           [class.fa-square]="!pushpinPart['checked']"
                                           class="far"></i>
                                    </div>
                                    <div class="reference">
                                        {{pushpinPart.pushpin?.reference}}
                                    </div>
                                </div>
                            </div>
                            <div class="part">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex flex-wrap row pt-1">
                                            <div class="col-2 title">Status</div>
                                            <div class="col-4 property">
                                                <span *ngIf="pushpinPart?.deleted_at">Verwijderd&nbsp;</span>
                                                <span [class.deleted]="pushpinPart?.deleted_at">{{pushpinPart.status.name}}</span>
                                                <ng-container *ngIf="pushpinPart.status_id === 9">
                                                    <small>{{pushpinPart.updated_at | date  : 'd MMM yyyy, H:mm'}}</small>
                                                </ng-container>
                                            </div>
                                            <ng-container *ngIf="pushpinPart.decoration.name">
                                                <div class="col-2 title">{{pushpinPart.decoration.type}}</div>
                                                <div class="col-4 property">{{pushpinPart.decoration.name}}</div>
                                            </ng-container>

                                            <div class="col-2 title">Materiaal</div>
                                            <div class="col-4 property">
                                                <span
                                                    [class.no-material]="pushpinPart.exclusive_material">
                                                    {{pushpinPart.material.name}}
                                                </span>
                                                {{pushpinPart.primer ? ', Primer' : ''}}
                                            </div>
                                            <div class="col-2 title">Kleur</div>
                                            <div class="col-4 property">{{pushpinPart.paint_color.name}}</div>

                                            <div class="col-2 title">Uitzetten</div>
                                            <div class="col-4 property">{{pushpinPart.stake_out ? 'Ja' : 'Nee'}}</div>
                                            <div class="col-2 title">Stralen</div>
                                            <div class="col-4 property">{{pushpinPart.blast ? 'Ja' : 'Nee'}}</div>

                                            <ng-container *ngIf="pushpinPart.decoration.type != 'Lengtemarkering'">
                                                <div class="col-2 title">Aantal</div>
                                                <div class="col-4 property">{{pushpinPart.amount}}</div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="pushpinPart.decoration.type != 'Diversen vervangen' && !pushpinPart.decoration.specify_length">
                                                <div class="col-2 title">Oppervlakte</div>
                                                <div class="col-4 property">{{pushpinPart.surface | number:'1.0-2'}}
                                                    m<sup>2</sup>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="pushpinPart.decoration.specify_length">
                                                <div class="col-2 title">Lengte</div>
                                                <div class="col-4 property">{{pushpinPart.length| number:'1.0-0'}}
                                                    m
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="pushpinPart.overgrown">
                                                <div class="col-6 title">Overgroeide kanten</div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="pushpinPart.status_id === 9 && pushpinPart['executedDate']">
                                                <div class="col-2 title">Uitgevoerd op</div>
                                                <div
                                                    class="col-4 property">{{pushpinPart['executedDate'] | date  : 'd MMM yyyy, H:mm'}}</div>
                                            </ng-container>
                                            <div *ngIf="pushpinPart.description && showName" class="col-12">
                                                <div class="title">Opmerking</div>
                                                <div class="partDescription">
                                                    {{pushpinPart.description}}
                                                </div>
                                            </div>
                                            <div *ngIf="pushpinPart.customer_note" class="col-12">
                                                <div *ngIf="showName" class="title">Opmerking Klant</div>
                                                <div *ngIf="!showName" class="title">Opmerking</div>
                                                <div>
                                                    {{pushpinPart.customer_note}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
<app-vvr-icon [dark]="false" [full]="false" [overlay]="true" [shadow]="true"></app-vvr-icon>
