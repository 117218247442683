<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Klanten</h1>
        </div>
        <div class="controls">
            <div class="fab small"
                 id="btn-Exit"
                 matTooltip="Terug naar startscherm"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <ng-container *ngIf="AuthorisationService.hasFeature('importUpload')">
                <div class="fab small" id="btn-import" matTooltip="Importeren"
                     matTooltipPosition="below"
                     routerLink="/import">
                    <i class="fas fa-file-import"></i>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-4">
            <mat-form-field class="mr-2 w-100">
                <mat-label>
                    Zoeken
                </mat-label>
                <input (keyup)="searching.emit()"
                       (keyup.enter)="openIfOne()"
                       [(ngModel)]="searchText"
                       autocomplete="off"
                       matInput>
            </mat-form-field>
        </div>
    </div>
    <div class="customer">
        <div *ngFor="let letterGroup of parsedCustomerAreas" class="letter-group">
            <h1 class="h6">{{letterGroup.letter}}</h1>
            <div class="">
                <div (click)="selectTown(customerArea)"
                     *ngFor="let customerArea of letterGroup.areas"
                     class="customer-area"
                     [matBadgeHidden]="customerArea?.calamities?.count < 1"
                     [matBadge]="customerArea?.calamities?.count"
                     matBadgePosition="after"
                     matBadgeColor="{{customerArea.calamities.old ? 'primary' : 'accent'}}">
                    {{customerArea.name}}
                    <span>{{customerArea.kind}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="version-info">{{cordovaVersion}}</div>
</div>
