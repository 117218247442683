import {Component, OnInit} from '@angular/core';
import {Repair} from '../../classes/repair.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';
import {User} from '../../classes/user.class';
import {Platform} from '@angular/cdk/platform';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {LocationService} from '../../pushpin-detail/location-selector-dialog/location.service';
import {GpsService} from '../../gps.service';
import {MatDialog} from '@angular/material/dialog';
import {CordovaService} from '../../cordova.service';
import {CameraService} from '../../camera.service';
import {RepairService} from '../../services/repair.service';
import {formatDate} from '@angular/common';
import {RepairComment} from '../../classes/repaircomment.class';
import {PushpinPartImageViewerDialogComponent} from '../../pushpin-detail/pushpin-part-image-viewer-dialog/pushpin-part-image-viewer-dialog.component';
import {UploadFile} from '../../import/upload-file';
import {Observable} from 'rxjs';
import {CodaltComponent} from '../../codalt.component';
import {Routenames} from '../../route-names.enum';

declare var cordova;
declare var window;

@Component({
    selector: 'app-edit-repair',
    templateUrl: './edit-repair.component.html',
    styleUrls: ['./edit-repair.component.scss']
})
export class EditRepairComponent extends CodaltComponent implements OnInit {
    saving = false;
    uploading = false;
    isCordova = false;
    repair: Repair;
    form: FormGroup;
    statusList: string[];
    url = Settings.API_ENDPOINT + 'file/';
    token = LocalStorage.getUserToken();
    mayEdit = false;

    private user: User;

    constructor(public platform: Platform,
                private route: ActivatedRoute,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private locationService: LocationService,
                private gpsService: GpsService,
                private dialog: MatDialog,
                private cordovaService: CordovaService,
                private cameraService: CameraService,
                private repairService: RepairService) {
        super();
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigateByUrl('/repairs');
        });
        this.isCordova = typeof cordova !== 'undefined';
        this.user = LocalStorage.getUser();
        this.mayEdit = this.AuthorisationService.hasFeature('repairManagement');
        this.repairService.getOptions().then(option => {
            this.statusList = option.status;
        });
        this.route.params.subscribe(params => {
            const repairId = +params['id'];
            if (repairId) {
                this.repairService.get(repairId).then(repair => {

                    this.repair = repair;
                    this.orderComments();
                    this.form = this.createForm(repair);
                    this.addEmptyComment();
                });
            } else {
                this.createNewRepair();
            }
        });
    }

    createForm(repair: Repair) {
        const form = new FormGroup({
            machine_number: new FormControl(repair.machine_number, [Validators.maxLength(255)]),
            description: new FormControl(repair.description, [Validators.required, Validators.maxLength(16500)]),
            status: new FormControl(repair.status, Validators.required)
        });
        if (!this.mayEdit) {
            form.get('status').disable();
        }
        if ((this.repair.id && this.user.id !== repair.user_id && !this.mayEdit)
            || (!this.mayEdit && this.repair.status !== 'ingediend')) {
            form.get('machine_number').disable();
            form.get('description').disable();
        }

        return form;
    }

    save(returnToOverview?: boolean) {
        if (this.form.valid) {
            this.repair.machine_number = this.form.get('machine_number').value;
            this.repair.description = this.form.get('description').value;
            this.repair.status = this.form.get('status').value;
            this.repairService.savePushpin(this.repair).then(repair => {
                if (returnToOverview) {
                    this.router.navigateByUrl(`/${Routenames.repairs}`);
                } else {
                    if (!this.repair.id) {
                        this.router.navigateByUrl(`/${Routenames.repairs}/${repair.id}`);
                    } else {
                        this.repair = repair;
                        this.addEmptyComment();
                    }
                    this.form.markAsPristine();
                }
            });
        } else {
            for (const name of Object.keys(this.form.controls)) {
                this.form.controls[name].markAsTouched();
            }
        }
    }

    takePicture() {
        this.cameraService.getPicture().then(pictureUrl => {
            this.addFile(pictureUrl, formatDate(new Date(), 'dd-mm-YYYY hh:mm:ss', 'nl'), 'jpg');
        }, error => {
            console.error(error);
        });
    }

    addFile(fileUrl, name, ext, object?: Repair | RepairComment) {
        if (!object) {
            object = this.repair;
        }
        if (!Array.isArray(object.files)) {
            object.files = [];
        }
        const file = new RepairFile();
        file.name = name;
        file.file = fileUrl;
        file.ext = ext;
        object.files.push(file);
        if (typeof object['repair_id'] !== 'undefined') {
            this.repairService.saveComment(object as RepairComment);
        } else {
            this.save();
        }
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    public commentUploadFiles(event, comment: RepairComment) {
        this.uploadFiles(event, comment);
    }

    createNewRepair() {
        this.repair = new Repair();
        this.repair.status = 'ingediend';
        this.form = this.createForm(this.repair);
    }

    openFile(clickedImage, allFiles) {
        if (['jpg', 'jpeg', 'png'].indexOf(clickedImage.ext) !== -1) {
            const images = allFiles
                .filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1)
                .map(f => (f.file.substr(0, 4) !== 'http' ? this.url : '') + f.file + '?access_token=' + this.token);
            const dialogRef = this.dialog.open(PushpinPartImageViewerDialogComponent, {
                panelClass: 'image-viewer-dialog',
                data: {
                    images: images,
                    viewIndex: allFiles.filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1).indexOf(clickedImage)
                }
            });
            this.cordovaService.setBackbuttonAction(() => {
                dialogRef.close();
            });
            const subs = dialogRef.afterClosed().subscribe(() => {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl('/repairs');
                });
                subs.unsubscribe();
            });
        } else {
            window.open(this.url + clickedImage.file + '?access_token=' + this.token);
        }
    }

    addEmptyComment() {
        const newComment = new RepairComment();
        newComment.user = this.user;
        newComment.user_id = this.user.id;
        newComment.repair_id = this.repair.id;
        this.repair.comments.push(newComment);
        this.orderComments();
    }

    deleteComment(comment: RepairComment) {
        const index = this.repair.comments.indexOf(comment);
        this.repair.comments.splice(index, 1);
    }

    openCommentsBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.repair.comments && this.repair.comments.filter(c => !c.id && c.comment).length > 0) {
                this.confirmDialogService.confirm(
                    'Niet opgeslagen reactie',
                    `Je bent nog een reactie aan het schrijven.`,
                    'Reactie aanpassen',
                    'Reactie verwijderen').then(() => {
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    public openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {

            if (this.form && this.form.dirty && this.repair.id && !(this.repair.comments && this.repair.comments.filter(c => !c.id && c.comment).length > 0)) {
                this.confirmDialogService.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                    'Wijzigingen opslaan',
                    'Wijzigingen verwerpen').then(() => {
                    this.save();
                    resolve(true);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    private orderComments() {
        this.repair.comments = this.repair.comments.sort((b, a) => {
            if (a.created_at < b.created_at || !b.created_at) {
                return -1;
            }
            if (a.created_at > b.created_at || !a.created_at) {
                return 1;
            }
            return 0;
        });
    }

    private uploadFiles(files: File[], object?: Repair | RepairComment) {
        this.uploading = true;
        const uploadedFiles = [];
        const url = `${Settings.API_ENDPOINT}images`;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const uploadFile = new UploadFile();
                uploadFile.filesize = Math.round(files[i].size / 1000);
                uploadFile.file = files[i].name;
                uploadFile.ext = files[i].type;
                if (['jpg', 'jpeg', 'image/jpeg', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'image/png', 'png', 'application/pdf',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword']
                    .indexOf(uploadFile.ext.toLowerCase()) !== -1 && files[i].size < 110664300) {
                    this.makeFileRequest(url, files[i], uploadFile).subscribe(data => {
                        if (data['success']) {
                            this.addFile(data['file'], data['name'], data['ext'], object);
                            uploadFile['uploading'] = null;
                            uploadFile.ext = data['ext'];
                        } else {
                            let error = 'Een onbekende fout';
                            if (typeof data['error'] === 'object' && Array.isArray(data['error']['upload'])) {
                                error = data['error']['upload'][0];
                            } else if (typeof data['error'] === 'string') {
                                error = data['error'];
                            }
                            this.confirmDialogService.confirm(
                                'Er is iets fout gegaan met uploaden',
                                error,
                                'Oké', null);
                        }
                        uploadedFiles.push(uploadFile);
                        if (uploadedFiles.length === files.length) {
                            this.uploading = false;
                        }
                    }, error => {
                        this.confirmDialogService.confirm(
                            'Er is iets fout gegaan met uploaden',
                            'Een onbekende fout',
                            'Oké', null);
                        this.uploading = false;
                    });
                } else if (['jpg', 'jpeg', 'image/jpeg'].indexOf(uploadFile.ext) === -1) {
                    this.uploading = false;
                    this.confirmDialogService.confirm(
                        'U kunt alleen documenten uploaden',
                        'U kunt alleen jpg, doc(x), xls(x) en pdf bestanden uploaden',
                        'Oké', null);
                } else {
                    this.uploading = false;
                    this.confirmDialogService.confirm(
                        'Het bestand is te groot',
                        'Het bestand is groter dan 100Mb',
                        'Oké', null);
                }
            }
        }
    }

    private makeFileRequest(url: string, file: File, uploadFile: UploadFile): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            formData.append('type', 'file');

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                uploadFile['uploading'] = Math.round(event.loaded / event.total * 100);

            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }
}

export class RepairFile {
    file: string;
    ext: string;
    name: string;
}
