import {Pipe, PipeTransform} from '@angular/core';
import {PushpinPart} from '../classes/pushpinpart.class';
import {MaterialPrice} from '../classes/materialprice.class';
import {DecorationPrice} from '../classes/decorationprice.class';
import {PricesService, PriceType} from '../services/prices/prices.service';
import {TotalPricePushpinPartsPipe} from './total-price-pushpin-parts.pipe';
import {formatNumber} from '@angular/common';

@Pipe({
    name: 'percentPrice'
})
export class PercentPricePipe implements PipeTransform {


    constructor(private pricesService: PricesService) {
    }

    transform(pushpinParts: PushpinPart[],
              materialPrices: MaterialPrice[],
              decorationPrices: DecorationPrice[],
              statusId: number,
              statusIdCompare: number,
              customerAreaId: number,
              inspectionId?: number,
              materialId?: number,
              specific_price = PriceType.all): string {
        const statusPrice = (new TotalPricePushpinPartsPipe(this.pricesService
            )).transform(
            pushpinParts,
            materialPrices,
            decorationPrices,
            statusId,
            customerAreaId,
            inspectionId,
            materialId,
            specific_price);
        const statusPriceCompare = (new TotalPricePushpinPartsPipe(this.pricesService
        )).transform(
            pushpinParts,
            materialPrices,
            decorationPrices,
            statusIdCompare,
            customerAreaId,
            inspectionId,
            materialId,
            specific_price);
        if(!statusPriceCompare.total){
            return '-';
        }
        return `${formatNumber((statusPriceCompare.total / (statusPriceCompare.total + statusPrice.total)) * 100, 'nl', '1.0-0')}%`;
    }

}
