import {Component, OnInit} from '@angular/core';
import {CordovaService} from '../../cordova.service';
import {Router} from '@angular/router';
import {LocalStorage} from '../../storage.class';
import {User} from '../../classes/user.class';
import {CodaltComponent} from '../../codalt.component';

@Component({
    selector: 'app-management',
    templateUrl: './management.component.html',
    styleUrls: ['./management.component.scss']
})
export class ManagementComponent extends CodaltComponent implements OnInit {
    user: User;

    constructor(private router: Router,
                private cordovaService: CordovaService) {
        super();
    }

    ngOnInit(): void {
        this.user = LocalStorage.getUser();

        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
    }

}
