export class ChecklistPoint {
    id: number;
    checklist_completed_id: number;
    answer: string;
    comment: string;
    updated_at: Date;
}

export enum ChecklistPointType {
    learn = 'learn', positive = 'positive'
}
