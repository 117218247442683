<div class="pushpin-move-container">
    <div class="content" *ngIf="customerArea">
        <h1 class="h4 condensed">Delen beheren</h1>
        <div>
            <div>
                Kies hieronder een schouwronde om de punten naartoe te verplaatsen of kopiëren.
            </div>
            <ng-select [(ngModel)]="moveToInspection"
                       (change)="inspectionChanged()"
                       [items]="customerArea.inspections"
                       bindLabel="name"
                       class="w-100"
                       placeholder="Verplaatsen naar schouwronde"></ng-select>
        </div>
        <div *ngIf="moveToInspection && !selectionArea">
            <button mat-stroked-button (click)="draw()" class="w-100">
                Maak een selectie op de kaart
            </button>
        </div>
        <div *ngIf="selectionArea">
            <i>Maak een selectievak door met de <b>rechter</b>muisknop op de kaart te klikken.</i>
        </div>
        <div *ngIf="selectionArea?.exteriorRing.length > 2">
            <div class="pt-2 pb-2">
                Je hebt {{selectedPushpins?.length}} locaties met {{selectedPushpinParts?.length}} onderliggende delen geselecteerd
            </div>
            <button mat-stroked-button (click)="movePoints()" class="w-100 mb-2" *ngIf="moveToInspection" [disabled]="moving || copying">
                <ng-container *ngIf="moving">
                    Bezig met verplaatsen van {{selectedPushpinParts?.length}} delen <i class="fas fa-circle-notch fa-spin"></i>
                </ng-container>
                <ng-container *ngIf="!moving">
                    Verplaats {{selectedPushpinParts?.length}} delen
                </ng-container>
            </button>
            <button mat-stroked-button (click)="copyPoints()" class="w-100 mb-2" *ngIf="moveToInspection" [disabled]="moving || copying">
                <ng-container *ngIf="copying">
                    Bezig met kopiëren van {{selectedPushpinParts?.length}} delen <i class="fas fa-circle-notch fa-spin"></i>
                </ng-container>
                <ng-container *ngIf="!copying">
                    Kopieer {{selectedPushpinParts?.length}} delen
                </ng-container>
            </button>
            <button mat-stroked-button color="warn" (click)="clearSelection()" class="w-100" [disabled]="moving">
                Selectie van {{selectedPushpins?.length}} punten annuleren
            </button>
        </div>
    </div>
</div>
