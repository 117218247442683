import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PricesService} from '../../services/prices/prices.service';
import {PriceLog} from '../../classes/price-log';

@Component({
    selector: 'app-price-log-dialog',
    templateUrl: './price-log-dialog.component.html',
    styleUrls: ['./price-log-dialog.component.scss']
})
export class PriceLogDialogComponent implements OnInit {

    public priceLogs: PriceLog[];

    constructor(
        private pricesService: PricesService,
        public dialogRef: MatDialogRef<PriceLogDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: object) {
    }

    ngOnInit() {
        this.pricesService.getLog(this.data['priceId'], this.data['type'], this.data['inspectionId']).then(log => {
            this.priceLogs = log;
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
