<div class="dialog-container">
    <div class="dialog-close">
        <div id="btn-cancel" class="fab small active" (click)="onNoClick()">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <h1 class="condensed dialog-title">Wijzigingshistorie markeringsitem</h1>
    <div class="dialog-content">
        <div *ngFor="let item of history" [innerHTML]="item" class="log-item">
        </div>
        <div class="log-item" *ngIf="history && history.length < 1">
            Er zijn nog geen wijzigingen
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="!history"></mat-progress-bar>
    </div>
</div>

