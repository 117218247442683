<div class="dialog-container">
    <div class="dialog-close">
        <div id="btn-cancel" class="fab small active" (click)="onNoClick()">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <ng-container *ngIf="!typeSelected">
        <h1 class="condensed dialog-title">Item toevoegen</h1>
        <div class="dialog-content">
            <ng-container *ngIf="checking">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-container>
            <ng-container class="d-flex" *ngIf="!checking">
                <button mat-stroked-button (click)="selectType(false)" id="btn-create-figuration" tabindex="-1">
                    <i class="fas fa-bicycle fa-4x pt-2"></i><br>
                    Figuratie
                </button>
                <button mat-stroked-button (click)="selectType(true)" id="btn-create-lengthmark" tabindex="-1">
                    <i class="fas fa-road fa-4x pt-2"></i><br>
                    Lengtemarkering
                </button>
                <button mat-stroked-button (click)="otherType('extraCost')" id="btn-add-extra-cost" tabindex="-1" class="ml-0"
                        *ngIf="this.AuthorisationService.hasFeature('extraCostSave') && showExtraCostsButton">
                    <i class="fas fa-euro-sign fa-4x pt-2"></i><br>
                    Extra kosten
                </button>
                <button mat-stroked-button (click)="otherType('tailCharge')" id="btn-add-tail-charge" tabindex="-1" class="ml-0"
                        *ngIf="this.AuthorisationService.hasFeature('saveTailCharges') && showExtraCostsButton">
                    <i class="fas fa-percent fa-4x pt-2"></i><br>
                    Staartkosten
                </button>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="typeSelected && !locationSelected && addresses">
        <h1 class="condensed dialog-title">Adres</h1>
        <div class="dialog-content">
            <table class="table table-hover curpoint" *ngIf="typeSelected" id="adress-table">
                <tr *ngFor="let address of addresses" (click)="select(address)">
                    <td>
                        {{address.address.addressLine}}
                        <br> {{address.address.postalCode}} {{address.address.adminDistrict2}}
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="(!addresses && typeSelected) || (typeSelected && locationSelected)">
        <h1 class="condensed dialog-title">Aan het laden...</h1>
        <div class="dialog-content">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </ng-container>
</div>
