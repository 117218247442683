import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {map} from 'rxjs/operators';
import {LocalStorage} from '../storage.class';

@Injectable({
    providedIn: 'root'
})
export class TwoFactorService {


    constructor(private apiService: ApiService) {

    }

    validate(code: string) {
        return this.apiService.postCall$<string>('auth/2fa', {code})
            .pipe(map(response => this.handleResponse(response.data)));
    }

    get2FaQr() {
        return this.apiService.getCall$<{ secret, qr }>('auth/2fa');
    }

    save2Fa(code: string, secret: string) {
        return this.apiService.postCall$<string>('auth/2fa/setup', {code, secret})
            .pipe(map(response => this.handleResponse(response.data)));
    }

    setupEmail() {
        return this.apiService.getCall$('auth/2fa/setup/email');
    }

    private handleResponse(authResponse: string) {
        LocalStorage.twoFaHash = authResponse;
        return authResponse;
    }
}
