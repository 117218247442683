import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {PushpinPart} from '../classes/pushpinpart.class';
import {combineLatest} from 'rxjs';
import {StatusService} from './status/status.service';
import {MaterialService} from './material/material.service';
import {DecorationsService} from './decorations/decorations.service';
import {PaintColorService} from './paintColor/paint-color.service';
import {PricesService} from './prices/prices.service';

@Injectable({
    providedIn: 'root'
})
export class ChangesService {

    constructor(private apiService: ApiService,
                private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService,
                private pricesService: PricesService) {
    }

    public getChanges(customerAreaId, inspectionId): Promise<PushpinPartChange[]> {
        return new Promise((resolve, reject) => {
            const status$ = this.statusService.getKeyValMap();
            const decorations$ = this.decorationsService.getKeyValMap();
            const materials$ = this.materialService.getMaterialsAsKeyValueMap(true);
            const paintColor$ = this.paintColorService.getKeyValMap();
            const changes$ = this.apiService.getCall<PushpinPart[]>(`inspection/finishedPartsLogChanges`, {'inspection_id': inspectionId});

            const price$ = this.pricesService.getItemsWithPrices([inspectionId]);
            combineLatest([status$, decorations$, materials$, paintColor$, changes$, price$])
                .subscribe(([statusMap, decorationMap, materialMap, paintColorMap, changedPushpinsData, prices]) => {
                    const changedLines = [];
                    const extraCostsPricesMap = {};
                    prices['extraCostsPrices'].filter(_ecPrice => {
                        return _ecPrice.customer_area_id === customerAreaId;
                    }).forEach(price => {
                        extraCostsPricesMap[price.extra_cost_type_id] = price.price;
                    });
                    const materialPrices = prices['materialPrices'];
                    const decorationPrices = prices['decorationPrices'];

                    const keyValMap = {};
                    keyValMap['status_id'] = statusMap;
                    keyValMap['decoration_id'] = decorationMap;
                    keyValMap['material_id'] = materialMap;
                    keyValMap['paint_color_id'] = paintColorMap;
                    decorationPrices.forEach(_decorationPrice => {
                        _decorationPrice.decoration = keyValMap['decoration_id'][_decorationPrice.decoration_id];
                        _decorationPrice.material = keyValMap['material_id'][_decorationPrice.material_id];
                        _decorationPrice.paint_color = keyValMap['paint_color_id'][_decorationPrice.paint_color_id];
                    });
                    materialPrices.forEach(_materialPrice => {
                        _materialPrice.material = keyValMap['material_id'][_materialPrice.material_id];
                        _materialPrice.paint_color = keyValMap['paint_color_id'][_materialPrice.paint_color_id];
                    });
                    const changedPushpins = changedPushpinsData;
                    changedPushpins.sort((a, b) => {
                        return a.pushpin_id - b.pushpin_id;
                    })
                        .forEach(changedPushpin => {
                            changedPushpin.decoration = keyValMap['decoration_id'][changedPushpin.decoration_id];
                            changedPushpin.material = keyValMap['material_id'][changedPushpin.material_id];
                            changedPushpin.paint_color = keyValMap['paint_color_id'][changedPushpin.paint_color_id];
                            changedPushpin.status = keyValMap['status_id'][changedPushpin.status_id];
                            const changeLine = {};
                            const decoration = this.pricesService.getSpecificPricePushpinPartDecoration(changedPushpin, decorationPrices);

                            if (!changedPushpin.decoration) {
                                return;
                            }
                            if (['surface', 'amount', 'length'].indexOf(changedPushpin['field']) !== -1) {
                                if (!changedPushpin.decoration.specific_price) {
                                    this.calculadeChangeForSurfaceCalc(changedPushpin);
                                    const surfaceInM2 = this.pricesService.calculateSurface(changedPushpin);
                                    const material = this.pricesService.getPushpinPartMaterial(changedPushpin, materialPrices);
                                    if (material) {
                                        changeLine['name'] = changedPushpin.material.name + ' ' +
                                            ((changedPushpin.paint_color_id === 1 || changedPushpin.material_id > 5) ? '' : changedPushpin.paint_color.name);
                                        changeLine['priceTotal'] = surfaceInM2 * material.price;
                                        changeLine['price'] = material.price;
                                        changeLine['surface'] = surfaceInM2;
                                        changeLine['unit'] = `m<sup>2</sup>`;
                                    }
                                } else {
                                    this.calculadeChangeForSurfaceCalc(changedPushpin);
                                    if (decoration) {
                                        changeLine['name'] = changedPushpin.material.name + ' ' + decoration.decoration.name;
                                        if (changedPushpin.paint_color.id !== 1) {
                                            changeLine['name'] += ' ' + changedPushpin.paint_color.name;
                                        }
                                        changeLine['priceTotal'] = this.pricesService.calculatePriceSpecificPriceDecoration(
                                            decoration,
                                            changedPushpin,
                                            0);
                                        changeLine['price'] = decoration.price;
                                        if (changedPushpin.decoration.specify_length) {
                                            changeLine['surface'] = changedPushpin.length;
                                            changeLine['unit'] = 'm';
                                        } else {
                                            if (!changedPushpin.decoration.surface) {
                                                changeLine['unit'] = `m<sup>2</sup>`;
                                            }
                                            changeLine['surface'] = changedPushpin.amount;
                                        }
                                    }
                                }
                            } else {
                                changedPushpin['negative'] = false;
                                if (changedPushpin['old_integer'] > changedPushpin['new_integer']) {
                                    changedPushpin['negative'] = true;
                                }
                                this.setOldValueIfChangeIsNegative(changedPushpin, changedPushpins);
                                const surfaceInM2 = this.pricesService.calculateSurface(changedPushpin);

                                if (changedPushpin['field'] === 'primer') {
                                    changeLine['name'] = 'Primer';
                                    changeLine['priceTotal'] = this.pricesService.calculateM2materialPrice(
                                        surfaceInM2, 0, (!changedPushpin.decoration.specific_price ? 6 : 13),
                                        materialPrices);
                                    changeLine['price'] = this.pricesService.calculateM2materialPrice(
                                        1, 0, (!changedPushpin.decoration.specific_price ? 6 : 13),
                                        materialPrices);
                                }
                                if (changedPushpin['field'] === 'blast') {
                                    changeLine['name'] = 'Stralen';
                                    changeLine['priceTotal'] = this.pricesService.calculateM2materialPrice(
                                        surfaceInM2, 0, 7,
                                        materialPrices);
                                    changeLine['price'] = this.pricesService.calculateM2materialPrice(
                                        1, 0, 7,
                                        materialPrices);
                                }
                                changeLine['unit'] = `m<sup>2</sup>`;
                                changeLine['surface'] = surfaceInM2;
                                if (!changedPushpin.decoration.specific_price) {
                                    if (changedPushpin['field'] === 'stake_out') {
                                        changeLine['name'] = 'Uitzetten figuraties';
                                        changeLine['priceTotal'] = this.pricesService.calculateM2materialPrice(
                                            surfaceInM2, 0, 9,
                                            materialPrices);
                                        changeLine['price'] = this.pricesService.calculateM2materialPrice(
                                            1, 0, 9,
                                            materialPrices);
                                    }
                                } else {
                                    if (changedPushpin['field'] === 'stake_out') {
                                        changeLine['name'] = 'Uitzetten belijning';
                                        const stake_out = materialPrices.filter(_material => {
                                            return _material.material_id === (decoration.decoration.specify_length ? 8 : 9);
                                        })[0];
                                        changeLine['priceTotal'] = (decoration.decoration.specify_length ? changedPushpin.length : changedPushpin.amount) * stake_out.price;
                                        changeLine['price'] = stake_out.price;
                                        if (decoration.decoration.specify_length) {
                                            changeLine['unit'] = `m`;
                                            changeLine['surface'] = changedPushpin.length;
                                        }
                                    }
                                }

                            }
                            changeLine['negative'] = changedPushpin['negative'];
                            changeLine['pushpin_id'] = changedPushpin.pushpin_id;
                            changeLine['location'] = `Locatie ${changedPushpin['number']} - ${changedPushpin['street']} ${changedPushpin['housenumber']}`;
                            changeLine['added'] = changedPushpin['added'];
                            changedLines.push(changeLine);
                            if (['surface', 'amount', 'length'].indexOf(changedPushpin['field']) !== -1) {
                                if (changedPushpin.primer) {
                                    const primerChange = changedPushpins.find(change => {
                                        return change['pushpin_part_id'] === changedPushpin['pushpin_part_id'] && change['field'] === 'primer';
                                    });
                                    if (!primerChange) {
                                        const otherChangeLine = {};
                                        const surfaceInM2 = this.pricesService.calculateSurface(changedPushpin);
                                        otherChangeLine['name'] = 'Primer';
                                        otherChangeLine['priceTotal'] = this.pricesService.calculateM2materialPrice(surfaceInM2, 0, changedPushpin.primer, materialPrices);
                                        otherChangeLine['price'] = this.pricesService.calculateM2materialPrice(1, 0, changedPushpin.primer, materialPrices);
                                        otherChangeLine['surface'] = surfaceInM2;
                                        otherChangeLine['negative'] = changedPushpin['negative'];
                                        otherChangeLine['unit'] = `m<sup>2</sup>`;
                                        otherChangeLine['pushpin_id'] = changedPushpin.pushpin_id;
                                        otherChangeLine['added'] = changedPushpin['added'];
                                        changedLines.push(otherChangeLine);
                                    }
                                }
                                if (changedPushpin.blast) {
                                    const blastChange = changedPushpins.find(change => {
                                        return change['pushpin_part_id'] === changedPushpin['pushpin_part_id'] && change['field'] === 'blast';
                                    });
                                    if (!blastChange) {
                                        const otherChangeLine = {};
                                        const surfaceInM2 = this.pricesService.calculateSurface(changedPushpin);
                                        otherChangeLine['name'] = 'Stralen';
                                        otherChangeLine['priceTotal'] = this.pricesService.calculateM2materialPrice(
                                            surfaceInM2, 0, 7,
                                            materialPrices);
                                        otherChangeLine['price'] = this.pricesService.calculateM2materialPrice(
                                            1, 0, 7,
                                            materialPrices);
                                        otherChangeLine['surface'] = surfaceInM2;
                                        otherChangeLine['negative'] = changedPushpin['negative'];
                                        otherChangeLine['unit'] = `m<sup>2</sup>`;
                                        otherChangeLine['pushpin_id'] = changedPushpin.pushpin_id;
                                        otherChangeLine['added'] = changedPushpin['added'];
                                        changedLines.push(otherChangeLine);
                                    }
                                }
                                if (changedPushpin.stake_out) {
                                    const stakeOutChange = changedPushpins.find(change => {
                                        return change['pushpin_part_id'] === changedPushpin['pushpin_part_id'] && change['field'] === 'stake_out';
                                    });
                                    if (!stakeOutChange) {
                                        const otherChangeLine = {};
                                        const surfaceInM2 = this.pricesService.calculateSurface(changedPushpin);
                                        if (!changedPushpin.decoration.specific_price) {
                                            otherChangeLine['name'] = 'Uitzetten figuraties';
                                            otherChangeLine['priceTotal'] = this.pricesService.calculateM2materialPrice(
                                                surfaceInM2, 0, 9,
                                                materialPrices);
                                            otherChangeLine['price'] = this.pricesService.calculateM2materialPrice(
                                                1, 0, 9,
                                                materialPrices);

                                        } else {
                                            otherChangeLine['name'] = 'Uitzetten belijning';
                                            const stake_out = materialPrices.filter(_material => {
                                                return _material.material_id === (decoration.decoration.specify_length ? 8 : 9);
                                            })[0];
                                            otherChangeLine['priceTotal'] = (decoration.decoration.specify_length ? changedPushpin.length : changedPushpin.amount) * stake_out.price;
                                            otherChangeLine['price'] = stake_out.price;
                                            if (decoration.decoration.specify_length) {
                                                otherChangeLine['unit'] = `m`;
                                                otherChangeLine['surface'] = changedPushpin.length;
                                            }

                                        }
                                        otherChangeLine['pushpin_id'] = changedPushpin.pushpin_id;
                                        otherChangeLine['negative'] = changedPushpin['negative'];
                                        otherChangeLine['added'] = changedPushpin['added'];
                                        changedLines.push(otherChangeLine);
                                    }

                                }
                            }
                        });
                    resolve(changedLines);
                });

        });
    }

    private setOldValueIfChangeIsNegative(changedPushpin, changedPushpins) {
        if (changedPushpin['negative']) {
            const alsoChangedDecorationSurface = changedPushpins.find(change => {
                return change['pushpin_part_id'] === changedPushpin['pushpin_part_id'] && ['surface', 'amount', 'length'].indexOf(change['field']) !== -1;
            });
            if (alsoChangedDecorationSurface) {
                this.setOldValueToPushpinPart(alsoChangedDecorationSurface);
                changedPushpin.length = alsoChangedDecorationSurface.length;
                changedPushpin.amount = alsoChangedDecorationSurface.amount;
                changedPushpin.surface = alsoChangedDecorationSurface.surface;
            }
        }
    }

    private setOldValueToPushpinPart(changedPushpin) {
        if (changedPushpin['field'] === 'length') {
            changedPushpin.length = parseFloat(changedPushpin['old']);
        }
        if (changedPushpin['field'] === 'amount') {
            changedPushpin.amount = parseFloat(changedPushpin['old_integer'] + '');
        }
        if (changedPushpin['field'] === 'surface') {
            changedPushpin.surface = parseFloat(changedPushpin['old']);
        }
    }

    private calculadeChangeForSurfaceCalc(changedPushpin) {
        changedPushpin['negative'] = false;
        if (changedPushpin['field'] === 'length') {
            changedPushpin.length = (parseFloat(changedPushpin['new']) - parseFloat(changedPushpin['old']));
        }
        if (changedPushpin.length < 0) {
            changedPushpin['negative'] = true;
            changedPushpin.length = Math.abs(changedPushpin.length);
        }
        if (changedPushpin['field'] === 'amount') {
            changedPushpin.amount = (parseFloat(changedPushpin['new_integer'] + '') - parseFloat(changedPushpin['old_integer'] + ''));
        }
        if (changedPushpin.amount < 0) {
            changedPushpin['negative'] = true;
            changedPushpin.amount = Math.abs(changedPushpin.amount);
        }
        if (changedPushpin['field'] === 'surface') {
            changedPushpin.surface = (parseFloat(changedPushpin['new']) - parseFloat(changedPushpin['old']));
        }
        if (changedPushpin.surface < 0) {
            changedPushpin['negative'] = true;
            changedPushpin.surface = Math.abs(changedPushpin.surface);
        }
    }
}


export class PushpinPartChange {
    name: string;
    priceTotal: number;
    price: number;
    surface: number;
    unit: string;
    negative: boolean;
    pushpin_id: number;
    location: string;
    added: boolean;
}

