import {ChecklistAnswer} from './checklist-answer';
import {ChecklistPoint} from './checklist-point';
import {ChecklistAction} from './checklist-action';
import {Checklist} from './checklist';
import {User} from './user.class';

export class ChecklistCompleted {
    id: number;
    checklist_id: number;
    worknumber: string;
    description: string;
    location: string;
    lat: number;
    lng: number;
    foreman_id: number;
    inspector_id: number;
    foreman: User;
    inspector: User;
    final: boolean;
    created_at: Date;
    updated_at: Date;
    checklist: Checklist;
    answers: ChecklistAnswer[] = [];
    points: ChecklistPoint[] = [];
    actions: ChecklistAction[] = [];
}
