import {Component, OnInit} from '@angular/core';
import {SortingComponent} from '../sorting.component';
import {PpeRequest} from '../ppe-request';
import {Router} from '@angular/router';
import {CordovaService} from '../cordova.service';
import {PpeService} from '../services/ppe.service';
import {LocalStorage} from '../storage.class';
import {User} from '../classes/user.class';
import {formatDate} from '@angular/common';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PpeStatus} from '../ppe-status';
import {AuthorisationService} from '../services/auth/authorisation.service';

@Component({
    selector: 'app-ppe-request',
    templateUrl: './ppe-request.component.html',
    styleUrls: ['./ppe-request.component.scss']
})
export class PpeRequestComponent extends SortingComponent<PpeRequest> implements OnInit {

    user: User;
    statusIds = [1, 2, 4, 8];
    statusList: PpeStatus[];
    currentSort = 'created_at';
    desc = true;
    showStatusFilter = true;

    constructor(private router: Router,
                private cordovaService: CordovaService,
                private confirmDialog: ConfirmDialogService,
                private ppeService: PpeService) {
        super();
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        if (!AuthorisationService.hasFeature('pbmManagement')) {
            this.statusIds = [1, 2, 3, 4, 5, 6, 7, 8];
            this.showStatusFilter = false;
        }
        this.getData();
        this.user = LocalStorage.getUser();
        this.ppeService.statusList().subscribe(status => {
            this.statusList = status.data;
        });
    }

    public getData() {
        this.ppeService.listRequests(this.statusIds).subscribe(requests => {
            this.list = requests.data;
        });
    }

    delete(event, ppeRequest: PpeRequest) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Aanvraag intrekken',
            `Weet u zeker dat u deze aanvraag aangemaakt op ${formatDate(ppeRequest.created_at, 'd MMM yyyy, H:mm', 'nl')} wilt intrekken?`,
            'Intrekken',
            'Behouden'
        ).then(() => {
            this.ppeService.deleteRequest(ppeRequest.id).subscribe(() => {
                const index = this.list.indexOf(ppeRequest);
                this.list.splice(index, 1);
            });
        }, () => {

        });
    }

}
