import {CustomerArea} from './customerarea.class';

export class ManualArea {
    id: number;
    name: string;
    fillColor: string;
    strokeColor: string;
    exteriorRing: Array<any>;
    snappedPoints: Array<any>;
    width = 20;
    show_notification: boolean;
    customer_area_id: number;
    inspection_id: number;
    isStreet = false;
    customer_area: CustomerArea;

}
