import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Repair} from '../classes/repair.class';
import {RepairComment} from '../classes/repaircomment.class';

@Injectable({
    providedIn: 'root'
})
export class RepairService {

    constructor(private apiService: ApiService) {
    }

    getList(final = false): Promise<Repair[]> {
        const param = final ? {final} : {};
        return this.apiService.getCall(`repair`, param);
    }

    get(id: number): Promise<Repair> {
        return this.apiService.getCall(`repair/${id}`);
    }

    delete(id: number): Promise<Repair> {
        return this.apiService.deleteCall(`repair/${id}`);
    }

    savePushpin(repair: Repair): Promise<Repair> {
        return this.apiService.postCall('repair', repair);
    }

    getOptions(): Promise<RepairOptions> {
        return this.apiService.getCall(`repair/options`);
    }

    saveComment(comment: RepairComment): Promise<RepairComment> {
        return this.apiService.postCall('repair/comment', comment);
    }

    deleteComment(id: number): Promise<boolean> {
        return this.apiService.deleteCall(`repair/comment/${id}`);
    }
}

export class RepairOptions {
    status: string[];
}
