import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

declare var tinymce: any;

@Component({
    selector: 'simple-tiny',
    template: `
        <div id="{{uniqueId}}{{propertyName}}"></div>`
})
export class SimpleTinyComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() menubar = true;
    @Input() fullscreen = true;
    @Input() resize = false;
    @Input() minHeight = 400;
    @Input() elementId: String;
    @Input() content: any;
    @Input() propertyName = 'content';

    @Output() onEditorKeyup = new EventEmitter<any>();

    @HostBinding('style.min-height.px') mHeight;

    uniqueId: String;
    editor;
    timeoutId;

    constructor(private elemRef: ElementRef) {
        this.uniqueId = Math.floor(Math.random() * Math.floor(Math.random() * Date.now())) + '';
    }

    updateview(editor) {
        const editorcontent = editor.getContent({format: 'html'}).trim();
        if (this.content instanceof FormControl) {
            this.content.setValue(editorcontent);
            this.content.markAsDirty();
        } else {
            this.content[this.propertyName] = editorcontent;
        }
    }

    ngOnChanges(changes) {
        if (changes.elementId?.currentValue !== changes.elementId?.previousValue) {
            this.reinit();
        } else {
            if (this.editor) {
                if (!this.content[this.propertyName]) {
                    this.content[this.propertyName] = '';
                }
                this.editor.setContent(this.content[this.propertyName]);
                this.editor.undoManager.clear();
            }
        }
    }

    reinit() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
            this.mHeight = this.elemRef.nativeElement.clientHeight;
            tinymce.remove('#' + this.uniqueId + this.propertyName);
            this.initTiny();
        }, 250);
    }

    ngAfterViewInit() {
        this.initTiny();
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
    }

    private initTiny() {
        setTimeout(() => {
            tinymce.init({
                selector: '#' + this.uniqueId + this.propertyName,
                base_url: '/tinymce',
                contextmenu: false,
                suffix: '.min',
                language: 'nl',
                menubar: this.menubar ? 'insert edit format table' : '',
                resize: this.resize,
                force_br_newlines: false,
                force_p_newlines: false,
                forced_root_block: '',
                menu: {
                    insert: {title: 'Invoegen', items: 'charmap'},
                    edit: {title: 'Bewerken', items: 'undo redo | cut copy paste pastetext | selectall | code '},
                    format: {title: 'Stijl', items: ' bold italic underline | align | forecolor backcolor | removeformat '},
                    table: {title: 'Tabel', items: 'inserttable tableprops | cell row column'}
                },
                plugins: ['link', 'media', 'paste', 'table', 'lists', 'advlist', 'fullscreen', 'charmap'],
                toolbar1: 'undo redo | bold italic | bullist numlist | link | indent outdent ' + (this.fullscreen ? ' | fullscreen' : ''),
                toolbar2: '',
                toolbar3: '',
                quickbars_selection_toolbar: 'bold italic underline quicklink h2 h3 charmap',
                skin: 'oxide',
                table_default_styles: {
                    width: '100%'
                },
                table_default_attributes: {
                    cellspacing: 0
                },
                style_formats: [
                    {title: 'Kop 1', block: 'h1'},
                    {title: 'Kop 2', block: 'h2'},
                    {title: 'Kop 3', block: 'h3'},
                    {title: 'Kleiner', inline: 'span', classes: 'textsmall'},
                    {title: 'Groter', inline: 'span', classes: 'textlarge'}
                ],
                height: this.mHeight ? this.mHeight : this.minHeight,
                min_height: this.minHeight,
                setup: editor => {
                    this.editor = editor;

                    editor.on('ExecCommand', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                    editor.on('change', () => {

                        editor.save();
                        if (this.content instanceof FormControl) {
                            this.content.markAllAsTouched();
                        }
                        this.updateview(editor);
                    });

                    editor.on('ObjectResized', () => {
                        editor.save();
                        this.updateview(editor);
                    });
                }
            });

            if (!this.content[this.propertyName]) {
                this.content[this.propertyName] = '';
            }

            if (this.editor) {
                setTimeout(() => {
                    this.editor.setContent(this.content[this.propertyName]);
                }, 300);
            }
        });
    }

}
