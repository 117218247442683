<form *ngIf="form" [formGroup]="form">

    <div class="row">
        <div class="col-12 col-sm-8">
            <div class="row">

                <mat-form-field *ngIf="pushpinPart.material_id !== 5" appearance="fill" class="col-sm-7 col-12">
                    <mat-label>
                <span class="ucfirst">
                    {{pushpinPart.decoration.decoration_group}}
                </span>
                        <span
                            *ngIf="pushpinPart.decoration.decoration_group == 'Lengtemarkering' && !pushpin.lengthmark"> (kort)</span>
                    </mat-label>
                    <mat-select (selectionChange)="decorationChange($event)"
                                formControlName="decoration_id"
                                id="{{uniqueId}}decoration"
                                placeholder="Figuratie">
                        <mat-option *ngFor="let decoration of decorations"
                                    [value]="decoration.id">
                            {{decoration.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div
                    *ngIf="AuthorisationService.hasFeature('customerAreaWithWork') && (pushpinPart.status_id === 3 || pushpinPart.status_id === 4) && executionAllowed"
                    class="col-12 col-sm-5">
                    <mat-checkbox (change)="realizedCheckboxChange($event)" [checked]="realizedCheckbox"
                                  class="mt-3 d-block"
                                  id="realized" name="blast">Gerealiseerd
                    </mat-checkbox>
                </div>
                <div
                    *ngIf="AuthorisationService.hasFeature('statusGiveOrder') && (pushpinPart.status_id === 2 || pushpinPart.status_id === 3) && pushpinPart.inspection.inspection_status.selection_allowed"
                    class="col-12 col-sm-5">
                    <mat-checkbox (change)="freeForExecutionCheckboxChange($event)" [checked]="freeForExecution"
                                  class="mt-3 d-block"
                                  id="freeForExecution"
                                  name="freeForExecution">Vrij voor uitvoering
                    </mat-checkbox>
                </div>
                <mat-form-field
                    *ngIf="AuthorisationService.hasFeature('pushpinPartStatusChangeAsNotVVRuserAllowd') && initialStatus !== undefined && initialStatus !== null"
                    appearance="fill"
                    class="col-sm-5 col-12">
                    <mat-label>Status</mat-label>
                    <mat-select (selectionChange)="statusChange($event)"
                                formControlName="status_id"
                                id="{{uniqueId}}status"
                                placeholder="Status">
                        <mat-option *ngFor="let status of statusList"
                                    [class.bold]="initialStatus.id === status.id"
                                    [disabled]="statusDisabled(status)"
                                    [value]="status.id">
                            {{status.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="dateExecuted">
                        Uitgevoerd op {{dateExecuted | date  : 'd MMM yyyy, H:mm'}}
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field *ngIf="pushpinPart.decoration.type != 'Diversen vervangen'"
                                appearance="fill"
                                class="col-md-5 col-sm-6 col-5">
                    <mat-label>CROW</mat-label>
                    <mat-select formControlName="crow" id="{{uniqueId}}crow" placeholder="A+ - D">
                        <mat-option value="A+">A+</mat-option>
                        <mat-option value="A">A</mat-option>
                        <mat-option value="B">B</mat-option>
                        <mat-option value="C">C</mat-option>
                        <mat-option value="D">D</mat-option>
                    </mat-select>
                    <mat-error>Selecteer een waarde</mat-error>
                    <button (click)="crowDialog($event)" aria-label="Clear" mat-button mat-icon-button matSuffix>
                        <mat-icon class="fas fa-question"></mat-icon>
                    </button>
                </mat-form-field>


                <mat-form-field appearance="fill"
                                class="col-md-7 col-sm-6 col-7">
                    <mat-label>Materiaal</mat-label>
                    <mat-select (selectionChange)="materialChange($event)"
                                formControlName="material_id"
                                id="{{uniqueId}}material" placeholder="Materiaal">
                        <mat-option *ngFor="let material of materials" [disabled]="material.id === 5"
                                    [value]="material.id">
                            {{material.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Selecteer een materiaal</mat-error>
                </mat-form-field>

            </div>

            <div class="row">
                <mat-form-field
                    *ngIf="pushpinPart.material.color"
                    appearance="fill"
                    class="col-sm-4">
                    <mat-label>Kleur</mat-label>
                    <mat-select formControlName="paint_color_id"
                                id="{{uniqueId}}paintcolor" placeholder="Verf kleur">
                        <mat-option *ngFor="let paintColor of paintColors" [value]="paintColor.id">
                            {{paintColor.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Selecteer een kleur</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="pushpinPart.decoration.specify_length"
                                appearance="fill"
                                class="col-8">
                    <mat-label>Lengte</mat-label>
                    <input class="inpLength" formControlName="length" id="{{uniqueId}}length" matInput
                           placeholder="Lengte in meters" step="0.01"
                           type="number">
                    <div *ngIf="pushpin.lengthmark" class="d-flex" matSuffix>
                        <button (click)="multiplyLength(2)" color="accent" mat-icon-button>
                            2 X
                        </button>
                        <button (click)="multiplyLength(3)" color="accent" mat-icon-button>
                            3 X
                        </button>
                        <button (click)="multiplyLength(4)" color="accent" mat-icon-button>
                            4 X
                        </button>
                        <button (click)="multiplyLength(null)" color="accent" mat-icon-button>
                            <i class="fas fa-undo"></i>
                        </button>
                    </div>
                    <mat-error>Vul een lengte in meters in</mat-error>
                    <mat-hint *ngIf="pushpinPart.final_length && pushpinPart.final_length != pushpinPart.length"
                              matSuffix>
                        <span class="changed-val">{{pushpinPart.final_length | number:'1.0-2'}}</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field *ngIf="!pushpinPart.decoration.specify_length && pushpinPart.decoration.surface !== null"
                                appearance="fill"
                                class="col-md-5 col-6">
                    <mat-label>Aantal</mat-label>
                    <input formControlName="amount" id="{{uniqueId}}amount" matInput name="amount" placeholder="Aantal"
                           type="number">
                    <mat-error>Dit veld mag niet kleiner zijn dan 1</mat-error>
                    <mat-hint *ngIf="pushpinPart.final_amount && pushpinPart.final_amount != pushpinPart.amount"
                              matSuffix>
                        <span class="changed-val">{{pushpinPart.final_amount}}</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field *ngIf="pushpinPart.decoration.surface === null &&
                        pushpinPart.decoration.type !== 'Diversen vervangen' &&
                        pushpinPart.decoration.decoration_group !== 'Voorgevormd' &&
                        pushpinPart.decoration.type" appearance="fill"
                                class="col-sm-6">
                    <mat-label>Oppervlakte</mat-label>
                    <input class="inpSurface" formControlName="surface" id="{{uniqueId}}surface" matInput
                           placeholder="Oppervlakte in m²"
                           step="0.01" type="number">
                    <mat-error>Vul een oppervlakte in m² in</mat-error>
                    <mat-hint *ngIf="pushpinPart.final_surface && pushpinPart.final_surface != pushpinPart.surface"
                              matSuffix>
                        <span class="changed-val">{{pushpinPart.final_surface | number:'1.0-2'}}</span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="row" *ngIf="AuthorisationService.hasFeature('changePushpinPartInspection')">
                <mat-form-field
                    *ngIf="inspections"
                    appearance="fill"
                    class="col-12">
                    <mat-label>{{inspection ? 'Schouwronde' : 'Selecteer een schouwronde'}}</mat-label>
                    <mat-select formControlName="inspection_id"
                                id="{{uniqueId}}inspection_id" placeholder="Selecteer een schouwronde">
                        <mat-option *ngFor="let inspection of inspections" [value]="inspection.id">
                            {{inspection.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Selecteer een schouwronde</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-4 d-flex d-sm-block flex-wrap mt-3 mt-sm-0 mb-2 mb-sm-0">
            <ng-container *ngIf="primers?.length === 1">
                <mat-checkbox class="d-block inpPrimer mr-3 mb-3 mb-sm-0" formControlName="primer"
                              id="{{uniqueId}}primer" name="primer">
                    Primer
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="primers?.length > 1">
                <mat-form-field appearance="fill"
                                class="w-100">
                    <mat-label>Primer</mat-label>
                    <mat-select formControlName="primer"
                                placeholder="Primer">
                        <mat-option [value]="null">
                            Geen
                        </mat-option>
                        <mat-option *ngFor="let material of primers" [value]="material.id">
                            {{material.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Selecteer een materiaal</mat-error>
                </mat-form-field>
            </ng-container>
            <mat-checkbox class="d-block inpBlast mr-3 mb-3 mb-sm-0" formControlName="blast" id="{{uniqueId}}blast"
                          name="blast">
                Stralen
            </mat-checkbox>
            <mat-checkbox class="d-block inpStakeout mr-3 mb-3 mb-sm-0" formControlName="stake_out"
                          id="{{uniqueId}}stake_out"
                          name="stake_out">
                Uitzetten
            </mat-checkbox>
            <mat-checkbox class="d-block inpTrafficControllers mr-3 mb-3 mb-sm-0" formControlName="traffic_controllers"
                          id="{{uniqueId}}traffic_controllers" name="traffic_controllers">
                Verkeersregelaars
            </mat-checkbox>
            <mat-checkbox class="d-block inpturbo_burn mr-3 mb-3 mb-sm-0" formControlName="turbo_burn"
                          id="{{uniqueId}}turbo_burn"
                          name="turbo_burn">
                Turbo branden
            </mat-checkbox>
            <mat-checkbox class="d-block inpmanual_burn mr-3 mb-3 mb-sm-0" formControlName="manual_burn"
                          id="{{uniqueId}}manual_burn"
                          name="manual_burn">
                Handmatig branden
            </mat-checkbox>
            <mat-checkbox class="d-block inpnight mr-3" formControlName="night" id="{{uniqueId}}night" name="night">
                Nacht
            </mat-checkbox>
            <mat-checkbox class="d-block inpnight mr-3" formControlName="exclusive_material" id="{{uniqueId}}exclusive_material" name="exclusive_material">
                Exclusief materiaal
            </mat-checkbox>
            <mat-checkbox class="d-block inpnight mr-3" formControlName="overgrown" id="{{uniqueId}}overgrown" name="overgrown">
                Overgroeide kanten
            </mat-checkbox>
        </div>
    </div>
    <div class="row">
        <mat-form-field appearance="fill" class="col-md-12">
            <mat-label>Opmerkingen</mat-label>
            <textarea cdkAutosizeMinRows="4"
                      cdkTextareaAutosize
                      class="inpDescription"
                      formControlName="description"
                      id="{{uniqueId}}description"
                      matInput
                      name="description" placeholder="Voeg hier eventuele notities en opmerkingen toe"
                      type="text"></textarea>
            <mat-error>{{pushpinPart.material_id === 5 ? 'Bij voorgevormde thermoplast is een opmerking verplicht' : 'Het opmerkingen veld mag niet langer dan 16500 tekens zijn'}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field appearance="fill" class="col-md-12">
            <mat-label>{{AuthorisationService.hasFeature('pushpinConsumerDescription') ? 'Opmerking' : 'Opmerking klant'}}</mat-label>
            <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize formControlName="customer_note" id="customer_note"
                      matInput
                      name="customer_note" placeholder="Voeg hier eventuele notities en opmerkingen toe"
                      type="text"></textarea>
            <mat-error>Dit veld mag maximaal 16500 tekens bevatten</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="images">
                <div *ngFor="let image of pushpinPart.images" class="image-wrapper">
                    <div class="image-aspect">
                        <div class="drawn" *ngIf="image.drawn_image">
                            <i class="fa-solid fa-pen-swirl"></i>
                        </div>
                        <div (click)="openImage(pushpinPart.images, image)"
                             [style.background-image]="(image.drawn_image ?? image.image)|safeBgUrl"
                             class="image curpoint">
                            <div class="text">
                                {{image.status.name}}<br>
                                <ng-container *ngIf="image.updated_at">
                                    {{image.updated_at | date : 'd MMM yyyy, H:mm'}}
                                </ng-container>
                                <ng-container *ngIf="!image.updated_at">
                                    Zojuist gemaakt
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isCordova" [class.dragover]="uploadDrag" class="image-wrapper drag">
                    <div class="image-aspect">
                        <div class="image d-flex align-items-center">
                            <i class="fas fa-image fa-8x"></i>
                        </div>
                    </div>
                </div>
            </div>
            <small *ngIf="!isCordova && (!pushpinPart.images || pushpinPart.images.length < 1)">
                <i>
                    Upload afbeeldingen voor dit deel door ze hierheen te slepen.
                </i>
            </small>
        </div>
    </div>
</form>
