import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-decoration-group-overview',
    templateUrl: './decoration-group-overview.component.html',
    styleUrls: ['./decoration-group-overview.component.scss']
})
export class DecorationGroupOverviewComponent implements OnInit {

    @Input() list;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private decorationsService: DecorationsService) {
    }

    ngOnInit() {

    }

    openDecoration(decoration) {
        this.router.navigate([`decorations/${decoration.id}`]);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.list, event.previousIndex, event.currentIndex);
        this.decorationsService.sortDecorations(this.list[0].decoration_group, this.list).subscribe();
    }

}
