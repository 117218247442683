import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {InspectionStatus} from '../classes/inspectionstatus.class';
import {PushpinPart} from '../classes/pushpinpart.class';
import {InspectionStatusLog} from '../classes/inspectionstatuslog.class';

@Injectable({
    providedIn: 'root'
})
export class InspectionStatusService {

    constructor(private apiService: ApiService) {
    }

    public checkAllPushpinPartsExecuted(inspectionId): Promise<boolean> {
        return this.apiService.getCall(
            'inspection/all-parts-executed-check',
            {inspection_id: inspectionId}
        );
    }

    public updateStatus(inspectionId, StatusId): Promise<InspectionStatus> {
        return this.apiService.postCall(
            'inspection/status',
            {inspection_id: inspectionId, status_id: StatusId}
        );
    }

    public setPushpinPartsToStatus(pushpinPartIds, statusId): Promise<InspectionStatus> {
        return this.apiService.postCall(
            'inspection/set-status',
            {pushpinPartIds: pushpinPartIds, status_id: statusId}
        );
    }

    public getFinishedPushpins(inspectionId): Promise<PushpinPart[]> {
        return this.apiService.getCall(
            'inspection/finishedParts',
            {inspection_id: inspectionId}
        );
    }

    public getReleasedPushpins(inspectionId): Promise<PushpinPart[]> {
        return this.apiService.getCall(
            'inspection/releasedparts',
            {inspection_id: inspectionId}
        );
    }

    public getWatchedPushpins(inspectionId): Promise<PushpinPart[]> {
        return this.apiService.getCall(
            'inspection/watchedparts',
            {inspection_id: inspectionId}
        );
    }

    public getConceptPushpins(inspectionId): Promise<PushpinPart[]> {
        return this.apiService.getCall(
            'inspection/getconceptparts',
            {inspection_id: inspectionId}
        );
    }

    public getCustomerVisiblePushpins(inspectionId): Promise<PushpinPart[]> {
        return this.apiService.getCall(
            'inspection/getcustomervisibleparts',
            {inspection_id: inspectionId}
        );
    }

    public getInspectionStatusLog(inspectionId): Promise<InspectionStatusLog[]> {
        return this.apiService.getCall(
            'inspection/log',
            {inspection_id: inspectionId}
        );
    }

    public addCommentToInspectionLog(comment: string, inspectionId): Promise<InspectionStatusLog[]> {
        return this.apiService.postCall(
            'inspection/comment',
            {comment: comment, inspection_id: inspectionId}
        );
    }

    public acceptWork(inspectionId): Promise<boolean> {
        return this.apiService.getCall(
            'inspection/accept-work',
            {inspection_id: inspectionId}
        );
    }

    public setPaid(inspectionId): Promise<boolean> {
        return this.apiService.getCall(
            'inspection/paid',
            {inspection_id: inspectionId}
        );
    }
}
