import {Component, OnInit} from '@angular/core';
import {ChecklistService} from '../../services/checklist.service';
import {CodaltComponent} from '../../codalt.component';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Checklist} from '../../classes/checklist';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Utils} from '../../utils.class';
import {Routenames} from '../../route-names.enum';

@Component({
    selector: 'app-checklist-edit',
    templateUrl: './checklist-edit.component.html',
    styleUrls: ['./checklist-edit.component.scss']
})
export class ChecklistEditComponent extends CodaltComponent implements OnInit {

    form: FormGroupChecklist;

    checklist: Checklist;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private checklistService: ChecklistService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            let checklistId = params['id'];
            if (params['copy']) {
                checklistId = params['copy'];
            }
            const copy = !!params['copy'];
            this.checklistService.get(checklistId).subscribe(checklist => {
                this.checklist = checklist.data || new Checklist();
                const questions = new FormArray(this.checklist.questions.map(question => {
                    return new FormGroup({
                        id: new FormControl(copy ? null : question.id),
                        parent_id: new FormControl(copy ? null : question.parent_id),
                        number: new FormControl(question.number),
                        question: new FormControl(question.question, [Validators.required, Validators.minLength(1), Validators.maxLength(65000)]),
                        sub_items: new FormArray(question.sub_items.map(subQuestion => {
                            return new FormGroup({
                                id: new FormControl(copy ? null : subQuestion.id),
                                parent_id: new FormControl(copy ? null : subQuestion.parent_id),
                                number: new FormControl(subQuestion.number),
                                question: new FormControl(subQuestion.question, [Validators.required, Validators.minLength(1), Validators.maxLength(65000)])
                            }) as FormGroupChecklistQuestion;
                        }), Validators.required)
                    }) as FormGroupChecklistQuestion;
                }), Validators.required) as FormArrayChecklistQuestions;
                this.form = new FormGroup({
                    id: new FormControl(copy ? null : this.checklist.id),
                    name: new FormControl(copy ? `${this.checklist.name} (kopie)` : this.checklist.name, [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
                    number: new FormControl(this.checklist.number, [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
                    managed_by: new FormControl(this.checklist.managed_by, [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
                    version: new FormControl(this.checklist.version, [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
                    questions: questions
                }) as FormGroupChecklist;
            });
        }));
    }

    removeQuestion(removeFrom: FormArrayChecklistQuestions, index: number) {
        const question = removeFrom.controls[index].value;
        this.confirmDialog.confirm(
            'Vraag verwijderen?',
            `Weet u zeker dat u vraag <b><span class="text-uppercase">${question.number}</span> ${question.sub_items?.length ? `met ${question.sub_items?.length}  onderliggende vragen` : ''}</b> wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            removeFrom.removeAt(index);
            this.reNumberQuestions();
        }, () => {

        });

    }

    addQuestion(event, addTo: FormArrayChecklistQuestions, parent?: FormGroupChecklistQuestion, onIndex?: number) {
        event.preventDefault();
        event.stopPropagation();
        const questionGroup = new FormGroup({
            id: new FormControl(),
            parent_id: new FormControl(parent?.value.id),
            number: new FormControl(''),
            question: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(65000)])
        }) as FormGroupChecklistQuestion;
        if (!parent) {
            questionGroup.addControl('sub_items', new FormArray([], Validators.required));
        }
        addTo.push(questionGroup);
        if (onIndex || onIndex === 0) {
            moveItemInArray(addTo.controls, addTo.controls.length - 1, onIndex + 1);
        }
        this.reNumberQuestions();
        if (typeof onIndex === 'undefined') {
            onIndex = addTo.length - 2;
        }
        let i = this.form.controls.questions.controls.indexOf(parent);
        let id = '' + i;
        if (i === -1) {
            id = '' + (onIndex + 1);
        } else if (parent) {
            id = i + '-' + (onIndex + 1);
        }

        setTimeout(() => {
            const elem = document.querySelector('#c' + id) as HTMLInputElement;
            if (elem) {
                elem.focus();
            }
        }, 50);
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.form.controls.questions.controls.forEach((q: FormGroupChecklistQuestion, i) => {
                if (!q.controls.question.value) {
                    this.form.controls.questions.removeAt(i);
                }
                q.controls.sub_items.controls.forEach((qq: FormGroupChecklistQuestion, ii) => {
                    if (!qq.controls.question.value) {
                        q.controls.sub_items.removeAt(ii);
                    }
                });
            });
            this.reNumberQuestions();
            this.checklistService.save(this.form.value).subscribe(() => {
                this.router.navigate([Routenames.checklists]);
            });
        }
    }

    drop(event: CdkDragDrop<FormGroupChecklistQuestion[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.reNumberQuestions();
    }

    reNumberQuestions() {
        let letter = '`';
        this.form.controls.questions.controls.forEach((question: FormGroupChecklistQuestion) => {
            letter = this.getNextLetter(letter);
            question.controls.number.setValue(letter);
            question.controls.sub_items.controls.forEach((subQuestion: FormGroupChecklistQuestion, i) => {
                subQuestion.controls.number.setValue(letter + (i + 1));
            });
        });
    }

    private getNextLetter(letter) {
        return letter.substring(0, letter.length - 1)
            + String.fromCharCode(letter.charCodeAt(letter.length - 1) + 1);
    }
}

export type FormGroupChecklistQuestion = FormGroup & {
    controls: {
        id: FormControl & { value: number },
        parent_id: FormControl & { value: number },
        number: FormControl & { value: string },
        question: FormControl & { value: string }
        sub_items: FormArray & { controls: FormGroupChecklistQuestion[] }
    }
};

export type FormArrayChecklistQuestions = FormArray & { controls: FormGroupChecklistQuestion[] };

export type FormGroupChecklist = FormGroup & {
    controls: {
        id: FormControl & { value: number },
        name: FormControl & { value: string },
        number: FormControl & { value: string },
        managed_by: FormControl & { value: string },
        version: FormControl & { value: string },
        questions: FormArrayChecklistQuestions
    }
};
