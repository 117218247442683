export class PushpinFilter {
    status_1 = true;
    status_2 = true;
    status_3 = true;
    status_4 = true;
    status_5 = false;
    status_6 = true;
    status_7 = false;
    status_8 = true;
    status_9 = false;
    status_10 = false;
    status_11 = true;
    status_12 = true;
    status_13 = true;
    status_14 = false;
    status_15 = false;
    status_16 = false;
    type_lengtemarkering = false;
    type_figuratie = false;
    type_vlak = false;
    priority_figuration = false;
    with_comment = false;
    always_check = false;
    stake_out = false;
    traffic_controllers = false;
    no_traffic_controllers = false;
    primer = false;
    turbo_burn = false;
    manual_burn = false;
    night = false;
    overgrown = false;
    exclusive_material = false;
    blast = false;
    crow: string[];
    paintcolor: number[];
    material = {};

    date_start: any;
    date_end: any;

    filterTypeText = '';
    filterMaterialText = '';
    filterPaintColorText = '';
    filterCrowText = '';
    disabledStatusText = '';
    enabledStatusText = '';

    filterCount = null;

    deleted = false;

    inspection_id;

    extraInfo?: boolean;


    getType() {
        if (this.type_figuratie || this.type_lengtemarkering || this.type_vlak) {
            const types = [];
            if (this.type_figuratie) {
                types.push('figuratie');
            }
            if (this.type_lengtemarkering) {
                types.push('lengtemarkering');
            }
            if (this.type_vlak) {
                types.push('vlak');
            }

            return 'Type: ' + types.join(', ');

        }
        return null;
    }

    getCrow() {
        if (this.crow && this.crow.length > 0) {
            return 'Crow: ' + this.crow.sort((a, b) => {
                return a.localeCompare(b);
            }).join(', ').replace('Aplus', 'A+').replace('X', '?');
        }
        return null;
    }

    getDisabledStatuses() {
        const disabledStatuses = [];
        if (!this.status_1) {
            disabledStatuses.push('Concept');
        }
        if (!this.status_2) {
            disabledStatuses.push('Geschouwd');
        }
        if (!this.status_3) {
            disabledStatuses.push('Vrij voor uitvoering');
        }
        if (!this.status_4) {
            disabledStatuses.push('Uitgevoerd');
        }
        if (!this.status_6) {
            disabledStatuses.push('Afgekeurd');
        }
        return disabledStatuses.join(', ');
    }

    getEnabledStatuses() {
        const enabledStatuses = [];
        if (this.status_4) {
            enabledStatuses.push('Uitgevoerd');
        }
        if (this.status_5) {
            enabledStatuses.push('Goedgekeurd');
        }
        if (this.status_7) {
            enabledStatuses.push('Vervallen');
        }
        return enabledStatuses.join(', ');
    }
}
