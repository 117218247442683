import {Component, EventEmitter, OnInit} from '@angular/core';
import {UserService} from '../../services/user/user.service';
import * as XLSX from 'xlsx';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Router} from '@angular/router';
import {CordovaService} from '../../cordova.service';
import {formatDate} from '@angular/common';
import {Settings} from '../../settings.class';
import {CodaltComponent} from '../../codalt.component';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-users-overview',
    templateUrl: './users-overview.component.html',
    styleUrls: ['./users-overview.component.scss']
})
export class UsersOverviewComponent extends CodaltComponent implements OnInit {

    filteredUsers: User[];
    users: User[];
    currentSort = null;
    desc = false;
    exporting = false;

    searching = new EventEmitter<any>();
    searchText: string;

    constructor(private userService: UserService,
                private router: Router,
                private cordovaService: CordovaService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit() {
        this.searching.pipe(debounceTime(125)).subscribe(() => {
            const regex = /([\\\- \/.])/g;
            this.filteredUsers = this.users.filter(u =>
                !!u.customer_areas?.find(ca => ca.name.toLowerCase().replace(regex, '').indexOf(this.searchText.toLowerCase().replace(regex, '')) !== -1)
                || u.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
            );
        });
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        this.userService.getUsers().subscribe(users => {
            this.users = this.filteredUsers = users.data;
            this.sortList('name');
        });
    }

    openIfOne() {
        if (this.filteredUsers?.length === 1) {
            this.router.navigateByUrl(`/users/${this.filteredUsers[0].id}`);
        }
    }

    deleteUser(event, user) {
        event.preventDefault();
        event.stopPropagation();
        this.confirmDialog.confirm('Verwijderen',
            `Weet u zeker dat u de gebruiker ${user.name} wilt verwijderen?`, 'Ja').then(() => {
            this.userService.deleteUser(user.id).then(result => {
                const index = this.filteredUsers.indexOf(user);
                this.filteredUsers.splice(index, 1);
            });
        });
    }

    public sortList(sortingOn) {
        let users = this.filteredUsers;
        this.filteredUsers = [];

        if (sortingOn === this.currentSort) {
            users = users.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = false;
            users = users.sort((a, b) => {
                if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                    return -1;
                }
                if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        }

        this.filteredUsers = users;

        this.currentSort = sortingOn;
    }

    public toExcel() {
        this.exporting = true;
        const sheetArray = this.filteredUsers.map((user) => {
            return {
                'Naam': user.name,
                'E-mailadres': user.email,
                'Rollen': user.roles.map((role) => role.name).join(', '),
                'Gebieden': user.customer_areas.map(c => c.name).join(', ')
            };
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetArray);
        worksheet['!cols'] = [
            {width: 25},
            {width: 35},
            {width: 20}
        ];
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const filename = `VVR_App_Gebruikers_${formatDate(new Date(), 'yyyy-MMM-d_H-mm-ss', 'nl')}`;
        Settings.saveAsExcelFile(excelBuffer, filename.replace('.', ''));
        this.exporting = false;
    }

}
