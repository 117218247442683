import {Component, HostListener, OnInit} from '@angular/core';
import {Settings} from '../settings.class';
import {Observable} from 'rxjs';
import {CustomerArea} from '../classes/customerarea.class';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {UploadFile} from './upload-file';
import {LocalStorage} from '../storage.class';
import {DecorationsService} from '../services/decorations/decorations.service';
import {Decoration} from '../classes/decoration.class';
import {ApiService} from '../services/api/api.service';
import {Router} from "@angular/router";
import {CordovaService} from "../cordova.service";

@Component({
    selector: 'app-import',
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

    public uploadingFiles: UploadFile[] = [];

    public dragOver = false;

    public pushpinImportFinished = false;

    public customerAreas: CustomerArea[];
    public decorations: Decoration[];
    public selectedCustomerAreaId;

    public imagePushpinPartIDMap = {};

    constructor(private customerAreaService: CustomerAreaService,
                private decorationsService: DecorationsService,
                private router: Router,
                private cordovaService: CordovaService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        this.customerAreaService.getList().then(customerAreas => {
            this.customerAreas = customerAreas;
            this.decorationsService.getList().then(decorations => {
                this.decorations = decorations;
            });
        });
    }

    @HostListener('dragover', ['$event']) ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.srcElement.classList.contains('card-block')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        this.uploadFiles(files);

    }

    public correctErrors(uploadFile) {
        const correction = {
            rows: uploadFile['partsWithError'].map((row) => {
                return row['row'];
            }),
            importedPushpinIds: uploadFile['importedPushpinIds'],
            file: {
                ext: uploadFile.ext,
                file: uploadFile.file,
                filesize: uploadFile.filesize,
                customer_area_id: uploadFile.customer_area_id
            },
            columnMap: uploadFile['columnMap']
        };
        this.apiService.postCall(`import/correction/${this.selectedCustomerAreaId}`, correction)
            .then((data) => {
                if (data['imagePushpinPartIDMap']) {
                    this.imagePushpinPartIDMap = {...this.imagePushpinPartIDMap, ...data['imagePushpinPartIDMap']}
                }
                if (data['success']) {
                    uploadFile['uploading'] = null;
                    uploadFile.ext = data['file']['ext'];
                    uploadFile['partsWithError'] = null;
                    this.pushpinImportFinished = true;
                    uploadFile['imgs'] = Object.keys(this.imagePushpinPartIDMap);
                } else {
                    uploadFile['partsWithError'] = data['partsWithError'];
                    uploadFile['importedPushpinIds'] = data['importedPushpinIds'];
                    uploadFile['columnMap'] = data['columnMap'];
                }
            }, err => {

            });
    }

    public valueChanged(uploadFile, newValue, oldValue) {
        console.log(newValue);
        uploadFile['partsWithError'].filter(item => {
            return item['row'][item['columnWithError']] === oldValue;
        }).forEach(item => {
            item['row'][item['columnWithError']] = newValue.id;
        });
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    private uploadFiles(files: File[]) {
        const url = `${Settings.API_ENDPOINT}import/upload/${this.selectedCustomerAreaId}`;
        if (files.length > 0 && this.selectedCustomerAreaId) {
            for (let i = 0; i < files.length; i++) {

                const uploadFile = new UploadFile();
                uploadFile.customer_area_id = this.selectedCustomerAreaId;
                uploadFile.filesize = Math.round(files[i].size / 1000);
                uploadFile.file = files[i].name;
                uploadFile.ext = files[i].type;

                if (['jpg', 'jpeg', 'image/jpeg'].indexOf(uploadFile.ext) !== -1) {
                    console.log(uploadFile);
                    if (typeof this.imagePushpinPartIDMap[uploadFile.file] !== 'undefined') {
                        uploadFile['pushpinPartID'] = this.imagePushpinPartIDMap[uploadFile.file];
                    }
                    const clearName = uploadFile.file.replace('.jpg', '').replace('.jpeg', '');
                    if (typeof this.imagePushpinPartIDMap[clearName] !== 'undefined') {
                        uploadFile['pushpinPartID'] = this.imagePushpinPartIDMap[clearName];
                    }
                    if (typeof uploadFile['pushpinPartID'] === 'undefined') {
                        return;
                    } else {
                        uploadFile['img'] = true;
                    }
                }
                this.uploadingFiles.push(uploadFile);
                if (files[i].size < 110664300) {
                    this.makeFileRequest(url, files[i], uploadFile).subscribe(data => {
                        console.log(data);
                        if (data['imagePushpinPartIDMap']) {
                            this.imagePushpinPartIDMap = {...this.imagePushpinPartIDMap, ...data['imagePushpinPartIDMap']};
                        }

                        if (data['success']) {
                            uploadFile['imgs'] = Object.keys(this.imagePushpinPartIDMap);
                            uploadFile['uploading'] = null;
                            uploadFile.ext = data['file']['ext'];
                            this.pushpinImportFinished = true;
                        } else if (['jpg', 'jpeg'].indexOf(uploadFile.ext) === -1) {
                            const uniqueValues = [];
                            uploadFile['partsWithError'] = data['partsWithError'];
                            uploadFile['partsWithError'].forEach(item => {
                                if (!uniqueValues.find(uItem => {
                                    return item['row'][item['columnWithError']] === uItem['row'][uItem['columnWithError']];
                                })) {
                                    uniqueValues.push(item);
                                }
                            });
                            uploadFile['uniqueErrors'] = uniqueValues;

                            uploadFile['importedPushpinIds'] = data['importedPushpinIds'];
                            uploadFile['columnMap'] = data['columnMap'];
                            uploadFile['nameMap'] = data['nameMap'];
                        } else {

                        }
                    });
                } else {
                    uploadFile['error'] = 'File is to large';
                    alert('error');
                }
            }
        }
    }

    private makeFileRequest(url: string, file: File, uploadFile: UploadFile): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            formData.append('customer_area_id', this.selectedCustomerAreaId + '');
            if (typeof uploadFile['pushpinPartID'] !== 'undefined') {
                formData.append('pushpinPartID', uploadFile['pushpinPartID'] + '');
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {

                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                uploadFile['uploading'] = Math.round(event.loaded / event.total * 100);

            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }
}
