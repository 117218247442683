import {Component, OnDestroy, OnInit} from '@angular/core';
import {ArticleService} from '../services/article.service';
import {Article} from '../classes/article.class';
import {CodaltComponent} from '../codalt.component';
import {Subscription} from 'rxjs';
import {IPageInfo} from 'ngx-virtual-scroller';
import {ActivatedRoute} from '@angular/router';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent extends CodaltComponent implements OnInit, OnDestroy {

    childActive: boolean;

    articles: Article[] = [];
    loading = true;
    future = false;
    private limit = 9;
    private skip = 0;
    private allLoaded = false;

    constructor(private articleService: ArticleService,
                private dialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.skip = 0;
        this.getArticles();
    }

    childClosed(event) {
        if (event?.reserve) {
            this.skip = 0;
            this.getArticles();
        } else if (event?.form) {
            const article = this.articles.find(a => a.id === event?.article?.id);
            Object.assign(article, event?.article);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    scroll(article: IPageInfo) {
        if (!this.loading && !this.allLoaded) {
            if (article.endIndex > (this.articles.length) - 3) {
                this.skip += this.limit;
                this.getArticles();
            }
        }
    }

    private getArticles() {
        this.loading = true;
        this.future = typeof this.route.snapshot.url[0] !== 'undefined' && this.route.snapshot.url[0].path === 'toekomst';
        this.articleService.getList(this.limit, this.skip, this.future).subscribe(articles => {
            if (this.skip === 0) {
                this.articles = [];
            }
            this.allLoaded = articles.data.length < this.limit;
            this.articles = this.articles.concat(articles.data);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }
}
