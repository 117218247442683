import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-vvr-icon',
    templateUrl: './vvr-icon.component.html',
    styleUrls: ['./vvr-icon.component.scss']
})
export class VvrIconComponent implements OnInit {

    @Input()
    public shadow = false;

    @Input()
    public overlay = false;

    @Input()
    public full = false;

    @Input()
    public dark = false;

    @Input()
    link = '/';

    @Output()
    iconClick = new EventEmitter();

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    clickAction() {
        if (this.link) {
            this.router.navigateByUrl(this.link);
        }
        this.iconClick.emit();
    }

}
