<div *ngIf="manualAreasMode" class="manual-areas">
    <h1 class="h4 condensed">Gemarkeerde gebieden</h1>
    <div *ngIf="areas?.length === 0">
        Gemarkeerde gebieden zijn gebieden op de kaart die extra aandacht vragen.
        Je kunt de gebieden een eigen kleur geven, en kiezen om een melding te tonen als een punt binnen dat gebied
        aangemaakt wordt.
        <br><br>
        Plaats het midden van de kaart op het punt waar je het gebied wilt starten, en klik dan op "Nieuw gebied
        markeren".
    </div>
    <div class="areas" id="area-list">
        <div *ngFor="let area of areas; let i = index" class="area">
            <div class="area-controls">
                <h1 class="h5 condensed">
                    <i *ngIf="!area.isStreet" [style.color]="area.fillColor" class="fas fa-draw-polygon"></i>
                    <i *ngIf="area.isStreet" [style.color]="area.fillColor" class="fas fa-road"></i> {{area.name}}
                </h1>
                <div (click)="removeArea(area)"
                     *ngIf="area.drawing" class="fab small mr-1"><i class="fas fa-trash"></i></div>
                <div (click)="finishArea(area)"
                     *ngIf="area.drawing" class="fab small active"><i class="fas fa-check"></i></div>
                <div (click)="editArea(area)" *ngIf="!area.drawing" class="fab small"><i
                    class="fas fa-pen"></i></div>
            </div>

            <div [class.visible]="area.drawing" class="details">
                <div (click)="changeColor(area, color)"
                     *ngFor="let color of areaColors"
                     [class.active]="color.color == area.fillColor"
                     [style.background-color]="color.color"
                     class="color"></div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-12">
                        <mat-label>Naam</mat-label>
                        <input [(ngModel)]="area.name" matInput name="name" placeholder="Naam van het gebied"
                               type="text">
                    </mat-form-field>
                </div>
                <div *ngIf="area.isStreet" class="d-flex">
                    <div>
                        20m
                    </div>
                    <div class="spacer">
                        <mat-slider (change)="streetWithChange()"
                                    [(ngModel)]="area.width"
                                    class="w-100"
                                    max="150"
                                    min="20"
                                    step="10"
                                    thumbLabel></mat-slider>
                    </div>
                    <div>
                        150m
                    </div>
                </div>

                <mat-checkbox [(ngModel)]="area.show_notification" name="show_notification">
                    Waarschuwing tonen
                </mat-checkbox>
                <mat-checkbox [checked]="!area.inspection_id" (change)="commonArea($event, area)" name="all_inspections" [disabled]="!inspection">
                    Toon in alle schouwronden
                </mat-checkbox>

                <div *ngIf="isCordova">
                    <b>Punten</b><br>
                    <div class="points">
                        <div *ngFor="let point of area.exteriorRing; let pi = index;" class="point">
                            P{{pi + 1}} <i (click)="removePoint(area, pi)" class="fas fa-times"></i>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>


                <div>
                    <br>
                    <i>
                        <ng-container *ngIf="!isCordova && isStreet">
                            Markeer de straat door met de rechtermuisknop punten toe te voegen op de kaart.<br>
                            Door dubbel te klikken op een punt kan deze verwijderd worden.
                        </ng-container>
                        <ng-container *ngIf="!isCordova && !isStreet">
                            Teken het gebied door met de rechtermuisknop punten toe te voegen op de kaart.
                            De punten kunnen achteraf met de muis worden versleept. <br>
                            Door dubbel te klikken op een punt kan deze verwijderd worden.
                        </ng-container>
                        <ng-container *ngIf="isCordova">
                            Teken het gebied door de kaart naar een nieuw punt te slepen, en dan op het vinkje
                            rechtsonderin te klikken.
                        </ng-container>
                        <br><br>
                        Als je "Waarschuwing tonen" inschakelt, krijgt de gebruiker een waarschuwing bij het aanmaken
                        van een punt binnen dit gebied.</i>

                </div>
                <br>
            </div>
        </div>

    </div>
    <div class="actions">
        <div class="pt-2 d-flex">
            <button (click)="addArea(true)"

                    [disabled]="manualAreaDrawing"
                    class="mb-1 w-100 mr-1"
                    mat-raised-button>
                <i class="fas fa-road fa-4x"></i>
                <br>
                <div class="icon-button-text">
                    Nieuwe straat <br> markeren
                </div>
            </button>
            <button (click)="addArea()"
                    [disabled]="manualAreaDrawing"
                    class="mb-1 ml-1 w-100"
                    mat-raised-button>
                <i class="fas fa-draw-polygon fa-4x"></i>
                <br>
                <div class="icon-button-text">
                    Nieuw gebied <br> markeren
                </div>
            </button>
        </div>
    </div>
</div>
