<mat-progress-bar *ngIf="!show" mode="indeterminate"></mat-progress-bar>
<div *ngIf="show" class="price-group">
    <div class="price-column">
        <div class="price-line">Item</div>
        <ng-container *ngFor="let priceItem of priceGroup.items.get(inspections[0])">
            <div *ngIf="priceGroup.namesMap[priceItem?.decoration_id]" class="price-line">
                {{priceGroup.namesMap[priceItem?.decoration_id]['name']}}
            </div>
            <div *ngIf="priceGroup.namesMap[priceItem?.extra_cost_type_id]" class="price-line">
                {{priceGroup.namesMap[priceItem?.extra_cost_type_id]['name']}}
            </div>
            <div *ngIf="!priceGroup.namesMap[priceItem?.decoration_id] && priceGroup.namesMap[priceItem?.material_id]" class="price-line">
                {{priceGroup.namesMap[priceItem?.material_id]['name']}}
                {{priceItem?.paint_color_id ? paintColorKeyVal[priceItem?.paint_color_id]['name'] : ''}}
            </div>
        </ng-container>
    </div>
    <div *ngFor="let inspection of inspections" class="price-column">
        <div class="price-line flex-column">
            <small>{{inspection.customer_area?.name}}</small>
            {{inspection.name}}
        </div>
        <ng-container *ngFor="let priceItem of priceGroup.items.get(inspection); let i = index;">
            <div class="price-line">
                <mat-form-field class="mr-2">
                    <span matPrefix>€&nbsp;&nbsp;</span>
                    <input [(ngModel)]="priceItem.price" matInput
                           [disabled]="inspection.lock"
                           id="panel-{{panelIndex}}-{{i}}"
                           min="0"
                           step="0.01"
                           type="number">
                    <span *ngIf="priceItem?.extra_cost_type_id" matSuffix>{{extraCostNames[priceItem['extra_cost_type_id']]['unit']}}</span>
                    <span *ngIf="priceItem?.material_id && !priceItem?.decoration_id" matSuffix>m<sup *ngIf="priceItem.material_id !== 8">2</sup></span>
                    <span *ngIf="priceItem?.decoration_id && priceItem.material_id !== 5" matSuffix>m</span>
                </mat-form-field>
                <mat-form-field>
                    <span matPrefix><i matPrefix class="fa-regular fa-hashtag"></i>&nbsp;&nbsp;</span>
                    <input type="text" matInput [(ngModel)]="priceItem.code" [disabled]="inspection.lock">
                </mat-form-field>
                <button (click)="openPriceLog(priceItem.id, priceGroup.type, inspection.id)" mat-icon-button tabindex="-1"><i class="fas fa-history"></i></button>
            </div>
        </ng-container>
    </div>
</div>
