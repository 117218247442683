<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
  <div class="topbar">
    <div class="title">
      <h1 class="h3 condensed">Extra kosten</h1>
    </div>
    <div class="controls">
      <div id="btn-Exit" class="fab small" routerLink="/management"
           matTooltip="Terug naar overzicht"
           matTooltipPosition="below">
        <i class="fas fa-sign-out-alt"></i>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-xl-7">

      <table class="table table-striped table-hover">
        <tr>
          <th>
            Naam
          </th>
          <th>
            Eenheid
          </th>
        </tr>
        <tr *ngFor="let extraCostType of extraCostTypes"
            (click)="openDecoration(extraCostType)"
            class="curpoint">
          <td>
            {{extraCostType.name}}
          </td>
          <td>
            {{extraCostType.unit}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div class="controls-bottom">
  <div id="btn-Mapview" class="fab big primary"
       routerLink="/extra-costs/new">
    <i class="fas fa-plus"></i>
  </div>
</div>
