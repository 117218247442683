<div class="dialog-container">
    <h1 class="condensed dialog-title">{{data.title}}</h1>
    <div class="dialog-content">
        <p [innerHTML]="data.text"></p>
    </div>
    <div class="d-flex flex-wrap">
        <button *ngIf="data.cancel" mat-button (click)="onNoClick()">{{data.cancel}}</button>
        <div class="spacer"></div>
        <button *ngIf="data.confirm" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary">{{data.confirm}}</button>
    </div>
</div>
