import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Inspection} from '../../classes/inspection';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InspectionService} from '../../services/inspection.service';
import {InspectionType} from '../../classes/inspection-type';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-new-inspection-dialog',
    templateUrl: './new-inspection-dialog.component.html',
    styleUrls: ['./new-inspection-dialog.component.scss']
})
export class NewInspectionDialogComponent implements OnInit {

    form: FormGroup;
    fc: {
        name: FormControl,
        reference: FormControl,
        inspection_type_id: FormControl
    };
    types: InspectionType[];

    constructor(public dialogRef: MatDialogRef<NewInspectionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public inspection: Inspection,
                private inspectionService: InspectionService,
                private confirmDialogService: ConfirmDialogService) {
    }

    ngOnInit(): void {
        this.inspectionService.getInspectionTypes().subscribe(types => {
            this.types = types.data;
        });
        this.fc = {
            name: new FormControl(this.inspection.name, [Validators.required, Validators.minLength(3)]),
            reference: new FormControl(this.inspection.reference),
            inspection_type_id: new FormControl(this.inspection.inspection_type_id, Validators.required)
        };
        this.form = new FormGroup(this.fc);
    }

    save() {
        const inspectionToSave = new Inspection();
        Object.assign(inspectionToSave, this.inspection);
        Object.assign(inspectionToSave, this.form.value);
        this.inspectionService.saveInspection(inspectionToSave).subscribe(inspection => {
            this.dialogRef.close(inspection.data);
        });
    }

    delete() {
        this.confirmDialogService.confirm(
            'Schouwronde verwijderen',
            `Wil je deze schouwronde echt verwijderen?`,
            'Verwijderen', 'Nee').then(() => {
            this.inspectionService.deleteInspection(this.inspection.id).subscribe(() => {
                this.dialogRef.close(null);
            });
        }, () => {

        });
    }

}
