import {Pipe, PipeTransform} from '@angular/core';
import {PushpinPart} from '../classes/pushpinpart.class';
import {PriceType} from '../services/prices/prices.service';

@Pipe({
    name: 'totalCountPushpinParts'
})
export class TotalCountPushpinPartsPipe implements PipeTransform {

    transform(pushpinParts: PushpinPart[],
              statusId: number,
              customerAreaId?: number,
              inspectionId?: number,
              materialId?: number,
              specific_price = PriceType.all
    ): number {

        let ppp = pushpinParts
            .filter(p => +p.status_id === +statusId && (!customerAreaId || +p.customer_area_id === +customerAreaId) && (!inspectionId || inspectionId === p.inspection_id));

        if (materialId) {
            ppp = ppp.filter(p => p.material_id === materialId);
        }
        if (specific_price === PriceType.specific) {
            ppp = ppp.filter(p => p?.decoration?.specific_price);
        }
        if (specific_price === PriceType.notSpecific) {
            ppp = ppp.filter(p => !p?.decoration?.specific_price);
        }

        let surfaceInM2 = 0;
        ppp.forEach(p => {
            surfaceInM2 += +p['count'];
        });

        return surfaceInM2;
    }

}
