<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div *ngIf="customerArea" class="container d-flex justify-content-center">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Werkzaamheden {{customerArea.kind}} {{customerArea.name}}</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div *ngIf="success" class="accept-work">
        <div>
            <h4 class="h4 condensed">De werkzaamheden zijn goedgekeurd</h4>
            De uitgevoerde markeerwerkzaamheden voor {{customerArea.kind}} {{customerArea.name}} zijn nu goedgekeurd.
            Mocht u nog vragen hebben, dan kunt u deze stellen via <a href="mailto:calculatie@vvr.nl">calculatie@vvr.nl</a>.<br><br>
        </div>
        <div class="row">
            <div class="col-8">
                <h5 class="h5 condensed">Afrekendocumenten</h5>
                Heeft u een afrekendocument of werkbon voor ons? Dan kunt u deze hiernaast direct uploaden.
            </div>
            <div class="col-4">
                <br>
                <button [disabled]="uploading || uploadedFile" class="p-0 curpoint" color="primary" mat-raised-button>
                    <label class="m-0 pl-3 pr-3 curpoint">
                        {{uploadedFile ? 'Bijlage geüpload' : 'Bijlage uploaden'}}
                        <i *ngIf="uploading" class="fas fa-circle-notch fa-spin"></i>
                        <input #uploadInput (change)="uploadFile($event)" *ngIf="!uploading && !uploadedFile" hidden type="file">
                    </label>
                </button>
            </div>
            <div class="col-12">
                <div class="attachment">
                    <div *ngIf="uploadedFile">
                        <i class="fa fa-check text-red"></i> De bijlage is toegevoegd: {{uploadedFile}}
                    </div>
                </div>
            </div>
        </div>

        <button (click)="toOverview()" class='mt-5' color="secondary" mat-raised-button>
            Terug naar het overzicht
        </button>
    </div>
</div>
<div *ngIf="!success" class="accept-work">
    <div>
        <b>Er zijn momenteel geen werkzaamheden om goed te keuren.</b><br><br>
        Mocht u vragen hebben, dan kunt u deze stellen via <a href="mailto:info@vvr.nl">info@vvr.nl</a>.<br>
    </div>
    <div class="actions pt-3">
        <button (click)="toOverview()" color="primary" mat-button>
            Naar het overzicht
        </button>
    </div>
</div>
