import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {LocalStorage} from '../../storage.class';
import {AuthResponse} from '../../classes/auth-response';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AuthenticationLog} from '../../classes/authenticationlog.class';

@Injectable()
export class AuthenticationService {

    private loggedIn = new EventEmitter<boolean>();
    onLogin = this.loggedIn.asObservable();

    constructor(public apiService: ApiService) {
    }

    updateFirebaseToken(token) {
        return this.apiService.postCall$<boolean>('auth/firebase', {token});
    }

    loginEmail(email) {
        return this.apiService.postCall$<boolean>('auth/login-email', {email});
    }

    login(email, password) {
        return this.apiService.postCall$<AuthResponse>('auth/login', {email, password, twoFaHash: LocalStorage.twoFaHash})
            .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
    }

    refresh(token?: string) {
        if (token) {
            return this.apiService.postCall$<AuthResponse>('auth/refresh', {token, twoFaHash: LocalStorage.twoFaHash})
                .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
        }
        return this.apiService.getCall$<AuthResponse>('auth/refresh', {twoFaHash: LocalStorage.twoFaHash})
            .pipe(map(response => this.handleResponse(response.data)));
    }

    logout() {
        return this.apiService.postCall$('auth/logout')
            .pipe(map(response => {
                this.loggedIn.emit(false);
                LocalStorage.logoutUser();
                return response;
            }), catchError(error => {
                this.loggedIn.emit(false);
                LocalStorage.logoutUser();
                return throwError(error);
            }));
    }

    requestRestorePassword(email): Promise<any> {
        return this.apiService.postCall('auth/request-restore', {email});
    }

    verifyResetToken(token: string): Promise<Object> {
        return this.apiService.getCall('auth/verify-reset-token', {token});
    }

    restorePassword(token: string, password: string) {
        return this.apiService.postCall$<AuthResponse>('auth/restore', {password, token})
            .pipe(map(authResponse => this.handleResponse(authResponse.data as AuthResponse)));
    }

    log(user_id) {
        return this.apiService.getCall$<AuthenticationLog[]>(`auth/log/${user_id}`);
    }

    fullLog() {
        return this.apiService.getCall$<AuthenticationLog[]>(`auth/log`);
    }

    loginAsUser(userId: number) {
        return this.apiService.postCall$<string>('auth/loginAsUser', {user_id: userId});
    }

    private handleResponse(authResponse: AuthResponse) {
        LocalStorage.setUserToken(authResponse.access_token);
        LocalStorage.setUser(authResponse.user);
        LocalStorage.setExpireTimeToken(authResponse.expires_in);
        this.loggedIn.emit(true);
        return authResponse;
    }
}
