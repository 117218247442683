<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container-fluid">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Prijzen</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-indexation" matTooltip="Prijzen indexeren"
                 [class.disabled]="lockedInspectionSelected"
                 matTooltipPosition="below"
                 *ngIf="!loading && fcCustomerAreas.value?.length > 0"
                 (click)="indexation()">
                <i class="fas fa-cash-register"></i>
            </div>
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/management">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <ng-select class="col-md-6"
                   *ngIf="customerAreas"
                   [formControl]="fcCustomerAreas"
                   [clearSearchOnAdd]="true"
                   [closeOnSelect]="false"
                   [disabled]="saving"
                   [hideSelected]="true"
                   [items]="customerAreas"
                   [multiple]="true"
                   bindLabel="name"
                   bindValue="id"
                   id="customerareas"
                   placeholder="Toon prijzen van"></ng-select>
        <ng-select class="col-md-6"
                   *ngIf="customerAreas"
                   [formControl]="fcInspections"
                   [clearSearchOnAdd]="true"
                   [closeOnSelect]="false"
                   [hideSelected]="true"
                   [items]="inspections"
                   [multiple]="true"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="Toon prijzen van">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
                <i class="fa-duotone fa-lock mr-1" *ngIf="item.lock"></i> <small>{{item?.customer_area?.name}}</small> {{item.name}}
            </ng-template>
        </ng-select>
    </div>
    <div class="row">
        <div class="col-auto">
            <mat-checkbox [formControl]="fcOnlyActiveItems">Alleen gebruikte prijzen</mat-checkbox>
        </div>
        <div class="col">

        </div>
        <div class="col-auto">
            <button mat-icon-button (click)="openAll()" matTooltip="Alles uitklappen" *ngIf="fcOnlyActiveItems.value">
                <i class="fa-solid fa-chevrons-down"></i>
            </button>
        </div>
    </div>
    <div *ngIf="loading && fcInspections.value?.length > 0">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <mat-accordion *ngIf="!loading && fcInspections.value?.length > 0" class="price-groups" [multi]="fcOnlyActiveItems.value">
        <mat-expansion-panel #panel *ngFor="let priceGroup of priceItems; let i = index;" id="panel-{{i}}">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{priceGroup.group}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-prices-edit
                [panelIndex]="i"
                [extraCostNames]="extraCostNames"
                [paintColorKeyVal]="paintColorKeyVal"
                [panel]="panel"
                [priceGroup]="priceGroup"
                [inspections]="selectedInspections">
            </app-prices-edit>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="controls-bottom">
        <div (click)="save()" *ngIf="AuthorisationService.hasFeature('pricesSave')" [class.disabled]="saving || loading" class="fab big primary"
             id="btn-Save">
            <i *ngIf="!saving" class="fas fa-save"></i>
            <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
</div>
