export class Status {
    id: number;
    name: string;
    color: string;
    release_number: any;
    consumer_visible: any;
    consumer_allowed: any;
    next_status: Array<any>;
    sort_order: number;
    weight: number;
    vvruser_visible: boolean;
    vvruser_allowed: any;
    inspection_type_id: number;
}

export enum StatusId {
    Concept = 1,
    Geschouwd = 2,
    VrijVoorUitvoering = 3,
    Uitgevoerd = 4,
    Goedgekeurd = 5,
    Afgekeurd = 6,
    Vervallen = 7,
    GeschouwdDoorKlant = 8,
    Gearchiveerd = 9,
    Gefactureerd = 10,
    CalGeschouwd = 11,
    CalGepland = 12,
    CalUitgevoerd = 13,
    CalVervallen = 14,
    CalGefactureerd = 15,
    CalGearchiveerd = 16,

}
