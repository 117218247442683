import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MaterialPrice} from '../../classes/materialprice.class';
import {DecorationPrice} from '../../classes/decorationprice.class';
import {ExtraCostPrice} from '../../classes/extracostprice.class';

@Component({
    selector: 'app-price-indexation-dialog',
    templateUrl: './price-indexation-dialog.component.html',
    styleUrls: ['./price-indexation-dialog.component.scss']
})
export class PriceIndexationDialogComponent implements OnInit {

    precent: number;

    constructor(public dialogRef: MatDialogRef<PriceIndexationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public prices: {
                    materialPrices: MaterialPrice[],
                    decorationPrices: DecorationPrice[],
                    extraCostPrices: ExtraCostPrice[]
                }) {
    }

    ngOnInit(): void {

    }

    apply() {
        const index = (100 + this.precent) / 100;
        this.prices.materialPrices.forEach(p => {
            p.price = Math.round((p.price * index) * 100) / 100;
        });
        this.prices.decorationPrices.forEach(p => {
            p.price = Math.round((p.price * index) * 100) / 100;
        });
        this.prices.extraCostPrices.forEach(p => {
            p.price = Math.round((p.price * index) * 100) / 100;
        });
        this.dialogRef.close();
    }

}
