import {Repair} from './repair.class';

export class RepairComment {
    id: number;
    repair_id: number;
    user_id: number;
    comment: string;
    files: Array<any>;
    created_at: Date;
    incident: Repair;
    user: any;
    statuslog: boolean;
}
