import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Inspection} from '../../classes/inspection';

@Component({
    selector: 'app-choose-inspection-dialog',
    templateUrl: './choose-inspection-dialog.component.html',
    styleUrls: ['./choose-inspection-dialog.component.scss']
})
export class ChooseInspectionDialogComponent {

    constructor(public dialogRef: MatDialogRef<ChooseInspectionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public inspections: Inspection[]) {
    }

    close() {
        this.dialogRef.close();
    }

    openInspection(inspection: Inspection) {
        this.dialogRef.close(inspection);
    }
}
