<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container" [class.edit-mode]="editMode">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Bibliotheek</h1>
        </div>
        <div class="controls">
            <div class="mr-3 d-inline" *ngIf="AuthorisationService.hasFeature('libraryAddChapter') && !touchDevice">
                <mat-slide-toggle [(ngModel)]="editMode" color="primary">Beheer
                    modus {{editMode ? 'aan' : 'uit'}}</mat-slide-toggle>
            </div>
            <div id="btn-Back" class="fab small" routerLink="/" *ngIf="!isRead"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <div id="btn-Exit" class="fab small" (click)="logout()" *ngIf="isRead"
                 matTooltip="Uitloggen"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="customer"
         *ngIf="!selectedChapter"
         cdkDropList
         (cdkDropListDropped)="drop($event, libraryChapters)"
         [cdkDropListDisabled]="!editMode">
        <div *ngFor="let group of libraryChapters"
             cdkDrag
             cdkDragHandle
             [class.w-100]="editMode"
             class="chapter">
            <div class="action" (click)="selectChapter(group.sub_items)" matRipple>
                <i class="{{group.file}} fa-4x"></i>
                <span>{{group.name}}</span>
            </div>
        </div>
    </div>
    <div *ngIf="selectedChapter">
        <button class="mb-3" mat-raised-button color="primary" (click)="selectChapter()">
            <i class="fa-solid fa-chevrons-left"></i> Terug
        </button>
        <app-library-tree [editMode]="editMode" [libraryChapters]="selectedChapter"></app-library-tree>
    </div>
</div>
