import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TwoFactorService} from '../../services/two-factor.service';
import {CodaltComponent} from '../../codalt.component';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {Subscription} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-two-factor-setup',
    templateUrl: './two-factor-setup.component.html',
    styleUrls: ['./two-factor-setup.component.scss']
})
export class TwoFactorSetupComponent extends CodaltComponent implements OnInit {

    twoFactor: { qr, secret };
    twoFactorCode: string;

    constructor(private TwoFa: TwoFactorService,
                private authService: AuthenticationService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['token']) {
                LocalStorage.setUserToken(params['token']);
            }
            const tokenData = Utils.getJwtData(LocalStorage.getUserToken());
            if (tokenData.setupOtp) {
                this.TwoFa.get2FaQr().subscribe(qr => this.twoFactor = qr.data);
            }
        }));
    }

    setup() {
        this.TwoFa.save2Fa(this.twoFactorCode, this.twoFactor.secret).subscribe(hash => {
            this.authService.refresh().subscribe(() => {
                this.router.navigateByUrl('/');
            });
        });
    }

}
