import {Component, OnInit, ViewChild} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../classes/user.class';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {CameraService} from '../../camera.service';
import {CordovaService} from '../../cordova.service';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {Routenames} from '../../route-names.enum';
import {formatDate} from '@angular/common';
import {RepairFile} from '../../repairs/edit-repair/edit-repair.component';
import {PushpinPartImageViewerDialogComponent} from '../../pushpin-detail/pushpin-part-image-viewer-dialog/pushpin-part-image-viewer-dialog.component';
import {Utils} from '../../utils.class';
import {UploadFile} from '../../import/upload-file';
import {MoldMaterial} from '../../mold-material';
import {MoldStatus} from '../../mold-status';
import {MoldRequest} from '../../mold-request';
import {MoldService} from '../../services/mold.service';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {Decoration} from '../../classes/decoration.class';
import {requiredConditional} from '../../validators/required-conditional.validator';

declare var cordova;

@Component({
    selector: 'app-mold-request-detail',
    templateUrl: './mold-request-detail.component.html',
    styleUrls: ['./mold-request-detail.component.scss']
})
export class MoldRequestDetailComponent extends CodaltComponent implements OnInit {

    @ViewChild('scrollviewport') upload;

    saving = false;
    uploading = false;
    isCordova = false;

    fc: {
        status_id: FormControl,
        material_id: FormControl,
        manager_id: FormControl,
        decoration_id: FormControl,
        comment: FormControl,
        reason: FormControl
    };

    form: FormGroup;

    request: MoldRequest;

    materialList: MoldMaterial[];
    statusList: MoldStatus[];
    managerList: User[];
    decorations: Decoration[];

    url = Settings.API_ENDPOINT + 'file/';
    token = LocalStorage.getUserToken();

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private cameraService: CameraService,
                private cordovaService: CordovaService,
                private authenticationService: AuthenticationService,
                private decorationsService: DecorationsService,
                private moldService: MoldService) {
        super();
    }

    ngOnInit(): void {
        this.isCordova = typeof cordova !== 'undefined';
        this.subscriptions.add(this.authenticationService.onLogin.subscribe(() => {
            this.token = LocalStorage.getUserToken();
        }));
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.moldService.getRequest(params['id']).subscribe(equipment => {
                this.request = equipment.data || new MoldRequest();
                this.fc = {
                    status_id: new FormControl(this.request.status_id || 1, Validators.required),
                    material_id: new FormControl(this.request.material_id, Validators.required),
                    manager_id: new FormControl(this.request.manager_id, Validators.required),
                    comment: new FormControl(this.request.comment),
                    decoration_id: new FormControl(this.request.decoration_id),
                    reason: new FormControl(this.request.reason, requiredConditional(() => this.fc?.status_id?.value === 3)),
                };
                if (!AuthorisationService.hasFeature('moldManagement')) {
                    this.fc.status_id.disable();
                    if (this.request.status_id > 1) {
                        this.fc.material_id.disable();
                        this.fc.manager_id.disable();
                        this.fc.comment.disable();
                        this.fc.decoration_id.disable();
                        this.fc.reason.disable();
                    }
                }
                this.form = new FormGroup(this.fc);
                this.moldService.listMaterial().subscribe(equipmentData => {
                    this.materialList = equipmentData.data;
                    if (this.request.material_id && !this.materialList.find(e => e.id === this.request.material_id)) {
                        this.materialList.push(this.request.material);
                    }
                });
            });
        }));


        this.moldService.statusList().subscribe(status => {
            this.statusList = status.data;
        });
        this.moldService.managerList().subscribe(managers => {
            this.managerList = managers.data;
        });
        this.decorationsService.getList().then(decorations => {
            this.decorations = decorations.filter(d => d.mold);
        });
    }

    save() {
        if (!this.request.images?.length && this.request.status_id === 1) {
            this.confirmDialog.confirm(
                'Geen foto toegevoegd',
                `Er is geen foto toegevoegd. Wilt u alsnog een foto toevoegen?`,
                'Ja, toevoegen',
                'Nee, opslaan').then(() => {
                this.takePicture();
            }, () => {
                this.saveRequest();
            });
        } else {
            this.saveRequest();
        }
    }

    delete() {
        this.confirmDialog.confirm(
            'Aanvraag intrekken',
            `Weet u zeker dat u deze aanvraag wilt intrekken?`,
            'Intrekken',
            'Behouden'
        ).then(() => {
            this.moldService.deleteRequest(this.request.id).subscribe(() => {
                this.router.navigate([Routenames.moldRequest]);
            });
        }, () => {

        });
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    takePicture() {
        if (this.isCordova) {
            this.cameraService.getPicture().then(pictureUrl => {
                this.addFile(pictureUrl, formatDate(new Date(), 'dd-mm-yyyy hh:mm:ss', 'nl'), 'jpg');
            }, error => {
                console.error(error);
            });
        }
    }

    addFile(fileUrl, name, ext, object?: MoldRequest) {
        if (!object) {
            object = this.request;
        }
        if (!Array.isArray(object.images)) {
            object.images = [];
        }
        const file = new RepairFile();
        file.name = name;
        file.file = fileUrl;
        file.ext = ext;
        object.images.push(file);
        if (typeof object['id'] !== 'undefined') {
            this.moldService.saveRequest(object as MoldRequest).subscribe();
        }
    }

    openFile(clickedImage, allFiles) {
        if (['jpg', 'jpeg', 'png'].indexOf(clickedImage.ext) !== -1) {
            const images = allFiles
                .filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1)
                .map(f => (f.file.substr(0, 4) !== 'http' ? this.url : '') + f.file + '?access_token=' + this.token);
            const dialogRef = this.dialog.open(PushpinPartImageViewerDialogComponent, {
                panelClass: 'image-viewer-dialog',
                data: {
                    images: images,
                    viewIndex: allFiles.filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1).indexOf(clickedImage)
                }
            });
            this.cordovaService.setBackbuttonAction(() => {
                dialogRef.close();
            });
            const subs = dialogRef.afterClosed().subscribe(() => {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl('/repairs');
                });
                subs.unsubscribe();
            });
        } else {
            window.open(this.url + clickedImage.file + '?access_token=' + this.token);
        }
    }

    private saveRequest() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.request.decoration_id = this.fc.decoration_id.value;
            this.request.comment = this.fc.comment.value;
            this.request.reason = this.fc.reason.value;
            this.request.status_id = this.fc.status_id.value;
            this.request.material_id = this.fc.material_id.value;
            this.request.manager_id = this.fc.manager_id.value;
            this.moldService.saveRequest(this.request).subscribe(() => {
                this.router.navigate([Routenames.moldRequest]);
            }, error => {

            });
        }
    }

    private uploadFiles(files: File[], object?: MoldRequest) {
        this.uploading = true;
        const uploadedFiles = [];
        const url = `${Settings.API_ENDPOINT}images`;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const uploadFile = new UploadFile();
                uploadFile.filesize = Math.round(files[i].size / 1000);
                uploadFile.file = files[i].name;
                uploadFile.ext = files[i].type;
                if (['jpg', 'jpeg', 'image/jpeg', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'image/png', 'png', 'application/pdf',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword']
                    .indexOf(uploadFile.ext.toLowerCase()) !== -1 && files[i].size < 110664300) {
                    this.makeFileRequest(url, files[i], uploadFile).subscribe(data => {
                        if (data['success']) {
                            this.addFile(data['file'], data['name'], data['ext'], object);
                            uploadFile['uploading'] = null;
                            uploadFile.ext = data['ext'];
                        } else {
                            let error = 'Een onbekende fout';
                            if (typeof data['error'] === 'object' && Array.isArray(data['error']['upload'])) {
                                error = data['error']['upload'][0];
                            } else if (typeof data['error'] === 'string') {
                                error = data['error'];
                            }
                            this.confirmDialog.confirm(
                                'Er is iets fout gegaan met uploaden',
                                error,
                                'Oké', null);
                        }
                        uploadedFiles.push(uploadFile);
                        if (uploadedFiles.length === files.length) {
                            this.uploading = false;
                        }
                    }, error => {
                        this.confirmDialog.confirm(
                            'Er is iets fout gegaan met uploaden',
                            'Een onbekende fout',
                            'Oké', null);
                        this.uploading = false;
                    });
                } else if (['jpg', 'jpeg', 'image/jpeg'].indexOf(uploadFile.ext) === -1) {
                    this.uploading = false;
                    this.confirmDialog.confirm(
                        'U kunt alleen documenten uploaden',
                        'U kunt alleen jpg, doc(x), xls(x) en pdf bestanden uploaden',
                        'Oké', null);
                } else {
                    this.uploading = false;
                    this.confirmDialog.confirm(
                        'Het bestand is te groot',
                        'Het bestand is groter dan 100Mb',
                        'Oké', null);
                }
            }
        }
    }

    private makeFileRequest(url: string, file: File, uploadFile: UploadFile): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            formData.append('type', 'file');

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                uploadFile['uploading'] = Math.round(event.loaded / event.total * 100);

            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }

}
