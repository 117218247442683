import {MaterialPrice} from '../classes/materialprice.class';
import {DecorationPrice} from '../classes/decorationprice.class';
import {ExtraCostPrice} from '../classes/extracostprice.class';
import {Inspection} from '../classes/inspection';

export class PriceItemsGroup {
    group: string;
    type: 'extra' | 'materials' | 'decoration';
    items = new Map<Inspection, MaterialPrice[] | DecorationPrice[] | ExtraCostPrice[]>();
    namesMap = {};
}
