import {Status} from './status.class';
import {User} from './user.class';
import {PushpinPart} from './pushpinpart.class';

export class PushpinImage {
    id: number;
    user_id: number;
    status_id: number;
    pushpin_part_id: number;
    image: string;
    drawn_image: string;
    created_at: Date;
    updated_at: Date;
    status: Status;
    user: User;
    pushpin_part: PushpinPart;
}
