import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {LocalStorage} from '../storage.class';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {ApiService} from '../services/api/api.service';
import {CustomerArea} from '../classes/customerarea.class';
import {AuthenticationService} from '../services/auth/authentication.service';
import {InspectionStatusService} from '../services/inspection-status.service';
import {InspectionService} from '../services/inspection.service';
import {Inspection} from '../classes/inspection';

@Component({
    selector: 'app-accept-work',
    templateUrl: './accept-work.component.html',
    styleUrls: ['./accept-work.component.scss']
})
export class AcceptWorkComponent implements OnInit, OnDestroy {

    inspection: Inspection;
    customerArea: CustomerArea;
    uploading;
    uploadedFile;
    subscriptions = new Subscription();
    success;

    constructor(private location: Location,
                private authService: AuthenticationService,
                private customerAreaService: CustomerAreaService,
                private inspectionService: InspectionService,
                private inspectionStatusService: InspectionStatusService,
                private router: Router,
                private route: ActivatedRoute,
                private apiService: ApiService,
                private confirmDialogService: ConfirmDialogService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            if (params['token']) {
                this.authService.refresh(params['token']).subscribe(authResponse => {
                    LocalStorage.setUserToken(authResponse.access_token);
                    LocalStorage.setUser(authResponse.user);
                    this.customerAreaService.reset();
                    this.router.navigateByUrl(`accept-work/${params['inspection']}`, {replaceUrl: true});
                }, error => {
                    if (LocalStorage.getUser()) {
                        this.location.replaceState(`accept-work/${params['inspection']}`);
                    } else {
                        this.authService.logout();
                        this.router.navigateByUrl(`login`, {replaceUrl: true});
                    }
                });
            } else {
                this.inspectionService.getInspection(params['inspection']).subscribe(inspection => {
                    this.inspection = inspection.data;
                    this.customerArea = this.inspection.customer_area;
                    this.inspectionStatusService.acceptWork(this.inspection.id).then(success => {
                        this.success = success;
                    });
                }, () => {
                    this.router.navigateByUrl(`login`, {replaceUrl: true});
                });
            }
        }));
    }

    toOverview() {
        this.router.navigate([`pushpins/map`, this.customerArea.id, '', '', '']);
    }

    uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    uploadFiles(files: File[]) {
        const url = `customer-area/attachment`;
        if (files.length > 0) {
            this.uploading = true;
            for (let i = 0; i < files.length; i++) {
                if (files[i].size < 110664300) { // ~100MB
                    this.apiService.makeFileRequest$<string>(url, files[i], {'customer_area_id': this.customerArea.id}).subscribe(data => {
                        this.uploading = false;
                        this.uploadedFile = files[i].name;
                    }, error => {
                        this.uploading = false;
                        let errorMessage = '';
                        if (typeof error.data === 'object' && Array.isArray(error.data.data)) {
                            errorMessage += error.data.data.reduce((t, e) => t += `<br>${e}`);
                        } else {
                            errorMessage = error.message ? error.message : 'Je kunt dit niet uploaden!';
                        }
                        this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${errorMessage}`);
                    });
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Fout bij het uploaden',
            `${message}`,
            'Oké', null);
    }
}
