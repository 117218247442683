import {ExtraCostPrice} from './extracostprice.class';

export class ExtraCostType {
    id: number;
    name: string;
    unit: string;
    count_based_on: ExtraCostCountType;
    night: boolean;
    extra_cost_prices: ExtraCostPrice[];
    deleted_at: Date;
}

export enum ExtraCostCountType {
    count = 'count',
    pushpin = 'pushpin',
    pushpin_figuration = 'pushpin_figuration',
    pushpin_lengthmark = 'pushpin_lengthmark'
}
