import {Component, NgZone, OnInit} from '@angular/core';
import {Settings} from './settings.class';
import {LocalStorage} from './storage.class';
import {NavigationEnd, Router} from '@angular/router';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from './services/auth/authentication.service';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';

declare var document;
declare var IonicDeeplink;
declare var cordova;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


    public constructor(private authService: AuthenticationService,
                       private router: Router,
                       private firebase: FirebaseX,
                       private ngZone: NgZone,
                       private matSnackbar: MatSnackBar,
                       private confirmDialogService: ConfirmDialogService) {
    }

    ngOnInit() {
        if (typeof cordova !== 'undefined') {
            this.firebase.grantPermission().then(() => {
            }, () => {
            });
        }
        if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            this.confirmDialogService.confirm(
                'Browser niet ondersteund',
                `U maakt gebruikt van een oude versie van Microsoft Edge. Deze browser wordt niet ondersteund.
                Download <a href='https://www.microsoft.com/en-us/edge'>hier</a> de laatste versie of gebruik Google Chrome.`,
                null, null);
        }
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE) {
            this.confirmDialogService.confirm(
                'Browser niet ondersteund',
                'U gebruikt Internet Explorer. Deze browser wordt niet ondersteund, gebruik Google Chrome.',
                null, null);
        }

        this.watchRouteChange();
        const routesToMatch = {};
        this.router.config.forEach(route => {
            if (typeof route.path !== 'undefined' && route.path.length > 2) {
                routesToMatch['/' + route.path] = {
                    target: route.path
                };
            }
        });
        if (typeof IonicDeeplink !== 'undefined') {
            IonicDeeplink.route(routesToMatch, (match) => {
                console.log(match);
                this.ngZone.run(() => {
                    this.router.navigate([`${match['$link']['path']}`]);
                });
            }, function (nomatch) {
                console.log(nomatch);
            });
        }

        if (LocalStorage.getUser()) {
            this.authService.refresh().subscribe();
            this.setFirebaseSubs();
        }
        this.authService.onLogin.subscribe(loggedIn => {
            if (loggedIn) {
                this.setFirebaseSubs();
            }
        });
    }

    watchRouteChange() {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                const user = LocalStorage.getUser();
                const firstPart = val.url.split('/')[1];
                if (user) {
                    if (typeof user.customer_areas === 'undefined' || !user.customer_areas) {
                        console.log('Deze gebruiker heeft geen klantgebieden');
                    }
                } else {
                    if (Settings.unauthorizedAllowedUrls.indexOf(firstPart) === -1) {
                        this.router.navigate(['login']);
                    }
                }
            }
        });
    }

    private setFirebaseSubs() {
        if (typeof cordova !== 'undefined') {
            this.firebase.getToken().then(token => {
                this.authService.updateFirebaseToken(token).subscribe();
            });
            this.firebase.onTokenRefresh().subscribe(() => {
                this.firebase.getToken().then(token => this.authService.updateFirebaseToken(token).subscribe());
            });
            this.firebase.onMessageReceived().subscribe(data => {
                if (data.tap) {
                    this.runNotification(data);
                }
            });
        }
    }

    private runNotification(data) {
        this.ngZone.run(() => {
            setTimeout(() => {
                this.router.navigateByUrl(data.url);
            });
        });
    }
}
