<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Inspectielijst <span *ngIf="form" class="d-none d-md-inline">invullen</span></h1>
        </div>
        <div class="controls">
            <div id="btn-Pdf" class="fab small" (click)="pdf()"
                 *ngIf="completed.id"
                 [class.disabled]=downloadingPdf
                 matTooltip="Download als PDF"
                 matTooltipPosition="below">
                <i *ngIf="!downloadingPdf" class="fas fa-file-pdf"></i>
                <i *ngIf="downloadingPdf" class="fas fa-spinner fa-spin"></i>
            </div>

            <div id="btn-Exit" class="fab small" routerLink="/{{Routenames.checklistFillIn}}"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>

        </div>
    </div>
    <div class="checklist-form" *ngIf="form">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9">
                <div>
                    <h4 class="h4 condensed">
                        Algemene gegevens
                    </h4>
                </div>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Werknummer</mat-label>
                    <input matInput type="text" [formControl]="form.controls.worknumber"
                           placeholder="Werknummer">
                </mat-form-field>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Werkomschrijving</mat-label>
                    <input matInput type="text" [formControl]="form.controls.description"
                           placeholder="Werkomschrijving">
                </mat-form-field>
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Adres / locatie</mat-label>
                    <input matInput type="text" [formControl]="form.controls.location"
                           placeholder="Adres / locatie">
                </mat-form-field>
                <div class="row">
                    <ng-select [formControl]="form.controls.foreman_id"
                               [items]="foremanList"
                               class="col-md-6"
                               appearance="outline"
                               placeholder="Voorman"
                               bindValue="id"
                               bindLabel="name"></ng-select>
                    <ng-select [formControl]="form.controls.inspector_id"
                               [items]="inspectorList"
                               class="col-md-6"
                               appearance="outline"
                               placeholder="Inspecteur"
                               bindValue="id"
                               bindLabel="name"></ng-select>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="mt-3">
                    <h4 class="h4 condensed">
                        Inspectielijst
                    </h4>
                </div>
                <table>
                    <tr class="header">
                        <th>
                            Nr.
                        </th>
                        <th>
                            Vraag
                        </th>
                        <th>
                            Antwoord
                        </th>
                        <th>
                            Toelichting
                        </th>
                    </tr>
                    <ng-container *ngFor="let question of checklist.questions">
                        <tr>
                            <th class="number">
                                {{question.number}}
                            </th>
                            <th colspan="5" [innerHTML]="question.question | nl2br">

                            </th>
                        </tr>
                        <tr *ngFor="let subQuestion of question.sub_items" class="sub-question">
                            <td class="number">
                                {{subQuestion.number}}
                            </td>
                            <td class="question" [innerHTML]="subQuestion.question | safeHtml">

                            </td>
                            <td class="answer">
                                <mat-radio-group [formControl]="questionFormMap.get(subQuestion).answer"
                                                 aria-label="{{subQuestion.question}}">
                                    <mat-radio-button [value]="AnswerOption.nvt">{{AnswerOption.nvt}}</mat-radio-button>
                                    <mat-radio-button [value]="AnswerOption.Nee">{{AnswerOption.Nee}}</mat-radio-button>
                                    <mat-radio-button [value]="AnswerOption.Ja">{{AnswerOption.Ja}}</mat-radio-button>
                                </mat-radio-group>
                            </td>
                            <td class="comment">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Toelichting</mat-label>
                                    <textarea matInput
                                              cdkTextareaAutosize
                                              cdkAutosizeMinRows="1"
                                              type="text"
                                              [formControl]="questionFormMap.get(subQuestion).comment"
                                              placeholder="Toelichting"></textarea>
                                </mat-form-field>
                            </td>
                        </tr>
                    </ng-container>
                </table>
                <div class="mt-5">
                    <h4 class="h4 condensed">
                        Algehele indruk
                    </h4>
                </div>
                <table>
                    <tr class="header">
                        <th>
                            Positief/Leer punt
                        </th>
                        <th>
                            Beschrijving
                        </th>
                        <th class="delete">

                        </th>
                    </tr>
                    <tr *ngFor="let point of form.controls.points.controls; let i = index;" class="point">
                        <td class="type">
                            <mat-form-field appearance="fill">
                                <mat-label>Positief/Leer punt</mat-label>
                                <mat-select [formControl]="point.controls.answer" placeholder="Positief/Leer punt">
                                    <mat-option [value]="ChecklistPointType.learn">
                                        Leerpunt
                                    </mat-option>
                                    <mat-option [value]="ChecklistPointType.learn">
                                        Positief punt
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td class="comment">
                            <mat-form-field appearance="fill">
                                <mat-label>Beschrijving</mat-label>
                                <textarea matInput
                                          cdkTextareaAutosize
                                          cdkAutosizeMinRows="1"
                                          cdkAutosizeMaxRows="5"
                                          [formControl]="point.controls.comment"
                                          placeholder="Beschrijving"></textarea>
                            </mat-form-field>
                        </td>
                        <td class="delete">
                            <button (click)="deletePoint(i)"
                                    color="warn"
                                    mat-icon-button>
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <button mat-button (click)="addPoint()" class="w-100">
                                Punt toevoegen
                            </button>
                        </td>
                    </tr>
                </table>
                <div class="mt-5">
                    <h4 class="h4 condensed">
                        Actielijst
                    </h4>
                </div>
                <table>
                    <tr class="header">
                        <th>
                            Nr.
                        </th>
                        <th>
                            Actiepunt
                        </th>
                        <th>
                            Door
                        </th>
                        <th>
                            Deadline
                        </th>
                        <th class="delete">

                        </th>
                    </tr>
                    <tr *ngFor="let action of form.controls.actions.controls; let i = index;" class="action">
                        <td class="number-inp">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Nr
                                </mat-label>
                                <mat-select [formControl]="action.controls.question_id">
                                    <mat-select-trigger>
                                        <b class="text-uppercase">{{questions.get(action.value.question_id)?.number}}</b>
                                    </mat-select-trigger>
                                    <mat-optgroup *ngFor="let question of checklist.questions"
                                                  label="{{question.number}} {{question.question}}">
                                        <mat-option *ngFor="let subQuestion of question.sub_items"
                                                    [value]="subQuestion.id">
                                            <b class="text-uppercase">{{subQuestion.number}} </b>{{subQuestion.question}}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td class="action">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Actie
                                </mat-label>
                                <textarea matInput
                                          cdkTextareaAutosize
                                          cdkAutosizeMinRows="1"
                                          [formControl]="action.controls.action"
                                          placeholder="Actie"></textarea>
                            </mat-form-field>
                        </td>
                        <td class="user">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Door
                                </mat-label>
                                <input matInput type="text"
                                       [formControl]="action.controls.user"
                                       placeholder="Door">
                            </mat-form-field>
                        </td>
                        <td class="deadline">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>
                                    Deadline
                                </mat-label>
                                <input matInput
                                       (focusin)="picker.open()"
                                       placeholder="Deadline"
                                       [formControl]="action.controls.deadline"
                                       [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </td>
                        <td class="delete">
                            <button (click)="deleteAction(i)"
                                    color="warn"
                                    mat-icon-button>
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <button mat-button (click)="addAction()" class="w-100">
                                Actie toevoegen
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row justify-content-center mt-3 pb-3">
            <div class="col-12">
                <div class="d-flex">
                    <button mat-button routerLink="/{{Routenames.checklistFillIn}}">Terug</button>
                    <div class="spacer"></div>
                    <div class="d-flex align-items-center">
                        <mat-checkbox color="primary" class="pr-3" [formControl]="form.controls.final">
                            Maak deze inspectielijst definitief
                        </mat-checkbox>
                        <button mat-raised-button color="primary" (click)="save()" id="btn-save">
                            Opslaan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!form && completed?.final" class="pb-5">
        <table class="mb-5">
            <tr>
                <th>
                    Checklist
                </th>
                <td class="pl-2">
                    {{checklist.name}}
                </td>
            </tr>
            <tr>
                <th>
                    Checklist nummer
                </th>
                <td class="pl-2">
                    {{checklist.number}}
                </td>
            </tr>
            <tr>
                <th>
                    Checklist versie
                </th>
                <td class="pl-2">
                    {{checklist.version}}
                </td>
            </tr>
            <tr>
                <th>
                    Beheert door
                </th>
                <td class="pl-2">
                    {{checklist.managed_by}}
                </td>
            </tr>
            <tr>
                <th>
                    Datum
                </th>
                <td class="pl-2">
                    {{checklist.updated_at | date : 'd MMMM yyyy'}}
                </td>
            </tr>
        </table>
        <app-checklist-pdf [completed]="completed"></app-checklist-pdf>
    </div>
</div>
