import {Component, OnInit} from '@angular/core';
import {CordovaService} from "../cordova.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-lmra',
    templateUrl: './lmra.component.html',
    styleUrls: ['./lmra.component.scss']
})
export class LMRAComponent implements OnInit {

    questions: Question[] = [];
    private questionTexts = [
        'Weet ik welk werk ik moet doen?',
        'Weet ik hoe ik het werk moet aanpakken?',
        'Heb ik de juiste, gekeurde gereedschappen?',
        'Draag ik de juiste werkkleding en PBM’s?',
        'Is het werkvak goed afgezet?',
        'Kan ik veilig werken?',
    ];

    constructor(private router: Router,
                private cordovaService: CordovaService) {
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        let previousQuestion = null;
        this.questionTexts.forEach(text => {
            const question = new Question();
            question.question = text;
            question.previousQuestion = previousQuestion;
            this.questions.push(question);
            previousQuestion = question;
        });
    }

    questionChange(event, question) {
        if (!event.value) {
            const index = this.questions.indexOf(question);
            this.questions.slice(index + 1).forEach(q => q.answer = null);
        }
    }
}

export class Question {
    question: string;
    answer: boolean;
    previousQuestion: Question;
}
