import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Checklist} from '../classes/checklist';
import {ChecklistCompleted} from '../classes/checklist-completed';
import {User} from '../classes/user.class';

@Injectable({
    providedIn: 'root'
})
export class ChecklistService {

    constructor(private apiService: ApiService) {

    }

    getPdf(id: number) {
        return this.apiService.getBlobCall(`checklist/answers/pdf/${id}`);
    }

    list() {
        return this.apiService.getCall$<Checklist[]>('checklist');
    }

    get(id: number) {
        return this.apiService.getCall$<Checklist>(`checklist/${id}`);
    }

    save(checklist: Checklist) {
        return this.apiService.postCall$<Checklist>('checklist', checklist);
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`checklist/${id}`);
    }

    saveAnswers(checklist: ChecklistCompleted) {
        return this.apiService.postCall$<ChecklistCompleted>('checklist/answers', checklist);
    }

    getAnswers(id: number) {
        return this.apiService.getCall$<ChecklistCompleted>(`checklist/answers/${id}`);
    }

    listAnswers(all?: boolean) {
        return this.apiService.getCall$<ChecklistCompleted[]>(`checklist/answers${all ? '/all' : ''}`);
    }

    deleteAnsweredChecklist(id: number) {
        return this.apiService.deleteCall$(`checklist/answers/${id}`);
    }

    inspectorList() {
        return this.apiService.getCall$<User[]>('checklist/answers/inspectors');
    }

    foremanList() {
        return this.apiService.getCall$<User[]>('checklist/answers/foremans');
    }
}
