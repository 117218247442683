import {ExtraCostType} from './extracosttype.class';
import {CustomerArea} from './customerarea.class';

export class ExtraCost {
    id: number;
    customer_area_id: number;
    extra_cost_type_id: number;
    comment: string;
    number: number;
    extra_cost_type: ExtraCostType;
    customer_area: CustomerArea;
    inspection_id: number;
}
