import {EventEmitter, Injectable, NgZone} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var cordova;

@Injectable()
export class CordovaService {

    public deviceHasInternet = new EventEmitter<boolean>();
    private backFunction: Function;
    private hasInternet = true;
    private resume = new EventEmitter();
    onResume = this.resume.asObservable();

    constructor(private ngZone: NgZone, private matSnackbar: MatSnackBar) {
        this.backFunction = () => {
            navigator['app'].exitApp();
        };

        if (typeof cordova !== 'undefined') {

            document.addEventListener('backbutton', () => {
                this.ngZone.run(() => {
                    this.backFunction();
                });
            }, false);
        }

        document.addEventListener('offline', () => {
            console.log('Device goes offline :-(');
            this.deviceHasInternet.emit(false);
            this.hasInternet = false;
            this.ngZone.run(() => {
                this.matSnackbar.open('Geen internetverbinding!', 'Oké', {duration: 3000});
            });
        }, false);

        document.addEventListener('online', () => {
            console.log('Device goes online!');
            this.deviceHasInternet.emit(true);
            if (!this.hasInternet) {
                this.ngZone.run(() => {
                    this.matSnackbar.open('Internetconnectie hersteld!', 'Oké', {duration: 2000});
                });
            }
            this.hasInternet = true;
        }, false);

        document.addEventListener('resume', () => {
            this.resume.emit();
        });
    }

    public setBackbuttonAction(func: Function) {
        this.backFunction = func;
    }

    public setBackbuttonExitAction() {
        this.backFunction = () => {
            navigator['app'].exitApp();
        };
    }

    public getCurrentBackFunction(): Function {
        return this.backFunction;
    }

    public getAppVersion(): Promise<any> {
        return cordova.getAppVersion.getVersionNumber();
    }
}
