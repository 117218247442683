<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Nieuws</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
</div>
<div class="items">
    <div *ngIf="articles.length === 0 && !loading" class="not-found">
        <div class="text-center">
            Er zijn nog geen nieuwsberichten geplaatst
        </div>
    </div>
    <virtual-scroller #virtualScroll (vsEnd)="scroll($event)" [items]="articles" class="item-list">
        <div *ngFor="let article of virtualScroll.viewPortItems" class="item"
             routerLink="/{{Routenames.articles}}/{{article.slug}}">
            <app-codalt-image [path]="article.id + '/' + article.image"
                              [thumb]="article.image_thumb"
                              class="detail__image">
            </app-codalt-image>
            <div [class.important]="article.alert" class="detail__block">
                <h3 [class.unread]="!article.read" class="item__title">{{article.title}}</h3>
                <div class="details">
                    <time>
                        {{article.publish_date | date:'EE d MMM yyyy HH:mm'}}
                    </time>
                    <div>{{article.user.name}}
                        <ng-container *ngFor="let classroom of article.classrooms; let last = last; let first = first">
                            {{first ? ' | ' : ''}} {{classroom.classroom_name}}{{last ? '' : ','}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="item-spacer"></div>
    </virtual-scroller>
    <button *ngIf="AuthorisationService.hasFeature('createArticle')" class="add-floating-button" color="primary" mat-fab
            routerLink="/{{Routenames.articles}}/nieuw/bewerken">
        <mat-icon class="fas fa-plus"></mat-icon>
    </button>
    <button *ngIf="AuthorisationService.hasFeature('createArticle')" class="delete-button"
            color="primary"
            mat-fab
            matTooltip="{{future ? 'Nieuws uit het verleden' : 'Toekomstig nieuws'}}"
            matTooltipPosition="before"
            routerLink="/{{Routenames.articles}}/{{future ? '' : 'toekomst'}}">
        <mat-icon [class.fa-clock]="!future" [class.fa-history]="future" class="fas"></mat-icon>
    </button>
</div>
<div class="router-outlet" [class.active]="childActive">
    <router-outlet (activate)="childActive=true"
                   (deactivate)="childClosed($event); childActive=false">
    </router-outlet>
</div>
