import {PpeEquipment} from './ppe-equipment';
import {PpeStatus} from './ppe-status';
import {User} from './classes/user.class';
import {PpeStatusLog} from './ppe-status-log';

export class PpeRequest {
    id: number;
    equipment_id: number;
    equipment: PpeEquipment;
    status_id = 1;
    status: PpeStatus;
    status_log: PpeStatusLog[];
    user_id: number;
    user: User;
    manager_id: number;
    manager: User;
    images: any[];
    size: string;
    comment: string;
    reason: string;
    created_at: Date;
}
