<h1 mat-dialog-title>CROW schaal</h1>
<div mat-dialog-content>
    <div class="items">
        <div class="item" [class.active]="pushpinPart.crow == 'A+'">
            <div class="indicator">
                A+
            </div>
            <div class="image">
                <img src="./assets/images/crow/a+.jpg">
            </div>
            <div class="text">
                De markering reflecteert goed.
            </div>
            <div class="nen-text">
                oppervlak dat niet voldoet aan NEN-EN 1436
            </div>
            <div class="precent">
                0% per wegvak
            </div>
        </div>
        <div class="item" [class.active]="pushpinPart.crow == 'A'">
            <div class="indicator">
                A
            </div>
            <div class="image">
                <img src="./assets/images/crow/a.jpg">
            </div>
            <div class="text">
                De markering reflecteert redelijk goed.
            </div>
            <div class="nen-text">
                oppervlak dat niet voldoet aan NEN-EN 1436
            </div>
            <div class="precent">
                < 5% per wegvak
            </div>
        </div>
        <div class="item" [class.active]="pushpinPart.crow == 'B'">
            <div class="indicator">
                B
            </div>
            <div class="image">
                <img src="./assets/images/crow/b.jpg">
            </div>
            <div class="text">
                De markering reflecteert in beperkte mate.
            </div>
            <div class="nen-text">
                oppervlak dat niet voldoet aan NEN-EN 1436
            </div>
            <div class="precent">
                < 20% per wegvak
            </div>
        </div>
        <div class="item" [class.active]="pushpinPart.crow == 'C'">
            <div class="indicator">
                C
            </div>
            <div class="image">
                <img src="./assets/images/crow/c.jpg">
            </div>
            <div class="text">
                De markering reflecteert weinig.
            </div>
            <div class="nen-text">
                oppervlak dat niet voldoet aan NEN-EN 1436
            </div>
            <div class="precent">
                < 30% per wegvak
            </div>
        </div>
        <div class="item" [class.active]="pushpinPart.crow == 'D'">
            <div class="indicator">
                D
            </div>
            <div class="image">
                <img src="./assets/images/crow/d.jpg">
            </div>
            <div class="text">
                De markering reflecteert niet.
            </div>
            <div class="nen-text">
                oppervlak dat niet voldoet aan NEN-EN 1436
            </div>
            <div class="precent">
                > 30% per wegvak
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="spacer"></div>
    <button mat-raised-button (click)="onNoClick()" color="primary">Sluiten</button>
</div>