import {ChecklistQuestion} from './checklist-question';

export class Checklist {
    id: number;
    name: string;
    number: string;
    managed_by: string;
    version: string;
    created_at: Date;
    updated_at: Date;
    questions: ChecklistQuestion[] = [];
}
