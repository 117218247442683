<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Beheer</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="customer">
        <div *ngIf="AuthorisationService.hasAnyFeature(['priceChangeLog', 'pricesSave'])">
            <div class="action" routerLink="/prices" matRipple>
                <i class="fas fa-euro-sign fa-4x"></i>
                <span>Prijzen beheren</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('decorationSave')">
            <div class="action" routerLink="/decorations" matRipple>
                <i class="fas fa-tools fa-4x"></i>
                <span>Markeringen beheren</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('customerAreaSave')">
            <div class="action" routerLink="/customer-areas" matRipple>
                <i class="fas fa-draw-polygon fa-4x"></i>
                <span>Klantgebieden beheren</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('savePbmEquipment')">
            <div class="action" routerLink="/{{Routenames.ppeEquipment}}" matRipple>
                <i class="fas fa-hard-hat fa-4x"></i>
                <span>PBM beheren</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('saveMoldMaterial')">
            <div class="action" routerLink="/{{Routenames.moldMaterial}}" matRipple>
                <i class="fas fa-do-not-enter fa-4x"></i>
                <span>Materialen mallen</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('saveSmallMaterial')">
            <div class="action" routerLink="/{{Routenames.smallMaterial}}" matRipple>
                <i class="fas fa-coffee-pot fa-4x"></i>
                <span>Klein materieel</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('userGet')">
            <div class="action" routerLink="/users" matRipple>
                <i class="fas fa-users fa-4x"></i>
                <span>Gebruikers beheren</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('extraCostSave')">
            <div class="action" routerLink="/extra-costs" matRipple>
                <i class="fas fa-traffic-light fa-4x"></i>
                <span>Extra kosten beheren</span>
            </div>
        </div>

        <div *ngIf="AuthorisationService.hasFeature('checklistEdit')">
            <div class="action" routerLink="/{{Routenames.checklists}}" matRipple>
                <i class="fas fa-clipboard-check fa-4x"></i>
                <span>Inspectielijsten beheren</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('declarationManagement')">
            <div class="action" routerLink="/{{Routenames.declarationTypes}}" matRipple>
                <i class="fas fa-file-invoice fa-4x"></i>
                <span>Declaraties beheren</span>
            </div>
        </div>

        <div *ngIf="AuthorisationService.hasFeature('managementDashboard')">
            <div class="action" routerLink="/dashboard" matRipple>
                <i class="fas fa-chart-line fa-4x"></i>
                <span>Dashboard</span>
            </div>
        </div>
        <div *ngIf="AuthorisationService.hasFeature('exportDayProduction')">
            <div class="action" routerLink="/{{Routenames.dataExport}}" matRipple>
                <i class="fas fa-file-excel fa-4x"></i>
                <span>Export</span>
            </div>
        </div>
    </div>
</div>
