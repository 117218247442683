import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {Role} from '../../../classes/role.class';
import {RoleFeature} from '../../../classes/rolefeature.class';
import {AuthorisationService} from '../../../services/auth/authorisation.service';

@Component({
    selector: 'app-user-field-dialog',
    templateUrl: './user-field-dialog.component.html',
    styleUrls: ['./user-field-dialog.component.scss']
})
export class UserFieldDialogComponent implements OnInit {
    saving = false;
    form: FormGroup;
    roleFeature: RoleFeature;
    role: Role;

    constructor(public dialogRef: MatDialogRef<UserFieldDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { roleFeature, role },
                private authorisationService: AuthorisationService) {
        this.roleFeature = data.roleFeature;
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            user_field: new FormControl(this.roleFeature.user_field)
        });
    }

    save() {
        this.saving = true;
        this.authorisationService.addFeatureToRole(
            this.roleFeature.feature_id,
            this.roleFeature.role_id,
            this.form.get('user_field').value)
            .subscribe(() => {
                this.dialogRef.close();
                this.saving = false;
            });
    }
}
