import {PushpinPart} from './pushpinpart.class';

export class Decoration {
    id: number;
    name: string;
    surface: number;
    img: string;
    type: string;
    decoration_group: string;
    specify_length: boolean;
    specific_price: boolean;
    priority_figuration: boolean;
    order: number;
    group_order: number;
    pushpin_parts: PushpinPart[];

    mold: boolean;
}
