import {Component, Inject, OnInit} from '@angular/core';
import {ChangesService, PushpinPartChange} from '../../../services/changes.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Inspection} from '../../../classes/inspection';

@Component({
    selector: 'app-pushpin-changes-dialog',
    templateUrl: './pushpin-changes-dialog.component.html',
    styleUrls: ['./pushpin-changes-dialog.component.scss']
})
export class PushpinChangesDialogComponent implements OnInit {

    changedLines: PushpinPartChange[];
    addedLines: PushpinPartChange[];

    constructor(public dialogRef: MatDialogRef<PushpinChangesDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public inspection: Inspection,
                private changesService: ChangesService) {
    }

    ngOnInit(): void {
        this.changesService.getChanges(this.inspection.customer_area_id, this.inspection.id).then(changedLines => {
            this.changedLines = changedLines.filter(c => c.added === true);
        }, () => {

        });
    }

}
