<div class="dialog-container">
    <h1 class="condensed dialog-title">Nieuwe melding</h1>
    <div class="dialog-content">

        <div class="question-list">
            <div class="questions">
                <div class="question">
                    <div>
                        Heeft de gebeurtenis geleid tot nadelige of schadelijke effecten en/of letsel?
                    </div>
                    <div class="answer">
                        <mat-radio-group (change)="badEffectsChange($event)" [(ngModel)]="badEffects">
                            <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                            <mat-radio-button [value]="false">Nee</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="badEffects === false" class="question">
                    <div>
                        Had de gebeurtenis, onder andere omstandigheden, geleidt tot nadelige of schadelijke effecten (waaronder persoonlijk letsel)?
                    </div>
                    <div class="answer">
                        <mat-radio-group (change)="differentConditions($event)" [(ngModel)]="nearBadEffects">
                            <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                            <mat-radio-button [value]="false">Nee</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="nearBadEffects === false" class="question">
                    <div>
                        Heb je een klacht?
                    </div>
                    <div class="answer">
                        <mat-radio-group (change)="complaint($event)" [(ngModel)]="improvement">
                            <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                            <mat-radio-button [value]="false">Nee</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="improvement === false" class="question">
                    <div>
                        Heb je een verbeteridee?
                    </div>
                    <div class="answer">
                        <mat-radio-group (change)="improve($event)">
                            <mat-radio-button [value]="true" class="mr-3">Ja</mat-radio-button>
                            <mat-radio-button [value]="false">Nee</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-wrap">
        <button (click)="onNoClick()" mat-button>Annuleren</button>
        <div class="spacer"></div>
    </div>
</div>
