<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Markering beheren</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/decorations"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">
            <form [formGroup]="form">
                <mat-form-field class="w-100" id="name">
                    <input matInput type="text" formControlName="name" name="name"
                           placeholder="Naam">
                    <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Oppervlakte</mat-label>
                    <input matInput type="number" step="0.001" formControlName="surface"
                           placeholder="Oppervlakte in m²">
                    <mat-error>Vul een oppervlakte in m² in</mat-error>
                </mat-form-field>
                <mat-checkbox formControlName="specify_length" name="specify_length">Lengte specificeren</mat-checkbox>
                <br>
                <mat-checkbox formControlName="specific_price" name="specify_length">Specifieke prijs</mat-checkbox>
                <br>
                <mat-checkbox formControlName="priority_figuration" name="priority_figuration">Voorrangsfiguratie</mat-checkbox>
                <br>
                <mat-checkbox formControlName="mold" name="mold">Mal aanvragen mogelijk</mat-checkbox>
                <ng-select *ngIf="types"
                           [items]="types"
                           id="type"
                           [closeOnSelect]="true"
                           formControlName="type"
                           [multiple]="false"
                           name="type"
                           placeholder="Type"></ng-select>
                <ng-select *ngIf="groups"
                           [items]="groups"
                           id="customerareas"
                           [closeOnSelect]="true"
                           formControlName="decoration_group"
                           [multiple]="false"
                           [addTag]="true"
                           name="decoration_group"
                           placeholder="Groep"></ng-select>
            </form>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6 col-12">
            <div class="d-flex">
                <button mat-button routerLink="/decorations">Terug</button>
                <div class="spacer"></div>
                <button mat-raised-button color="primary" (click)="save()" [disabled]="form.invalid" id="btn-save">
                    Opslaan
                </button>
            </div>
        </div>
    </div>
</div>
