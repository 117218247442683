<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Reparaties</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div>
        <div class="pb-3">
            Gebruik de VVR app voor het melden van storingen aan machines.
        </div>
        <div class="row align-items-center">
            <div class="col-auto">
                <button color="primary" mat-raised-button routerLink="/repairs/new">
                    Nieuwe reparatie
                </button>
            </div>
            <div class="col-auto">
                <mat-slide-toggle (change)="getData()"
                                  [(ngModel)]="showClosed"
                                  class="mt-2 mb-2"
                                  color="primary">
                    Toon afgehandelde reparaties
                </mat-slide-toggle>
            </div>
            <button mat-raised-button (click)="export()" *ngIf="mayExport && !isCordova">
                Exporteren
            </button>
            <div class="col-md col-12">
                <div class="d-flex w-100 justify-content-end mr-0">
                    <mat-form-field class="col col-lg-6 pr-0 pl-0">
                        <mat-label>
                            Zoeken
                        </mat-label>
                        <input (keyup)="searching.emit()"
                               (keyup.enter)="openIfOne()"
                               [(ngModel)]="searchText"
                               autocomplete="off"
                               matInput>
                    </mat-form-field>
                </div>
            </div>
        </div>


        <div class="pt-3">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('id')" matRipple>
                        Melding
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="id"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('machine_number')" matRipple>
                        Machine nummer
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="machine_number"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('status')" matRipple>
                        Status
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="status"></app-sorting-icon>
                    </th>
                    <th>
                        Aangemaakt door
                    </th>
                    <th (click)="sortList('created_at')" matRipple>
                        Aangemaakt
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="created_at"></app-sorting-icon>
                    </th>
                    <th class="trash-column">

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of list" class="curpoint" routerLink="/repairs/{{item.id}}">
                    <td>
                        {{item.id}}
                    </td>
                    <td>
                        {{item.machine_number}}
                    </td>
                    <td>
                        {{item.status}}
                    </td>
                    <td>
                        {{item?.user?.name}}
                    </td>
                    <td>
                        {{item.created_at | date : 'd MMM yyyy, H:mm'}}
                    </td>
                    <td class="pt-0 pb-0">
                        <button (click)="delete($event, item)"
                                *ngIf="item.user_id === user.id && item.status === 'ingediend'"
                                color="warn"
                                mat-icon-button>
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
