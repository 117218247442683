import {Component, OnInit} from '@angular/core';
import {ExtraCostType} from '../classes/extracosttype.class';
import {ExtraCostsService} from '../services/extraCosts/extra-costs.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-extra-costs-overview',
    templateUrl: './extra-costs-overview.component.html',
    styleUrls: ['./extra-costs-overview.component.scss']
})
export class ExtraCostsOverviewComponent implements OnInit {

    public extraCostTypes: ExtraCostType[];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private extraCostsService: ExtraCostsService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.extraCostsService.getList().then(extraCostTypes => {
                this.extraCostTypes = extraCostTypes;
            });
        });
    }

    openDecoration(extraCostType) {
        this.router.navigate([`extra-costs/${extraCostType.id}`]);
    }

}
