import {Injectable} from '@angular/core';

import {ApiService} from '../api/api.service';
import {User} from '../../classes/user.class';

@Injectable()
export class UserService {

    constructor(private apiService: ApiService) {

    }

    public saveUser(user: User) {
        return this.apiService.postCall$<User>('user', user);
    }

    public saveProfile(user: User): Promise<User> {
        return this.apiService.postCall('user/current', user);
    }

    public getUser(userId: number) {
        return this.apiService.getCall$<User>(`user/${userId}`);
    }

    public getCurrentUser(): Promise<User> {
        return this.apiService.getCall(`user/current`);
    }

    getUsers() {
        return this.apiService.getCall$<User[]>('users');
    }

    public deleteUser(userId: number): Promise<Object> {
        return this.apiService.deleteCall(`user/${userId}`);
    }


}
