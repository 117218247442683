import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Settings} from '../../settings.class';
import {HaversineService} from '../../haversine.service';
import {ApiService} from '../../services/api/api.service';
import {Hectometre} from '../../hectometre';


@Injectable()
export class LocationService {

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private haversineService: HaversineService) {
    }

    getHecto(way: string) {
        return this.apiService.getCall$<Hectometre[]>('hecto', {way});
    }

    searchHecto(search: string) {
        return this.apiService.postCall$<Hectometre[]>('search', {search});
    }

    getLocations(lat: number, lng: number): Observable<any> {
        const url = `https://dev.virtualearth.net/REST/v1/Locations/${lat},${lng}?key=${Settings.BING_MAPS_API_KEY}&c=nl`;
        return this.http.jsonp(url, 'jsonp');
    }

    getAddresses(search: string): Observable<any> {
        search += ' Nederland';
        const url = `https://dev.virtualearth.net/REST/v1/Locations/NL/${encodeURI(('' + search).trim())}?key=${Settings.BING_MAPS_API_KEY}&c=nl`;
        return this.http.jsonp(url, 'jsonp');
    }

    getSnappedPoints(points, travelModeWalking?: boolean): Observable<any> {
        return new Observable(observer => {
            const resolveWithDistance = (parsedSnappedPoints) => {

                let totalMeters = 0;
                parsedSnappedPoints.forEach((point, index) => {
                    if (index > 0) {
                        const meter = this.haversineService.getDistanceInMeters(
                            parsedSnappedPoints[index - 1],
                            point
                        );
                        totalMeters += meter;
                    }
                });

                observer.next({
                    snappedPoints: parsedSnappedPoints,
                    totalLength: Math.round(totalMeters)
                });
            };
            const url = `https://dev.virtualearth.net/REST/v1/Routes/SnapToRoad?key=${Settings.BING_MAPS_API_KEY}`;
            this.http.post(url, {
                'points': points,
                'interpolate': true,
                'travelMode': travelModeWalking ? 'walking' : 'driving'
            }).subscribe((data) => {
                if (data['resourceSets'].length > 0) {
                    const snappedPoints = data['resourceSets'][0]['resources'][0]['snappedPoints'];
                    const parsedSnappedPoints = snappedPoints.map((p) => {
                        return {
                            latitude: p.coordinate.latitude,
                            longitude: p.coordinate.longitude
                        };
                    });

                    if (parsedSnappedPoints.length < 2) {
                        resolveWithDistance(points);
                    } else {
                        resolveWithDistance(parsedSnappedPoints);
                    }
                } else {
                    resolveWithDistance(points);
                }

            }, () => {
                resolveWithDistance(points);
            });
        });
    }
}
