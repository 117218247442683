import {Utils} from './utils.class';
import {LocalStorage} from './storage.class';
import {Settings} from './settings.class';
import {AuthorisationService} from './services/auth/authorisation.service';
import {Component, OnDestroy} from '@angular/core';
import {Routenames} from './route-names.enum';
import {Subscription} from 'rxjs';

declare const cordova;

@Component({template: ''})
export class CodaltComponent implements OnDestroy {

    Utils = Utils;
    LocalStorage = LocalStorage;
    Settings = Settings;
    AuthorisationService = AuthorisationService;
    isCordova = typeof cordova !== 'undefined';
    Routenames = Routenames;

    protected subscriptions = new Subscription();


    constructor() {

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
