import {Component, OnInit} from '@angular/core';
import {TwoFactorService} from '../../services/two-factor.service';
import {Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {CodaltComponent} from '../../codalt.component';

@Component({
    selector: 'app-two-factor',
    templateUrl: './two-factor.component.html',
    styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent extends CodaltComponent implements OnInit {

    twoFactorCode: string;
    validating = false;

    constructor(private twoFactorService: TwoFactorService,
                private confirmDialog: ConfirmDialogService,
                private authService: AuthenticationService,
                private router: Router) {
        super();
    }


    ngOnInit(): void {
    }

    login() {
        this.validating = true;
        this.twoFactorService.validate(`${this.twoFactorCode}`.replace(/\s/g, '')).subscribe(() => {
            this.authService.refresh().subscribe(() => {
                this.validating = false;
                this.router.navigateByUrl('/');
            });
        }, error => {
            this.validating = false;
            this.confirmDialog.confirm('Er ging iets fout', error.message);
        });
    }

}
