<ng-container *ngIf="completed">
    <div class="not-final" *ngIf="!completed.final">
        <div>
            Concept
        </div>
    </div>
    <div style="page-break-inside: avoid">
        <table class="checklist-info">
            <tr>
                <th>
                    Werknummer
                </th>
                <td>
                    {{completed.worknumber}}
                </td>
            </tr>
            <tr>
                <th>
                    Werkomschrijving
                </th>
                <td>
                    {{completed.description}}
                </td>
            </tr>
            <tr>
                <th>
                    Adres / locatie
                </th>
                <td>
                    {{completed.location}}
                </td>
            </tr>
            <tr>
                <th>
                    Voorman
                </th>
                <td>
                    {{completed?.foreman?.name}}
                </td>
            </tr>
            <tr>
                <th>
                    Inspecteur
                </th>
                <td>
                    {{completed?.inspector?.name}}
                </td>
            </tr>
            <tr>
                <th>
                    Datum
                </th>
                <td>
                    {{completed.created_at | date  : 'd MMMM yyyy'}}
                </td>
            </tr>
        </table>
        <div class="checklist-tables">

            <div class="mt-5 mb-1">
                <h2 class="h4 condensed">Inspectielijst</h2>
            </div>

            <table>
                <thead>
                <tr>
                    <th>
                        Nr.
                    </th>
                    <th>
                        Vraag
                    </th>
                    <th>
                        Antwoord
                    </th>
                    <th>
                        Toelichting
                    </th>
                </tr>
                </thead>
                <ng-container *ngFor="let question of completed.checklist.questions">
                    <tr>
                        <th class="number">
                            {{question.number}}
                        </th>
                        <th colspan="5">
                            {{question.question}}
                        </th>
                    </tr>
                    <tr *ngFor="let subQuestion of question.sub_items" class="sub-question">
                        <td class="number">
                            {{subQuestion.number}}
                        </td>
                        <td class="question" [innerHTML]="subQuestion.question | safeHtml">
                            {{subQuestion.question}}
                        </td>
                        <td class="answer">
                            {{questionAnswerMap.get(subQuestion.id)?.answer}}
                        </td>
                        <td class="comment" [innerHTML]="questionAnswerMap.get(subQuestion.id)?.comment | nl2br">

                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
    <div class="checklist-tables" style="page-break-inside: avoid">

        <div class="mt-5 mb-1">
            <h2 class="h4 condensed">Algehele indruk</h2>
        </div>
        <table>
            <thead>
            <tr>
                <th>
                    Positieve punten
                </th>
                <th>
                    Leerpunten
                </th>
            </tr>
            </thead>
            <tr>
                <td>
                    <ul>
                        <li [innerHTML]="point.comment | nl2br" *ngFor="let point of completed.points | filter : ChecklistPointType.positive : 'answer';">
                        </li>
                    </ul>
                </td>
                <td>
                    <ul>
                        <li [innerHTML]="point.comment | nl2br" *ngFor="let point of completed.points | filter : ChecklistPointType.learn : 'answer';">

                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
    <div class="checklist-tables" style="page-break-inside: avoid">
        <div class="mt-5 mb-1">
            <h2 class="h4 condensed">Actielijst</h2>
        </div>
        <table>
            <thead>
            <tr>
                <th>
                    Nr.
                </th>
                <th>
                    Actiepunt
                </th>
                <th>
                    Door
                </th>
                <th>
                    Deadline
                </th>
            </tr>
            </thead>
            <tr *ngFor="let action of completed.actions; let i = index;" class="action">
                <td class="number">
                    {{questions.get(action.question_id)?.number}}
                </td>
                <td class="action" [innerHTML]="action.action | nl2br">

                </td>
                <td class="user">
                    {{action.user}}
                </td>
                <td class="deadline">
                    {{action.deadline | date : 'd MMM yyyy'}}
                </td>
            </tr>
        </table>
    </div>
</ng-container>
