<div class="info">
    <div class="name">
        {{incidentComment?.user?.name}}
    </div>
    <div class="date">
        <ng-container *ngIf="incidentComment.created_at">
            {{incidentComment.created_at | date  : 'd MMM yyyy, H:mm'}}
        </ng-container>
        <ng-container *ngIf="!incidentComment.created_at">
            Nieuwe reactie
        </ng-container>
    </div>
    <div>
        <button (click)="edit()" *ngIf="!editing && mayEdit" color="primary" mat-icon-button>
            <mat-icon class="fas fa-pencil-alt"></mat-icon>
        </button>
        <button (click)="deleteComment()" *ngIf="mayEdit && incidentComment.id" color="warn" mat-icon-button>
            <mat-icon class="fas fa-trash"></mat-icon>
        </button>
    </div>
</div>
<mat-form-field *ngIf="editing" appearance="fill" class="w-100">
    <mat-label>Uw reactie</mat-label>
    <textarea [(ngModel)]="incidentComment.comment"
              cdkTextareaAutosize
              matInput
              placeholder="Typ hier uw reactie..."></textarea>
</mat-form-field>
<div *ngIf="!editing" [innerHTML]="incidentComment.comment | nl2br" class="comment">

</div>
<div class="files">
    <div *ngFor="let file of incidentComment.files" class="file">
        <div (click)="openFile.emit(file)"
             [class.is-img]="['jpg', 'jpeg', 'png'].indexOf(file.ext) !== -1">
            <div [style.background-image]="url + file.file+'?access_token='+token|safeBgUrl"
                 class="icon">
                <i class="fas fa-file"></i>
            </div>
            <div class="filename">
                {{file.name}}
            </div>
        </div>
    </div>
</div>
<div *ngIf="editing" class="actions">
    <button class="curpoint" mat-stroked-button style="padding: 0">
        <label style="margin: 0; padding: 0 16px; cursor: pointer">
            Bijlage toevoegen <input (change)="uploadFile($event)" hidden multiple type="file">
        </label>
    </button>

    <button (click)="placeOrEditComment()" color="primary" mat-flat-button [disabled]="!incidentComment.comment || saving">
        {{incidentComment.id ? 'Update reactie' : 'Plaats reactie'}}
        <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
    </button>
</div>
