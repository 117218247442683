import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {PpeStatus} from '../ppe-status';
import {User} from '../classes/user.class';
import {SmallMaterial} from '../smallmaterial';
import {SmallMaterialRequest} from '../smallmaterial-request';

@Injectable({
    providedIn: 'root'
})
export class SmallMaterialService {

    constructor(private apiService: ApiService) {
    }

    saveMaterial(equipment: SmallMaterial) {
        return this.apiService.postCall$<SmallMaterial>(`small-material/material`, equipment);
    }

    listMaterials() {
        return this.apiService.getCall$<SmallMaterial[]>(`small-material/material`);
    }

    getMaterial(id: number) {
        return this.apiService.getCall$<SmallMaterial>(`small-material/material/${id}`);
    }

    saveRequest(request: SmallMaterialRequest) {
        return this.apiService.postCall$<SmallMaterialRequest>(`small-material/requests`, request);
    }

    listRequests(statusIds: number[]) {
        return this.apiService.getCall$<SmallMaterialRequest[]>(`small-material/requests`, {statusIds});
    }

    getRequest(id: number) {
        return this.apiService.getCall$<SmallMaterialRequest>(`small-material/requests/${id}`);
    }

    deleteRequest(id: number) {
        return this.apiService.deleteCall$(`small-material/requests/${id}`);
    }

    deleteMaterial(id: number) {
        return this.apiService.deleteCall$(`small-material/material/${id}`);
    }

    statusList() {
        return this.apiService.getCall$<PpeStatus[]>(`small-material/status`);
    }

    managerList() {
        return this.apiService.getCall$<User[]>(`small-material/managers`);
    }

}
