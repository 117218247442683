import {Component, Inject, OnInit} from '@angular/core';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {Decoration} from '../../classes/decoration.class';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PushpinPart} from '../../classes/pushpinpart.class';
import {MaterialService} from '../../services/material/material.service';
import {Material} from '../../classes/material.class';
import {CustomerArea} from "../../classes/customerarea.class";

@Component({
    selector: 'app-pushpin-part-creation-wizard',
    templateUrl: './pushpin-part-creation-wizard.component.html',
    styleUrls: ['./pushpin-part-creation-wizard.component.scss']
})
export class PushpinPartCreationWizardComponent implements OnInit {

    public amountInput = '_';
    public type: string;
    public group: string;
    public types: string[] = [];
    public groups: string[] = [];
    public decorations: Decoration[];
    public amountsList: number[] = [];
    public pushpinPart: PushpinPart;
    public materials: Material[];
    public lengthmark: boolean;
    public copy: boolean;
    private _decorations: Decoration[];
    private customerArea: CustomerArea;

    materialList: Material[];

    constructor(
        private decorationsService: DecorationsService,
        private materialService: MaterialService,
        private dialogRef: MatDialogRef<PushpinPartCreationWizardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            lengthmark: boolean,
            copy?: boolean,
            customerArea: CustomerArea
        }) {
        this.lengthmark = data.lengthmark;
        this.copy = data.copy;
        this.customerArea = data.customerArea;
        for (let i = 1; i < 10; i++) {
            this.amountsList.push(i);
        }
    }

    ngOnInit() {
        this.initData();

    }

    public backspaceAmountInput() {
        this.amountInput = (this.amountInput + '').substring(0, (this.amountInput + '').length - 1);
        if (!this.amountInput) {
            this.amountInput = '_';
        }
    }

    public setAmountInput(number) {
        if (this.amountInput === '_') {
            this.amountInput = '' + number;
        } else if (this.amountInput) {
            this.amountInput = (this.amountInput + '' + number);
        } else {
            this.amountInput = number;
        }
    }

    selectMaterial(material) {
        this.pushpinPart.material = material;
        this.pushpinPart.material_id = material.id;

        if (material.id === 10) {
            this.group = 'Vlak';
            this.selectDecoration(this.decorations.find(decoration => {
                return decoration.id === 36;
            }));
        } else if (material.id === 5) {
            this.group = 'Voorgevormd';
            this.decorations = this.decorations.filter(decoration => {
                return ['Voorgevormd'].indexOf(decoration.decoration_group) !== -1;
            });
        } else {
            this.groups = this.groups.filter(group => {
                return group !== 'Voorgevormd';
            });
            if (this.groups.length === 1) {
                this.filterGroup(this.groups[0]);
            }
        }
        if (this.type === 'Diversen vervangen') {
            this.selectAmount(1);
        }
    }

    selectDecoration(decoration) {
        this.pushpinPart.decoration = decoration;
        this.pushpinPart.decoration_id = decoration.id;
        if (this.pushpinPart.material.primer) {
            const primer = this.materialList.sort((a, b) => a.sort_order - b.sort_order)
                .find(m => m.kind === 'Primer' &&
                    ['both', (this.pushpinPart.decoration.specific_price && this.pushpinPart.decoration.surface ? 'figuration' : 'material')].includes(m.suitable_kind) &&
                    m.suitable_materials.includes(this.pushpinPart.material_id)
                );
            this.pushpinPart.primer = primer.id;
        }
        if (this.copy) {
            this.pushpinPart.amount = null;
            this.dialogRef.close(this.pushpinPart);
        } else if ((decoration.specify_length || !decoration.surface) && this.pushpinPart.material_id) {
            this.selectAmount(1);
        }
    }

    selectAmount(amount) {
        if (!amount || amount < 1 || amount === '_') {
            amount = 1;
        }
        this.pushpinPart.amount = amount;
        this.dialogRef.close(this.pushpinPart);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    filterType(type: string) {
        if (type === 'Lengtemarkering') {
            this.materials = this.materials.filter(_material => {
                return _material.id !== 5 && _material.id !== 10;
            });
        }
        this.type = type;
        this.decorations = this._decorations.filter(decoration => {
            return decoration.type === type;
        });
        this.groups = [];
        this.decorations.forEach(decoration => {
            if (this.groups.indexOf(decoration.decoration_group) === -1) {
                this.groups.push(decoration.decoration_group);
            }
        });
        if (this.groups.length === 1) {
            this.filterGroup(this.groups[0]);
        }
    }

    filterGroup(group: string) {
        this.group = group;
        this.decorations = this.decorations.filter(decoration => {
            return decoration.decoration_group === group;
        });
        if (this.decorations.length === 1) {
            this.selectDecoration(this.decorations[0]);
        }
    }

    breadcrumBack(backToStep) {
        if (backToStep === 3) {
            this.filterGroup(this.group);
            this.pushpinPart.decoration = null;
            this.pushpinPart.decoration_id = null;
        }
        if (backToStep === 2) {
            this.group = null;
            this.pushpinPart.decoration = null;
            this.pushpinPart.decoration_id = null;
            this.filterType(this.type);
            this.selectMaterial(this.pushpinPart.material);
        }
        if (backToStep === 1) {
            this.filterType(this.type);
            this.pushpinPart.material_id = null;
        }
        if (backToStep < 1) {
            this.type = null;
            this.group = null;
            this.initData();
        }


    }

    private initData() {
        this.pushpinPart = new PushpinPart();
        this.materialService.getMaterials(true).then(materials => {
            this.materials = materials.filter(m => m.usable);
            this.materialList = materials.filter(m => !m.usable);
        });


        this.decorationsService.getList(this.customerArea).then(decorations => {
            this._decorations = decorations;
            this._decorations.forEach(decoration => {
                if (this.types.indexOf(decoration.type) === -1) {
                    if (decoration.type) {
                        this.types.push(decoration.type);
                    }
                }
            });
            if (this.lengthmark) {
                this.filterType('Lengtemarkering');
            }
        });
    }

}
