import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {EditIncidentComponent} from '../incident-registration/edit-incident/edit-incident.component';

@Injectable()
export class CanDeactivateIncidentComment implements CanDeactivate<EditIncidentComponent> {
    constructor() {
    }

    canDeactivate(
        component: EditIncidentComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component) {
            return component.openCommentsBackActionCheck();
        }
        return true;
    }
}
