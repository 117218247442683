import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ExtraCost} from '../../../classes/extracost.class';
import {ExtraCostsService} from '../../../services/extraCosts/extra-costs.service';
import {ExtraCostCountType, ExtraCostType} from '../../../classes/extracosttype.class';
import {ConfirmDialogService} from '../../../services/confirm-dialog-service/confirm-dialog.service';
import {Inspection} from '../../../classes/inspection';
import {PricesService} from '../../../services/prices/prices.service';

export class ExtraCostDialogData {
    extraCost: ExtraCost;
    extraCostsPricesMap: object;
    inspection: Inspection;
}

@Component({
    selector: 'app-extra-costs-dialog',
    templateUrl: './extra-costs-dialog.component.html',
    styleUrls: ['./extra-costs-dialog.component.scss']
})
export class ExtraCostsDialogComponent implements OnInit, OnDestroy {

    public extraCost: ExtraCost;
    public extraCosts: ExtraCostType[];
    public extraCostsPricesMap = {};

    constructor(private extraCostsService: ExtraCostsService,
                private pricesService: PricesService,
                private confirmDialogService: ConfirmDialogService,
                public dialogRef: MatDialogRef<ExtraCostsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public extraCostDialogData: ExtraCostDialogData) {
    }

    ngOnInit() {
        this.extraCost = this.extraCostDialogData.extraCost;
        this.extraCostsPricesMap = this.extraCostDialogData.extraCostsPricesMap;
        if (!this.extraCost) {
            this.extraCost = new ExtraCost();
        }
        this.extraCostsService.getList().then(_extraCosts => {
            this.extraCosts = _extraCosts;
            if (this.extraCost?.extra_cost_type_id && !this.extraCosts.some(e => e.id === this.extraCost.extra_cost_type_id)) {
                this.extraCosts.push(this.extraCost.extra_cost_type);
            }
        });
    }

    costTypeChanged(event) {
        this.extraCost.extra_cost_type = this.extraCosts.find(type => {
            return type.id === event;
        });
        if (this.extraCost.extra_cost_type?.count_based_on !== ExtraCostCountType.count) {
            this.extraCost.number = Math.round(this.pricesService.extraCostSurfaceCalculation(this.extraCost) * 100) / 100;
        }
    }

    save() {
        this.extraCost.inspection_id = this.extraCostDialogData.inspection.id;
        this.extraCostsService.saveExtraCost(this.extraCost).then(extraCost => {
            this.extraCost.id = extraCost.id;
            this.dialogRef.close();
        });
    }

    delete() {
        this.confirmDialogService.confirm(
            'Verwijderen',
            'Weet u zeker dat u deze extra kosten wilt verwijderen?',
            'Ja',
            'Nee').then(
            () => {
                if (this.extraCost.id) {
                    this.extraCostsService.deleteExtraCost(this.extraCost.id).then(result => {
                        this.dialogRef.close(true);
                    }, () => {

                    });
                } else {
                    this.dialogRef.close(true);
                }
            }, () => {

            }
        );
    }

    close() {
        this.dialogRef.close((!this.extraCost.id));
    }

    ngOnDestroy(): void {
    }

    protected readonly ExtraCostCountType = ExtraCostCountType;
}

