import {Component, OnInit} from '@angular/core';
import {DecorationsService} from '../services/decorations/decorations.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Decoration} from '../classes/decoration.class';

@Component({
    selector: 'app-decorations-overview',
    templateUrl: './decorations-overview.component.html',
    styleUrls: ['./decorations-overview.component.scss']
})
export class DecorationsOverviewComponent implements OnInit {

    decorationGroups: {
        group: string,
        order: number,
        decorations: Decoration[]
    }[];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private decorationsService: DecorationsService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.decorationsService.getList().then(decorations => {
                this.decorationGroups = [];
                const groups = decorations.map(d => d.decoration_group)
                    .filter((value, index, self) => self.indexOf(value) === index);

                groups.forEach(group => {
                    const decorationGroup = decorations.filter(d => d.decoration_group === group);
                    this.decorationGroups.push({
                        decorations: decorationGroup,
                        group: group,
                        order: decorationGroup[0]?.order
                    });
                });

            });
        });
    }

    openDecoration(decoration) {
        this.router.navigate([`decorations/${decoration.id}`]);
    }

}
