import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {ProfileComponent} from "./profile.component";

export class ProfileRequiredRules {
    static current_password = (component: ProfileComponent) => {
        return component.form.get('change_pass').value || component.form.get('email').value !== component.user.email;
    };


}

export function RequiredRule(component: ProfileComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const getControlName = (c: AbstractControl): string | null => {
            if (c && c.parent) {
                const formGroup = c.parent.controls;
                return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
            }
        };

        // controlName is empty on initializing because parent has not been set yet
        const controlName = getControlName(control);
        if (controlName) {
            const rule = ProfileRequiredRules[controlName];
            if (!rule) {
                throw('No rule defined for ' + controlName + ', please define rule in planning-detail-dialog.required-rules.ts');
            }

            if (
                (control.value == null || control.value === undefined || control.value == ''
                    || (Array.isArray(control.value) && control.value.length < 1)) &&
                rule.call(null, component)
            ) {
                return {required: true};
            }
            return null;
        }
        return {required: true};
    };


}