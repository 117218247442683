import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Incident} from '../classes/incident.class';
import {IncidentComment} from "../classes/incidentcomment.class";

@Injectable({
    providedIn: 'root'
})
export class IncidentService {

    constructor(private apiService: ApiService) {
    }

    getList(final = false): Promise<Incident[]> {
        const param = final ? {final} : {};
        return this.apiService.getCall(`incident`, param);
    }

    get(id: number): Promise<Incident> {
        return this.apiService.getCall(`incident/${id}`);
    }

    delete(id: number): Promise<Incident> {
        return this.apiService.deleteCall(`incident/${id}`);
    }

    savePushpin(incident: Incident): Promise<Incident> {
        return this.apiService.postCall('incident', incident);
    }

    getOptions(): Promise<IncidentOptions> {
        return this.apiService.getCall(`incident/options`);
    }

    saveComment(comment: IncidentComment): Promise<IncidentComment> {
        return this.apiService.postCall('incident/comment', comment);
    }

    deleteComment(id: number): Promise<boolean> {
        return this.apiService.deleteCall(`incident/comment/${id}`);
    }
}

export class IncidentOptions {
    status: string[];
    type: string[];
}
