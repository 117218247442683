import {Component, Inject, OnInit} from '@angular/core';
import {PushpinService} from '../../services/pushpin/pushpin.service';
import {PushpinPartLog} from '../../classes/pushpinpartlog.class';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PushpinPart} from '../../classes/pushpinpart.class';
import {StatusService} from '../../services/status/status.service';
import {MaterialService} from '../../services/material/material.service';
import {DecorationsService} from '../../services/decorations/decorations.service';
import {DatePipe} from '@angular/common';
import {PaintColorService} from '../../services/paintColor/paint-color.service';
import {LocalStorage} from '../../storage.class';
import {combineLatest} from "rxjs";
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {CustomerArea} from '../../classes/customerarea.class';

@Component({
    selector: 'app-pushpin-part-history',
    templateUrl: './pushpin-part-history.component.html',
    styleUrls: ['./pushpin-part-history.component.scss']
})
export class PushpinPartHistoryComponent implements OnInit {

    public userGroup;

    public history;

    private customerArea: CustomerArea;
    private keyValMap = {};
    private pushpinPartLog: PushpinPartLog[];
    private fieldNameMap = {
        status_id: 'Status',
        user_id: 'Gebruiker',
        crow: 'CROW',
        description: 'Beschrijving',
        customer_note: 'Beschrijving (consument)',
        primer: 'Primer',
        blast: 'Stralen',
        stake_out: 'Uitzetten',
        material_id: 'Meteriaal',
        decoration_id: 'Figuratie',
        paint_color_id: 'Kleur',
        amount: 'Aantal',
        length: 'Lengte',
        surface: 'Oppervlakte',
        traffic_controllers: 'Verkeersregelaars',
        night: 'Nacht',
        exclusive_material: 'Exclusief materiaal',
        manual_burn: 'Handmatig branden',
        overgrown: 'Overgroeide kanten',
        turbo_burn: 'Turbo branden',
        inspection_id: 'Schouwronde'
    };
    private isBoolean = ['primer', 'blast', 'stake_out', 'traffic_controllers', 'night', 'exclusive_material', 'manual_burn', 'overgrown', 'turbo_burn'];
    private isForeignKey = ['material_id', 'decoration_id', 'status_id', 'paint_color_id'];
    private toSkip = ['final_amount', 'final_length', 'final_surface'];

    constructor(private pushpinService: PushpinService,
                private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService,
                private customerAreaService: CustomerAreaService,
                private datePipe: DatePipe,
                public dialogRef: MatDialogRef<PushpinPartHistoryComponent>,
                @Inject(MAT_DIALOG_DATA) public pushpinPart: PushpinPart) {
    }

    ngOnInit() {
        this.userGroup = LocalStorage.getUser().group;

        const customerArea$ = this.customerAreaService.getPolygon(this.pushpinPart.customer_area_id);
        const status$ = this.statusService.getKeyValMap();
        const decorations$ = this.decorationsService.getKeyValMap();
        const materials$ = this.materialService.getMaterialsAsKeyValueMap();
        const paintColor$ = this.paintColorService.getKeyValMap();
        const pushpinLog$ = this.pushpinService.getPushpinPartLog(this.pushpinPart.id);
        combineLatest([status$, decorations$, materials$, paintColor$, pushpinLog$, customerArea$])
            .subscribe(([status, decoration, material, paintColor, pushpinPartLog, customerArea]) => {
                this.customerArea = customerArea;
                this.keyValMap['status_id'] = status;
                this.keyValMap['decoration_id'] = decoration;
                this.keyValMap['material_id'] = material;
                this.keyValMap['paint_color_id'] = paintColor;
                this.pushpinPartLog = pushpinPartLog;
                this.parseLog();
            });
    }

    parseLog() {
        let userAndDate = '';
        const history = [];
        this.pushpinPartLog.forEach(partLogItem => {
            if ((this.toSkip.indexOf(partLogItem.field) === -1) &&
                ((partLogItem.new_integer !== partLogItem.old_integer && partLogItem.old_integer) ||
                    (partLogItem.new_text !== partLogItem.old_text && partLogItem.old_text) ||
                    (partLogItem.old !== partLogItem.new && (!!partLogItem.old || !!partLogItem.new)))) {
                let historyItem = '';
                const itemUserAndDate = `<div class="log-date-split"><b>${partLogItem.user?.name}</b> op ${this.datePipe.transform(partLogItem.updated_at, 'dd MMMM yyyy \'om\' HH:mm')} uur</div>`;
                if (itemUserAndDate !== userAndDate) {
                    userAndDate = itemUserAndDate;
                    historyItem += `${userAndDate}`;
                }
                if (partLogItem.field === 'inspection_id') {
                    historyItem += `${this.fieldNameMap[partLogItem.field]} van `;
                    historyItem += `<b>${this.customerArea?.inspections?.find(i => i.id === +partLogItem.old)?.name}</b>`;
                    historyItem += ` naar <b>${this.customerArea?.inspections?.find(i => i.id === +partLogItem.new)?.name}</b>`;
                } else if (partLogItem.field === 'deleted_at') {
                    if (partLogItem.old) {
                        historyItem += 'Heeft dit deel hersteld';
                    } else {
                        historyItem += 'Heeft dit deel verwijderd';
                    }
                } else {
                    historyItem += `${this.fieldNameMap[partLogItem.field]} van `;
                    if (this.isBoolean.indexOf(partLogItem.field) > -1) {
                        historyItem += `<b>${partLogItem.old_integer || +partLogItem.old ? 'ja' : 'nee'}</b>`;
                        historyItem += ` naar <b>${partLogItem.new_integer || +partLogItem.new ? 'ja' : 'nee'}</b>`;
                    } else if (this.isForeignKey.indexOf(partLogItem.field) > -1) {
                        if (this.keyValMap[partLogItem.field][partLogItem.old_integer]) {
                            historyItem += `<i>${this.keyValMap[partLogItem.field][partLogItem.old_integer]['name']}</i>`;
                        }
                        if (this.keyValMap[partLogItem.field][partLogItem.new_integer]) {
                            historyItem += ` naar <b>${this.keyValMap[partLogItem.field][partLogItem.new_integer]['name']}</b>`;
                        }
                    } else if (partLogItem.new_text) {
                        historyItem += `<i>'${partLogItem.old_text}'</i> naar <b>'${partLogItem.new_text}</b>'`;
                    } else if (partLogItem.new_integer) {
                        historyItem += `<i>${partLogItem.old_integer}</i> naar <b>${partLogItem.new_integer}</b>`;
                    } else {
                        if (partLogItem.old) {
                            historyItem += `<i>${partLogItem.old}</i> naar <b>${partLogItem.new}</b>`;
                        }
                    }
                }

                history.push(historyItem);
            }
        });
        if (history.length === 0) {
            history.push('Dit markeringsitem is nog niet aangepast');
        }
        this.history = history;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
