import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {PpeEquipment} from '../ppe-equipment';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Router} from '@angular/router';
import {Routenames} from '../route-names.enum';
import {SmallMaterial} from '../smallmaterial';
import {SmallMaterialService} from '../services/small-material.service';

@Component({
    selector: 'app-small-material-management',
    templateUrl: './small-material-management.component.html',
    styleUrls: ['./small-material-management.component.scss']
})
export class SmallMaterialManagementComponent extends CodaltComponent implements OnInit {

    smallMaterials: SmallMaterial[];
    currentSort = null;
    desc = false;

    constructor(private smallMaterialService: SmallMaterialService,
                private confirmDialog: ConfirmDialogService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.smallMaterialService.listMaterials().subscribe(equipment => {
            this.smallMaterials = equipment.data;
        });
    }

    edit(equipment: PpeEquipment) {
        this.router.navigate([Routenames.smallMaterial, equipment.id]);
    }

    sortList(sortingOn) {
        let materials = this.smallMaterials;
        this.smallMaterials = [];

        if (sortingOn === this.currentSort) {
            materials = materials.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = false;
            materials = materials.sort((a, b) => {
                if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                    return -1;
                }
                if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        }

        this.smallMaterials = materials;

        this.currentSort = sortingOn;
    }

    delete(event, equipment: PpeEquipment) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Materiaal verwijderen',
            `Weet u zeker dat u materiaal ${equipment.name} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.smallMaterialService.deleteMaterial(equipment.id).subscribe(() => {
                const index = this.smallMaterials.indexOf(equipment);
                this.smallMaterials.splice(index, 1);
            });
        }, () => {

        });
    }

}
