<div class="image-viewer-container">
    <div (click)="dialogRef.close()" class="close-viewer">
        <i class="fas fa-times"></i>
    </div>
    <div [(index)]="images.viewIndex" [swiper]="config" class="swiper-container">
        <div class="swiper-wrapper">
            <div *ngFor="let img of images.images" class="image swiper-slide">
                <div class="image-box">
                    <img *ngIf="img.path"
                         [src]="(Settings.API_ENDPOINT+'file/thumb?'+secure+'path=/'+img.article_id+'/'+img.path+'&access_token='+LocalStorage.getUserToken())">
                    <img *ngIf="!img.path" [src]="img">
                </div>
                <div class="text">
                </div>
            </div>
        </div>

        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
</div>
