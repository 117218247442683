<div class="dialog-container">
    <h1 class="condensed dialog-title">Extra kosten {{extraCost.id ? 'Wijzigen' : 'Toevoegen'}}</h1>
    <div class="dialog-content">
        <div class="row" *ngIf="extraCosts?.length === 0">
            <div class="col-12">
                Er zijn nog geen extra kosten aangemaakt. Je kunt deze toevoegen via het prijzenscherm.
            </div>
        </div>
        <ng-container *ngIf="extraCosts?.length !== 0">
            <div class="row">
                <mat-form-field class="col-6" appearance="fill">
                    <mat-label>Soort kosten</mat-label>
                    <mat-select placeholder="Soort kosten"
                                (ngModelChange)="costTypeChanged($event)"
                                [(ngModel)]="extraCost.extra_cost_type_id">
                        <mat-option *ngFor="let cost of extraCosts"
                                    [value]="cost.id">
                            {{cost.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-6 txtblack" appearance="fill">
                    <mat-label>Prijs per {{extraCost.extra_cost_type ? extraCost.extra_cost_type.unit : ''}}
                    </mat-label>
                    <span matPrefix>€&nbsp;&nbsp;</span>
                    <input matInput type="text" disabled
                           value="{{extraCostsPricesMap[extraCost.extra_cost_type_id] | number:'1.2-2'}}">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col-6" appearance="fill">
                    <mat-label>Aantal</mat-label>
                    <input matInput type="number" step="0.1" [min]="0.1"
                           [disabled]="extraCost?.extra_cost_type?.count_based_on !== ExtraCostCountType.count"
                           [(ngModel)]="extraCost.number">
                    <span matSuffix *ngIf="extraCost.extra_cost_type">{{extraCost.extra_cost_type.unit}}</span>
                </mat-form-field>
                <mat-form-field class="col-6 txtblack" appearance="fill">
                    <mat-label>Totaal prijs</mat-label>
                    <span matPrefix>€&nbsp;&nbsp;</span>
                    <input matInput type="text" disabled
                           value="{{extraCost.number * extraCostsPricesMap[extraCost.extra_cost_type_id] | number:'1.2-2'}}">
                </mat-form-field>
            </div>

            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Opmerking</mat-label>
                <input matInput type="text"
                       placeholder="Opmerking" [(ngModel)]="extraCost.comment">
            </mat-form-field>
        </ng-container>
    </div>
    <div class="d-flex flex-wrap">
        <button mat-button (click)="close()">Annuleren</button>
        <ng-container *ngIf="extraCosts?.length !== 0">
            <div class="spacer"></div>
            <button mat-icon-button (click)="delete()" color="primary" class="mr-3" *ngIf="extraCost.id">
                <i class="fas fa-trash"></i>
            </button>
            <button mat-raised-button (click)="save()" cdkFocusInitial
                    color="primary">{{extraCost.id ? 'Wijzigen' : 'Toevoegen'}}</button>
        </ng-container>
    </div>
</div>
