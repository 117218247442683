import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {User} from '../classes/user.class';
import {MoldMaterial} from '../mold-material';
import {MoldRequest} from '../mold-request';
import {MoldStatus} from '../mold-status';

@Injectable({
    providedIn: 'root'
})
export class MoldService {

    constructor(private apiService: ApiService) {
    }

    saveMaterial(equipment: MoldMaterial) {
        return this.apiService.postCall$<MoldMaterial>(`mold/material`, equipment);
    }

    removeMaterial(id: number) {
        return this.apiService.deleteCall$(`mold/material/${id}`);
    }

    listMaterial() {
        return this.apiService.getCall$<MoldMaterial[]>(`mold/material`);
    }

    getMaterial(id: number) {
        return this.apiService.getCall$<MoldMaterial>(`mold/material/${id}`);
    }

    saveRequest(request: MoldRequest) {
        return this.apiService.postCall$<MoldRequest>(`mold/requests`, request);
    }

    listRequests(statusIds: number[]) {
        return this.apiService.getCall$<MoldRequest[]>(`mold/requests`, {statusIds});
    }

    getRequest(id: number) {
        return this.apiService.getCall$<MoldRequest>(`mold/requests/${id}`);
    }

    deleteRequest(id: number) {
        return this.apiService.deleteCall$(`mold/requests/${id}`);
    }

    deleteMaterial(id: number) {
        return this.apiService.deleteCall$(`mold/material/${id}`);
    }

    statusList() {
        return this.apiService.getCall$<MoldStatus[]>(`mold/status`);
    }

    managerList() {
        return this.apiService.getCall$<User[]>(`mold/managers`);
    }

}
