import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Role} from '../../classes/role.class';
import {LocalStorage} from '../../storage.class';
import {ConditionalFunctions} from './conditional-functions';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Feature} from '../../classes/feature.class';
import {User} from "../../classes/user.class";

@Injectable({
    providedIn: 'root'
})
export class AuthorisationService {

    constructor(public apiService: ApiService) {
    }

    public static hasFeature(featureKey, params?: object, user?: User) {
        if (!user) {
            user = LocalStorage.user;
        }
        return !!user.roles?.find(role => {
            if (!role.conditional_function ||
                (typeof ConditionalFunctions[role.conditional_function] === 'function'
                    && ConditionalFunctions[role.conditional_function](user, role, params))) {
                return role.role_features.find(rf =>
                    rf.feature.key === featureKey
                    && (!rf.user_field || (params && params[rf.user_field] === user.id))
                );
            }
            return false;
        });
    }

    public static hasAnyFeature(featureKeys: string[], user?: User) {
        return !!featureKeys.find(featureKey => {
            return this.hasFeature(featureKey, null, user);
        });
    }

    public getRoles(): Observable<Role[]> {
        return this.apiService.getCall$<Role[]>('auth/roles').pipe(map(r => r.data));
    }

    public getFeatures(): Observable<Feature[]> {
        return this.apiService.getCall$<Feature[]>('auth/features').pipe(map(r => r.data));
    }

    public addFeatureToRole(feature_id, role_id, user_field?: string) {
        return this.apiService.postCall$('auth/addFeatureToRole', {
            role_id,
            feature_id,
            user_field
        });
    }

    public removeFeatureFromRole(feature_id, role_id) {
        return this.apiService.postCall$('auth/removeFeatureFromRole', {
            role_id,
            feature_id
        });
    }
}
