<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Inspectielijst {{checklist?.id ? 'aanpassen' : 'aanmaken'}}</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/{{Routenames.checklists}}"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <ng-container *ngIf="form">
        <div class="row justify-content-center mb-3">
            <div class="col-12 col-lg-9">
                <div [formGroup]="form">
                    <div class="pt-3 pb-2">
                        <h4 class="h4 condensed">Algemeen</h4>
                    </div>
                    <mat-form-field class="w-100">
                        <input matInput type="text" [formControl]="form.controls.name"
                               placeholder="Naam">
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <input matInput type="text" [formControl]="form.controls.number"
                               placeholder="Documentnummer">
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <input matInput type="text" [formControl]="form.controls.managed_by"
                               placeholder="Beheert door">
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <input matInput type="text" [formControl]="form.controls.version"
                               placeholder="Versie">
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                    <div class="pt-3 pb-2">
                        <h4 class="h4 condensed">Vragen</h4>
                    </div>
                    <div *ngIf="!form.value.questions?.length" class="text-red text-center">
                        <i>Voeg minimaal 1 groep toe</i>
                    </div>
                    <div class="questions"
                         cdkDropList
                         [cdkDropListData]="form.controls.questions.controls"
                         (cdkDropListDropped)="drop($event)">
                        <div cdkDrag
                             [cdkDragData]="question"
                             *ngFor="let question of form.controls.questions.controls; let i = index">
                            <div class="question">
                                <div class="number" cdkDragHandle>{{question.value.number}}</div>
                                <mat-form-field class="w-100" style="font-weight: bold">
                                    <textarea id="c{{i}}" matInput type="text" [formControl]="question.controls.question"
                                              cdkTextareaAutosize
                                              placeholder="Titel"
                                              (keyup.control.enter)="addQuestion($event, form.controls.questions, null, i)">
                                    </textarea>
                                </mat-form-field>
                                <button mat-icon-button tabindex="-1"
                                        (click)="removeQuestion(form.controls.questions,i)"
                                        color="primary">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <div *ngIf="!question.value.sub_items?.length" class="text-center text-red">
                                <i>Voeg minimaal 1 vraag toe</i>
                            </div>
                            <div class="questions"
                                 cdkDropList
                                 [cdkDropListData]="question.controls.sub_items.controls"
                                 (cdkDropListDropped)="drop($event)">
                                <div class="pl-3"
                                     cdkDrag
                                     (cdkDragDropped)="tiny.reinit()"
                                     [cdkDragData]="subQuestion"
                                     *ngFor="let subQuestion of question.controls.sub_items.controls; let ii = index">
                                    <div class="question" *cdkDragPreview>
                                        <div class="number" cdkDragHandle>{{subQuestion.value.number}}</div>
                                        <div class="w-100" [innerHTML]="subQuestion.value.question | safeHtml">

                                        </div>
                                    </div>
                                    <div class="question">
                                        <div class="number" cdkDragHandle>{{subQuestion.value.number}}</div>
                                        <simple-tiny [content]="subQuestion.controls.question" class="w-100"
                                                     [menubar]="false"
                                                     [fullscreen]="false"
                                                     [minHeight]="150"
                                                     [resize]="true"
                                                     elementId="{{i}}{{ii}}"
                                                     #tiny
                                                     ngDefaultControl
                                                     propertyName="value"
                                        ></simple-tiny>

                                        <button mat-icon-button tabindex="-1"
                                                (click)="removeQuestion(question.controls.sub_items,ii)"
                                                color="primary">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <button mat-button class="w-100"
                                        (click)="addQuestion($event, question.controls.sub_items, question)">
                                    Vraag {{question.value.number.toUpperCase()}}{{(question.value.sub_items.length + 1)}} toevoegen
                                </button>
                            </div>

                        </div>
                        <button mat-button class="w-100"
                                (click)="addQuestion($event, form.controls.questions)">
                            Groep {{form.value?.questions?.length ? getNextLetter(form.value.questions[form.value.questions.length - 1].number.toUpperCase()) : 'A'}} toevoegen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-9 col-12 mb-3">
                <div class="d-flex">
                    <button mat-button routerLink="/{{Routenames.checklists}}">Terug</button>
                    <div class="spacer"></div>
                    <button mat-raised-button color="primary" (click)="save()" id="btn-save">
                        Opslaan
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
