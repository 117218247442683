import {Pipe, PipeTransform} from '@angular/core';
import {ExtraCost} from '../classes/extracost.class';
import {PricesService} from '../services/prices/prices.service';
import {PushpinPart} from '../classes/pushpinpart.class';
import {ExtraCostPrice} from '../classes/extracostprice.class';

@Pipe({
    name: 'totalPriceExtraCosts'
})
export class TotalPriceExtraCostsPipe implements PipeTransform {

    constructor(private pricesService: PricesService) {
    }

    transform(extraCosts: ExtraCost[],
              pushpinParts: PushpinPart[],
              extraCostPrices: ExtraCostPrice[],
              customerAreaId?: number,
              inspectionId?: number): number {
        const ec = extraCosts.filter(e => (!customerAreaId || +e.customer_area_id === +customerAreaId) && (!inspectionId || inspectionId === e.inspection_id));
        let total = 0;
        ec.forEach(extraCost => {
            const ppp = pushpinParts.filter(p => extraCost.inspection_id === p.inspection_id);
            const price = extraCostPrices.find(ecp => extraCost.inspection_id === ecp.inspection_id && ecp.extra_cost_type_id === extraCost.extra_cost_type_id);
            if (price?.price) {
                total += this.pricesService.calculatePriceExtraCost(extraCost, +price?.price, ppp);
            }
        });
        return total;
    }

}
