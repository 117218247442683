<div class="dialog-container">
    <h1 class="condensed dialog-title">
       Hernoemen
    </h1>
    <div class="dialog-content mt-3">
        <form [formGroup]="form">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Naam</mat-label>
                <input matInput type="text" formControlName="name" name="name" id="name"
                       placeholder="Naam">
                <mat-error>Dit veld mag maximaal 255 tekens bevatten</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="d-flex flex-wrap">
        <button mat-button [mat-dialog-close]="false">Annuleren</button>
        <div class="spacer"></div>
        <button mat-raised-button (click)="save()" cdkFocusInitial color="primary" [disabled]="saving || !form.valid">
            Opslaan <i class="fas fa-circle-notch fa-spin" *ngIf="saving"></i>
        </button>
    </div>
</div>
