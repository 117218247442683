<div class="dialog-container">
    <h1 class="condensed dialog-title">Staartkosten {{tailCharge.id ? 'Wijzigen' : 'Toevoegen'}}</h1>
    <div class="dialog-content">
        <div class="row">
            <mat-form-field class="col-6" appearance="fill">
                <mat-label>Percentage</mat-label>
                <input matInput
                       appDecimalInput
                       [decimalInput]="3"
                       [maxNumberLength]="3"
                       [(ngModel)]="percentage">
                <span matSuffix>%</span>
                <mat-hint>Gebaseerd op huidige delen</mat-hint>
            </mat-form-field>
            <mat-form-field class="col-6 txtblack" appearance="fill">
                <mat-label>Totaal bedrag</mat-label>
                <span matPrefix>€&nbsp;&nbsp;</span>
                <input matInput type="text" disabled
                       value="{{tailChargeDialogData.totalPrice * (percentage / 100) | number:'1.2-2'}}">
            </mat-form-field>
        </div>

        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Opmerking</mat-label>
            <input matInput type="text"
                   placeholder="Opmerking" [(ngModel)]="tailCharge.comment">
        </mat-form-field>

    </div>
    <div class="d-flex flex-wrap">
        <button mat-button (click)="close()">Annuleren</button>
        <div class="spacer"></div>
        <button mat-icon-button (click)="delete()" color="primary" class="mr-3" *ngIf="tailCharge.id">
            <i class="fas fa-trash"></i>
        </button>
        <button mat-raised-button (click)="save()" cdkFocusInitial
                color="primary">{{tailCharge.id ? 'Wijzigen' : 'Toevoegen'}}</button>
    </div>
</div>
