import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';

@Injectable({
    providedIn: 'root'
})
export class DataExportService {

    constructor(private apiService: ApiService) {

    }

    getDayCounts() {
        return this.apiService.getCall$<DayCount[]>('data-export/day-counts');
    }
}

export class DayCount {
    amount: number;
    date: Date;
    length: number;
    material_id: number;
    specify_length: boolean;
    totalSurface: number;
}
