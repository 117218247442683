import {Component, OnInit} from '@angular/core';
import {CustomerArea} from '../classes/customerarea.class';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {combineLatest} from 'rxjs';
import {Status, StatusId} from '../classes/status.class';
import {StatusService} from '../services/status/status.service';
import {PricesService, PriceType} from '../services/prices/prices.service';
import {PushpinService} from '../services/pushpin/pushpin.service';
import {MaterialService} from '../services/material/material.service';
import {DecorationsService} from '../services/decorations/decorations.service';
import {PaintColorService} from '../services/paintColor/paint-color.service';
import {PushpinPart} from '../classes/pushpinpart.class';
import {MaterialPrice} from '../classes/materialprice.class';
import {DecorationPrice} from '../classes/decorationprice.class';
import {ExtraCostsService} from '../services/extraCosts/extra-costs.service';
import {ExtraCost} from '../classes/extracost.class';
import {CodaltComponent} from '../codalt.component';
import {Material} from '../classes/material.class';
import {ExtraCostPrice} from '../classes/extracostprice.class';

class stat {
    count: number;
    customer_area_id: number;
    status_id: number;
    type: string;
    name: string;
    surface: number;
    length: number;
    price: number;
}

class statMaterial {
    name: string;
    stat_free: stat;
    stat_executed: stat;
    stat_paid: stat;
    percentageCount: number;
    percentageSurface: number;
    percentagePrice: number;
}

class statCustomerArea {
    name: string;
    materials: statMaterial[];
}

class statType {
    priceType: PriceType;
    name: string;
    color: string;
    ca?: statCustomerArea[];
}


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends CodaltComponent implements OnInit {

    filter = [];
    types = [
        {
            priceType: PriceType.notSpecific,
            name: 'Figuratie',
            color: '#d6dce4'
        },
        {
            priceType: PriceType.specific,
            name: 'Lengtemarkering',
            color: '#ededed'
        }
    ] as statType[];
    allMaterials: Material[];
    materials: Material[];

    allCustomerAreas: CustomerArea[];
    customerAreas: CustomerArea[];
    stats: any;
    highlightCa: any;
    statuses: Status[];
    showPrice = true;
    showCount = true;
    showSurface = true;
    showRevenue = true;
    showEmptyCas = true;
    showMaterialSpecific = true;
    visibleCas = [];
    showInspectionsFor: CustomerArea;

    pushpinParts: PushpinPart[];
    extraCosts: ExtraCost[];

    materialPrices: MaterialPrice[];
    decorationPrices: DecorationPrice[];
    extraCostsPrices: ExtraCostPrice[];
    SpecificPrice = PriceType;

    constructor(private customerAreaService: CustomerAreaService,
                private pricesService: PricesService,
                private pushpinService: PushpinService,
                private extraCostsService: ExtraCostsService,
                private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService) {
        super();
    }

    mousen(ca: any) {
        this.highlightCa = ca;
    }

    updateFilter(evt) {

        this.showCount = evt.indexOf('count') !== -1;
        this.showSurface = evt.indexOf('surface') !== -1;
        this.showPrice = evt.indexOf('price') !== -1;
        this.showRevenue = evt.indexOf('revenue') !== -1;
        this.showEmptyCas = evt.indexOf('emptyCas') !== -1;
        this.showMaterialSpecific = evt.indexOf('materialSpecific') !== -1;
        this.visibleCas = [];
        this.filterCustomerAreas();
        this.filterMaterials(evt.filter(e => typeof e === 'number'));
    }

    ngOnInit(): void {
        this.filter = ['revenue', 'surface', 'price', 1, 2];
        this.filter = this.filter.concat(this.materials);
        this.updateFilter(this.filter);

        const statusMap$ = this.statusService.getKeyValMap();
        const decorationsMap$ = this.decorationsService.getKeyValMap();
        const materialsMap$ = this.materialService.getMaterialsAsKeyValueMap(true);
        const paintColorMap$ = this.paintColorService.getKeyValMap();
        const materials$ = this.materialService.getMaterials(true);
        const pushpinParts$ = this.pushpinService.allInspectionPushpins();
        const extraCosts$ = this.extraCostsService.getAllExtraCosts();
        const extraCostsMap$ = this.extraCostsService.getKeyValMap();
        const prices$ = this.pricesService.getPrices();
        const customerAreas$ = this.customerAreaService.getAllCustomerAreas();


        this.statusService.getStatuses().then(statuses => {
            this.statuses = statuses.filter(s => s.id !== StatusId.GeschouwdDoorKlant);
        });

        this.materialService.getMaterials().then(materials => {
            this.materials = materials;
            this.allMaterials = materials;
        });

        this.subscriptions.add(combineLatest(pushpinParts$, extraCosts$, prices$, statusMap$, decorationsMap$, materialsMap$, paintColorMap$, extraCostsMap$, materials$, customerAreas$)
            .subscribe(([allPushpins, extraCosts, allPriceData, status, decoration, material, paintColor, extraCostsMap, materials, customerAreas]) => {
                allPushpins.data.forEach(pushpinPart => {
                    pushpinPart.decoration = decoration[pushpinPart.decoration_id];
                    pushpinPart.material = decoration[pushpinPart.material_id];
                    pushpinPart.paint_color = decoration[pushpinPart.paint_color_id];
                    pushpinPart.status = decoration[pushpinPart.status_id];
                    pushpinPart.amount = +pushpinPart.amount;
                    pushpinPart.surface = +pushpinPart.surface;
                    pushpinPart.length = +pushpinPart.length;
                    pushpinPart.stake_out = !!pushpinPart.stake_out;
                    pushpinPart.exclusive_material = !!pushpinPart.exclusive_material;
                    pushpinPart.blast = !!pushpinPart.blast;
                    pushpinPart.night = !!pushpinPart.night;
                });
                this.pushpinParts = allPushpins.data;
                this.materialPrices = allPriceData.data.materialPrices;
                this.decorationPrices = allPriceData.data.decorationPrices;
                this.extraCostsPrices = allPriceData.data.extraCostPrices;
                this.allCustomerAreas = customerAreas.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                this.filterCustomerAreas();

                extraCosts.data.forEach(extraCost => {
                    extraCost.extra_cost_type = extraCostsMap[extraCost.extra_cost_type_id];
                    extraCost.number = +extraCost.number;
                });
                this.extraCosts = extraCosts.data;
            }));
    }

    private filterCustomerAreas() {
        if (this.showEmptyCas) {
            this.customerAreas = this.allCustomerAreas;
        } else {
            this.customerAreas = this.allCustomerAreas?.filter(c => !!this.pushpinParts.find(p => p.customer_area_id === c.id));
        }
    }

    private filterMaterials(materialIds: number[]) {
        if (!materialIds?.length) {
            this.materials = this.allMaterials;
        } else {
            this.materials = this.allMaterials?.filter(m => materialIds.includes(m.id));
        }
    }

    readonly StatusId = StatusId;
}
