import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {ExtraCostType} from '../../classes/extracosttype.class';
import {ExtraCost} from '../../classes/extracost.class';

@Injectable({
    providedIn: 'root'
})
export class ExtraCostsService {

    private _extraCostsList: ExtraCostType[] = [];
    private reload = false;

    constructor(private apiService: ApiService) {
    }

    getAllExtraCosts() {
        return this.apiService.getCall$<ExtraCost[]>('all-inspection-extra-costs');
    }

    public getList(): Promise<ExtraCostType[]> {
        return new Promise((resolve, reject) => {
            if (this._extraCostsList && this._extraCostsList.length > 0 && !this.reload) {
                resolve(this._extraCostsList);
            } else {
                this.apiService.getCall('extra-costs').then((statuses: ExtraCostType[]) => {
                    this._extraCostsList = statuses;
                    this.reload = false;
                    resolve(statuses);
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public setReload() {
        this.reload = true;
    }

    public getKeyValMap(): Promise<object> {
        return new Promise((resolve, reject) => {
            if (this._extraCostsList && this._extraCostsList.length > 0) {
                resolve(this.toKeyVal(this._extraCostsList));
            } else {
                this.apiService.getCall('extra-costs').then((statuses: ExtraCostType[]) => {
                    this._extraCostsList = statuses;
                    resolve(this.toKeyVal(statuses));
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public saveExtraCost(extraCost): Promise<ExtraCost> {
        return this.apiService.postCall('extra-costs', {
            extraCost: extraCost
        });
    }

    public deleteExtraCost(extraCostId): Promise<boolean> {
        return this.apiService.deleteCall(`extra-costs/${extraCostId}`);
    }

    public getSavedExtraCosts(inspection_id): Promise<ExtraCost[]> {
        return this.apiService.getCall(`extra-costs/saved/${inspection_id}`);
    }

    public saveType(extraCostType: ExtraCostType): Promise<ExtraCostType> {
        if (!extraCostType.id) {
            this.setReload();
        }
        return this.apiService.postCall('extra-cost-type', extraCostType);
    }

    private toKeyVal(arr: object[]) {
        const keyValMap = {};
        arr.forEach(item => {
            keyValMap[item['id']] = item;
        });
        return keyValMap;
    }
}
