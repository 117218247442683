<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Markeringen</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/management"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-xl-9">
            <div *ngFor="let decorationGroup of decorationGroups">
                <h2 class="h3 condensed">{{decorationGroup.group}}</h2>
                <app-decoration-group-overview
                    [list]="decorationGroup.decorations">
                </app-decoration-group-overview>
            </div>
        </div>
    </div>
</div>
<div class="controls-bottom">
    <div id="btn-Mapview" class="fab big primary"
         routerLink="/decorations/new">
        <i class="fas fa-plus"></i>
    </div>
</div>
