import {Component, OnInit} from '@angular/core';
import {DataExportService} from '../services/data-export.service';
import {MaterialService} from '../services/material/material.service';
import {combineLatest} from 'rxjs';
import * as XLSX from 'xlsx';
import {formatDate} from '@angular/common';
import {Settings} from '../settings.class';
import {CodaltComponent} from '../codalt.component';

@Component({
    selector: 'app-data-export',
    templateUrl: './data-export.component.html',
    styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent extends CodaltComponent implements OnInit {

    public exporting = false;
    private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION = '.xlsx';

    constructor(private dataExportService: DataExportService,
                private materialService: MaterialService) {
        super();
    }

    ngOnInit(): void {

    }

    export() {
        this.exporting = true;
        const materials$ = this.materialService.getMaterialsAsKeyValueMap(true);
        const dayWork$ = this.dataExportService.getDayCounts();
        this.subscriptions.add(combineLatest([materials$, dayWork$])
            .subscribe(([materialMap, counts]) => {
                const sheetArray = [];
                const materials = [
                    ...new Set([...counts.data.map(p => p.material_id)] || [])
                ];
                const dates = [
                    ...new Set([...counts.data.map(p => p.date)] || [])
                ];
                dates.forEach(date => {
                    const dayData = {
                        Datum: new Date(date),
                    };
                    materials.forEach(materialId => {
                        const material = materialMap[materialId];
                        dayData[material.name + ' Oppervlakte'] = +(counts.data.find(c => c.date === date && c.material_id === materialId && !c.specify_length)?.totalSurface ?? 0);
                        dayData[material.name + ' Lengte'] = +(counts.data.find(c => c.date === date && c.material_id === materialId && c.specify_length)?.length ?? 0);
                    });

                    sheetArray.push(dayData);
                });
                const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetArray);
                for (let i = 2; i <= sheetArray.length + 1; i++) {
                    worksheet[`A${i}`].z = 'd mmmm yy';
                }
                worksheet['!cols'] = [
                    {width: 20},
                ];

                const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
                const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const filename = `VVR_werk_per_dag_${formatDate(new Date(), 'yyyy-MMM-d_H-mm-ss', 'nl')}`;
                Settings.saveAsExcelFile(excelBuffer, filename.replace('.', ''));
                this.exporting = false;
            }, () => {
                this.exporting = false;
            }));
    }

}
