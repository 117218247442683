import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PushpinImage} from '../../classes/pushpin-image.class';
import {ApiService} from '../../services/api/api.service';
import {DrawOnImageComponent} from '../../draw-on-image/draw-on-image.component';
import {Settings} from '../../settings.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-draw-on-image-dialog',
    templateUrl: './draw-on-image-dialog.component.html',
    styleUrls: ['./draw-on-image-dialog.component.scss']
})
export class DrawOnImageDialogComponent implements OnInit {

    @ViewChild('draw') draw: DrawOnImageComponent;

    width: number;
    height: number;

    saving = false;
    showSave = true;

    editImage: string;

    constructor(public dialogRef: MatDialogRef<DrawOnImageDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public image: PushpinImage,
                private confirmDialog: ConfirmDialogService,
                private apiService: ApiService) {
    }

    ngOnInit(): void {
        const imageContainer = document.querySelector('.image-viewer-container');

        this.width = imageContainer.clientWidth;
        this.height = imageContainer.clientHeight;
        if (this.image.drawn_image) {
            this.confirmDialog.confirm('Bestaande markering',
                `Er zijn al markeringen aangebracht op deze foto. Wilt u die behouden?`,
                'Behouden',
                'Verwijderen').then(() => {
                this.editImage = this.image.drawn_image;
            }, () => {
                this.editImage = this.image.image;
            });
        } else {
            this.editImage = this.image.image;
        }
    }

    save() {
        this.draw.save().then(drawnImage => {
            console.log(drawnImage);
            this.apiService.postCall$(`images`, {base64: drawnImage.base64})
                .subscribe((imageUrl: object) => {
                    this.image.drawn_image = `${Settings.API_ENDPOINT}${imageUrl['imageUrl']}`;
                    this.dialogRef.close(this.image);
                }, error => {

                });
        }, () => {

        });
    }

}
