import {Component, OnInit} from '@angular/core';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Router} from '@angular/router';
import {CordovaService} from '../../cordova.service';
import {CustomerArea} from '../../classes/customerarea.class';
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {CodaltComponent} from '../../codalt.component';

@Component({
    selector: 'app-customer-area-overview',
    templateUrl: './customer-area-overview.component.html',
    styleUrls: ['./customer-area-overview.component.scss']
})
export class CustomerAreaOverviewComponent extends CodaltComponent implements OnInit {

    customerAreas: CustomerArea[];
    currentSort = null;
    desc = false;
    exporting = false;

    constructor(private customerAreaService: CustomerAreaService,
                private router: Router,
                private cordovaService: CordovaService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit() {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        this.customerAreaService.getList().then(customerAreas => {
            this.customerAreas = customerAreas;
            this.sortList('name');
        });
    }

    deleteCustomerArea(event, customerArea) {
        event.preventDefault();
        event.stopPropagation();
        this.confirmDialog.confirm('Verwijderen',
            `Weet u zeker dat u het klantgebied ${customerArea.name} wilt verwijderen?`, 'Ja').then(() => {
            this.customerAreaService.delete(customerArea.id).then(result => {
                const index = this.customerAreas.indexOf(customerArea);
                this.customerAreas.splice(index, 1);
            });
        });
    }

    public sortList(sortingOn) {
        let customerAreas = this.customerAreas;
        this.customerAreas = [];

        if (sortingOn === this.currentSort) {
            customerAreas = customerAreas.reverse();
            this.desc = !this.desc;
        } else {
            this.desc = false;
            customerAreas = customerAreas.sort((a, b) => {
                if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                    return -1;
                }
                if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        }

        this.customerAreas = customerAreas;

        this.currentSort = sortingOn;
    }


}
