<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Dagproductie exporteren</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div>
        <div class="pb-3">
            Exporteer de productie van alle klanten per dag per materiaal naar een Excel document.
        </div>
        <div *ngIf="!exporting">
            <button mat-raised-button (click)="export()">Exporteren</button>
        </div>
        <div *ngIf="exporting">
            Bezig met exporteren <i class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
</div>



