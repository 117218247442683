import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {MaterialPrice} from '../../classes/materialprice.class';
import {DecorationPrice} from '../../classes/decorationprice.class';
import {PushpinPart} from '../../classes/pushpinpart.class';
import {ExtraCostPrice} from '../../classes/extracostprice.class';
import {ExtraCost} from '../../classes/extracost.class';
import {PriceLog} from '../../classes/price-log';
import {TailCharge} from '../../classes/tail-charge';
import {Observable} from 'rxjs';
import {Material} from '../../classes/material.class';
import {ExtraCostCountType} from '../../classes/extracosttype.class';

@Injectable({
    providedIn: 'root'
})
export class PricesService {

    priceSelectionOpened = new EventEmitter<boolean>();
    selectedPushpinParts: PushpinPart[] = [];
    pushpinPartPricesMap = {};

    loadingPrices = false;
    private pricesLoaded = new EventEmitter<PricesData>();

    private _prices: PricesData;

    constructor(private apiService: ApiService) {
    }

    getPrices() {
        return this.apiService.getCall$<AllPriceData>('prices');
    }

    getTotalPrice(inspection_id): Promise<object> {
        return this.apiService.getCall(`prices/gettotal/${inspection_id}`);
    }

    getLog(priceId, type, inspectionId): Promise<PriceLog[]> {
        return this.apiService.getCall(`prices/log/${priceId}/${type}`, {inspectionId});
    }


    getItemsWithPrices(inspectionIds): Observable<PricesData> {

        return new Observable<PricesData>(observer => {
            let existingInspection = true;

            if (this._prices) {
                const existCustomerAreaIds = this._prices.materialPrices.map(mp => {
                    return mp.inspection_id;
                });
                inspectionIds.forEach(inspectionId => {
                    if (existCustomerAreaIds.indexOf(inspectionId) === -1) {
                        existingInspection = false;
                    }
                });
            }
            if (this._prices && existingInspection) {
                observer.next(this._prices);
            } else {
                if (!this.loadingPrices) {
                    this.loadingPrices = true;
                    this.apiService.getCall<PricesData>(
                        `prices/itemswithprice`,
                        {inspectionIds: inspectionIds}).then(prices => {
                        this._prices = prices;
                        this.loadingPrices = false;
                        this.pricesLoaded.emit(prices);
                        observer.next(this._prices);
                    }, err => {
                        this.loadingPrices = false;
                        observer.next(this._prices);
                    });
                } else {
                    this.pricesLoaded.subscribe(priceData => {
                        observer.next(priceData);
                    });
                }
            }
        });


    }

    save(materialPrices: MaterialPrice[],
         decorationPrices: DecorationPrice[],
         extraCostPrices: ExtraCostPrice[]): Promise<object> {
        this._prices = null;
        return this.apiService.postCall('prices', {
            materialPrices: materialPrices,
            decorationPrices: decorationPrices,
            extraCostPrices: extraCostPrices
        });
    }

    getSpecificPricePushpinPartDecoration(pushpinPart, decorationPrices: DecorationPrice[]) {
        return decorationPrices.find(_decoration => {
            return _decoration.decoration_id === pushpinPart.decoration_id
                && _decoration.material_id === pushpinPart.material_id &&
                _decoration.paint_color_id === pushpinPart.paint_color_id;
        });
    }

    getPushpinPartMaterial(pushpinPart, materialPrices: MaterialPrice[]) {
        return materialPrices.find(_material => {
            return _material.material_id === pushpinPart.material_id && _material.paint_color_id === pushpinPart.paint_color_id;
        });
    }

    getMaterialPriceCode(materialId, materialPrices: MaterialPrice[]) {
        return materialPrices.find(materialPrice => {
            return materialPrice.material_id === materialId;
        })?.code;
    }

    calculateM2materialPrice(surfaceInM2, totalPrice: number, materialId, materialPrices: MaterialPrice[], materialSurfaces?: Map<number, number>) {
        const material = materialPrices.find(materialPrice => {
            return materialPrice.material_id === materialId;
        });
        if (materialSurfaces) {
            if (!materialSurfaces.has(material.id)) {
                materialSurfaces.set(material.id, 0);
            }
            materialSurfaces.set(material.id, materialSurfaces.get(material.id) + surfaceInM2);
        }
        if (material) {
            totalPrice += surfaceInM2 * material.price;
        }
        return totalPrice;
    }

    calculatePriceSpecificPriceDecoration(decoration, pushpinPart, totalPrice: number, specificPriceDecorationLength: Map<number, number> = null) {
        if (pushpinPart.decoration.specify_length) {
            if (specificPriceDecorationLength) {
                specificPriceDecorationLength.set(decoration.id, specificPriceDecorationLength.get(decoration.id) + parseFloat(pushpinPart.length + ''));
            }
            totalPrice += pushpinPart.length * decoration.price;
        } else if (!pushpinPart.decoration?.surface) {
            if (specificPriceDecorationLength) {
                specificPriceDecorationLength.set(decoration.id, specificPriceDecorationLength.get(decoration.id) + parseFloat(pushpinPart.surface + ''));
            }
            totalPrice += pushpinPart.surface * decoration.price;
        } else {
            if (specificPriceDecorationLength) {
                specificPriceDecorationLength.set(decoration.id, specificPriceDecorationLength.get(decoration.id) + parseFloat(pushpinPart.amount + ''));
            }
            totalPrice += pushpinPart.amount * decoration.price;
        }
        return totalPrice;
    }

    extraCostSurfaceCalculation(extraCosts: ExtraCost, selectedPushpinParts?: PushpinPart[]) {
        if (!selectedPushpinParts) {
            selectedPushpinParts = this.selectedPushpinParts;
        }
        return selectedPushpinParts
            .filter(ppp => {
                let returnThis = true;
                if (extraCosts.extra_cost_type.count_based_on === ExtraCostCountType.pushpin_lengthmark) {
                    returnThis = ppp.decoration?.specific_price;
                } else if (extraCosts.extra_cost_type.count_based_on === ExtraCostCountType.pushpin_figuration) {
                    returnThis = !ppp.decoration?.specific_price;
                }
                if (extraCosts.extra_cost_type.night) {
                    returnThis = ppp.night;
                }
                return returnThis;
            })
            .map(ppp => this.calculateSurface(ppp))
            .reduce((p, c) => p + c);
    }

    calculateTotalPriceExtraCosts(extraCosts: ExtraCost[], extraCostsPricesMap: object, selectedPushpinParts?: PushpinPart[]) {
        let total = 0;
        if (extraCosts && typeof extraCosts !== 'undefined') {
            extraCosts.forEach(extracost => {
                if (typeof extraCostsPricesMap[extracost.extra_cost_type_id] !== 'undefined') {
                    total += this.calculatePriceExtraCost(extracost, extraCostsPricesMap[extracost.extra_cost_type_id], selectedPushpinParts);
                }
            });
        }
        return total;
    }

    calculatePriceExtraCost(extracost: ExtraCost, price: number, selectedPushpinParts?: PushpinPart[]): number {
        if (price) {
            if (extracost.extra_cost_type?.count_based_on === ExtraCostCountType.count) {
                return extracost.number * price;
            } else {
                extracost.number = this.extraCostSurfaceCalculation(extracost, selectedPushpinParts);
                return extracost.number * price;
            }
        }
        return 0;
    }

    calculateTotalTailCharges(tailCharges: TailCharge[], totalPrice: number) {
        if (tailCharges?.length > 0) {
            return tailCharges.map(tc => tc.number * totalPrice).reduce((p, c) => p + c);
        }
        return 0;
    }

    calculateSurface(pushpinPart) {
        let surfaceInM2 = 0;
        if (pushpinPart.decoration) {
            if (pushpinPart.decoration.specify_length) {
                surfaceInM2 = (pushpinPart.decoration.surface * pushpinPart.length);
            } else {
                if (pushpinPart.decoration.surface) {
                    surfaceInM2 = (parseFloat(pushpinPart.decoration.surface + '') * pushpinPart.amount);
                } else {
                    if (pushpinPart.surface) {
                        surfaceInM2 = parseFloat(pushpinPart.surface + '');
                    } else {
                        surfaceInM2 = 0;
                    }
                }
            }
        }
        return surfaceInM2;
    }

    groupPrices(
        materials: Material[],
        materialPrices: MaterialPrice[],
        materialSurfaces: Map<number, number>,
        specificPriceDecoration: DecorationPrice[]
    ) {
        const groups = [] as MaterialPricesGroup[];
        materials?.sort((a, b) => a.sort_order - b.sort_order).forEach(material => {
            const group = groups.find(g => g.group === (material.kind ?? material.name)) ?? new MaterialPricesGroup();
            group.group = material.kind ?? material.name;
            group.materialIds = [...(group?.materialIds ?? []), material.id];
            group.materialPrices = [...(group.materialPrices ?? []), ...materialPrices.filter(mp => mp.material_id === material.id && materialSurfaces.get(mp.id) > 0)];
            group.specificPriceDecoration = [...(group.specificPriceDecoration ?? []), ...specificPriceDecoration.filter(spd => spd.material_id === material.id)];
            if (!groups.includes(group)) {
                groups.push(group);
            }
            group.specificPriceDecoration.sort((a, b) => a.decoration?.order - b.decoration?.order);
            group.specificPriceDecoration.sort((a, b) => a.decoration?.group_order - b.decoration?.group_order);
        });

        return groups.filter(g => g.materialPrices?.length || g.specificPriceDecoration?.length);
    }

    calculatePushpinPartPriceDetail(pushpinPart: PushpinPart,
                                    materialPrices: MaterialPrice[],
                                    decorationPrices: DecorationPrice[],
                                    materialSurfaces?: Map<number, number>,
                                    specificPriceDecorationLength?: Map<number, number>,
                                    specificPriceDecoration?: DecorationPrice[]): {
        total: number,
        material: number,
        primer: number,
        blast: number,
        stake_out: number,
        materialCode: string,
        primerCode: string,
        blastCode: string,
        stake_outCode: string
    } {
        const price = {
            total: 0,
            material: 0,
            primer: 0,
            blast: 0,
            stake_out: 0,
            materialCode: '',
            primerCode: '',
            blastCode: '',
            stake_outCode: ''
        };
        if (!pushpinPart.decoration) {
            return price;
        }

        if (!pushpinPart.decoration.specific_price) {
            const surfaceInM2 = this.calculateSurface(pushpinPart);
            const material = this.getPushpinPartMaterial(pushpinPart, materialPrices);
            if (material && typeof material.price !== 'undefined' && !pushpinPart.exclusive_material) {
                if (materialSurfaces) {
                    if (!materialSurfaces.has(material.id)) {
                        materialSurfaces.set(material.id, 0);
                    }
                    materialSurfaces.set(material.id, materialSurfaces.get(material.id) + surfaceInM2);
                }
                price.material = surfaceInM2 * material.price;
                price.materialCode = material.code;
            }

            if (pushpinPart.primer) {
                price.primer = this.calculateM2materialPrice(surfaceInM2, 0, pushpinPart.primer, materialPrices, materialSurfaces);
                price.primerCode = this.getMaterialPriceCode(pushpinPart.primer, materialPrices);
            }
            if (pushpinPart.blast) {
                price.blast = this.calculateM2materialPrice(surfaceInM2, 0, 7, materialPrices, materialSurfaces);
                price.blastCode = this.getMaterialPriceCode(7, materialPrices);
            }
            if (pushpinPart.stake_out) {
                price.stake_out = this.calculateM2materialPrice(surfaceInM2, 0, 9, materialPrices, materialSurfaces);
                price.stake_outCode = this.getMaterialPriceCode(9, materialPrices);
            }
        } else {
            const decoration = this.getSpecificPricePushpinPartDecoration(pushpinPart, decorationPrices);
            if (decoration) {
                if (specificPriceDecorationLength && !specificPriceDecorationLength.has(decoration.id)) {
                    specificPriceDecorationLength.set(decoration.id, 0);
                }
                if (!pushpinPart.exclusive_material) {
                    price.material = this.calculatePriceSpecificPriceDecoration(decoration, pushpinPart, 0, specificPriceDecorationLength);
                    price.materialCode = decoration.code;
                }
                const surfaceInM2 = this.calculateSurface(pushpinPart);
                if (pushpinPart.primer) {
                    price.primer = this.calculateM2materialPrice(surfaceInM2, 0, pushpinPart.primer, materialPrices, materialSurfaces);
                    price.primerCode = this.getMaterialPriceCode(pushpinPart.primer, materialPrices);
                }
                if (pushpinPart.blast) {
                    price.blast = this.calculateM2materialPrice(surfaceInM2, 0, 7, materialPrices, materialSurfaces);
                    price.blastCode = this.getMaterialPriceCode(7, materialPrices);
                }
                if (pushpinPart.stake_out) {
                    const stake_out = materialPrices.filter(_material => {
                        return _material.material_id === (pushpinPart.decoration.specify_length ? 8 : 9);
                    })[0];
                    if (materialSurfaces) {
                        if (!materialSurfaces.has(stake_out.id)) {
                            materialSurfaces.set(stake_out.id, 0);
                        }
                        materialSurfaces.set(stake_out.id, materialSurfaces.get(stake_out.id) + (+(decoration.decoration.specify_length ? pushpinPart.length : pushpinPart.amount)));
                    }
                    price.stake_out += (pushpinPart.decoration.specify_length ? pushpinPart.length : pushpinPart.amount) * (stake_out?.price ?? 0);
                    price.stake_outCode = stake_out?.code;
                }
                if (specificPriceDecoration) {
                    const uniqueDecoration = specificPriceDecoration.filter(_decoration => {
                        return _decoration.decoration_id === pushpinPart.decoration_id
                            && _decoration.material_id === pushpinPart.material_id
                            && _decoration.paint_color_id === pushpinPart.paint_color_id;
                    });
                    if (uniqueDecoration.length < 1) {
                        specificPriceDecoration.push(decoration);
                    }
                }
            }
        }
        price.total = price.blast + price.primer + price.stake_out + price.material;

        return price;
    }

    calculatePushpinPartPrice(pushpinPart: PushpinPart,
                              materialPrices: MaterialPrice[],
                              decorationPrices: DecorationPrice[],
                              materialSurfaces?: Map<number, number>,
                              specificPriceDecorationLength?: Map<number, number>,
                              specificPriceDecoration?: DecorationPrice[]): number {
        const price = this.calculatePushpinPartPriceDetail(pushpinPart, materialPrices, decorationPrices, materialSurfaces, specificPriceDecorationLength, specificPriceDecoration);
        this.pushpinPartPricesMap[pushpinPart.id] = price.total;
        return price.total;
    }

}

export class PricesData {
    activePushpinPrices: PushpinPart[];
    activeExtraCostPrices: ExtraCost[];
    materialPrices: MaterialPrice[];
    decorationPrices: DecorationPrice[];
    extraCostsPrices: ExtraCostPrice[];
}

export class MaterialPricesGroup {
    materialIds: number[];
    group: string;
    materialPrices: MaterialPrice[];
    specificPriceDecoration: DecorationPrice[];
}

export class AllPriceData {
    materialPrices: MaterialPrice[];
    decorationPrices: DecorationPrice[];
    extraCostPrices: ExtraCostPrice[];
}

export class PushpinPartPrice {
    total = 0;
    material = 0;
    primer = 0;
    blast = 0;
    stake_out = 0;
    materialCode = '';
    primerCode = '';
    blastCode = '';
    stake_outCode = '';
}

export enum PriceType {
    all, specific, notSpecific
}
