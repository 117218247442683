import {FormControl} from '@angular/forms';

export function requiredConditional(dependantOn: () => boolean) {
    return (control: FormControl) => {
        if ((!control.value || (Array.isArray(control.value) && control.value.length < 1)) && !!dependantOn.call(this)) {
            return {required: true};
        }
        return null;
    };
}
