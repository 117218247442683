<div class="dialog-container">
    <div class="dialog-close">
        <div class="fab small active" id="btn-cancel" mat-dialog-close="">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <h1 class="condensed dialog-title">Schouwronde {{inspection.id ? 'opslaan' : 'toevoegen'}}</h1>
    <div class="dialog-content">
        <div class="w-100">
            <mat-form-field class="w-100" id="name">
                <input [formControl]="fc.name"
                       matInput
                       placeholder="Naam"
                       type="text">
                <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" id="reference">
                <input [formControl]="fc.reference"
                       matInput
                       placeholder="Klant referentie"
                       type="text">
            </mat-form-field>
            <mat-form-field class="w-100" *ngIf="!inspection.id">
                <mat-select [formControl]="fc.inspection_type_id" placeholder="Type schouwronde">
                    <mat-option *ngFor="let type of types" [value]="type.id">
                        {{type.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="d-flex flex-wrap">
        <button mat-button mat-dialog-close="">
            Annuleren
        </button>
        <div class="spacer"></div>
        <button mat-icon-button (click)="delete()" color="primary" class="mr-3" *ngIf="inspection.id">
            <i class="fas fa-trash"></i>
        </button>
        <button (click)="save()" color="primary" mat-raised-button>
            {{inspection.id ? 'Opslaan' : 'Toevoegen'}}
        </button>
    </div>
</div>
