import {Pushpin} from './pushpin.class';
import {Decoration} from './decoration.class';
import {PaintColor} from './paintcolor.class';
import {Status} from './status.class';
import {User} from './user.class';
import {Material} from './material.class';
import {PushpinImage} from './pushpin-image.class';
import {Inspection} from './inspection';

export class PushpinPart {
    id: number;
    inspection_id: number;
    inspection: Inspection;
    customer_area_id: number;
    pushpin_id: number;
    status_id: number;
    user_id: number;
    crow: string;
    description: any;
    customer_note: any;
    primer: number;
    blast: boolean;
    stake_out: boolean;
    traffic_controllers: boolean;
    night: boolean;
    manual_burn: boolean;
    turbo_burn: boolean;
    exclusive_material: boolean;
    overgrown: boolean;
    material_id: number;
    decoration_id: number;
    paint_color_id: number;
    amount: number;
    length: number;
    surface: any;
    final_amount: number;
    final_length: number;
    final_surface: any;
    executedDate: Date;
    executedBy: string;
    created_by: string;
    inQuotation: string;
    created_at: Date;
    updated_at: Date;
    pushpin: Pushpin;
    decoration: Decoration;
    paint_color: PaintColor;
    status: Status;
    user: User;
    material: Material;
    images: PushpinImage[];
    deleted_at: Date;
}
