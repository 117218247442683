<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Declaratie rapport</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/{{Routenames.declarationRequest}}">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div>
        <div class="pb-3">
            Gebruik de VVR app voor het invoeren van je declaraties.
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <mat-form-field class="status-filter">
                <mat-label>Periode</mat-label>
                <input matInput [matDatepicker]="dp" [(ngModel)]="date">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp
                                startView="year"
                                (monthSelected)="chosenMonthHandler($event, dp)"
                                panelClass="example-month-picker">
                </mat-datepicker>
            </mat-form-field>
            <div>
                <button color="primary" mat-raised-button routerLink="/{{Routenames.declarationTypes}}">
                    Types beheren
                </button>
            </div>
        </div>
        <div class="pt-3">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('name')" matRipple>
                        Medewerker
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('email')" matRipple>
                        E-mailadres
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="email"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('count')" matRipple class="text-right" matTooltip="Aantal declaraties">
                        Aantal
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="count"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('status')" matRipple>
                        Status
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="status"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('amount')" matRipple class="text-right">
                        Bedrag
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="amount"></app-sorting-icon>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of list">
                    <td>
                        {{item.name}}
                    </td>
                    <td>
                        {{item.email}}
                    </td>
                    <td class="text-right" matTooltip="Aantal declaraties">
                        {{item.count | number}} st
                    </td>
                    <td class="status-col">
                        <div>
                            <span>
                                {{item.status}}
                            </span>
                            <button mat-icon-button
                                    (click)="statusPayed(item)"
                                    matTooltip="Zet op vergoed" *ngIf="item.status_id == statusGoedgekeurd">
                                <i class="fas fa-money-check-edit"></i>
                            </button>
                        </div>
                    </td>
                    <td class="text-right">
                        <span matTooltip="Kopieer bedrag"
                              class="curpoint"
                              matTooltipPosition="before"
                              [cdkCopyToClipboard]="copyClip(item.amount)">
                            {{item.amount | currency : 'EUR'}}
                        </span>
                    </td>
                </tr>
                </tbody>
                <thead>
                <tr>
                    <th colspan="4" class="text-right">

                    </th>
                    <th class="text-right">
                        Totaal: {{total | currency  : 'EUR'}}
                    </th>
                </tr>
                </thead>
            </table>
        </div>

    </div>
</div>
