import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Pushpin} from '../../classes/pushpin.class';
import {Library} from '../../classes/library.class';

@Injectable({
    providedIn: 'root'
})
export class LibraryService {

    constructor(private apiService: ApiService) {
    }

    public download(chapterId): Promise<any> {
        return this.apiService.getCall(`library/download/${chapterId}`);
    }

    public getLibrary(): Promise<Library[]> {
        return this.apiService.getCall(`library`);
    }

    public save(chapter: Library): Promise<Library> {
        return this.apiService.postCall('library/addChapter', chapter);
    }

    public rename(chapter: Library): Promise<Library> {
        return this.apiService.postCall('library/renameChapter', chapter);
    }

    public changeOrder(chapters: Library[]): Promise<boolean> {
        return this.apiService.postCall('library/changeOrder', chapters);
    }

    public delete(chapterId: number): Promise<Object> {
        return this.apiService.deleteCall(`library/${chapterId}`);
    }
}
