import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CodaltComponent} from '../../codalt.component';
import {PpeService} from '../../services/ppe.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {PpeEquipment} from '../../ppe-equipment';
import {Utils} from '../../utils.class';
import {Routenames} from '../../route-names.enum';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-ppe-equipment-edit',
    templateUrl: './ppe-equipment-edit.component.html',
    styleUrls: ['./ppe-equipment-edit.component.scss']
})
export class PpeEquipmentEditComponent extends CodaltComponent implements OnInit {

    fc: {
        name: FormControl
    };

    form: FormGroup;

    equipment: PpeEquipment;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private ppeService: PpeService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.ppeService.getEquipment(params['id']).subscribe(equipment => {
                this.equipment = equipment.data || new PpeEquipment();
                this.fc = {
                    name: new FormControl(this.equipment.name, Validators.required)
                };
                this.form = new FormGroup(this.fc);
            });
        }));
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            console.log(this.equipment);
            this.equipment.name = this.fc.name.value;
            this.ppeService.saveEquipment(this.equipment).subscribe(() => {
                this.router.navigate([Routenames.ppeEquipment]);
            }, error => {
                this.confirmDialog.confirm(
                    'Fout bij het opslaan',
                    error.message,
                    'Sluiten'
                ).then(() => {

                }, () => {

                });
            });
        }
    }
}
