<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Inspectielijsten beheren</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/management"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center mb-3">
        <div class="col-12 col-md-9">
            <div class="pb-3">
                Beheer de inspectielijsten voor de VVR app.
            </div>
            <button color="primary" mat-raised-button routerLink="/{{Routenames.checklistEdit}}/new">
                Nieuwe inspectielijst
            </button>
            <div class="pt-3">
                <table class="table table-striped">
                    <tr>
                        <th (click)="sortList('number')" matRipple class="curpoint">
                            Nr
                            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="number"></app-sorting-icon>
                        </th>
                        <th (click)="sortList('name')" matRipple class="curpoint">
                            Checklist
                            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="name"></app-sorting-icon>
                        </th>
                        <th (click)="sortList('managed_by')" matRipple class="curpoint">
                            Beheert door
                            <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="managed_by"></app-sorting-icon>
                        </th>
                        <th>
                            Versie
                        </th>
                        <th class="trash-column">

                        </th>
                    </tr>
                    <tr *ngFor="let checklist of list" (click)="edit(checklist)" class="curpoint">
                        <td>
                            {{checklist.number || '-'}}
                        </td>
                        <td>
                            {{checklist.name}}
                        </td>
                        <td>
                            {{checklist.managed_by}}
                        </td>
                        <td class="text-right">
                            {{checklist.version}}
                        </td>
                        <td>
                            <div class="d-flex">
                                <button (click)="copy($event, checklist)"
                                        matTooltip="Kopieër inspectielijst"
                                        mat-icon-button>
                                    <i class="fa fa-copy"></i>
                                </button>
                                <button (click)="delete($event, checklist)"
                                        *ngIf="AuthorisationService.hasFeature('checklistDelete')"
                                        color="warn"
                                        mat-icon-button>
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
