<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Meldingen</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div>
        <div class="pb-3">
            Gebruik de VVR app voor alles wat je wilt melden.
            Ongevallen (incident met letsel en of schade), Bijna Ongevallen (incidenten die hadden kunnen leiden tot letsel of schade),
            Verbeterideeën of Klachten. Alle meldingen worden door de KAM-coördinator en Directie in behandeling genomen en je ontvangt hiervan z.s.m. een terugkoppeling.
        </div>
        <div class="d-flex align-items-center">
            <button color="primary" mat-raised-button routerLink="/incident/new">
                Nieuwe melding
            </button>
            <mat-slide-toggle (change)="getData()"
                              [(ngModel)]="showClosed"
                              class="ml-3"
                              color="primary">
                Toon afgehandelde meldingen
            </mat-slide-toggle>
            <div class="spacer"></div>
            <button mat-raised-button (click)="export()" *ngIf="mayExport && !isCordova">
                Exporteren
            </button>
        </div>
        <div class="pt-3">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('id')" matRipple>
                        Melding
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="id"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('place')">
                        Plaats
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="place"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('type')">
                        Type
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="type"></app-sorting-icon>
                    </th>
                    <th (click)="sortList('status')">
                        Status
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="status"></app-sorting-icon>
                    </th>
                    <th>
                        Aangemaakt door
                    </th>
                    <th (click)="sortList('created_at')">
                        Aangemaakt
                        <app-sorting-icon [currentSort]="currentSort" [desc]="desc" property="created_at"></app-sorting-icon>
                    </th>
                    <th class="trash-column">

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of list" class="curpoint" routerLink="/incident/{{item.id}}">
                    <td>
                        {{item.id}}
                    </td>
                    <td>
                        {{item.place}}
                    </td>
                    <td>
                        {{item.type}}
                    </td>
                    <td>
                        {{item.status}}
                    </td>
                    <td>
                        {{item?.user?.name}}
                    </td>
                    <td>
                        {{item.created_at | date : 'd MMM yyyy, H:mm'}}
                    </td>
                    <td class="pt-0 pb-0">
                        <button (click)="delete($event, item)"
                                *ngIf="item.user_id === user.id && item.status === 'ingediend'"
                                color="warn"
                                mat-icon-button>
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
