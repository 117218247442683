export class ChecklistAnswer {
    id: number;
    question_id: number;
    checklist_completed_id: number;
    answer: AnswerOption;
    comment: string;
    updated_at: Date;
}

export enum AnswerOption {
    Ja = 'Ja', Nee = 'Nee', nvt = 'N.v.t'
}
