import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Article} from '../../classes/article.class';
import {ArticleService} from '../../services/article.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {CodaltComponent} from '../../codalt.component';
import {ImageViewerDialogComponent} from '../../image-viewer-dialog/image-viewer-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ImageViewerData} from '../../image-viewer-dialog/image-viewer-data';
import {CordovaService} from '../../cordova.service';
import {ArticleImage} from '../../classes/article-image.class';

@Component({
    selector: 'app-article-detail',
    templateUrl: './article-detail.component.html',
    styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent extends CodaltComponent implements OnInit, OnDestroy {

    loading = false;
    article: Article;

    constructor(private articleService: ArticleService,
                private location: Location,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private matDialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private cordovaService: CordovaService,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe((params) => {
            this.getArticle(params['id']);
        }));
    }

    delete() {
        this.confirmDialog.confirm('Verwijderen',
            `Weet je zeker dat je dit artikel wilt verwijderen?`, 'Ja', 'Nee').then(() => {
            this.articleService.delete(this.article.id).subscribe(() => {
                this.router.navigateByUrl(Routenames.articles);
            });
        }, () => {
        });
    }

    openImage(image: ArticleImage) {
        this.matDialog.open(ImageViewerDialogComponent, {
            panelClass: 'image-viewer-dialog',
            data: {
                images: this.article.images,
                secure: 'article',
                viewIndex: this.article.images.indexOf(image)
            } as ImageViewerData
        });

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getArticle(slug) {
        this.articleService.get(slug).subscribe((article) => {
            this.article = article.data;
            if (new Date(this.article.publish_date).getTime() > new Date().getTime()) {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl(`${Routenames.articles}/toekomst`);
                });
            }
            this.location.replaceState(`${Routenames.articles}/${article.data.slug}`);
        }, error => {
            if (!this.isCordova) {
                this.confirmDialogService.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                    this.router.navigateByUrl(Routenames.articles);
                });
            } else {
                this.router.navigateByUrl(Routenames.articles);
            }
        });
    }
}
