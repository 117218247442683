<div class="dialog-container">
    <div class="dialog-close">
        <div class="fab small active" id="btn-cancel" mat-dialog-close="">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <h1 class="condensed dialog-title">Kies een schouwronde</h1>
    <div class="dialog-content">
        <mat-list class="w-100">
            <mat-list-item *ngFor="let inspection of inspections" matRipple (click)="openInspection(inspection)">
                {{inspection.name}}
            </mat-list-item>
        </mat-list>
    </div>
    <div class="d-flex flex-wrap pt-2">
        <button mat-button (click)="close()">
            Terug
        </button>
    </div>
</div>
