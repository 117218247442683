import {Injectable} from '@angular/core';
import {ApiResponse, ApiService} from '../api/api.service';
import {Material} from '../../classes/material.class';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MaterialService {

    private _materialList: Material[] = [];
    private getListCall: Observable<ApiResponse<Material[]>>;

    constructor(private apiService: ApiService) {
    }

    public getMaterials(includeUnusable = false): Promise<Material[]> {
        return new Promise((resolve, reject) => {
            if (this._materialList && this._materialList.length > 0) {
                if (!includeUnusable) {
                    resolve(this._materialList.filter(p => p.usable));
                } else {
                    resolve(this._materialList);
                }
                resolve(this._materialList);
            } else {
                if (!this.getListCall) {
                    this.getListCall = this.apiService.getCall$<Material[]>('materials/all').pipe(share());
                }
                this.getListCall.subscribe((materials: ApiResponse<Material[]>) => {
                    this._materialList = materials.data;
                    this.getListCall = undefined;
                    if (!includeUnusable) {
                        resolve(this._materialList.filter(p => p.usable));
                    } else {
                        resolve(this._materialList);
                    }
                }, err => {
                    reject(err);
                });
            }
        });
    }

    public getMaterialsAsKeyValueMap(includeUnusable = false): Promise<object> {
        return new Promise((resolve, reject) => {
            this.getMaterials(includeUnusable).then((materials) => {
                resolve(this.toKeyVal(materials));
            }, () => reject());
        });
    }

    private toKeyVal(arr: object[]) {
        const keyValMap = {};
        arr.forEach(item => {
            keyValMap[item['id']] = item;
        });
        return keyValMap;
    }
}
