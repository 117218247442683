import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {TailCharge} from '../classes/tail-charge';

@Injectable({
    providedIn: 'root'
})
export class TailChargeService {

    constructor(private apiService: ApiService) {
    }

    get(inspection_id: number) {
        return this.apiService.getCall$<TailCharge[]>(`tailcharge/${inspection_id}`);
    }

    delete(tailChargeId) {
        return this.apiService.deleteCall$(`tailcharge/${tailChargeId}`);
    }

    save(tailCharge: TailCharge) {
        return this.apiService.postCall$<TailCharge>('tailcharge', tailCharge);
    }
}
