import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../../codalt.component';
import {ChecklistService} from '../../../services/checklist.service';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {Routenames} from '../../../route-names.enum';
import {Checklist} from '../../../classes/checklist';

@Component({
    selector: 'app-checklist-choose-dialog',
    templateUrl: './checklist-choose-dialog.component.html',
    styleUrls: ['./checklist-choose-dialog.component.scss']
})
export class ChecklistChooseDialogComponent extends CodaltComponent implements OnInit {

    checklists: Checklist[];

    constructor(private checklistService: ChecklistService,
                private router: Router,
                public dialogRef: MatDialogRef<ChecklistChooseDialogComponent>) {
        super();
    }

    ngOnInit(): void {
        this.checklistService.list().subscribe(list => {
            this.checklists = list.data;
            if (this.checklists.length === 1) {
                this.choose(this.checklists[0]);
            }
        });
    }

    choose(checklist) {
        this.router.navigate([Routenames.checklistFillIn, checklist.id]);
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }

}
