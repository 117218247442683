import {Pipe, PipeTransform} from '@angular/core';
import {PriceType} from '../services/prices/prices.service';
import {PushpinPart} from '../classes/pushpinpart.class';
import {formatNumber} from '@angular/common';
import {TotalCountPushpinPartsPipe} from './total-count-pushpin-parts.pipe';

@Pipe({
    name: 'percentCount'
})
export class PercentCountPipe implements PipeTransform {

    transform(pushpinParts: PushpinPart[],
              statusId: number,
              statusIdCompare: number,
              customerAreaId: number,
              inspectionId?: number,
              materialId?: number,
              specific_price = PriceType.all
    ): string {
        const statusPrice = (new TotalCountPushpinPartsPipe())
            .transform(
                pushpinParts,
                statusId,
                customerAreaId,
                inspectionId,
                materialId,
                specific_price);
        const statusPriceCompare = (new TotalCountPushpinPartsPipe())
            .transform(
                pushpinParts,
                statusIdCompare,
                customerAreaId,
                inspectionId,
                materialId,
                specific_price);
        if (!statusPriceCompare) {
            return '-';
        }
        return `${formatNumber((statusPriceCompare / (statusPriceCompare + statusPrice)) * 100, 'nl', '1.0-0')}%`;
    }

}
