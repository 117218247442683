import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {FileItem} from '../file-manager/file-selection-dialog/FileItem';

@Injectable()
export class FileService {

    constructor(private apiService: ApiService) {
    }

    read(dir?: string) {
        return this.apiService.getCall$<FileItem[]>(`file/read`, {dir});
    }

    createFolder(name: string, path?: string) {
        return this.apiService.postCall$(`file/create-folder`, {name, path});
    }

    delete(path) {
        return this.apiService.deleteCall$('file', {path});
    }

    rotateImage(path?: string, root?: boolean, secure?: string) {
        if (path.substr(0, 7) === '/media/') {
            path = path.substr(6);
        }
        if (root) {
            return this.apiService.getCall$<FileItem>(`file/rotate-image`, {path, root, secure});
        } else {
            return this.apiService.getCall$<FileItem>(`file/rotate-image`, {path, secure});
        }
    }
}
