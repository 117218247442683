<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="true"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Autorisatie</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar gebruikers"
                 matTooltipPosition="below"
                 routerLink="/users">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <table class="table table-hover">
        <thead>
        <tr>
            <th class="feature">
                <b>Feature</b>
            </th>
            <th *ngFor="let role of roles">
                <div class="role-name">
                    {{role.name}}
                </div>
            </th>
            <th class="feature">
                <b>Key</b>
            </th>
        </tr>
        </thead>
        <tr *ngFor="let featureHasRole of featureHasRoleMatrix">
            <th class="feature">
                {{featureHasRole.feature.name}}
            </th>
            <td (contextmenu)="setUserFieldForFeature($event, featureHasRole, role)" *ngFor="let role of roles"
                title="{{role.name}}">
                <mat-checkbox
                    (change)="checkFeature($event, featureHasRole.feature, role)"
                    [checked]="featureHasRole.hasRoles.has(role.id)"
                    [disabled]="disabled">
                    <span *ngIf="featureHasRole.hasRoles.has(role.id)">
                        {{featureHasRole.hasRoles.get(role.id).user_field}}
                    </span>
                </mat-checkbox>

            </td>
            <th class="feature">
                {{featureHasRole.feature.key}}
            </th>
        </tr>
    </table>
</div>
