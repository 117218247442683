<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Declaratie indienen</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/{{Routenames.declarationRequest}}"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9" *ngIf="form">
            <form [formGroup]="form">
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>Status</mat-label>
                        <mat-select [formControl]="form.controls.status_id"
                                    placeholder="Status">
                            <mat-option *ngFor="let status of statusList" [value]="status.id">
                                {{status.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>Manager</mat-label>
                        <mat-select [formControl]="form.controls.manager_id"
                                    placeholder="Manager">
                            <mat-option *ngFor="let manager of managerList" [value]="manager.id">
                                {{manager.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-4" (click)="picker.open()">
                        <mat-label>Declaratie datum</mat-label>
                        <input placeholder="Datum"
                               [matDatepicker]="picker"
                               [max]="maxDate"
                               [formControl]="form.controls.date" matInput>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-4">
                        <mat-label>Declaratie</mat-label>
                        <mat-select [formControl]="form.controls.type_id"
                                    placeholder="PBM">
                            <mat-option *ngFor="let equipment of declarationTypes" [value]="equipment.id">
                                {{equipment.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-4">
                        <mat-label>Bedrag</mat-label>
                        <span matPrefix>€&nbsp;</span>
                        <input [formControl]="form.controls.amount"
                               matInput
                               appDecimalInput
                               [decimalInput]="2"
                               placeholder="Bedrag"
                               type="text">
                        <mat-error *ngIf="form.controls.amount.errors && form.controls.amount.errors.required">
                            Dit veld is verplicht
                        </mat-error>
                        <mat-error *ngIf="form.controls.amount.errors && form.controls.amount.errors.max ">
                            Het maximum is € 5000,00
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-12">
                        <mat-label>Opmerkingen</mat-label>
                        <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize
                                  [formControl]="form.controls.comment"
                                  matInput
                                  placeholder="Voeg hier eventuele notities en opmerkingen toe"></textarea>
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                </div>
                <div class="row mb-2" *ngIf="form.controls.status_id.value === 3">
                    <mat-form-field appearance="fill" class="col-md-12">
                        <mat-label>Reden van afkeuren</mat-label>
                        <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize
                                  [formControl]="form.controls.reason"
                                  matInput
                                  placeholder="Voeg hier de reden van het afkeuren toe"></textarea>
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div class="distances" *ngIf="declarationType?.distance">
                <table class="table table-striped">
                    <thead>
                    <tr>

                        <th>
                            Datum
                        </th>
                        <th>
                            van
                        </th>
                        <th>
                            naar
                        </th>
                        <th>
                            afstand
                        </th>
                        <th class="trash-column"
                            *ngIf="request.status_id < 2 || AuthorisationService.hasFeature('declarationManagement')">

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="!form.controls.declaration_distances.controls?.length">
                        <td colspan="5" class="text-center">
                            Voeg minimaal 1 rit toe
                        </td>
                    </tr>
                    <tr *ngFor="let distance of form.controls.declaration_distances.controls" [formGroup]="distance">
                        <td>
                            <input placeholder="Datum"
                                   (click)="picker.open()"
                                   [max]="maxDate"
                                   [matDatepicker]="picker"
                                   [formControl]="distance.controls.date"
                                   matInput>
                            <mat-datepicker #picker></mat-datepicker>
                        </td>
                        <td>
                            <input matInput [formControl]="distance.controls.from" placeholder="van locatie">
                        </td>
                        <td>
                            <input matInput [formControl]="distance.controls.to" placeholder="naar locatie">
                        </td>
                        <td>
                            <input matInput [formControl]="distance.controls.distance" appDecimalInput [decimalInput]="2" placeholder="KM's">
                        </td>
                        <td *ngIf="request.status_id < 2 || AuthorisationService.hasFeature('declarationManagement')">
                            <button mat-icon-button color="warn" (click)="removeDistance(distance)">
                                <mat-icon class="fas fa-trash"></mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <button mat-button color="primary" class="w-100" (click)="addDistance()">
                                Rit toevoegen
                            </button>
                        </td>
                    </tr>
                    </tbody>

                </table>
            </div>
            <div class="files">
                <div *ngFor="let file of request.images" class="file">
                    <div (click)="openFile(file, request.images)"
                         [class.is-img]="['jpg', 'jpeg', 'png'].indexOf(file.ext) !== -1"
                         [style.background-image]="(file.file.substr(0,4) !== 'http' ? url : '') + file.file+'?access_token='+token|safeBgUrl">
                        <div class="icon"><i class="fas fa-file fa-3x"></i></div>
                        <div class="filename">
                            {{file.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <mat-list class="row" *ngIf="request?.status_log?.length">
                <div mat-subheader class="pb-0">Statuswijzigingen</div>
                <mat-list-item *ngFor="let log of request.status_log">
                    <div mat-line>
                        {{log.status.name}}
                    </div>
                    <div mat-line>
                        {{log.created_at | date:'dd MMMM yyyy HH:mm'}}<br>
                        {{log.user.name}}
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div class="controls-bottom">
        <label *ngIf="request?.status_id === 1"
               #upload
               matTooltip="Upload bestand"
               matTooltipPosition="before">
            <input
                (change)="uploadFile($event)" hidden multiple type="file">
            <div class="fab big">
                <i *ngIf="!uploading" class="fas fa-upload"></i>
                <i *ngIf="uploading" class="fas fa-circle-notch fa-spin"></i>
            </div>
        </label>
        <div (click)="takePicture()"
             *ngIf="isCordova && request?.status_id === 1" class="fab big"
             id="btn-AddImage"
             matTooltip="Camera"
             matTooltipPosition="before">
            <i class="fas fa-camera"></i>
        </div>
        <div (click)="delete()"
             *ngIf="request?.status_id === 1 && request?.id" class="fab big"
             matTooltip="Aanvraag intrekken"
             matTooltipPosition="before">
            <i class="fas fa-trash"></i>
        </div>
        <div (click)="save()" [class.disabled]="saving" class="fab big primary" id="btn-Save"
             matTooltip="Opslaan"
             matTooltipPosition="before">
            <i *ngIf="!saving" class="fas fa-save"></i>
            <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
</div>
