import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '../auth/login/login.component';
import {HomeComponent} from '../home/home.component';
import {RequestRestorePasswordComponent} from '../auth/request-restore-password/request-restore-password.component';
import {RestorePasswordComponent} from '../auth/restore-password/restore-password.component';
import {UsersOverviewComponent} from '../users/users-overview/users-overview.component';
import {UserEditComponent} from '../users/user-edit/user-edit.component';
import {PushpinOverviewComponent} from '../pushpin-overview/pushpin-overview.component';
import {ResetComponent} from '../reset/reset.component';
import {SettingsComponent} from '../settings/settings.component';
import {CanDeactivatePushpinDetail} from './can-deactivate-pushpin-detail';
import {DecorationsOverviewComponent} from '../decorations-overview/decorations-overview.component';
import {DecorationDetailComponent} from '../decorations-overview/decoration-detail/decoration-detail.component';
import {PricesComponent} from '../prices/prices.component';
import {ExtraCostsOverviewComponent} from '../extra-costs-overview/extra-costs-overview.component';
import {ExtraCostDetailComponent} from '../extra-costs-overview/extra-cost-detail/extra-cost-detail.component';
import {DocumentComponent} from '../document/document.component';
import {ImportComponent} from '../import/import.component';
import {LibraryComponent} from '../library/library.component';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {ProfileComponent} from '../profile/profile.component';
import {CustomerAreaOverviewComponent} from '../customer-areas/customer-area-overview/customer-area-overview.component';
import {CustomerAreaEditComponent} from '../customer-areas/customer-area-edit/customer-area-edit.component';
import {CustomerSelectionComponent} from '../customer-selection/customer-selection.component';
import {ManagementComponent} from '../home/management/management.component';
import {LMRAComponent} from '../lmra/lmra.component';
import {IncidentRegistrationComponent} from '../incident-registration/incident-registration.component';
import {EditIncidentComponent} from '../incident-registration/edit-incident/edit-incident.component';
import {CanDeactivateEdit} from './can-deactivate-edit.service';
import {CanDeactivateIncidentComment} from './can-deactivate-incident-comment';
import {AcceptWorkComponent} from '../accept-work/accept-work.component';
import {LogoutComponent} from '../auth/logout/logout.component';
import {AuthRolesComponent} from '../auth/auth-roles/auth-roles.component';
import {RepairsComponent} from '../repairs/repairs.component';
import {EditRepairComponent} from '../repairs/edit-repair/edit-repair.component';
import {Routenames} from '../route-names.enum';
import {ArticlesComponent} from '../articles/articles.component';
import {ArticleDetailComponent} from '../articles/article-detail/article-detail.component';
import {ArticleEditComponent} from '../articles/article-edit/article-edit.component';
import {CanDeactivateForm} from '../can-deactivate-form';
import {TwoFactorComponent} from '../auth/two-factor/two-factor.component';
import {TwoFactorSetupComponent} from '../auth/two-factor-setup/two-factor-setup.component';
import {PpeManagementComponent} from '../ppe-management/ppe-management.component';
import {PpeEquipmentEditComponent} from '../ppe-management/ppe-equipment-edit/ppe-equipment-edit.component';
import {PpeRequestComponent} from '../ppe-request/ppe-request.component';
import {PpeRequestDetailComponent} from '../ppe-request/ppe-request-detail/ppe-request-detail.component';
import {ChecklistsComponent} from '../checklists/checklists.component';
import {ChecklistEditComponent} from '../checklists/checklist-edit/checklist-edit.component';
import {ChecklistFillInComponent} from '../checklists/checklist-fill-in/checklist-fill-in.component';
import {ChecklistOverviewComponent} from '../checklists/checklist-overview/checklist-overview.component';
import {ChecklistPDFComponent} from '../checklists/checklist-pdf/checklist-pdf.component';
import {DeclarationManagementComponent} from '../declaration-management/declaration-management.component';
import {DeclarationTypeEditComponent} from '../declaration-management/declaration-type-edit/declaration-type-edit.component';
import {DeclarationRequestComponent} from '../declaration-request/declaration-request.component';
import {DeclarationRequestDetailComponent} from '../declaration-request/declaration-request-detail/declaration-request-detail.component';
import {DeclarationReportComponent} from '../declaration-management/declaration-report/declaration-report.component';
import {MoldManagementComponent} from '../mold-management/mold-management.component';
import {MoldMaterialEditComponent} from '../mold-management/mold-material-edit/mold-material-edit.component';
import {MoldRequestDetailComponent} from '../mold-request/mold-request-detail/mold-request-detail.component';
import {MoldRequestComponent} from '../mold-request/mold-request.component';
import {SmallMaterialRequestComponent} from '../small-material-request/small-material-request.component';
import {SmallMaterialRequestDetailComponent} from '../small-material-request/small-material-request-detail/small-material-request-detail.component';
import {SmallMaterialEditComponent} from '../small-material-management/small-material-edit/small-material-edit.component';
import {SmallMaterialManagementComponent} from '../small-material-management/small-material-management.component';
import {DataExportComponent} from '../data-export/data-export.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {path: 'lmra', component: LMRAComponent},
    {path: 'repairs', component: RepairsComponent},
    {path: 'repairs/:id', component: EditRepairComponent, canDeactivate: [CanDeactivateEdit, CanDeactivateIncidentComment]},
    {path: 'incident', component: IncidentRegistrationComponent},
    {path: 'incident/:id', component: EditIncidentComponent, canDeactivate: [CanDeactivateEdit, CanDeactivateIncidentComment]},
    {path: 'management', component: ManagementComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'twofactor', component: TwoFactorComponent},
    {path: 'twofactor/setup', component: TwoFactorSetupComponent},
    {path: 'twofactor/setup/:token', component: TwoFactorSetupComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'reset', component: ResetComponent},
    {path: 'settings', component: SettingsComponent},
    {path: 'request-restore-password', component: RequestRestorePasswordComponent},
    {path: 'forgot-password', component: RequestRestorePasswordComponent},
    {path: 'restore-password/:token', component: RestorePasswordComponent},
    {path: 'customer-areas', component: CustomerAreaOverviewComponent},
    {path: 'customer-areas/:customerAreaId', component: CustomerAreaEditComponent},
    {path: 'users', component: UsersOverviewComponent},
    {path: 'users/:userId', component: UserEditComponent},
    {path: 'customer-selection', component: CustomerSelectionComponent},
    {
        path: 'pushpins/:view/:id/:zoom/:lat/:lng',
        component: PushpinOverviewComponent,
        canDeactivate: [CanDeactivatePushpinDetail]
    },
    {path: 'decorations', component: DecorationsOverviewComponent},
    {path: 'decorations/:id', component: DecorationDetailComponent},
    {path: 'extra-costs', component: ExtraCostsOverviewComponent},
    {path: 'extra-costs/:id', component: ExtraCostDetailComponent},
    {path: 'prices', component: PricesComponent},
    {path: 'document/:inspection', component: DocumentComponent},
    {path: 'document/:inspection/:token', component: DocumentComponent},
    {path: 'import', component: ImportComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'accept-work/:inspection/:token', component: AcceptWorkComponent},
    {path: 'accept-work/:inspection', component: AcceptWorkComponent},
    {
        path: Routenames.articles, component: ArticlesComponent, children: [
            {path: 'toekomst', component: ArticlesComponent},
            {path: ':id', component: ArticleDetailComponent},
            {path: ':id/bewerken', component: ArticleEditComponent, canDeactivate: [CanDeactivateForm]},
            {path: ':id/kopie', component: ArticleEditComponent, canDeactivate: [CanDeactivateForm]}
        ]
    },
    {path: Routenames.ppeEquipment, component: PpeManagementComponent},
    {path: Routenames.ppeEquipment + '/:id', component: PpeEquipmentEditComponent},
    {path: Routenames.ppeRequest, component: PpeRequestComponent},
    {path: Routenames.ppeRequest + '/:id', component: PpeRequestDetailComponent},

    {path: Routenames.moldMaterial, component: MoldManagementComponent},
    {path: Routenames.moldMaterial + '/:id', component: MoldMaterialEditComponent},
    {path: Routenames.moldRequest, component: MoldRequestComponent},
    {path: Routenames.moldRequest + '/:id', component: MoldRequestDetailComponent},


    {path: Routenames.smallMaterialRequest, component: SmallMaterialRequestComponent},
    {path: Routenames.smallMaterialRequest + '/:id', component: SmallMaterialRequestDetailComponent},
    {path: Routenames.smallMaterial, component: SmallMaterialManagementComponent},
    {path: Routenames.smallMaterial + '/:id', component: SmallMaterialEditComponent},

    {path: Routenames.declarationTypes, component: DeclarationManagementComponent},
    {path: Routenames.declarationTypes + '/:id', component: DeclarationTypeEditComponent},
    {path: Routenames.declarationRequest, component: DeclarationRequestComponent},
    {path: Routenames.declarationRequest + '/:id', component: DeclarationRequestDetailComponent},
    {path: Routenames.declarationReport, component: DeclarationReportComponent},

    {path: Routenames.checklists, component: ChecklistsComponent},
    {path: Routenames.checklistEdit + '/:id', component: ChecklistEditComponent},

    {path: Routenames.checklistFillIn, component: ChecklistOverviewComponent},
    {path: Routenames.checklistFillIn + '/:checklistId', component: ChecklistFillInComponent},

    {path: Routenames.checklistPDF + '/:completedId', component: ChecklistPDFComponent},

    {path: Routenames.dataExport, component: DataExportComponent},


    {path: 'library', component: LibraryComponent},
    {path: 'roles', component: AuthRolesComponent},

    {path: '**', component: HomeComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})
    ],
    exports: [
        RouterModule
    ],
    declarations: [],
    providers: [CanDeactivatePushpinDetail, CanDeactivateEdit, CanDeactivateIncidentComment]
})
export class AppRoutingModule {
}
