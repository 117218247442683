<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Nieuws</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar nieuws overzicht"
                 matTooltipPosition="below"
                 routerLink="/{{Routenames.articles}}">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
</div>
<section *ngIf="article" class="detail">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9">
                <app-codalt-image [path]="article.id + '/' + article.image"
                                  [thumb]="article.image_thumb"
                                  class="detail__image">
                </app-codalt-image>
                <div class="detail__block pb-0">
                    <h1 class="detail__title">
                        {{article.title}}
                    </h1>
                    <div class="detail__details">
                        <div class="detail__author">
                            {{article.user.name}}
                        </div>
                        <time class="detail__date">
                            {{article.publish_date | date:'EEEE d MMMM yyyy HH:mm'}}
                        </time>
                    </div>
                </div>
                <div class="detail__block info pt-0">
                    <div class="content">
                        <p [innerHTML]="article.content"></p>
                    </div>

                    <div class="item-images">
                        <app-codalt-image (click)="openImage(image)"
                                          *ngFor="let image of article.images"
                                          [path]="article.id + '/' + image.path"
                                          [thumb]="image.thumb">
                        </app-codalt-image>
                    </div>
                </div>
            </div>
            <div *ngIf="article.readBy" class="col-12 col-lg-3">
                <div class="detail__block readby">
                    <h3 class="mb-2">Gelezen ({{article.readBy.length || '0'}})</h3>
                    <div *ngFor="let user of article.readBy">
                        {{user.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="true" class="floating-buttons">
        <button (click)="delete()" color="primary" mat-fab *ngIf="AuthorisationService.hasFeature('deleteArticle')">
            <mat-icon class="fas fa-trash"></mat-icon>
        </button>
        <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/kopie">
            <mat-icon class="fas fa-copy"></mat-icon>
        </button>
        <button color="primary" mat-fab routerLink="/{{Routenames.articles}}/{{article.slug}}/bewerken">
            <mat-icon class="fas fa-pencil-alt"></mat-icon>
        </button>
    </div>
</section>
