import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IncidentComment} from '../../classes/incidentcomment.class';
import {IncidentService} from '../../services/incident.service';
import {LocalStorage} from '../../storage.class';
import {User} from '../../classes/user.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Settings} from "../../settings.class";

@Component({
    selector: 'app-incident-comment',
    templateUrl: './incident-comment.component.html',
    styleUrls: ['./incident-comment.component.scss']
})
export class IncidentCommentComponent implements OnInit {

    @Input() incidentComment: IncidentComment;

    @Output() uploadFiles = new EventEmitter<File[]>();

    @Output() deleted = new EventEmitter<IncidentComment>();

    @Output() placedComment = new EventEmitter<IncidentComment>();

    @Output() openFile = new EventEmitter<object>();

    url = Settings.API_ENDPOINT + 'file/';
    token = LocalStorage.getUserToken();

    editing = false;

    mayEdit = false;

    saving = false;

    private user: User;

    constructor(private incidentService: IncidentService,
                private confirmDialog: ConfirmDialogService) {
    }

    ngOnInit(): void {
        this.user = LocalStorage.getUser();
        this.editing = !this.incidentComment.id;
        this.mayEdit = this.user.id === this.incidentComment.user_id || !this.incidentComment.user_id;
    }

    placeOrEditComment() {
        this.saving = true;
        this.incidentService.saveComment(this.incidentComment).then(comment => {
            if (!this.incidentComment.id) {
                this.incidentComment.id = comment.id;
                this.incidentComment.created_at = comment.created_at;
                this.placedComment.emit(this.incidentComment);
            }
            this.editing = false;
            this.saving = false;
        }, () => {
            this.saving = false;
        });
    }

    edit() {
        this.editing = true;
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles.emit(files);
    }

    deleteComment() {
        this.confirmDialog.confirm(
            'Verwijderen',
            `Weet u zeker dat u deze reactie van ${this.incidentComment?.user?.name} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            if (this.incidentComment.id) {
                this.incidentService.deleteComment(this.incidentComment.id).then(success => {
                    if (success) {
                        this.deleted.emit(this.incidentComment);
                    }
                });
            } else {
                this.deleted.emit(this.incidentComment);
            }
        }, () => {
        });
    }

}
