<div class="codalt-container">
    <div class="corner">
        <mat-form-field appearance="fill">
            <mat-label>Filter dashboard</mat-label>
            <mat-select (ngModelChange)="updateFilter($event)" [(ngModel)]="filter" multiple placeholder="Filter dashboard">
                <mat-optgroup label="Gegevens">
                    <mat-option value="revenue">Omzet per status</mat-option>
                    <mat-option value="count">Aantallen</mat-option>
                    <mat-option value="surface">Oppervlakte / lengte</mat-option>
                    <mat-option value="price">Omzet</mat-option>
                    <mat-option value="emptyCas">Klantgebieden zonder werk</mat-option>
                    <mat-option value="materialSpecific">Per materiaal</mat-option>
                </mat-optgroup>
                <mat-optgroup label="Materialen">
                    <mat-option *ngFor="let material of allMaterials" [disabled]="!showMaterialSpecific" [value]="material.id">
                        {{material.name}}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <a class="back" routerLink="/management">
            <i class="fa fa-arrow-circle-left"></i> Terug
        </a>
    </div>
    <div class="areas sticky-left">
        <div class="space"></div>
        <div *ngFor="let ca of customerAreas"
             [class.show-inspections]="showInspectionsFor === ca"
             class="customer-area curpoint">
            <div class="area" (mouseenter)="mousen(ca.name)" (click)="showInspectionsFor = ca"

                 [class.highlight]="highlightCa === ca.name">
                {{ca.name}}
            </div>
            <ng-container *ngIf="showInspectionsFor === ca">
                <div class="area inspection pl-3"
                     [class.highlight]="highlightCa === inspection.id"
                     [class.last]="last" *ngFor="let inspection of ca.inspections; let last = last" (mouseenter)="mousen(inspection.id)">
                    {{inspection.name}}
                </div>
            </ng-container>
        </div>
        <div (mouseenter)="mousen('total')"
             [class.highlight]="highlightCa === 'total'">
            Totaal
        </div>
    </div>
    <div class="cols col-right" *ngIf="materialPrices && decorationPrices && extraCostsPrices && pushpinParts">
        <div *ngIf="showRevenue" class="type statuses">
            <div class="sticky-top">
                <div class="name">Status</div>
                <div class="cols">
                    <div *ngFor="let status of statuses"
                         class="status">
                        <div [class.txtwhite]="[StatusId.Afgekeurd,StatusId.Concept].indexOf(status.id) !== -1"
                             [style.background-color]="'#' + status.color"
                             class="text">{{status.name}}</div>
                    </div>
                </div>
            </div>
            <div *ngFor="let ca of customerAreas"
                 class="customer-area"
                 [class.show-inspections]="showInspectionsFor === ca">
                <div (mouseenter)="mousen(ca.name)"
                     [class.highlight]="highlightCa === ca.name"
                     class="material">
                    <div class="cols nrs">
                        <div *ngFor="let status of statuses" class="status-{{status.id}}">&nbsp;
                            {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : status.id : ca.id)?.total  | number :'1.0-0'}}
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showInspectionsFor === ca">
                    <div *ngFor="let inspection of ca.inspections" (mouseenter)="mousen(inspection.name)"
                         [class.highlight]="highlightCa === inspection.id"
                         class="material">
                        <div class="cols nrs">
                            <div *ngFor="let status of statuses" class="status-{{status.id}}">&nbsp;
                                {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : status.id : ca.id : inspection.id)?.total  | number :'1.0-0'}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div (mouseenter)="mousen('total')"
                 [class.highlight]="highlightCa === 'total'"
                 class="material">
                <div class="cols nrs">
                    <div *ngFor="let status of statuses"
                         class="status-{{status.id}}">

                        {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : status.id)?.total  | number :'1.0-0'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="type">
            <div class="sticky-top">
                <div class="name">&nbsp;</div>
                <div class="cols">
                    <div class="material">
                        <div class="name">
                            <div class="innername">
                                &nbsp;
                            </div>
                        </div>
                        <div class="cols">
                            <div class="partname whiteborder">&nbsp;</div>
                        </div>
                        <div class="cols header">

                            <div class="status">
                                <div class="text">Extra kosten</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let ca of customerAreas">
                <div

                     [class.show-inspections]="showInspectionsFor === ca"
                     class="customer-area">
                    <div class="material" (mouseenter)="mousen(ca.name)" [class.highlight]="highlightCa === ca.name">
                        <div class="cols">
                            <div class="part">
                                <div class="cols nrs extra">
                                    <div>
                                        &nbsp;{{(extraCosts | totalPriceExtraCosts : pushpinParts : extraCostsPrices : ca.id) | number :'1.0-0'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="showInspectionsFor === ca">
                        <div class="material"
                             [class.highlight]="highlightCa === inspection.id"
                             *ngFor="let inspection of ca.inspections"
                             (mouseenter)="mousen(inspection.id)">
                            <div class="cols">
                                <div class="part">
                                    <div class="cols nrs extra">
                                        <div>
                                            &nbsp;{{(extraCosts | totalPriceExtraCosts : pushpinParts : extraCostsPrices : ca.id : inspection.id) | number :'1.0-0'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </ng-container>
            <div (mouseenter)="mousen('total')"
                 [class.highlight]="highlightCa === 'total'"
                 class="cols">
                <div class="material">
                    <div class="cols">
                        <div class="part">
                            <div class="cols nrs">
                                <div>
                                    {{(extraCosts | totalPriceExtraCosts : pushpinParts : extraCostsPrices) | number :'1.0-0'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="showMaterialSpecific">
            <div *ngFor="let type of types" class="type">
                <div class="sticky-top">
                    <div [style.background-color]="type.color" class="name">{{type.name}}</div>
                    <div class="cols">
                        <ng-container *ngFor="let material of materials">
                            <div class="material">
                                <div [style.background-color]="material.color" class="name">
                                    <div class="innername">
                                        {{material.name}}
                                    </div>
                                </div>
                                <div class="cols">
                                    <div *ngIf="showCount" class="partname">Aantal punaises</div>
                                    <div *ngIf="showSurface" class="partname">
                                        {{type.name === 'Figuratie' ? 'Oppervlakte' : 'Lengte'}}
                                    </div>
                                    <div *ngIf="showPrice" class="partname revenue">Omzet</div>
                                </div>
                                <div class="cols header">
                                    <ng-container *ngIf="showCount">
                                        <div class="status freefor">
                                            <div class="text">Vrij voor uitvoering</div>
                                        </div>
                                        <div class="status executed">
                                            <div class="text">Uitgevoerd</div>
                                        </div>
                                        <div class="status">
                                            <div class="text">Percentage</div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="showSurface">
                                        <div class="status freefor">
                                            <div class="text">Vrij voor uitvoering</div>
                                        </div>
                                        <div class="status executed">
                                            <div class="text">Uitgevoerd</div>
                                        </div>
                                        <div class="status">
                                            <div class="text">Percentage</div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="showPrice">
                                        <div class="status freefor revenue">
                                            <div class="text">Vrij voor uitvoering</div>
                                        </div>
                                        <div class="status executed revenue">
                                            <div class="text">Uitgevoerd</div>
                                        </div>
                                        <div class="status revenue">
                                            <div class="text">Percentage</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="customer-area per-material"
                     [class.show-inspections]="showInspectionsFor === ca"
                     *ngFor="let ca of customerAreas">
                    <div (mouseenter)="mousen(ca.name)"
                         [class.highlight]="highlightCa === ca.name"
                         class="cols">
                        <ng-container *ngFor="let material of materials">
                            <div class="material">
                                <div class="cols">
                                    <div class="part">
                                        <div class="cols nrs">
                                            <ng-container *ngIf="showCount">
                                                <div class="freefor">
                                                    {{(pushpinParts | totalCountPushpinParts : StatusId.VrijVoorUitvoering : ca.id : null : material.id : type.priceType) | number :'1.0-0'}}
                                                </div>
                                                <div class="executed">
                                                    {{(pushpinParts | totalCountPushpinParts : StatusId.Uitgevoerd : ca.id : null : material.id : type.priceType) | number :'1.0-0'}}
                                                </div>
                                                <div class="other">
                                                    {{(pushpinParts | percentCount : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : ca.id : null : material.id : type.priceType)}}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="showSurface">
                                                <div class="freefor">
                                                    {{(pushpinParts | totalSurfacePushpinParts : StatusId.VrijVoorUitvoering : ca.id : null : material.id : type.priceType) | number :'1.0-0'}}
                                                </div>
                                                <div class="executed">
                                                    {{(pushpinParts | totalSurfacePushpinParts : StatusId.Uitgevoerd : ca.id : null : material.id : type.priceType) | number :'1.0-0'}}
                                                </div>
                                                <div class="other">
                                                    {{(pushpinParts | percentSurface : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : ca.id : null : material.id : type.priceType)}}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="showPrice">
                                                <div class="freefor revenue">
                                                    {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : StatusId.VrijVoorUitvoering : ca.id : null : material.id : type.priceType)?.total  | number :'1.0-0'}}
                                                </div>
                                                <div class="executed revenue">
                                                    {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : StatusId.Uitgevoerd : ca.id : null : material.id : type.priceType)?.total  | number :'1.0-0'}}
                                                </div>
                                                <div class="other revenue">
                                                    {{(pushpinParts | percentPrice : materialPrices : decorationPrices : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : ca.id : null : material.id : type.priceType)}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="showInspectionsFor === ca">
                        <div *ngFor="let inspection of ca.inspections"
                             (mouseenter)="mousen(inspection.id)"
                             [class.highlight]="highlightCa === inspection.id"
                             class="cols">
                            <ng-container *ngFor="let material of materials">
                                <div class="material">
                                    <div class="cols">
                                        <div class="part">
                                            <div class="cols nrs">
                                                <ng-container *ngIf="showCount">
                                                    <div class="freefor">
                                                        {{(pushpinParts | totalCountPushpinParts : StatusId.VrijVoorUitvoering : ca.id : inspection.id : material.id : type.priceType) | number :'1.0-0'}}
                                                    </div>
                                                    <div class="executed">
                                                        {{(pushpinParts | totalCountPushpinParts : StatusId.Uitgevoerd : ca.id : inspection.id : material.id : type.priceType) | number :'1.0-0'}}
                                                    </div>
                                                    <div class="other">
                                                        {{(pushpinParts | percentCount : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : ca.id : inspection.id : material.id : type.priceType)}}
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="showSurface">
                                                    <div class="freefor">
                                                        {{(pushpinParts | totalSurfacePushpinParts : StatusId.VrijVoorUitvoering : ca.id : inspection.id : material.id : type.priceType) | number :'1.0-0'}}
                                                    </div>
                                                    <div class="executed">
                                                        {{(pushpinParts | totalSurfacePushpinParts : StatusId.Uitgevoerd : ca.id : inspection.id : material.id : type.priceType) | number :'1.0-0'}}
                                                    </div>
                                                    <div class="other">
                                                        {{(pushpinParts | percentSurface : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : ca.id : inspection.id : material.id : type.priceType)}}
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="showPrice">
                                                    <div class="freefor revenue">
                                                        {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : StatusId.VrijVoorUitvoering : ca.id : inspection.id : material.id : type.priceType)?.total  | number :'1.0-0'}}
                                                    </div>
                                                    <div class="executed revenue">
                                                        {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : StatusId.Uitgevoerd : ca.id : inspection.id : material.id : type.priceType)?.total  | number :'1.0-0'}}
                                                    </div>
                                                    <div class="other revenue">
                                                        {{(pushpinParts | percentPrice : materialPrices : decorationPrices : StatusId.VrijVoorUitvoering : StatusId.Uitgevoerd : ca.id : inspection.id : material.id : type.priceType)}}
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                </div>
                <div (mouseenter)="mousen('total')"
                     [class.highlight]="highlightCa === 'total'"
                     class="cols">
                    <ng-container *ngFor="let material of materials">
                        <div *ngIf="material" class="material">
                            <div class="cols">
                                <div class="part">
                                    <div class="cols nrs">
                                        <ng-container *ngIf="showCount">
                                            <div class="freefor">
                                                {{(pushpinParts | totalCountPushpinParts : StatusId.VrijVoorUitvoering : null : null : material.id : type.priceType) | number :'1.0-0'}}
                                            </div>
                                            <div class="executed">
                                                {{(pushpinParts | totalCountPushpinParts : StatusId.Uitgevoerd : null : null : material.id : type.priceType) | number :'1.0-0'}}
                                            </div>
                                            <div class="other">
                                                {{(pushpinParts | percentCount : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : null : null : material.id : type.priceType)}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="showSurface">
                                            <div class="freefor">
                                                {{(pushpinParts | totalSurfacePushpinParts : StatusId.VrijVoorUitvoering : null : null : material.id : type.priceType) | number :'1.0-0'}}
                                            </div>
                                            <div class="executed">
                                                {{(pushpinParts | totalSurfacePushpinParts : StatusId.Uitgevoerd : null : null : material.id : type.priceType) | number :'1.0-0'}}
                                            </div>
                                            <div class="other">
                                                {{(pushpinParts | percentSurface : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : null : null : material.id : type.priceType)}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="showPrice">
                                            <div class="freefor revenue">
                                                {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : StatusId.VrijVoorUitvoering : null : null : material.id : type.priceType)?.total  | number :'1.0-0'}}
                                            </div>
                                            <div class="executed revenue">
                                                {{(pushpinParts | totalPricePushpinParts : materialPrices : decorationPrices : StatusId.Uitgevoerd : null : null : material.id : type.priceType)?.total  | number :'1.0-0'}}
                                            </div>
                                            <div class="other revenue">
                                                {{(pushpinParts | percentPrice : materialPrices : decorationPrices : StatusId.VrijVoorUitvoering :StatusId.Uitgevoerd : null : null : material.id : type.priceType)}}
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
