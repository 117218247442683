import {User} from './user.class';
import {Pushpin} from './pushpin.class';
import {Inspection} from './inspection';

export class CustomerArea {
    id: number;
    name: string;
    area: Array<any>;
    kind: string;
    street: string;
    number: string;
    zip: string;
    city: string;
    phone: string;
    users: User[];
    pushpin: Pushpin[];
    inspections: Inspection[];
    calamities: { date: Date, count: number, old: boolean };
    dashboard: boolean;
    notifications: boolean;
    decoration_types: string[];
}
