import {Component, OnInit} from '@angular/core';
import {Incident} from '../classes/incident.class';
import {IncidentService} from '../services/incident.service';
import {SortingComponent} from '../sorting.component';
import {LocalStorage} from '../storage.class';
import {User} from '../classes/user.class';
import {ConfirmDialogService} from "../services/confirm-dialog-service/confirm-dialog.service";
import {formatDate} from "@angular/common";
import {Router} from "@angular/router";
import {CordovaService} from "../cordova.service";
import * as XLSX from 'xlsx';
import {Settings} from '../settings.class';

@Component({
    selector: 'app-incident-registration',
    templateUrl: './incident-registration.component.html',
    styleUrls: ['./incident-registration.component.scss']
})
export class IncidentRegistrationComponent extends SortingComponent<Incident> implements OnInit {

    private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION = '.xlsx';

    showClosed: boolean;
    user: User;

    mayExport = false;

    constructor(private incidentService: IncidentService,
                private router: Router,
                private cordovaService: CordovaService,
                private confirmDialog: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        this.mayExport = this.AuthorisationService.hasFeature('incidentManagement');
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        this.user = LocalStorage.getUser();
        this.getData();
    }

    public getData() {
        this.incidentService.getList(this.showClosed).then(incidents => {
            this.list = incidents;
            this.currentSort = 'created_at';
            this.desc = true;
        });
    }

    delete(event, incident: Incident) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Verwijderen',
            `Weet u zeker dat u dit incident aangemaakt op ${formatDate(incident.created_at, 'd MMM yyyy, H:mm', 'nl')} wilt verwijderen?`,
            'Verwijderen',
            'Behouden'
        ).then(() => {
            this.incidentService.delete(incident.id).then(() => {
                const index = this.list.indexOf(incident);
                this.list.splice(index, 1);
            });
        }, () => {

        });
    }

    export() {
        const sheetArray = [];
        this.list.forEach(incident => {
            sheetArray.push({
                Melding: incident.id,
                Type: incident.type,
                Status: incident.status,
                'Aangemaakt door': incident.user?.name,
                Plaats: incident.place,
                Straat: incident.street,
                Huisnummer: incident.housenumber,
                Postcode: incident.zipcode,
                Aangemaakt: new Date(incident.created_at),
                Beschrijving: incident.description
            });
        });
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetArray);
        for (let i = 2; i <= sheetArray.length + 1; i++) {
            worksheet[`I${i}`].z = 'd mmmm yy h:mm';
        }
        worksheet['!cols'] = [
            {width: 8},
            {width: 14},
            {width: 15},
            {width: 30},
            {width: 25},
            {width: 25},
            {width: 12},
            {width: 12},
            {width: 20},
            {width: 40},
        ];
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const filename = `VVR_reparaties_${formatDate(new Date(), 'yyyy-MMM-d_H-mm-ss', 'nl')}`;
        Settings.saveAsExcelFile(excelBuffer, filename.replace('.', ''));
    }
}
