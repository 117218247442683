import {Component, HostListener, Inject, ViewChild} from '@angular/core';
import {SwiperConfigInterface, SwiperDirective} from 'ngx-swiper-wrapper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageViewerData} from './image-viewer-data';
import {CodaltComponent} from '../codalt.component';

@Component({
    selector: 'app-image-viewer-dialog',
    templateUrl: './image-viewer-dialog.component.html',
    styleUrls: ['./image-viewer-dialog.component.scss']
})
export class ImageViewerDialogComponent extends CodaltComponent {

    @ViewChild(SwiperDirective, {static: true}) directiveRef?: SwiperDirective;
    secure = '';
    public config: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 'auto',
        navigation: true,
        preloadImages: true
    };

    constructor(
        public dialogRef: MatDialogRef<ImageViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public images: ImageViewerData) {
        super();
        if (images.secure) {
            this.secure = `secure=${images.secure}&`;
        }
    }

    @HostListener('click', ['$event'])
    public click(event) {
        if (event.target.localName !== 'img' && event.srcElement.className.indexOf('swiper-button') === -1) {
            this.dialogRef.close();
        }
    }
}

