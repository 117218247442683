<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div *ngIf="form" class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Mijn profiel</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">
            <form [formGroup]="form">
                <mat-form-field class="w-100" id="name">
                    <input formControlName="name"
                           matInput
                           name="name"
                           placeholder="Naam"
                           type="text">
                    <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100" id="email">
                    <input formControlName="email"
                           matInput
                           name="email"
                           placeholder="E-mailadres"
                           type="email">
                    <mat-error>Vul een correct e-mailadres in</mat-error>
                </mat-form-field>
                <mat-checkbox class="w-100" formControlName="notifications" name="notifications">
                    Notificaties ontvangen per e-mail
                </mat-checkbox>
                <div>
                    <mat-checkbox class="w-100" formControlName="change_pass" name="change_pass">
                        Wachtwoord wijzigen
                    </mat-checkbox>
                </div>
                <div *ngIf="form.get('email').value !== user.email" class="alert alert-warning">
                    Je moet je wachtwoord invoeren om je e-mailadres te wijzigen.
                    Na het opslaan moet je een nieuw wachtwoord instellen via de link die we naar je nieuwe e-mailadres sturen.
                </div>
                <div *ngIf="form.get('change_pass').value || form.get('email').value !== user.email">
                    <mat-form-field class="w-100" id="current_password">
                        <mat-label>Huidig wachtwoord</mat-label>
                        <input autocomplete="password"
                               formControlName="current_password"
                               matInput
                               name="current_password"
                               placeholder="Je huidige wachtwoord"
                               type="password">
                        <mat-error *ngIf="form.get('current_password').errors && form.get('current_password').errors.required">
                            Je moet een wachtwoord opgeven
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="form.get('change_pass').value">
                    <mat-form-field class="w-100" id="password">
                        <mat-label>Nieuw wachtwoord</mat-label>
                        <input autocomplete="new-password"
                               formControlName="password"
                               matInput
                               name="password"
                               placeholder="Je nieuwe wachtwoord"
                               type="password">
                        <mat-error *ngIf="password.errors && password.errors.minlength">
                            Je wachtwoord is te kort
                        </mat-error>
                        <mat-error *ngIf="password.errors && password.errors.pattern && !password.errors.minlength">
                            Je wachtwoord is niet sterk genoeg
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" id="passwordconf">
                        <mat-label>Nieuw wachtwoord (herhaling)</mat-label>
                        <input autocomplete="new-password"
                               formControlName="passwordconf"
                               matInput
                               name="passwordconf"
                               placeholder="Je wachtwoord ter bevestiging"
                               type="password">
                        <mat-error>De wachtwoorden moeten overeenkomen</mat-error>
                    </mat-form-field>
                </div>
            </form>
            <button (click)="setup2Fa()" *ngIf="!LocalStorage.user.hasOtp" [disabled]="sending2faEmail" color="primary" mat-raised-button>
                {{sending2faEmail ? 'Er wordt een email verstuurd' : 'Stel tweestapsverificatie in' }}
            </button>
        </div>
    </div>
    <div *ngIf="!AuthorisationService.hasFeature( 'allCustomerAreas')" class="row justify-content-center mt-3">
        <div class="col-md-6 col-12">
            <p *ngIf="AuthorisationService.hasFeature('customerAreaWithWork')">
                Je hebt toegang tot alle klantgebieden die in de status schouwen of uitvoeren staan.
            </p>
            <ng-container *ngIf="!AuthorisationService.hasFeature('customerAreaWithWork')">
                Je hebt toegang tot de volgende gebieden:
                <ul>
                    <li *ngFor="let ca of user.customer_areas">
                        {{ca.kind}} {{ca.name}}
                    </li>
                </ul>
            </ng-container>
        </div>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-md-6 col-12">
            <div class="d-flex">
                <button mat-button routerLink="/">Terug</button>
                <div class="spacer"></div>
                <button (click)="save()" [disabled]="saving" color="primary" id="btn-save"
                        mat-raised-button>
                    Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                </button>
            </div>
        </div>
    </div>
</div>
