import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Library} from '../../classes/library.class';

@Component({
    selector: 'app-pdf-viewer-dialog',
    templateUrl: './pdf-viewer-dialog.component.html',
    styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public pdf: any) {
    }

    ngOnInit() {
        document.getElementById('PDFviewer').setAttribute('src', this.pdf);
    }

    close() {
        this.dialogRef.close();
    }
}
