<table class="table table-striped table-hover">
    <tr>
        <th>
            Naam
        </th>
        <th>
            Type
        </th>
        <th>
            Groep
        </th>
    </tr>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
    <tr *ngFor="let decoration of list"
        (click)="openDecoration(decoration)"
        cdkDrag
        class="curpoint">
        <td>
            {{decoration.name}}
        </td>
        <td>
            {{decoration.type}}
        </td>
        <td>
            {{decoration.decoration_group}}
        </td>
    </tr>
    </tbody>
</table>

