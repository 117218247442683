import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Incident} from '../../classes/incident.class';

@Component({
    selector: 'app-new-incident-dialog',
    templateUrl: './new-incident-dialog.component.html',
    styleUrls: ['./new-incident-dialog.component.scss']
})
export class NewIncidentDialogComponent implements OnInit {

    badEffects: boolean;
    nearBadEffects: boolean;
    improvement: boolean;

    constructor(public dialogRef: MatDialogRef<NewIncidentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public incident: Incident) {
    }

    ngOnInit(): void {

    }

    badEffectsChange(event) {
        if (event.value) {
            this.incident.type = 'ongeval';
            this.dialogRef.close();
        }
    }

    differentConditions(event) {
        if (event.value) {
            this.incident.type = 'bijna-ongeval';
            this.dialogRef.close();
        }
    }

    complaint(event) {
        this.incident.type = 'verbeteridee';
        if (event.value) {
            this.incident.type = 'klacht';
            this.dialogRef.close();
        }
    }

    improve(event) {
        this.incident.type = 'overig';
        if (event.value) {
            this.incident.type = 'verbeteridee';
        }
        this.dialogRef.close();
    }

    onNoClick() {
        this.dialogRef.close();
    }


}
