import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SortingComponent} from '../../sorting.component';
import {AuthenticationLog} from '../../classes/authenticationlog.class';
import {User} from '../../classes/user.class';
import {AuthenticationService} from '../../services/auth/authentication.service';

@Component({
    selector: 'app-user-auth-log',
    templateUrl: './user-auth-log.component.html',
    styleUrls: ['./user-auth-log.component.scss']
})
export class UserAuthLogComponent extends SortingComponent<AuthenticationLog> implements OnInit, OnDestroy {

    @Input() user: User;

    private authLogInterval;

    constructor(private authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit() {
        this.getAuthLog();
        this.authLogInterval = setInterval(() => {
            this.getAuthLog();
        }, 25000);
    }

    getAuthLog() {
        this.authenticationService.log(this.user.id).subscribe(log => {
            this.list = log.data;
        });
    }

    ngOnDestroy() {
        clearInterval(this.authLogInterval);
    }
}
