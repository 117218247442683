import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {User} from '../classes/user.class';
import {Declaration} from '../declaration';
import {DeclarationType} from '../declaration-type';
import {DeclarationStatus} from '../declaration-status';
import {DeclarationReport} from '../declarationReport';
import {formatDate} from '@angular/common';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DeclarationService {

    constructor(private apiService: ApiService) {
    }

    saveType(declarationType: DeclarationType) {
        return this.apiService.postCall$<DeclarationType>(`declaration/types`, declarationType);
    }

    removeType(id: number) {
        return this.apiService.deleteCall$(`declaration/types/${id}`);
    }

    listType() {
        return this.apiService.getCall$<DeclarationType[]>(`declaration/types`);
    }

    getType(id: number) {
        return this.apiService.getCall$<DeclarationType>(`declaration/types/${id}`);
    }

    saveRequest(request: Declaration) {
        return this.apiService.postCall$<Declaration>(`declaration/requests`, request);
    }

    listRequests(statusIds: number[]) {
        return this.apiService.getCall$<Declaration[]>(`declaration/requests`, {statusIds}).pipe(map(requests => {
            requests.data.forEach(r => {
                r.status.name = r.status.name.charAt(0).toUpperCase() + r.status.name.slice(1);
            });
            return requests;
        }));
    }

    getRequest(id: number) {
        return this.apiService.getCall$<Declaration>(`declaration/requests/${id}`).pipe(map(requests => {
            requests.data?.status_log.forEach(r => {
                r.status.name = r.status.name.charAt(0).toUpperCase() + r.status.name.slice(1);
            });
            return requests;
        }));
    }

    deleteRequest(id: number) {
        return this.apiService.deleteCall$(`declaration/requests/${id}`);
    }

    deleteType(id: number) {
        return this.apiService.deleteCall$(`declaration/types/${id}`);
    }

    statusList() {
        return this.apiService.getCall$<DeclarationStatus[]>(`declaration/status`).pipe(map(status => {
            status.data.forEach(s => {
                s.name = s.name.charAt(0).toUpperCase() + s.name.slice(1);
            });
            return status;
        }));
    }

    managerList() {
        return this.apiService.getCall$<User[]>(`declaration/managers`);
    }

    report(period) {
        period = formatDate(period, 'yyy-MM-01', 'nl');
        return this.apiService.getCall$<DeclarationReport[]>('declaration/report', {period}).pipe(map(r => {
            r.data.forEach(s => {
                s.status = s.status.charAt(0).toUpperCase() + s.status.slice(1);
            });
            return r;
        }));
    }

    pay(ids: string) {
        return this.apiService.postCall$<Declaration>(`declaration/pay`, {ids});
    }
}
