<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Nieuws {{reserve ? 'toevoegen' : 'wijzigen'}}</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/{{Routenames.articles}}">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
</div>
<div class="container bg-white mt-2 item-edit">
    <div *ngIf="form">
        <form [formGroup]="form">
            <div (click)="fileManagerMain.chooseSource()"
                 class="main-image">
                <app-codalt-image [path]="form.value.id+'/'+form.controls['image'].value"
                                  [thumb]="article.image_thumb">
                </app-codalt-image>
                <file-manager #fileManagerMain
                              (callback)="uploadedMainImage($event)"
                              (loading)="uploadMainImage($event)"
                              [class.text-white]="isCordova && form.controls['image'].value"
                              [currentFile]="form.controls['image'].value"
                              [directUpload]="true"
                              [path]="form.value.id"
                              [remove]="true"
                              icon="{{isCordova ? 'fas fa-image' : null}}"
                              secure="article">
                </file-manager>
            </div>
            <div class="row">
                <mat-form-field class="col-auto flex-grow-1 mt-3">
                    <mat-label>Titel</mat-label>
                    <input formControlName="title" matInput type="text">
                    <mat-error>Titel is een verplicht veld</mat-error>
                </mat-form-field>
                <mat-checkbox class="col-auto mt-4" formControlName="alert">Belangrijk bericht</mat-checkbox>
            </div>
            <div class="w-100 col-date">
                <mat-form-field (click)="picker.open()" class="w-50">
                    <mat-label>Publicatiedatum</mat-label>
                    <input [matDatepicker]="picker" formControlName="publish_date" matInput>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>De publicatiedatum is verplicht</mat-error>
                </mat-form-field>
                <mat-form-field class="w-50 pl-2">
                    <mat-label>Publicatietijd</mat-label>
                    <input formControlName="publish_time" matInput placeholder="hh:mm" type="text">
                    <mat-error>De publicatietijd is verplicht</mat-error>
                </mat-form-field>
            </div>
            <div>
                <b>Beschrijving: </b>
                <simple-tiny [content]="form.get('content')"
                             elementId="content"
                             ngDefaultControl
                             propertyName="value"
                ></simple-tiny>
                <mat-error *ngIf="form.get('content')?.invalid">Content is een verplicht veld</mat-error>
            </div>
            <div class="mt-2 mb-2">
                <b>Zichtbaarheid: </b>
                <div class="mt-2">
                    <mat-radio-group formControlName="access_level">
                        <mat-radio-button class="mr-2" value="EVERYONE">iedereen</mat-radio-button>
                        <mat-radio-button class="mr-2" value="VVR">medewerkers</mat-radio-button>
                        <mat-radio-button class="mr-2" value="CONSUMER">klanten</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="pb-3">
                <b>Afbeeldingen</b>
                <div (cdkDropListDropped)="reorderImages($event)" cdkDropList class="images">
                    <div *ngFor="let image of article.images" cdkDrag class="image-edit">
                        <app-codalt-image [path]="article.id + '/' + image.path"
                                          [thumb]="image.thumb">
                        </app-codalt-image>
                        <button (click)="deleteImage(image)" color="primary" mat-mini-fab>
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                        <div cdkDragHandle class="drag-handle">
                            <i class="fa fa-arrows-alt-v"></i>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-2">
                    <file-manager
                        (callback)="uploadedFiles($event)"
                        (loading)="uploadFiles($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="article">
                    </file-manager>
                </div>
            </div>
        </form>

    </div>
    <button (click)="save()" [disabled]="saving" class="save-button" color="primary" mat-fab>
        <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
        <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
    </button>
</div>
