<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="true"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Importeren</h1>
        </div>
        <div class="controls">
            <a class="fab small mr-3" href="https://app.vvr.nl/backend/public/templates/VVR_Import_template.xlsx" id="btn-template"
               matTooltip="Download template"
               matTooltipPosition="below"
               target="_blank">
                <i class="fas fa-file-excel"></i>
            </a>
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/customer-selection">
                <i class="fas fa-sign-out-alt"></i>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div>
                <ng-select *ngIf="customerAreas"
                           [(ngModel)]="selectedCustomerAreaId"
                           [clearSearchOnAdd]="true"
                           [hideSelected]="true"
                           [items]="customerAreas"
                           bindLabel="name"
                           bindValue="id"
                           id="customerareas"
                           placeholder="Importeer voor gemeente"></ng-select>
            </div>
            <div *ngIf="selectedCustomerAreaId">
                <button color="primary" mat-raised-button>
                    <label>
                        {{pushpinImportFinished ? 'Upload bijbehorende Afbeeldingen' : 'Selecteer een excel sheet'}}
                        <input
                            (change)="uploadFile($event)" hidden multiple type="file">
                    </label>
                </button>
                <br><br>
                <div *ngFor="let file of uploadingFiles">
                    <ng-container *ngIf="!file['partsWithError'] && file['uploading']">
                        <div>
                            Bezig met het importeren van '{{file.file}}'
                        </div>
                        <mat-progress-bar [value]="file['uploading']" mode="determinate"></mat-progress-bar>
                    </ng-container>
                    <ng-container *ngIf="!file['partsWithError'] && !file['uploading']">
                        <div>
                            <b>'{{file.file}}' is geïmporteerd</b>
                            <ng-container *ngIf="!file['img']">
                                <div *ngIf="pushpinImportFinished">
                                    <p>
                                        Upload nu de afbeeldingen met de namen die u aangegeven hebt in de colom image
                                        in de Excel sheet.
                                        U kunt de afbeeldingen in het venster slepen of deze selecteren door op de knop
                                        'Upload bijbehorende
                                        Afbeeldingen' te klikken.
                                    </p>
                                    <p>
                                        Verwachte afbeeldingen: {{file['imgs']}}
                                        <ng-container *ngIf="!file['imgs']">Geen</ng-container>
                                    </p>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="file['partsWithError']">
                        <div>
                            <b>Fout bij het importeren van '{{file.file}}'</b>
                            <br><br>
                        </div>
                        <div>
                            <b>Corrigeer de volgende velden: </b>
                            <table>
                                <tr>
                                    <th>Kolom</th>
                                    <th>Waarde</th>
                                    <th>Correctie</th>
                                </tr>
                                <tr *ngFor="let error of file['uniqueErrors']">
                                    <td>
                                        {{file['nameMap'][file['columnMap'][error['columnWithError']]]}}
                                    </td>
                                    <td>
                                        {{error['row'][error['columnWithError']]}}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="error['columnNameWithError'] != 'decoration_id'">
                                            <input [(ngModel)]="error['row'][error['columnWithError']]" class="correction-field"
                                                   type="text">
                                        </ng-container>
                                        <ng-container *ngIf="error['columnNameWithError'] == 'decoration_id'">
                                            <ng-select (change)="valueChanged(file, $event, error['row'][error['columnWithError']])"
                                                       *ngIf="decorations"
                                                       [clearSearchOnAdd]="true"
                                                       [hideSelected]="true"
                                                       [items]="decorations"
                                                       bindLabel="name"
                                                       bindValue="id"
                                                       class="correction-field"
                                                       groupBy="decoration_group"
                                                       placeholder="Selecteer een figuratie">
                                                <ng-template let-index="index" let-item="item" let-search="searchTerm"
                                                             ng-option-tmp>
                                                    {{item.id}}. {{item.name}}
                                                </ng-template>
                                            </ng-select>
                                        </ng-container>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div>
                            <button (click)="correctErrors(file)" color="primary" mat-raised-button>Corrigeer fouten
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

