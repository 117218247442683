<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">PBM aanvragen</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/{{Routenames.ppeRequest}}"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9" *ngIf="form">
            <form [formGroup]="form">
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>Status</mat-label>
                        <mat-select [formControl]="fc.status_id"
                                    placeholder="Status">
                            <mat-option *ngFor="let status of statusList" [value]="status.id">
                                {{status.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>Manager</mat-label>
                        <mat-select [formControl]="fc.manager_id"
                                    placeholder="Manager">
                            <mat-option *ngFor="let manager of managerList" [value]="manager.id">
                                {{manager.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>PBM</mat-label>
                        <mat-select [formControl]="fc.equipment_id"
                                    placeholder="PBM">
                            <mat-option *ngFor="let equipment of equipmentList" [value]="equipment.id">
                                {{equipment.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6">
                        <mat-label>Maat</mat-label>
                        <input [formControl]="fc.size"
                               matInput
                               placeholder="Maat"
                               type="text">
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="fill" class="col-md-12">
                        <mat-label>Opmerkingen</mat-label>
                        <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize
                                  [formControl]="fc.comment"
                                  matInput
                                  placeholder="Voeg hier eventuele notities en opmerkingen toe"></textarea>
                    </mat-form-field>
                </div>
                <div class="row mb-2" *ngIf="fc.status_id.value === 3">
                    <mat-form-field appearance="fill" class="col-md-12">
                        <mat-label>Reden van afkeuren</mat-label>
                        <textarea cdkAutosizeMinRows="4" cdkTextareaAutosize
                                  [formControl]="fc.reason"
                                  matInput
                                  placeholder="Voeg hier de reden van het afkeuren toe"></textarea>
                        <mat-error>Dit veld is verplicht</mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div class="files">
                <div *ngFor="let file of request.images" class="file">
                    <div (click)="openFile(file, request.images)"
                         [class.is-img]="['jpg', 'jpeg', 'png'].indexOf(file.ext) !== -1"
                         [style.background-image]="(file.file.substr(0,4) !== 'http' ? url : '') + file.file+'?access_token='+token|safeBgUrl">
                        <div class="icon"><i class="fas fa-file fa-3x"></i></div>
                        <div class="filename">
                            {{file.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <mat-list class="row" *ngIf="request?.status_log?.length">
                <div mat-subheader class="pb-0">Statuswijzigingen</div>
                <mat-list-item *ngFor="let log of request.status_log">
                    <div mat-line>
                        {{log.status.name}}
                    </div>
                    <div mat-line>
                        {{log.user.name}} - {{log.created_at | date:'dd MMMM yyyy HH:mm'}}
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div class="controls-bottom">
        <label *ngIf="!isCordova && request?.status_id === 1"
               #upload
               matTooltip="Upload bestand"
               matTooltipPosition="before">
            <input
                (change)="uploadFile($event)" hidden multiple type="file">
            <div class="fab big">
                <i *ngIf="!uploading" class="fas fa-upload"></i>
                <i *ngIf="uploading" class="fas fa-circle-notch fa-spin"></i>
            </div>
        </label>
        <div (click)="takePicture()"
             *ngIf="isCordova && request?.status_id === 1" class="fab big"
             id="btn-AddImage"
             matTooltip="Camera"
             matTooltipPosition="before">
            <i class="fas fa-camera"></i>
        </div>
        <div (click)="delete()"
             *ngIf="request?.status_id === 1 && request?.id" class="fab big"
             matTooltip="Aanvraag intrekken"
             matTooltipPosition="before">
            <i class="fas fa-trash"></i>
        </div>
        <div (click)="save()" [class.disabled]="saving" class="fab big primary" id="btn-Save"
             matTooltip="Opslaan"
             matTooltipPosition="before">
            <i *ngIf="!saving" class="fas fa-save"></i>
            <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
        </div>
    </div>
</div>
