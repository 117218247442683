<div class="dialog-container">
    <div class="dialog-close">
        <div (click)="onNoClick()" class="fab small active" id="btn-cancel">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <h1 class="condensed dialog-title">Wijzigingshistorie prijs</h1>
    <div class="dialog-content">
        <div *ngFor="let log of priceLogs" class="log-item">
            <b>{{log.user}}</b> heeft de prijs van <b>{{log.material}}</b> aangepast van
            {{log.old | currency : 'EUR'}} naar <b>{{log.new | currency : 'EUR'}}</b>
            op {{log.updated_at | date : 'd MMMM yyyy'}} om {{log.updated_at | date : 'H:mm'}} uur
        </div>
        <div *ngIf="priceLogs && priceLogs.length < 1" class="log-item">
            Er zijn nog geen wijzigingen
        </div>
        <mat-progress-bar *ngIf="!priceLogs" mode="indeterminate"></mat-progress-bar>
    </div>
</div>

