<p>
    settings works!
</p>

<h1 class="h3 condensed">Statussen</h1>
<div *ngFor="let status of statuses">
    <div [style.background-color]="'#' + status.color" style="padding: 10px 15px;">
        {{status.name}}
      {{status | json}}
    </div>
</div>