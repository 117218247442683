import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IncidentService} from '../../services/incident.service';
import {Incident} from '../../classes/incident.class';
import {LocationService} from '../../pushpin-detail/location-selector-dialog/location.service';
import {GpsService} from '../../gps.service';
import {CameraService} from '../../camera.service';
import {Settings} from '../../settings.class';
import {UploadFile} from '../../import/upload-file';
import {Observable} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {formatDate} from '@angular/common';
import {PushpinPartImageViewerDialogComponent} from "../../pushpin-detail/pushpin-part-image-viewer-dialog/pushpin-part-image-viewer-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CordovaService} from "../../cordova.service";
import {NewIncidentDialogComponent} from "../new-incident-dialog/new-incident-dialog.component";
import {IncidentComment} from "../../classes/incidentcomment.class";
import {User} from "../../classes/user.class";
import {Platform} from "@angular/cdk/platform";
import {CodaltComponent} from "../../codalt.component";
import {Routenames} from '../../route-names.enum';

declare var cordova;
declare var window;

@Component({
    selector: 'app-edit-incident',
    templateUrl: './edit-incident.component.html',
    styleUrls: ['./edit-incident.component.scss']
})
export class EditIncidentComponent extends CodaltComponent implements OnInit {

    saving = false;
    uploading = false;
    isCordova = false;

    incident: Incident;
    form: FormGroup;
    statusList: string[];
    typeList: string[];

    url = Settings.API_ENDPOINT + 'file/';
    token = LocalStorage.getUserToken();

    mayEdit = false;

    private user: User;

    constructor(public platform: Platform,
                private route: ActivatedRoute,
                private router: Router,
                private confirmDialogService: ConfirmDialogService,
                private locationService: LocationService,
                private gpsService: GpsService,
                private dialog: MatDialog,
                private cordovaService: CordovaService,
                private cameraService: CameraService,
                private incidentService: IncidentService) {
        super();
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigateByUrl('/incident');
        });
        this.isCordova = typeof cordova !== 'undefined';
        this.user = LocalStorage.getUser();
        this.mayEdit = this.AuthorisationService.hasFeature('incidentManagement');
        this.incidentService.getOptions().then(option => {
            this.statusList = option.status;
            this.typeList = option.type;
        });
        this.route.params.subscribe(params => {
            const incidentId = +params['id'];
            if (incidentId) {
                this.incidentService.get(incidentId).then(incident => {
                    this.incident = incident;
                    this.form = this.createForm(incident);
                    this.addEmptyComment();
                });
            } else {
                this.createNewIncident();
            }
        });
    }

    createForm(incident: Incident) {
        const form = new FormGroup({
            street: new FormControl(incident.street, [Validators.maxLength(255)]),
            zipcode: new FormControl(incident.zipcode, [Validators.maxLength(7)]),
            housenumber: new FormControl(incident.housenumber, [Validators.maxLength(20)]),
            place: new FormControl(incident.place, [Validators.maxLength(255)]),
            description: new FormControl(incident.description, [Validators.required, Validators.maxLength(16500)]),
            type: new FormControl(incident.type, Validators.required),
            status: new FormControl(incident.status, Validators.required)
        });
        if (!this.mayEdit) {
            form.get('type').disable();
            form.get('status').disable();
        }
        if ((this.incident.id && this.user.id !== incident.user_id && !this.mayEdit)
            || (!this.mayEdit && this.incident.status !== 'ingediend')) {
            form.get('street').disable();
            form.get('zipcode').disable();
            form.get('housenumber').disable();
            form.get('place').disable();
            form.get('description').disable();
        }

        return form;
    }

    save(returnToOverview?: boolean) {
        if (this.form.valid) {
            this.incident.street = this.form.get('street').value;
            this.incident.zipcode = this.form.get('zipcode').value;
            this.incident.housenumber = this.form.get('housenumber').value;
            this.incident.description = this.form.get('description').value;
            this.incident.type = this.form.get('type').value;
            this.incident.status = this.form.get('status').value;
            this.incidentService.savePushpin(this.incident).then(incident => {
                if (returnToOverview) {
                    this.confirmDialogService.confirm(
                        'Melding opgeslagen',
                        `Je melding is opgeslagen!`,
                        'Terug naar overzicht',
                        null).then(() => {
                        this.router.navigateByUrl(`/${Routenames.incident}`);
                    }, () => {

                    });
                } else {
                    if (!this.incident.id) {
                        this.router.navigateByUrl(`/${Routenames.incident}/${incident.id}`);
                    } else {
                        this.incident = incident;
                        this.addEmptyComment();
                    }
                    this.form.markAsPristine();
                }
            });
        } else {
            for (const name of Object.keys(this.form.controls)) {
                this.form.controls[name].markAsTouched();
            }
        }
    }

    takePicture() {
        this.cameraService.getPicture().then(pictureUrl => {
            this.addFile(pictureUrl, formatDate(new Date(), 'dd-mm-YYYY hh:mm:ss', 'nl'), 'jpg');
        }, error => {
            console.error(error);
        });
    }

    addFile(fileUrl, name, ext, object?: Incident | IncidentComment) {
        if (!object) {
            object = this.incident;
        }
        if (!Array.isArray(object.files)) {
            object.files = [];
        }
        const file = new IncidentFile();
        file.name = name;
        file.file = fileUrl;
        file.ext = ext;
        object.files.push(file);
        if (typeof object['incident_id'] !== 'undefined') {
            this.incidentService.saveComment(object as IncidentComment);
        } else {
            this.save();
        }
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    public commentUploadFiles(event, comment: IncidentComment) {
        this.uploadFiles(event, comment);
    }

    createNewIncident() {
        this.incident = new Incident();
        this.incident.status = 'ingediend';
        const dialogRef = this.dialog.open(NewIncidentDialogComponent, {
            panelClass: 'new-incident-dialog',
            data: this.incident
        });
        const subs = dialogRef.beforeClosed().subscribe(() => {

            if (this.incident.type) {
                this.gpsService.getCurrentPosition().then((geo) => {
                    this.incident.lat = geo['latitude'];
                    this.incident.lng = geo['longitude'];
                    this.locationService.getLocations(geo['latitude'], geo['longitude']).subscribe(response => {
                        if (response && response['resourceSets'][0]['resources']) {
                            const addresses = [];
                            const uniqueCheck = [];
                            response['resourceSets'][0]['resources'].forEach(addr => {
                                const flatAddress = addr.address.addressLine + addr.address.postalCode + addr.address.adminDistrict2;
                                if (uniqueCheck.indexOf(flatAddress) === -1) {
                                    uniqueCheck.push(flatAddress);
                                    addresses.push(addr);
                                }
                            });
                            if (addresses.length > 0) {
                                this.setLocation(addresses[0]);
                            }
                        }
                        this.form = this.createForm(this.incident);
                    }, e => {
                        this.form = this.createForm(this.incident);
                    });
                });
            } else {
                this.router.navigateByUrl('/incident');
            }
            subs.unsubscribe();
        });
    }

    openFile(clickedImage, allFiles) {
        if (['jpg', 'jpeg', 'png'].indexOf(clickedImage.ext) !== -1) {
            const images = allFiles
                .filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1)
                .map(f => (f.file.substr(0, 4) !== 'http' ? this.url : '') + f.file + '?access_token=' + this.token);
            const dialogRef = this.dialog.open(PushpinPartImageViewerDialogComponent, {
                panelClass: 'image-viewer-dialog',
                data: {
                    images: images,
                    viewIndex: allFiles.filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1).indexOf(clickedImage)
                }
            });
            this.cordovaService.setBackbuttonAction(() => {
                dialogRef.close();
            });
            const subs = dialogRef.afterClosed().subscribe(() => {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl('/incident');
                });
                subs.unsubscribe();
            });
        } else {
            window.open(this.url + clickedImage.file + '?access_token=' + this.token);
        }
    }

    addEmptyComment() {
        const newComment = new IncidentComment();
        newComment.user = this.user;
        newComment.user_id = this.user.id;
        newComment.incident_id = this.incident.id;
        this.incident.comments.push(newComment);
    }

    deleteComment(comment: IncidentComment) {
        const index = this.incident.comments.indexOf(comment);
        this.incident.comments.splice(index, 1);
    }

    openNavi() {
        const geocoords = this.incident.lat + ',' + this.incident.lng;
        if (this.platform.IOS) {
            window.location = 'maps://?q=' + geocoords;
        } else if (this.platform.ANDROID) {
            const label = encodeURI('Locatie Incident');
            window.location = 'geo:0,0?q=' + geocoords + '(' + label + ')';
        } else {
            window.open('https://google.com/maps?q=' + geocoords, '_blank');
        }

    }

    openCommentsBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.incident.comments && this.incident.comments.filter(c => !c.id && c.comment).length > 0) {
                this.confirmDialogService.confirm(
                    'Niet opgeslagen reactie',
                    `Je bent nog een reactie aan het schrijven.`,
                    'Reactie aanpassen',
                    'Reactie verwijderen').then(() => {
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    public openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {

            if (this.form && this.form.dirty && this.incident.id && !(this.incident.comments && this.incident.comments.filter(c => !c.id && c.comment).length > 0)) {
                this.confirmDialogService.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                    'Wijzigingen opslaan',
                    'Wijzigingen verwerpen').then(() => {
                    this.save();
                    resolve(true);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    private setLocation(addressData: any) {
        const address = addressData.address;
        let number: any = '0';
        let street: any = '-';


        if (typeof address.addressLine !== 'undefined') {
            number = address.addressLine.split(' ').reverse()[0];

            if (isNaN(number[0])) {
                street = address.addressLine;
                number = '-';
            } else {
                street = address.addressLine.split(' ');

                delete street[street.indexOf(number)];
                street = street.join(' ');
            }
        }
        this.incident.zipcode = address.postalCode;
        this.incident.street = street;
        this.incident.housenumber = number;
        this.incident.place = address.locality;
    }

    private uploadFiles(files: File[], object?: Incident | IncidentComment) {
        this.uploading = true;
        const uploadedFiles = [];
        const url = `${Settings.API_ENDPOINT}images`;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const uploadFile = new UploadFile();
                uploadFile.filesize = Math.round(files[i].size / 1000);
                uploadFile.file = files[i].name;
                uploadFile.ext = files[i].type;
                if (['jpg', 'jpeg', 'image/jpeg', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'image/png', 'png', 'application/pdf',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword']
                    .indexOf(uploadFile.ext.toLowerCase()) !== -1 && files[i].size < 110664300) {
                    this.makeFileRequest(url, files[i], uploadFile).subscribe(data => {
                        if (data['success']) {
                            this.addFile(data['file'], data['name'], data['ext'], object);
                            uploadFile['uploading'] = null;
                            uploadFile.ext = data['ext'];
                        } else {
                            let error = 'Een onbekende fout';
                            if (typeof data['error'] === 'object' && Array.isArray(data['error']['upload'])) {
                                error = data['error']['upload'][0];
                            } else if (typeof data['error'] === 'string') {
                                error = data['error'];
                            }
                            this.confirmDialogService.confirm(
                                'Er is iets fout gegaan met uploaden',
                                error,
                                'Oké', null);
                        }
                        uploadedFiles.push(uploadFile);
                        if (uploadedFiles.length === files.length) {
                            this.uploading = false;
                        }
                    }, error => {
                        this.confirmDialogService.confirm(
                            'Er is iets fout gegaan met uploaden',
                            'Een onbekende fout',
                            'Oké', null);
                        this.uploading = false;
                    });
                } else if (['jpg', 'jpeg', 'image/jpeg'].indexOf(uploadFile.ext) === -1) {
                    this.uploading = false;
                    this.confirmDialogService.confirm(
                        'U kunt alleen documenten uploaden',
                        'U kunt alleen jpg, doc(x), xls(x) en pdf bestanden uploaden',
                        'Oké', null);
                } else {
                    this.uploading = false;
                    this.confirmDialogService.confirm(
                        'Het bestand is te groot',
                        'Het bestand is groter dan 100Mb',
                        'Oké', null);
                }
            }
        }
    }

    private makeFileRequest(url: string, file: File, uploadFile: UploadFile): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            formData.append('type', 'file');

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                uploadFile['uploading'] = Math.round(event.loaded / event.total * 100);

            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }
}

export class IncidentFile {
    file: string;
    ext: string;
    name: string;
}
