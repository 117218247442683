import {ChangeDetectorRef, Component, HostListener, Inject, NgZone, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PushpinImage} from '../../classes/pushpin-image.class';
import {SwiperConfigInterface, SwiperDirective} from 'ngx-swiper-wrapper';
import {PinchZoomComponent} from 'ngx-pinch-zoom';
import {DrawOnImageDialogComponent} from '../draw-on-image-dialog/draw-on-image-dialog.component';

export class ImageViewerData {
  public images: PushpinImage[];
  public viewIndex: number;
}
@Component({
    selector: 'app-pushpin-part-image-viewer-dialog',
    templateUrl: './pushpin-part-image-viewer-dialog.component.html',
    styleUrls: ['./pushpin-part-image-viewer-dialog.component.scss']
})
export class PushpinPartImageViewerDialogComponent implements OnInit {

    @ViewChildren('pinchZoom') pinchZooms: QueryList<PinchZoomComponent>;

    @ViewChild(SwiperDirective, { static: true }) directiveRef?: SwiperDirective;

    public activeImg = 0;

    showDrawing = false;

    public config: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 'auto',
        navigation: true
    };

    constructor(
        public dialogRef: MatDialogRef<PushpinPartImageViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public images: ImageViewerData,
        private dialog: MatDialog,
        private ngZone: NgZone,
        private cdref: ChangeDetectorRef) {
        if (images.images?.length) {
            this.showDrawing = !!images.images[0].image;
        }
    }

    ngOnInit(): void {
        this.directiveRef.indexChange.subscribe(() => {
            this.pinchZooms.forEach(pz => {
                if (pz.isZoomedIn) {
                    pz.toggleZoom();
                }
            });
        });
    }

    @HostListener('click', ['$event'])
    public click(event) {
        if (event.target.localName !== 'img'
            && event.srcElement.className.indexOf('swiper-button') === -1
            && event.srcElement.className.indexOf('pz-zoom-button') === -1) {
            this.dialogRef.close();
        }
    }

    drawOnImage(event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(DrawOnImageDialogComponent, {
            panelClass: 'image-viewer-dialog',
            data: this.images.images[this.directiveRef.getIndex()]
        });
        dialogRef.afterClosed().subscribe((image) => {
            this.ngZone.run(() => {
                setTimeout(() => {
                    this.images.images[this.directiveRef.getIndex()].drawn_image = image.drawn_image + '?v=1';
                    this.images.images = [...this.images.images];
                    this.cdref.detectChanges();
                });
            });
        });
    }

    protected readonly event = event;
}

