import {Component, OnDestroy, OnInit} from '@angular/core';
import {SortingComponent} from '../sorting.component';
import {Router} from '@angular/router';
import {CordovaService} from '../cordova.service';
import {LocalStorage} from '../storage.class';
import {User} from '../classes/user.class';
import {formatDate} from '@angular/common';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {AuthorisationService} from '../services/auth/authorisation.service';
import {DeclarationStatus} from '../declaration-status';
import {DeclarationService} from '../services/declaration.service';
import {Declaration} from '../declaration';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-declaration-request',
    templateUrl: './declaration-request.component.html',
    styleUrls: ['./declaration-request.component.scss']
})
export class DeclarationRequestComponent extends SortingComponent<Declaration> implements OnInit, OnDestroy {

    user: User;
    statusIds = [1, 2];
    statusList: DeclarationStatus[];
    currentSort = 'created_at';
    desc = true;
    showStatusFilter = true;

    constructor(private router: Router,
                private cordovaService: CordovaService,
                private confirmDialog: ConfirmDialogService,
                private declarationService: DeclarationService) {
        super();
    }

    ngOnInit(): void {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/']);
        });
        if (!AuthorisationService.hasFeature('declarationManagement')) {
            this.statusIds = [1, 2, 3, 4];
            this.showStatusFilter = false;
        }
        this.getData();
        this.user = LocalStorage.getUser();
        this.subscriptions.add(this.declarationService.statusList().subscribe(status => {
            this.statusList = status.data;
        }));
    }

    public getData() {
        this.subscriptions.add(this.declarationService.listRequests(this.statusIds).subscribe(requests => {
            this.list = requests.data;
        }));
    }

    delete(event, declaration: Declaration) {
        event.stopPropagation();
        this.confirmDialog.confirm(
            'Aanvraag intrekken',
            `Weet u zeker dat u deze aanvraag aangemaakt op ${formatDate(declaration.created_at, 'd MMM yyyy, H:mm', 'nl')} wilt intrekken?`,
            'Intrekken',
            'Behouden'
        ).then(() => {
            this.declarationService.deleteRequest(declaration.id).subscribe(() => {
                const index = this.list.indexOf(declaration);
                this.list.splice(index, 1);
            });
        }, () => {

        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }



}
