import {Component, OnInit} from "@angular/core";
import {User} from "../classes/user.class";
import {CustomerArea} from "../classes/customerarea.class";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {UserService} from "../services/user/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CordovaService} from "../cordova.service";
import {CustomerAreaService} from "../services/consumer-area/customer-area.service";
import {ConfirmDialogService} from "../services/confirm-dialog-service/confirm-dialog.service";
import {RequiredRule} from "./profile.required-rules";
import {Utils} from "../utils.class";
import {CodaltComponent} from "../codalt.component";
import {TwoFactorService} from '../services/two-factor.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends CodaltComponent implements OnInit {

    saving = false;
    user: User;
    customerAreas: CustomerArea[];
    groups;
    form: FormGroup;

    sending2faEmail = false;

    constructor(private userService: UserService,
                private route: ActivatedRoute,
                private cordovaService: CordovaService,
                private customerAreaService: CustomerAreaService,
                private confirmDialogService: ConfirmDialogService,
                private twoFactorService: TwoFactorService,
                private router: Router) {
        super();
    }

    get password() {
        return this.form.get('password');
    }

    setup2Fa() {
        this.sending2faEmail = true;
        this.twoFactorService.setupEmail().subscribe(() => {
            this.sending2faEmail = false;
            this.confirmDialogService.confirm('Tweestapsverificatie instellen',
                `Open de link in de zojuist gestuurde email om tweestapsverificatie in te stellen.`,
                'Oké', null).then(() => {

            }, () => {
            });
        });
    }

    ngOnInit() {
        this.userService.getCurrentUser().then(user => {
            this.user = user;
            this.setFormGroup(this.user);
        });
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            const user = JSON.parse(JSON.stringify(this.user));
            Object.assign(user, this.form.value);
            this.saving = true;
            this.userService.saveProfile(user).then(response => {
                this.saving = false;
                this.router.navigate([`/`]);
            }, error => {
                this.saving = false;
                this.confirmDialogService.confirm('Er ging iets fout', error['error']['error']['errormessage']);
            });
        }
    }

    matchFieldValidator(matchField: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.form) {
                const toValue = this.form.controls[matchField].value;
                const equal = toValue === control.value;
                return equal ? null : {'matchField': true};
            }
            return null;
        };
    }

    private setFormGroup(user: User) {
        this.form = new FormGroup({
            name: new FormControl(user.name, [Validators.required, Validators.minLength(3)]),
            notifications: new FormControl(user.notifications),
            email: new FormControl(user.email, [Validators.required, Validators.email]),
            change_pass: new FormControl(false),
            current_password: new FormControl('', RequiredRule(this)),
            password: new FormControl('',
                [
                    Validators.minLength(7),
                    Validators.pattern(/^(?=.*[!@#$&*0-9A-Z].*[!@#$&*0-9A-Z]).*$/)
                ]),
            passwordconf: new FormControl('', [
                this.matchFieldValidator('password')
            ])
        });

    }

}
