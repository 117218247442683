<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div *ngIf="form" class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Klantgebied beheren</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/customer-areas">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="w-100" id="name">
                            <input formControlName="name"
                                   matInput
                                   name="name"
                                   placeholder="Naam"
                                   type="text">
                            <mat-error>Dit veld moet minimaal 3 tekens bevatten</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="w-100" id="kind">
                            <input formControlName="kind"
                                   matInput
                                   name="kind"
                                   placeholder="Soort"
                                   type="text">
                            <mat-error>Dit veld is verplicht</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-9">
                        <mat-form-field class="w-100" id="street">
                            <input formControlName="street"
                                   matInput
                                   name="street"
                                   placeholder="Straat"
                                   type="text">
                            <mat-error>Dit veld is verplicht</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="w-100" id="number">
                            <input formControlName="number"
                                   matInput
                                   name="number"
                                   placeholder="Huisnummer"
                                   type="text">
                            <mat-error>Dit veld is verplicht</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field class="w-100" id="zip">
                            <input formControlName="zip"
                                   matInput
                                   name="zip"
                                   placeholder="Postcode"
                                   type="text">
                            <mat-error>Dit veld is verplicht</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-8">
                        <mat-form-field class="w-100" id="city">
                            <input formControlName="city"
                                   matInput
                                   name="city"
                                   placeholder="Plaats"
                                   type="text">
                            <mat-error>Dit veld is verplicht</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <mat-form-field class="w-100" id="phone">
                    <input formControlName="phone"
                           matInput
                           name="phone"
                           placeholder="Telefoonnummer"
                           type="text">
                    <mat-error>Dit veld is verplicht</mat-error>
                </mat-form-field>
                <mat-checkbox formControlName="notifications" color="primary">Notificaties</mat-checkbox>
                <div>
                    <small>
                        Als de notificaties <b>uit</b> staan<br>
                        - worden er voor dit klantgebied geen Push notificaties verstuurd naar iOS en Android.<br>
                        - worden er geen status updates meer verstuurd per email<br>
                        - er wordt wel een email naar vvrapp@vvr.nl gestuurd
                    </small>
                </div>
            </form>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6 col-12">
            <div class="d-flex mt-3">
                <button mat-button routerLink="/customer-areas">Terug</button>
                <div class="spacer"></div>
                <button (click)="save()" [disabled]="form.invalid || saving" color="primary" id="btn-save"
                        mat-raised-button>
                    Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
                </button>
            </div>
        </div>
    </div>
</div>
