import {CustomerArea} from './customerarea.class';
import {InspectionStatus} from './inspectionstatus.class';
import {ManualArea} from './manualarea.class';

export class Inspection {
    id: number;
    name: string;
    customer_area_id: number;
    customer_area: CustomerArea;
    inspection_status_id: number;
    inspection_status: InspectionStatus;
    inspection_type_id: number;
    user_id: number;
    manual_areas: ManualArea[];
    reference: string;
    lock: boolean;
    updated_at: Date;
}
