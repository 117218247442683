import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Utils} from '../../utils.class';
import {Routenames} from '../../route-names.enum';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {DeclarationType} from '../../declaration-type';
import {DeclarationService} from '../../services/declaration.service';

@Component({
    selector: 'app-declaration-type-edit',
    templateUrl: './declaration-type-edit.component.html',
    styleUrls: ['./declaration-type-edit.component.scss']
})
export class DeclarationTypeEditComponent extends CodaltComponent implements OnInit, OnDestroy {

    fc: {
        name: FormControl,
        distance: FormControl,
        unit_price: FormControl,
    };

    form: FormGroup;

    type: DeclarationType;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private declarationService: DeclarationService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.declarationService.getType(params['id']).subscribe(equipment => {
                this.type = equipment.data || new DeclarationType();
                this.fc = {
                    name: new FormControl(this.type.name, Validators.required),
                    distance: new FormControl(this.type.distance || false),
                    unit_price: new FormControl(this.type.unit_price)
                };
                this.form = new FormGroup(this.fc);
            });
        }));
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.type.name = this.fc.name.value;
            this.type.distance = this.fc.distance.value;
            this.type.unit_price = this.fc.unit_price.value;
            this.subscriptions.add(this.declarationService.saveType(this.type).subscribe(() => {
                this.router.navigate([Routenames.declarationTypes]);
            }, error => {
                this.confirmDialog.confirm(
                    'Fout bij het opslaan',
                    error.message,
                    'Sluiten'
                ).then(() => {

                }, () => {

                });
            }));
        }
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
