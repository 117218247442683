import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {debounceTime} from 'rxjs/operators';

export class Utils {

    constructor() {

    }


    static getJwtData(token): { setupOtp: boolean, needsOtp: boolean, Token: string, TokenCreation: string, aud: string, exp: number, iss: string } {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static isIOS() {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            return true;
        } else {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }
    }

    static isAndroid() {
        if (/Android/.test(navigator.userAgent)) {
            return true;
        }
        return false;
    }

    static triggerValidation(control: AbstractControl, source: AbstractControl[] = []) {
        if (control instanceof FormGroup) {
            const group = (control as FormGroup);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else if (control instanceof FormArray) {
            const group = (control as FormArray);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else {
            control.markAllAsTouched();
            control.updateValueAndValidity({emitEvent: source.indexOf(control) === -1});
        }

    }

    static triggerValidationP(control: AbstractControl, source: AbstractControl[] = []) {
        return new Promise<void>((resolve, reject) => {
            const sub = control.statusChanges.pipe(debounceTime(5)).subscribe(status => {
                if (status === 'VALID') {
                    resolve();
                    sub.unsubscribe();
                }
                if (status === 'INVALID') {
                    reject();
                    sub.unsubscribe();
                }
            });

            Utils.triggerValidation(control, source);
        });
    }

    public static getNextWorkDay(today?: Date) {
        const checkDay = (date => {
            if (date.getUTCDay() === 0) {
                return new Date(date.setUTCDate(date.getUTCDate() + 1));
            }
            if (date.getUTCDay() > 5) { // if friday or saturday move to next monday
                return new Date(date.setUTCDate(date.getUTCDate() + (8 - date.getUTCDay())));
            }
            return new Date(date);
        });
        if (!today) {
            today = new Date();
            today.setUTCDate(today.getUTCDate() + 1);
        }
        today = checkDay(today);
        return checkDay(today);
    }
}
