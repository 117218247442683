<div class="dialog-container">
    <div class="dialog-close">
        <div id="btn-cancel" class="fab small active" mat-dialog-close>
            <i class="fas fa-times"></i>
        </div>
    </div>

    <h1 class="condensed dialog-title">Meerwerk t.o.v. offerte</h1>
    <div class="dialog-content flex-column">
        <div class="pb-2">
            <i *ngIf="inspection.inspection_status_id === 5">
                Let op: de schouw is nog in uitvoering. Nog niet uitgevoerde delen worden hier als wijziging t.o.v. offerte getoond.
            </i>
        </div>
        <table cellpadding="3" class="price-table" *ngIf="changedLines">
            <tr class="border-bottom">
                <th style="width: 106mm;">
                    Omschrijving
                </th>
                <th style="text-align: right; width: 36mm;">
                    Hoeveelheid
                </th>
                <th style="width: 14mm; text-align: right;">
                    Eh.
                </th>
                <th class="text-right" style="width: 30mm;">
                    Eenheidsprijs
                </th>
                <th class="text-right" style="width: 42mm;">
                    Bedrag
                </th>
            </tr>
            <ng-container *ngFor="let change of changedLines; let i = index">
                <ng-container *ngIf="i === 0 || change['pushpin_id'] != changedLines[i-1]['pushpin_id']">
                    <tr>
                        <td colspan="5">
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <i>{{change['location']}}</i>
                        </td>
                    </tr>
                </ng-container>
                <tr>
                    <td>
                        <div class="ellipsis">
                            {{change['name']}}
                        </div>
                    </td>
                    <td class="price-cell">
                        {{change['negative'] ? '-' : '+'}}
                        {{change['surface'] | number:'1.0-2'}}
                    </td>
                    <td class="text-right">
                        <span [innerHTML]="change['unit']" class="gray"></span>
                    </td>
                    <td class="price-cell">
                        <span class="eu">€</span>
                        {{change['price'] | number:'1.2-2'}}
                    </td>
                    <td class="price-cell">
                        <span class="eu">€</span>
                        <ng-container *ngIf="change['negative']">-</ng-container>
                        {{change['priceTotal'] | number:'1.2-2'}}
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
