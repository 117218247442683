import {Component, Input, OnInit} from '@angular/core';
import {Library} from '../../classes/library.class';
import {Platform} from '@angular/cdk/platform';
import {CodaltComponent} from '../../codalt.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Settings} from '../../settings.class';
import {LocalStorage} from '../../storage.class';
import {PdfViewerDialogComponent} from '../pdf-viewer-dialog/pdf-viewer-dialog.component';
import {RenameChapterComponent} from '../rename-chapter/rename-chapter.component';
import {AddChapterComponent} from '../add-chapter/add-chapter.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {LibraryService} from '../../services/library/library.service';

declare var cordova;

@Component({
    selector: 'app-library-tree',
    templateUrl: './library-tree.component.html',
    styleUrls: ['./library-tree.component.scss']
})
export class LibraryTreeComponent extends CodaltComponent implements OnInit {

    @Input()
    libraryChapters: Library[];

    touchDevice = false;
    isRead = false;
    @Input()
    editMode = false;

    constructor(public platform: Platform,
                private dialog: MatDialog,
                private confirmDialogService: ConfirmDialogService,
                private libraryService: LibraryService) {
        super();
    }

    ngOnInit(): void {
        this.touchDevice = this.platform.ANDROID || this.platform.IOS;
        this.isRead = !this.AuthorisationService.hasAnyFeature(['customerAreaGet', 'lrma', 'priceList', 'customerAreaSave', 'extraCostSave', 'decorationSave', 'userGet', 'managementDashboard']);
    }

    drop(event: CdkDragDrop<string[]>, array) {
        if (this.editMode) {
            moveItemInArray(array, event.previousIndex, event.currentIndex);
            array.forEach((item, index) => {
                item.order = index;
            });
            this.libraryService.changeOrder(array).then(result => {

            }, error => {

            });
        }
    }

    download(chapterId) {
        const url = `${Settings.API_ENDPOINT}library/download/${chapterId}?access_token=${LocalStorage.getUserToken()}`;
        if (typeof cordova !== 'undefined') {
            window['PreviewAnyFile'].previewPath(
                win => console.log("open status", win),
                error => console.error("open failed", error),
                url, {name: 'file.pdf'}
            );
        } else {
            const dialogRef = this.dialog.open(PdfViewerDialogComponent, {
                width: '97%',
                maxWidth: '97%',
                maxHeight: '97%',
                height: '97%',
                data: url
            });
        }
    }

    deleteItem(event, chapter: Library, parentChapter: Library = null) {
        event.stopPropagation();
        const messageType = chapter.file ? 'Bestand' : 'Hoofdstuk';
        const messageSingular = (chapter.sub_items && chapter.sub_items.length) === 1 ? `met 1 onderliggend item` : '';
        const messagePlural = (chapter.sub_items && chapter.sub_items.length) > 1 ? `met ${chapter.sub_items.length} onderliggende items` : messageSingular;
        const messageSubItems = chapter.sub_items ? `<b>${messagePlural}</b>` : '';
        this.confirmDialogService.confirm(
            `${chapter.file ? 'Bestand' : 'Hoofdstuk'} verwijderen`,
            `Weet u zeker dat u ${messageType} <u>${chapter.name}</u> ${messageSubItems} wilt verwijderen?`,
            'Verwijderen',
            'Annuleren').then(() => {
            this.libraryService.delete(chapter.id).then(result => {
                if (parentChapter) {
                    const index = parentChapter.sub_items.indexOf(chapter);
                    parentChapter.sub_items.splice(index, 1);
                } else {
                    const index = this.libraryChapters.indexOf(chapter);
                    this.libraryChapters.splice(index, 1);
                }
            }, () => {
                this.confirmDialogService.confirm(
                    'Fout bij verwijderen',
                    `Er is iets fout gegaan bij het verwijderen`,
                    'Oké', null);
            });
        }, () => {

        });
    }

    renameItem(event, chapter: Library) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(RenameChapterComponent, {
            width: '500px',
            maxHeight: '600px',
            data: chapter
        });
        const subs = dialogRef.afterClosed().subscribe((chapterS) => {

        });
    }


    addNewItem(event, parentChapter: Library = null, root = false) {
        event.stopPropagation();
        if (parentChapter) {
            parentChapter['expanded'] = true;
        }
        const dialogRef = this.dialog.open(AddChapterComponent, {
            width: '500px',
            maxHeight: '600px',
            data: {
                parentChapter,
                libraryChapters: this.libraryChapters,
                root
            }
        });
        const subs = dialogRef.afterClosed().subscribe((chapterS) => {
            if (chapterS) {
                if (parentChapter) {
                    if (Array.isArray(chapterS)) {
                        chapterS.forEach(chapter => {
                            parentChapter.sub_items.push(chapter);
                        });
                    } else {
                        parentChapter.sub_items.push(chapterS);
                    }
                } else {
                    this.libraryChapters.push(chapterS);
                }
            }
        });
    }

}
