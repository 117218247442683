import {Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Pushpin} from '../classes/pushpin.class';
import {PushpinPart} from '../classes/pushpinpart.class';
import {PushpinImage} from '../classes/pushpin-image.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PushpinService} from '../services/pushpin/pushpin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerArea} from '../classes/customerarea.class';
import {PushpinPartCreationWizardComponent} from './decoration-selector-dialog/pushpin-part-creation-wizard.component';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {CalculatingLengthDialogComponent} from './calculating-length-dialog/calculating-length-dialog.component';
import {CameraService} from '../camera.service';
import {Status} from '../classes/status.class';
import {MaterialService} from '../services/material/material.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Material} from '../classes/material.class';
import {CordovaService} from '../cordova.service';
import {PushpinPartHistoryComponent} from './pushpin-part-history/pushpin-part-history.component';
import {first} from 'rxjs/operators';
import {LocalStorage} from '../storage.class';
import {Location} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Settings} from '../settings.class';
import {UploadFile} from '../import/upload-file';
import {CodaltComponent} from '../codalt.component';
import {Utils} from '../utils.class';
import {PushpinPartDetailComponent} from './pushpin-part-detail/pushpin-part-detail.component';
import {Inspection} from '../classes/inspection';
import {ChooseInspectionDialogComponent} from '../choose-inspection/choose-inspection-dialog/choose-inspection-dialog.component';

declare var window;
declare var cordova;

@Component({
    selector: 'app-pushpin-detail',
    templateUrl: './pushpin-detail.component.html',
    styleUrls: ['./pushpin-detail.component.scss']
})
export class PushpinDetailComponent extends CodaltComponent implements OnInit, OnDestroy {

    zoom = 13;
    cordovaVersion = '';
    @Output()
    pushpinChanged = new EventEmitter<boolean>();
    isCordova = false;
    showSave = true;
    showFromOtherInspections = false;
    saving = false;
    dragOver = false;
    uploading = false;
    openSideBar = false;
    pushpin: Pushpin;
    form = new FormGroup({
        street: new FormControl('', [Validators.maxLength(255)]),
        zipcode: new FormControl('', [Validators.maxLength(7)]),
        housenumber: new FormControl('', [Validators.maxLength(20)]),
        place: new FormControl('', [Validators.maxLength(255)]),
        description: new FormControl('', [Validators.maxLength(16500)]),
        reference: new FormControl('', [Validators.maxLength(255)])
    });
    activePushpinPart: PushpinPart;
    materials: Material[];
    backToList = false;
    userId: number;
    showAddPartButton = true;
    deviceHasInternet = true;
    loading = true;
    lastTimeOpenChangesCheck = 0;
    showPortraitControls = false;
    iconToMap = false;
    @Input()
    customerArea: CustomerArea;
    @Input()
    inspection: Inspection;

    forms = new Map<PushpinPart, FormGroup>([]);
    @ViewChild('pushpinPartDetail', {static: false})
    private pushpinPartDetailComponent: PushpinPartDetailComponent;
    private firstClick = true;

    constructor(private pushpinService: PushpinService,
                private activeRoute: ActivatedRoute,
                private location: Location,
                private materialService: MaterialService,
                private router: Router,
                private dialog: MatDialog,
                private ngZone: NgZone,
                private confirmDialogService: ConfirmDialogService,
                private cordovaService: CordovaService,
                public platform: Platform,
                private cameraService: CameraService,
                private breakpointObserver: BreakpointObserver,
                private matSnackbar: MatSnackBar) {
        super();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.pushpin.pushpin_parts.filter(pushpinPart => {
            return pushpinPart['changed'] || !pushpinPart.id;
        }).length > 0) {
            $event.returnValue = true;
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (event.target.className.indexOf('detail-sidebar') === -1) {
            if (!this.firstClick) {
                this.ngZone.run(() => {
                    this.openSideBar = false;
                });
                this.firstClick = true;
            } else {
                this.firstClick = false;
            }
        }
    }

    @HostListener('dragover', ['$event']) ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (!evt.srcElement.classList.contains('images') && !evt.srcElement.classList.contains('image-wrapper')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        this.uploadFiles(files);
    }

    openNavi() {
        const geocoords = this.pushpin.lat + ',' + this.pushpin.lng;
        if (Utils.isIOS()) {
            window.location = 'maps://?q=' + geocoords;
        } else if (this.platform.ANDROID) {
            const label = encodeURI('Punaise: ' + this.pushpin.number);
            window.location = 'geo:0,0?q=' + geocoords + '(' + label + ')';
        } else {
            window.open('https://google.com/maps?q=' + geocoords, '_blank');
        }
    }

    copy(pushpinPartToCopy: PushpinPart) {
        const dialogRef = this.dialog.open(PushpinPartCreationWizardComponent, {
            panelClass: 'part-dialog',
            data: {
                lengthmark: this.pushpin.lengthmark,
                copy: true,
                customerArea: this.customerArea
            }
        });
        this.cordovaService.setBackbuttonAction(() => {
            dialogRef.close();
        });
        const subs = dialogRef.afterClosed().subscribe((pushpinPart: PushpinPart) => {
            if (pushpinPart) {
                pushpinPart['changed'] = true;
                pushpinPart.inspection_id = pushpinPartToCopy.inspection_id;
                pushpinPart.inspection = JSON.parse(JSON.stringify(pushpinPartToCopy.inspection));
                pushpinPart.description = this.forms.get(pushpinPartToCopy).value['description'] || '';
                if (pushpinPart.description) {
                    pushpinPart.description += '\n';
                }
                if (!pushpinPart.decoration.specify_length && pushpinPart.decoration.surface !== null) {
                    pushpinPart.amount = this.forms.get(pushpinPartToCopy).value['amount'];
                } else {
                    pushpinPart.amount = 1;
                    if (!pushpinPartToCopy.decoration.specify_length && pushpinPartToCopy.decoration.surface !== null) {
                        pushpinPart.description += `Kopie - oorspronkelijk aantal: ${this.forms.get(pushpinPartToCopy).value['amount']}`;
                    }
                }
                if (pushpinPart.decoration.specify_length && pushpinPartToCopy.length) {
                    pushpinPart.length = this.forms.get(pushpinPartToCopy).value['length'];
                } else if (this.forms.get(pushpinPartToCopy).value['length']) {
                    pushpinPart.description += `Kopie - oorspronkelijke lengte: ${this.forms.get(pushpinPartToCopy).value['length']}`;
                }
                if (pushpinPart.decoration.surface === null &&
                    pushpinPart.decoration.type !== 'Diversen vervangen' &&
                    pushpinPart.decoration.decoration_group !== 'Voorgevormd' &&
                    pushpinPart.decoration.type &&
                    pushpinPartToCopy.surface) {
                    pushpinPart.surface = this.forms.get(pushpinPartToCopy).value['surface'];
                } else if (this.forms.get(pushpinPartToCopy).value['surface']) {
                    pushpinPart.description += `Kopie - oorspronkelijk oppervlakte: ${this.forms.get(pushpinPartToCopy).value['surface']}`;
                }

                if (pushpinPart.material.primer && this.forms.get(pushpinPartToCopy).value['primer']) {
                    pushpinPart.primer = this.forms.get(pushpinPartToCopy).value['primer'];

                    this.materialService.getMaterials(true).then(materials => {
                        const primers = materials.sort((a, b) => a.sort_order - b.sort_order)
                            .filter(m => m.kind === 'Primer' &&
                                ['both', (pushpinPart.decoration.specific_price && pushpinPart.decoration.surface ? 'figuration' : 'material')].includes(m.suitable_kind) &&
                                m.suitable_materials.includes(pushpinPart.material_id)
                            );

                        if (!primers.find(p => p.id === pushpinPart.primer)) {
                            pushpinPart.primer = primers[0].id;
                        }
                    });
                }
                pushpinPart.blast = this.forms.get(pushpinPartToCopy).value['blast'];
                pushpinPart.stake_out = this.forms.get(pushpinPartToCopy).value['stake_out'];
                pushpinPart.traffic_controllers = this.forms.get(pushpinPartToCopy).value['traffic_controllers'];
                pushpinPart.turbo_burn = this.forms.get(pushpinPartToCopy).value['turbo_burn'];
                pushpinPart.manual_burn = this.forms.get(pushpinPartToCopy).value['manual_burn'];
                pushpinPart.night = this.forms.get(pushpinPartToCopy).value['night'];
                pushpinPart.exclusive_material = this.forms.get(pushpinPartToCopy).value['exclusive_material'];
                if (pushpinPart.material.color) {
                    pushpinPart.paint_color_id = this.forms.get(pushpinPartToCopy).value['paint_color_id'];
                }
                pushpinPart.status_id = this.forms.get(pushpinPartToCopy).value['status_id'];
                pushpinPart.status = pushpinPartToCopy.status;
                pushpinPart.crow = this.forms.get(pushpinPartToCopy).value['crow'];
                pushpinPart.images = pushpinPartToCopy.images.map(imageToCopy => {
                    const newImage = {} as PushpinImage;
                    Object.assign(newImage, imageToCopy);
                    newImage.id = undefined;
                    newImage.pushpin_part_id = undefined;
                    return newImage;
                });

                this.pushpin.pushpin_parts = this.pushpin.pushpin_parts.concat([pushpinPart]);
                this.openPushpinPart(pushpinPart);
            } else {
                this.cordovaService.setBackbuttonAction(() => {
                    this.setBackbuttonAction();
                });
            }
            subs.unsubscribe();
        });
    }

    openSideBarAction(event) {
        event.stopPropagation();
        this.openSideBar = true;
        this.cordovaService.setBackbuttonAction(() => {
            this.openSideBar = false;
            this.setBackbuttonAction();
        });
    }

    ngOnInit() {
        this.userId = LocalStorage.getUser().id;
        this.isCordova = typeof cordova !== 'undefined';
        this.subscriptions.add(this.breakpointObserver.observe(['(max-width: 767px)']).subscribe(result => {
            this.iconToMap = result.matches;
        }));
        this.subscriptions.add(this.breakpointObserver.observe(['(min-width: 600px) and (max-width: 899px) and (orientation: portrait)']).subscribe(result => {
            this.showPortraitControls = result.matches;
            if (result.matches && (Utils.isIOS() || this.platform.ANDROID)) {
                this.matSnackbar.open('Kantel uw tablet voor optimale werking van dit scherm', 'Oké', {duration: 3500});
            } else {
                this.matSnackbar.dismiss();
            }

        }));
        if (window['cordova']) {
            this.cordovaService.getAppVersion().then((version) => {
                this.cordovaVersion = version;
            });
        }
        this.subscriptions.add(this.cordovaService.deviceHasInternet.subscribe((hasInternet) => {
            this.deviceHasInternet = hasInternet;
        }));
        this.cordovaService.setBackbuttonAction(() => {
            this.onNoClick();
        });
        this.materialService.getMaterials().then(materials => {
            this.materials = materials;
        });

        this.subscriptions.add(this.activeRoute.params.pipe(first()).subscribe((params) => {
            this.zoom = params['zoom'];
            if (params['view'] !== 'map-detail' && params['view'] !== 'list-detail') {
                return;
            }
            this.backToList = params['view'] !== 'map-detail';

            const loadPushpin = () => {
                this.pushpinService.getPushpin(+params['id']).then((pushpin) => {
                    this.pushpin = pushpin;
                    this.customerArea = this.pushpin.customer_area;
                    this.inspection = this.customerArea.inspections.find(ca => ca.id === +params['inspection']);
                    this.showAddPartButton = this.AuthorisationService.hasFeature('pushpinSave') &&
                        !this.inspection?.lock &&
                        (this.AuthorisationService.hasFeature('pushpinAlwaysAdd')
                            || this.inspection?.inspection_status.create_allowed
                            || !!this.pushpin.pushpin_parts.find(pp => pp.inspection.inspection_status.create_allowed));
                    Object.entries(this.form.controls).forEach(control => {
                        if (typeof this.pushpin[control[0]] !== 'undefined') {
                            this.form.controls[control[0]].setValue(this.pushpin[control[0]]);
                        }
                    });
                    const routerParamPushpinPartId = +params['pushpinPartId'];
                    if (routerParamPushpinPartId) {
                        const pushpinPart = this.pushpin.pushpin_parts.find(p => p.id === routerParamPushpinPartId);
                        if (pushpinPart) {
                            this.activePushpinPart = pushpinPart;
                        }
                    }
                    if (this.pushpin.lengthmark && this.pushpin.draft) {
                        if (this.pushpin.end_lng) {
                            this.pushpin.mid_lat = params['lat'];
                            this.pushpin.mid_lng = params['lng'];
                        } else {
                            this.pushpin.end_lat = params['lat'];
                            this.pushpin.end_lng = params['lng'];
                        }

                        const dialogRef = this.dialog.open(CalculatingLengthDialogComponent, {
                            width: '300px',
                            maxHeight: '600px',
                            disableClose: true,
                            data: {
                                pushpin,
                                walking: params['walking'] === 'true',
                                useRoute: params['useRoute'] === 'true'
                            }
                        });
                        const subs = dialogRef.afterClosed().subscribe((result) => {
                            this.pushpin.draft = false;
                            this.pushpin.pushpin_parts.forEach(pushpinPart => {
                                if (!pushpinPart.length) {
                                    pushpinPart.length = Math.round(this.pushpin.snappedpoints.totalLength);
                                }
                            });
                            this.pushpinService.savePushpin(this.pushpin).then(() => {
                                this.pushpinChanged.emit(true);
                            }, () => {
                            });
                            subs.unsubscribe();
                        });
                    }
                    this.loading = false;
                }, error => {
                    if (error.data.statusCode === 404) {
                        this.confirmDialogService.confirm(
                            'Punaise niet gevonden',
                            'We konden deze punaise niet meer vinden. Deze punaise is waarschijnlijk verwijderd. ' +
                            '(PUHSPIN_404_' + params['id'] + ')', 'Oké', null)
                            .then(() => {
                                this.router.navigate(['/']);
                            });
                    } else {
                        this.confirmDialogService.confirm(
                            'Punaise niet geladen',
                            `We konden deze punaise niet laden.
                                  <br>Code: ${error.data.statusCode}
                                  <br>${error.data.errormessage}
                                  <br>pId:${params['id']}
                                  <br>cId:${this.customerArea?.id}`, 'Oké', null)
                            .then(() => {
                                this.router.navigate(['/']);
                            });
                    }
                });
            };

            if (+params['id'] > 0) {
                loadPushpin();
            } else {
                this.pushpin = new Pushpin();
                this.pushpin.pushpin_parts = [];
                this.pushpin.lat = params['lat'];
                this.pushpin.lng = params['lng'];
                this.pushpin.customer_area_id = this.customerArea.id;
                this.pushpin.lengthmark = false;
            }

        }));
    }

    addForm(formGroup: FormGroup, pushpinPart: PushpinPart) {
        this.forms.set(pushpinPart, formGroup);
    }


    correctLengthmark() {
        if (this.deviceHasInternet) {
            if (this.allFormsValid()) {
                this.pushpin.draft = true;
                LocalStorage.removeSnappedPointsHistory();
                this.save();
            }
        } else {
            this.confirmDialogService.confirm(
                'Geen Internet',
                'Het apparaat heeft momenteel geen internetverbinding. Daarom kan de correctie niet worden uitgevoerd.',
                'Oké',
                null).then(() => {

            }, () => {

            });
        }
    }

    public materialName(materialId: number) {
        const material = this.materials?.filter(p => p.id === materialId).pop();
        if (material) {
            return material.name;
        }
        return '';
    }

    public openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.saving) {
                resolve(false);
            }
            if (this.openChanges()) {
                this.confirmDialogService.confirm(
                    'Niet opgeslagen wijzigingen - A',
                    `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                    'Wijzigingen opslaan',
                    'Wijzigingen verwerpen').then(() => {
                    this.save();
                    resolve(false);
                }, () => {
                    this.pushpin.pushpin_parts.forEach(pushpinpart => {
                        pushpinpart['changed'] = false;
                    });
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    save(noRouting = false) {
        if (this.allFormsValid() && this.deviceHasInternet) {
            if (this.pushpin.draft && (!this.pushpin.pushpin_parts || this.pushpin.pushpin_parts.length < 1)) {
                Object.assign(this.pushpin, this.form.value);
                this.saveData(noRouting);
            } else if (this.pushpin.pushpin_parts && this.pushpin.pushpin_parts.length > 0) {
                let customerAreaStatusIsSelection = false;
                let noImg = true;
                let imgRequiredForPushpinPart;
                this.pushpin.pushpin_parts.forEach(pushpinPart => {
                    if (pushpinPart.images && pushpinPart.images.length > 0) {
                        noImg = false;
                    } else if (pushpinPart.material_id === 5) {
                        imgRequiredForPushpinPart = pushpinPart;
                    }
                });
                if (!window['cordova']) {
                    imgRequiredForPushpinPart = null;
                    noImg = false;
                }
                if (this.inspection?.inspection_status_id === 3) {
                    customerAreaStatusIsSelection = this.pushpin.pushpin_parts.filter(pushPinPart => {
                        return pushPinPart.status_id === 1;
                    }).length > 0;
                }
                if (noImg && !imgRequiredForPushpinPart) {
                    this.confirmDialogService.confirm(
                        'Geen foto toegevoegd',
                        `Er is aan geen van de markeringsitem's een foto toegevoegd. Wilt u alsnog een foto toevoegen?`,
                        'Ja',
                        'Nee'
                    ).then(() => {
                        this.activePushpinPart = this.pushpin.pushpin_parts[0];
                        this.takePicture();
                    }, () => {
                        Object.assign(this.pushpin, this.form.value);
                        this.saveData(noRouting);
                    });
                } else if (noImg && imgRequiredForPushpinPart) {
                    this.confirmDialogService.confirm(
                        'Geen foto toegevoegd',
                        `Bij voorgevormde thermoplast is een foto verplicht. Wilt u nu een foto maken of verder gaan met bewerken?`,
                        'Foto maken',
                        'Bewerken'
                    ).then(() => {
                        this.activePushpinPart = imgRequiredForPushpinPart;
                        this.takePicture();
                    }, () => {
                        this.activePushpinPart = imgRequiredForPushpinPart;
                    });
                } else if (customerAreaStatusIsSelection) {
                    this.confirmDialogService.confirm(
                        'Selectie door klant',
                        `De status van de schouwronde is momenteel “Selectie door klant”.
                        Wil je deze markeringsitems opslaan met de status “Geschouwd”,
                        zodat de klant deze kan toevoegen aan het goedgekeurde werkpakket?`,
                        'Opslaan als Geschouwd',
                        'Opslaan als Concept'
                    ).then(() => {
                        this.pushpin.pushpin_parts.filter(pp => pp.inspection_id === this.inspection.id).forEach(pushpinPart => {
                            pushpinPart.status_id = 2;
                        });
                        Object.assign(this.pushpin, this.form.value);
                        this.saveData(noRouting);
                    }, () => {
                        Object.assign(this.pushpin, this.form.value);
                        this.saveData(noRouting);
                    });
                } else {
                    Object.assign(this.pushpin, this.form.value);
                    this.saveData(noRouting);
                }
            } else {
                this.confirmDialogService.confirm(
                    'Geen markeringsitems toegevoegd',
                    `Er zijn geen markeringsitem toegevoegd, wilt u toch opslaan?`,
                    'Opslaan',
                    'Verwijderen'
                ).then(() => {
                    Object.assign(this.pushpin, this.form.value);
                    this.saveData(noRouting);
                }, () => {
                    this.pushpinService.deletePushpin(this.pushpin.id, this.pushpin.customer_area_id).then((result) => {
                        this.pushpinChanged.emit(true);
                        this.backToMapOrList(
                            this.pushpin.lat,
                            this.pushpin.lng
                        );

                    });
                });
            }
        } else if (!this.deviceHasInternet) {
            this.confirmDialogService.confirm(
                'Geen Internet',
                'Het apparaat heeft momenteel geen internet, de gegevens kunnen niet worden opgeslagen.',
                'Wachten',
                'Wijzigingen verwerpen'
            ).then(() => {

            }, () => {
                this.pushpin.pushpin_parts.forEach(pushpinpart => {
                    pushpinpart['changed'] = false;
                });
                this.backToMapOrList(
                    this.pushpin.lat,
                    this.pushpin.lng
                );
            });
        }
    }

    takePicture() {
        this.cameraService.getPicture().then(pictureUrl => {
            if (!Array.isArray(this.activePushpinPart.images)) {
                this.activePushpinPart.images = [];
            }
            this.addImage(pictureUrl);
        }, error => {
            console.error(error);
        });
    }

    openPushpinPart(pushpinPart: PushpinPart) {
        const newPushpinPartId = pushpinPart ? pushpinPart.id : null;
        const activePushpinPartId = this.activePushpinPart ? this.activePushpinPart.id : null;
        const newUrl = this.location.path().replace('pushpinPartId=' + activePushpinPartId, 'pushpinPartId=' + newPushpinPartId);
        this.openSideBar = false;
        if (this.activePushpinPart) {
            this.assignPushpinPartFromData(this.activePushpinPart);
        }
        this.activePushpinPart = pushpinPart;
        this.location.go(newUrl);
        this.setBackbuttonAction();
    }

    assignPushpinPartFromData(pushpinPart: PushpinPart) {
        const formGroup = this.forms.get(pushpinPart);
        const oldPpP = JSON.stringify(pushpinPart);
        Object.assign(pushpinPart, formGroup.value);
        const newPpP = JSON.stringify(pushpinPart);
        if (oldPpP !== newPpP && !pushpinPart['changed']) {
            pushpinPart['changed'] = true;
        }
    }

    allFormsValid(): boolean {
        let allValid = true;
        Utils.triggerValidation(this.form);

        this.forms.forEach((formGroup: FormGroup, pushpinPart: PushpinPart) => {
            Utils.triggerValidation(formGroup);
            if (formGroup.valid) {
                const oldPpP = JSON.stringify(pushpinPart);
                Object.assign(pushpinPart, formGroup.value);
                const newPpP = JSON.stringify(pushpinPart);
                if (oldPpP !== newPpP && !pushpinPart['changed']) {
                    pushpinPart['changed'] = true;
                }
            }
            if (!formGroup.valid && !formGroup.disabled) {
                pushpinPart['invalid'] = true;
                // Only open when this is the first invalid subform
                if (allValid) {
                    this.openPushpinPart(pushpinPart);
                }
            } else {
                pushpinPart['invalid'] = false;
            }
            if (allValid && !formGroup.disabled) {
                allValid = formGroup.valid;
            }
        });
        return allValid;
    }

    createNewPushpinPart() {
        if (!Array.isArray(this.pushpin.pushpin_parts)) {
            this.pushpin.pushpin_parts = [];
        }
        const dialogRef = this.dialog.open(PushpinPartCreationWizardComponent, {
            panelClass: 'part-dialog',
            data: {
                lengthmark: this.pushpin.lengthmark,
                customerArea: this.customerArea
            }
        });
        this.cordovaService.setBackbuttonAction(() => {
            dialogRef.close();
        });
        const subs = dialogRef.afterClosed().subscribe((pushpinPart: PushpinPart) => {
            if (pushpinPart) {
                const openPushpin = () => {
                    if (this.pushpin.snappedpoints && typeof this.pushpin.snappedpoints !== 'undefined') {
                        pushpinPart.length = Math.round(this.pushpin.snappedpoints.totalLength);
                    }
                    if (pushpinPart.inspection.inspection_status_id === 5) {
                        pushpinPart.status_id = 4;
                    }
                    this.pushpin.pushpin_parts = this.pushpin.pushpin_parts.concat([pushpinPart]);
                    this.openPushpinPart(pushpinPart);
                };

                pushpinPart['changed'] = true;
                if (this.inspection) {
                    pushpinPart.inspection = this.inspection;
                    pushpinPart.inspection_id = +this.inspection.id;
                    openPushpin();
                } else if (this.pushpin.pushpin_parts?.length > 0) {
                    pushpinPart.inspection = this.pushpin.pushpin_parts[0].inspection;
                    pushpinPart.inspection_id = this.pushpin.pushpin_parts[0].inspection_id;
                    openPushpin();
                } else {
                    const chooseDialogRef = this.dialog.open(ChooseInspectionDialogComponent, {
                        panelClass: 'pushpin-dialog',
                        data: this.customerArea.inspections,
                        disableClose: true
                    });
                    const sub = chooseDialogRef.afterClosed().subscribe(inspection => {
                        if (inspection) {
                            pushpinPart.inspection = inspection;
                            pushpinPart.inspection_id = inspection.id;
                            openPushpin();
                        }
                        sub.unsubscribe();
                    });
                }
            } else {
                this.cordovaService.setBackbuttonAction(() => {
                    this.setBackbuttonAction();
                });
            }
            subs.unsubscribe();
        });
    }

    openPushpinPartLog(pushpinPart: PushpinPart) {
        const dialogRef = this.dialog.open(PushpinPartHistoryComponent, {
            panelClass: 'pushpinpart-log-dialog',
            data: pushpinPart
        });
        this.cordovaService.setBackbuttonAction(() => {
            dialogRef.close();
        });
        const subs = dialogRef.afterClosed().subscribe(() => {
            this.cordovaService.setBackbuttonAction(() => {
                this.setBackbuttonAction();
            });
            subs.unsubscribe();
        });
    }

    restorePushpinPart(pushpinPart) {
        this.confirmDialogService.confirm(
            'Verwijderd markeringsitem terugzetten?',
            `Weet je zeker dat je dit verwijderde markeringsitem wilt herstellen?`,
            'ja', 'nee').then(() => {
            pushpinPart.deleted_at = null;
            pushpinPart['changed'] = true;
            this.pushpinPartDetailComponent.disableSomeFieldsIfConsumerOrPreformedThermo();
        }, () => {
        });
    }

    removePushpinPart(pushpinPart) {
        this.confirmDialogService.confirm(
            'Markeringsitem verwijderen?',
            `Weet je zeker dat je dit markeringsitem wilt verwijderen?`,
            'ja', 'nee').then(() => {
            pushpinPart['changed'] = true;
            if (!this.AuthorisationService.hasFeature('showDeletedPushpins')) {
                this.activePushpinPart = null;
                const index = this.pushpin.pushpin_parts.indexOf(pushpinPart);
                this.forms.delete(pushpinPart);
                this.pushpin.pushpin_parts.splice(index, 1);
            } else {
                pushpinPart.deleted_at = new Date();
                this.pushpinPartDetailComponent.disableSomeFieldsIfConsumerOrPreformedThermo();
            }
        }, () => {
        });
    }

    onNoClick(): void {
        if (this.openChanges()) {
            this.confirmDialogService.confirm(
                'Niet opgeslagen wijzigingen - B',
                `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                'Wijzigingen opslaan',
                'Wijzigingen verwerpen').then(() => {
                this.save();
            }, () => {
                this.pushpin.pushpin_parts.forEach(pushpinpart => {
                    pushpinpart['changed'] = false;
                });
                this.backToMapOrList(
                    this.activeRoute.snapshot.params['lat'],
                    this.activeRoute.snapshot.params['lng']
                );
            });
        } else {
            this.backToMapOrList(
                this.activeRoute.snapshot.params['lat'],
                this.activeRoute.snapshot.params['lng']
            );
        }
    }

    backToPushpinLocation() {
        this.backToList = false;
        this.zoom = 22;
        this.backToMapOrList(
            this.pushpin.lat,
            this.pushpin.lng
        );
    }

    private backToMapOrList(lat, lng) {
        this.router.navigate([
            this.backToList ? 'pushpins/list' : 'pushpins/map',
            this.pushpin.customer_area_id,
            this.zoom,
            lat,
            lng,
            {inspection: this.inspection?.id}
        ]);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();

        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate([`/`]);
        });
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    private addImage(pictureUrl: string) {
        const image = new PushpinImage();
        image.image = pictureUrl;
        image.pushpin_part_id = this.activePushpinPart.id;
        image.status_id = this.activePushpinPart.status_id;
        image.status = new Status();
        Object.assign(image.status, this.activePushpinPart.status);
        this.activePushpinPart['changed'] = true;
        if (typeof this.activePushpinPart.images === 'undefined') {
            this.activePushpinPart.images = [];
        }
        this.activePushpinPart.images.push(image);
    }

    private openChanges() {
        const now = (new Date()).getTime();
        // Don't show message again within 3000 ms = 3s
        if ((now - this.lastTimeOpenChangesCheck) < 3000) {
            console.log('skipping check because too short', (now - this.lastTimeOpenChangesCheck));
            return false;
        } else {
            console.log('long enough ago', (now - this.lastTimeOpenChangesCheck));
        }
        this.lastTimeOpenChangesCheck = now;

        if (!this.pushpin) {
            return false;
        }
        this.allFormsValid();
        return this.pushpin.pushpin_parts.some(pushpinPart => pushpinPart['changed']);
    }

    private saveData(noRouting = false) {
        this.saving = true;
        this.pushpinService.savePushpin(this.pushpin).then(pushpin => {
            this.pushpin = pushpin;
            this.pushpinChanged.emit(true);
            this.saving = false;
            if (!noRouting) {
                this.backToMapOrList(pushpin.lat, pushpin.lng);
            }
        }, error => {
            console.warn(error);
            if (error['message']['error']['error'] === 'UPDATED_AT_TO_OLD') {
                if (typeof error['message']['error']['data']['old'] !== 'undefined') {
                    const oldPushpinPart: PushpinPart = error['message']['error']['data']['old'];
                    const newPushpinPart: PushpinPart = error['message']['error']['data']['new'];
                    if (oldPushpinPart && newPushpinPart) {
                        const currentPushpin = this.pushpin.pushpin_parts.filter((pushpinpart) => {
                            return pushpinpart.id === newPushpinPart.id;
                        })[0];
                        if (typeof currentPushpin['changed'] !== 'undefined' && currentPushpin['changed']) {
                            this.confirmDialogService.confirm(
                                'Gelijktijdige wijziging in markeringsitem',
                                `Het markeringsitem "${oldPushpinPart.decoration.decoration_group},
                            ${this.materialName(oldPushpinPart.material_id)} ${oldPushpinPart.decoration.name}"
                            is al aangepast door een andere gebruiker, wil je dit markeringsitem als kopie opslaan?`,
                                'Als kopie opslaan', 'Mijn wijzigingen Annuleren'
                            ).then(() => {
                                this.activePushpinPart = null;
                                Object.assign(currentPushpin, newPushpinPart);
                                oldPushpinPart.id = null;
                                this.pushpin.pushpin_parts.push(oldPushpinPart);
                                currentPushpin['changed'] = false;
                                this.saveData();
                            }, () => {
                                this.activePushpinPart = null;
                                Object.assign(currentPushpin, newPushpinPart);
                                currentPushpin['changed'] = false;
                                this.saveData();
                            });
                        } else {
                            this.activePushpinPart = null;
                            Object.assign(currentPushpin, newPushpinPart);
                            this.saveData();
                        }
                    }
                    this.saving = false;
                } else {
                    this.saving = false;
                    this.confirmDialogService.confirm(
                        'Conflict in Punaise',
                        'De punaise is al geupdate door een andere gebruiker.',
                        'Oké', null
                    ).then(() => {

                    });
                }
            } else {
                this.saving = false;
                console.error(error);
            }
        });
    }

    setBackbuttonAction() {
        if (this.activePushpinPart) {
            this.cordovaService.setBackbuttonAction(() => {
                this.openPushpinPart(null);
            });
        } else {
            this.cordovaService.setBackbuttonAction(() => {
                this.onNoClick();
            });
        }
    }

    private uploadFiles(files: File[]) {
        this.uploading = true;
        const uploadedFiles = [];
        const url = `${Settings.API_ENDPOINT}images`;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const uploadFile = new UploadFile();
                uploadFile.customer_area_id = this.customerArea.id;
                uploadFile.filesize = Math.round(files[i].size / 1000);
                uploadFile.file = files[i].name;
                uploadFile.ext = files[i].type;
                if (['jpg', 'jpeg', 'image/jpeg'].indexOf(uploadFile.ext.toLowerCase()) !== -1 && files[i].size < 110664300) {
                    this.makeFileRequest(url, files[i], uploadFile).subscribe(data => {
                        if (data['success']) {
                            this.addImage(data['image']);
                            uploadFile['uploading'] = null;
                            uploadFile.ext = data['file']['ext'];
                        } else {
                            let error = 'Een onbekende fout';
                            if (typeof data['error'] === 'object' && Array.isArray(data['error']['upload'])) {
                                error = data['error']['upload'][0];
                            } else if (typeof data['error'] === 'string') {
                                error = data['error'];
                            }
                            this.confirmDialogService.confirm(
                                'Er is iets fout gegaan met uploaden',
                                error,
                                'Oké', null);
                        }
                        uploadedFiles.push(uploadFile);
                        if (uploadedFiles.length === files.length) {
                            this.uploading = false;
                        }
                    }, error => {
                        this.confirmDialogService.confirm(
                            'Er is iets fout gegaan met uploaden',
                            'Een onbekende fout',
                            'Oké', null);
                        this.uploading = false;
                    });
                } else if (['jpg', 'jpeg', 'image/jpeg'].indexOf(uploadFile.ext) === -1) {
                    this.uploading = false;
                    this.confirmDialogService.confirm(
                        'U kunt alleen afbeeldingen uploaden',
                        'U kunt alleen JPG afbeeldingen uploaden',
                        'Oké', null);
                } else {
                    this.uploading = false;
                    this.confirmDialogService.confirm(
                        'Het bestand is te groot',
                        'Het bestand is groter dan 100Mb',
                        'Oké', null);
                }
            }
        }
    }

    private makeFileRequest(url: string, file: File, uploadFile: UploadFile): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            formData.append('customer_area_id', this.customerArea.id + '');
            formData.append('pushpinPartID', this.activePushpinPart.id + '');

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                uploadFile['uploading'] = Math.round(event.loaded / event.total * 100);

            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }

}
