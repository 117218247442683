<app-vvr-icon [dark]="false" [full]="false" [overlay]="false" [shadow]="true"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Gebruikers</h1>
        </div>
        <div class="controls">
            <div class="fab small" id="btn-Exit" matTooltip="Terug naar overzicht"
                 matTooltipPosition="below"
                 routerLink="/management">
                <i class="fas fa-sign-out-alt"></i>
            </div>
            <div *ngIf="AuthorisationService.hasFeature('authFeatures')" class="fab small" id="btn-Roles"
                 matTooltip="Rechten matrix"
                 matTooltipPosition="below"
                 routerLink="/roles">
                <i class="fas fa-receipt"></i>
            </div>
            <div (click)="toExcel()" class="fab small"
                 matTooltip="Exporteren naar Excel"
                 matTooltipPosition="below">
                <i [class.fa-file-excel]="!exporting" [class.fa-spin]="exporting" [class.fa-spinner]="exporting" class="fas"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-4">
            <mat-form-field class="mr-2 w-100">
                <mat-label>
                    Zoeken
                </mat-label>
                <input (keyup)="searching.emit()"
                       (keyup.enter)="openIfOne()"
                       [(ngModel)]="searchText"
                       autocomplete="off"
                       matInput>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th (click)="sortList('name')">
                        Naam <i [class.fa-sort-down]="!desc && currentSort === 'name'"
                                [class.fa-sort-up]="desc && currentSort === 'name'"
                                [class.fa-sort]="currentSort !== 'name'"
                                class="fas"></i>
                    </th>
                    <th (click)="sortList('email')">
                        Email <i [class.fa-sort-down]="!desc && currentSort === 'email'"
                                 [class.fa-sort-up]="desc && currentSort === 'email'"
                                 [class.fa-sort]="currentSort !== 'email'"
                                 class="fas"></i>
                    </th>
                    <th>
                        Rollen
                    </th>
                    <th matTooltip="Tweestapsverificatie">
                        2FA
                    </th>
                    <th>
                        Gebieden
                    </th>
                    <th>

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of filteredUsers" class="curpoint" routerLink="/users/{{user.id}}">
                    <td>
                        {{user.name}}
                    </td>
                    <td>
                        {{user.email}}
                    </td>
                    <td>
                        <span *ngFor="let role of user.roles; let last = last">
                            {{role.name}}{{last ? '' : ', '}}
                        </span>
                    </td>
                    <td>
                        {{user.hasOtp ? 'Ja' : ''}}
                    </td>
                    <td>
                        <div style="line-height: 1.05" *ngFor="let ca of user?.customer_areas | slice : 0 : 3">
                            <small>
                                {{ca.name}}
                            </small><br>
                        </div>
                        <small class="red" *ngIf="(user?.customer_areas | slice : 3).length">
                        En {{(user?.customer_areas | slice : 3).length}} meer...
                        </small>
                    </td>
                    <td class="pt-0 pb-0">
                        <button (click)="deleteUser($event, user)" color="warn" mat-icon-button><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<button class="add-floating-button" color="primary" mat-fab
        routerLink="/users/new">
    <mat-icon class="fas fa-plus"></mat-icon>
</button>
