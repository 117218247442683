<app-vvr-icon [full]="true" [dark]="true" [overlay]="true" [shadow]="false"></app-vvr-icon>
<div class="login-background">
    <form [formGroup]="loginForm" class="login-form">
        <h1 class="h2 condensed">Inloggen</h1>
        <mat-form-field appearance="fill" id="email">
            <mat-label>E-mailadres</mat-label>
            <input matInput type="text" formControlName="email" name="email"
                   placeholder="Vul hier uw e-mailadres in">
            <mat-error *ngIf="loginForm.invalid">Vul een correct e-mailadres in</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" id="password">
            <mat-label>Wachtwoord</mat-label>
            <input matInput type="password" formControlName="password" name="password"
                   placeholder="Wat is uw wachtwoord?">
            <mat-error *ngIf="loginForm.invalid">Vul uw wachtwoord in</mat-error>
        </mat-form-field>

        <div class="login-buttons">


            <button mat-raised-button id="btn-login" color="primary" [disabled]="loginForm.invalid"
                    (click)="login()">
                <ng-container *ngIf="loginFormState == LoginFormState.FillIn">
                    Inloggen
                </ng-container>
                <ng-container *ngIf="loginFormState == LoginFormState.Login">
                    Aan het inloggen...
                </ng-container>
            </button>
            <button mat-button id="btn-lost-password" class="mr-2" (click)="forgotPassword()">Wachtwoord
                vergeten
            </button>
        </div>

        <div id="errorMessage" *ngIf="errorMessage">
            <i class="fa fa-warning"></i>{{errorMessage}}
        </div>

        <div class="links">
            <h1 class="h5 condensed">Kennismaken met onze app?</h1>
            <img class="float-right" width="90" src="/assets/logos/VVR_b-rood.svg"/>
            <a href="tel:+31172619238" target="_BLANK">
                <i class="fa fa-phone float-left"></i> 0172 61 92 38</a>
            <a href="mailto:vanvelsen@vvr.nl" target="_BLANK">
                <i class="fa fa-envelope float-left"></i> vanvelsen@vvr.nl</a>
            <a href="https://vvr.nl" target="_BLANK">
                <i class="fa fa-globe float-left"></i> vvr.nl</a>
        </div>

    </form>
</div>
