import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {User} from '../../classes/user.class';
import {formatDate} from '@angular/common';
import {Settings} from '../../settings.class';
import {UploadFile} from '../../import/upload-file';
import {LocalStorage} from '../../storage.class';
import {RepairFile} from '../../repairs/edit-repair/edit-repair.component';
import {CameraService} from '../../camera.service';
import {PushpinPartImageViewerDialogComponent} from '../../pushpin-detail/pushpin-part-image-viewer-dialog/pushpin-part-image-viewer-dialog.component';
import {CordovaService} from '../../cordova.service';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../../utils.class';
import {Routenames} from '../../route-names.enum';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {requiredConditional} from '../../validators/required-conditional.validator';
import {DeclarationType} from '../../declaration-type';
import {DeclarationStatus} from '../../declaration-status';
import {DeclarationService} from '../../services/declaration.service';
import {Declaration} from '../../declaration';
import {DeclarationDistance} from '../../declaration-distance';
import {debounceTime, startWith} from 'rxjs/operators';

declare var cordova;

@Component({
    selector: 'app-declaration-request-detail',
    templateUrl: './declaration-request-detail.component.html',
    styleUrls: ['./declaration-request-detail.component.scss']
})
export class DeclarationRequestDetailComponent extends CodaltComponent implements OnInit, OnDestroy {

    @ViewChild('upload') uploadInput;
    @ViewChild('scrollviewport') upload;

    saving = false;
    uploading = false;
    isCordova = false;

    form: FormGroup<ControlsOf<FormGroupDeclaration>>;

    request: Declaration;

    declarationType: DeclarationType;
    declarationTypes: DeclarationType[];
    statusList: DeclarationStatus[];
    managerList: User[];

    url = Settings.API_ENDPOINT + 'file/';
    token = LocalStorage.getUserToken();

    maxDate = new Date();

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private cameraService: CameraService,
                private cordovaService: CordovaService,
                protected confirmDialogService: ConfirmDialogService,
                private authenticationService: AuthenticationService,
                private declarationService: DeclarationService) {
        super();
    }

    ngOnInit(): void {
        this.isCordova = typeof cordova !== 'undefined';
        this.subscriptions.add(this.authenticationService.onLogin.subscribe(() => {
            this.token = LocalStorage.getUserToken();
        }));


        this.subscriptions.add(this.route.params.subscribe(params => {
            this.subscriptions.add(combineLatest([
                this.declarationService.getRequest(params['id']),
                this.declarationService.listType()
            ]).subscribe(([request, types]) => {
                this.request = request.data || new Declaration();
                this.declarationTypes = types.data;
                if (this.request.type_id && !this.declarationTypes.find(e => e.id === this.request.type_id)) {
                    this.declarationTypes.push(this.request.type);
                }
                this.form = new FormGroup<ControlsOf<FormGroupDeclaration>>({
                    date: new FormControl(this.request.date || new Date(), Validators.required),
                    status_id: new FormControl(this.request.status_id || 1, Validators.required),
                    type_id: new FormControl(this.request.type_id, Validators.required),
                    manager_id: new FormControl(this.request.manager_id, Validators.required),
                    comment: new FormControl(this.request.comment, Validators.required),
                    reason: new FormControl(this.request.reason, requiredConditional(() => this.form?.controls?.status_id?.value === 3)),
                    amount: new FormControl(this.request.amount, [Validators.required, Validators.max(5000.00)]),
                    declaration_distances: new FormArray<FormGroupDeclarationDistance>([])
                });
                this.subscriptions.add(this.form.controls.type_id
                    .valueChanges.pipe(startWith(this.request.type_id)).subscribe(type_id => {
                        this.declarationType = this.declarationTypes?.find(t => t.id === type_id);
                        if (this.declarationType?.distance || this.declarationType?.unit_price) {
                            this.form.controls.amount.disable();
                        } else {
                            this.form.controls.amount.enable();
                        }
                        if (!this.declarationType?.distance && this.declarationType?.unit_price) {
                            this.form.controls.amount.setValue(this.declarationType.unit_price);
                        }

                        if (this.declarationType?.distance) {
                            this.form.controls.declaration_distances.setValidators(Validators.required);
                        } else {
                            this.form.controls.declaration_distances.setValidators([]);
                        }
                    }));
                this.subscriptions.add(this.form.controls.declaration_distances
                    .valueChanges.pipe(debounceTime(250)).subscribe(distance => {
                        if (this.declarationType?.distance) {
                            const totalDist = this.form.controls.declaration_distances.value.map(d => d.distance || 0).reduce((p, c) => p + c, 0);
                            this.form.controls.amount.setValue(totalDist * this.declarationType.unit_price);
                        }
                    }));
                this.request.declaration_distances?.forEach(distance => {
                    this.addDistance(distance);
                });
                if (!this.request?.id) {
                    this.form?.controls?.status_id.disable();
                }
                if (!AuthorisationService.hasFeature('declarationManagement')) {
                    this.form?.controls?.status_id.disable();
                    if (this.request?.id) {
                        this.form?.controls?.type_id.disable();
                        this.form?.controls?.date.disable();
                    }
                    if (this.request.status_id > 1) {
                        this.form?.controls?.manager_id.disable();
                        this.form?.controls?.comment.disable();
                        this.form?.controls?.reason.disable();
                        this.form?.controls?.declaration_distances.disable();
                    }

                }


            }));
        }));


        this.subscriptions.add(this.declarationService.statusList().subscribe(status => {
            this.statusList = status.data;
        }));
        this.subscriptions.add(this.declarationService.managerList().subscribe(managers => {
            this.managerList = managers.data;
        }));
    }

    addDistance(distance?: DeclarationDistance) {
        const prevItem = this.form.controls.declaration_distances.value[this.form.controls.declaration_distances.value.length - 1];
        const date = new Date((distance?.date || prevItem?.date || this.form.controls.date.value) as any);
        if (prevItem) {
            date.setDate(date.getDate() + 1);
        }
        const fg = new FormGroup<ControlsOf<FormGroupDeclarationDistance>>({
            id: new FormControl(distance?.id),
            date: new FormControl(date, Validators.required),
            distance: new FormControl(distance?.distance, Validators.required),
            to: new FormControl(distance?.to, Validators.required),
            from: new FormControl(distance?.from, Validators.required)
        });
        this.form.controls.declaration_distances.push(fg);
    }

    removeDistance(distance) {
        this.confirmDialogService.confirm('Rit verwijderen',
            'Weet je zeker dat je deze rit wilt verwijderen?',
            'Verwijderen',
            'behouden').then(() => {
            this.form.controls.declaration_distances.remove(distance.value);
        });
    }

    save() {
        if (!this.request.images?.length && this.form.controls.status_id.value === 1) {
            this.confirmDialog.confirm(
                'Geen foto toegevoegd',
                `Er is geen foto toegevoegd. Wilt u alsnog een foto toevoegen?`,
                'Ja, toevoegen',
                'Nee, opslaan').then(() => {
                this.takePicture();
            }, () => {
                this.saveRequest();
            });
        } else {
            this.saveRequest();
        }
    }

    delete() {
        this.confirmDialog.confirm(
            'Aanvraag intrekken',
            `Weet u zeker dat u deze aanvraag wilt intrekken?`,
            'Intrekken',
            'Behouden'
        ).then(() => {
            this.declarationService.deleteRequest(this.request.id).subscribe(() => {
                this.router.navigate([Routenames.declarationRequest]);
            });
        }, () => {

        });
    }

    public uploadFile(event) {
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    takePicture() {
        if (this.isCordova) {
            this.cameraService.getPicture().then(pictureUrl => {
                this.addFile(pictureUrl, formatDate(new Date(), 'dd-mm-yyyy hh:mm:ss', 'nl'), 'jpg');
            }, error => {
                console.error(error);
            });
        } else {
            this.uploadInput.nativeElement.click();
        }
    }

    addFile(fileUrl, name, ext, object?: Declaration) {
        if (!object) {
            object = this.request;
        }
        if (!Array.isArray(object.images)) {
            object.images = [];
        }
        const file = new RepairFile();
        file.name = name;
        file.file = fileUrl;
        file.ext = ext;
        object.images.push(file);
        if (typeof object['id'] !== 'undefined') {
            this.declarationService.saveRequest(object as Declaration).subscribe();
        }
    }

    openFile(clickedImage, allFiles) {
        if (['jpg', 'jpeg', 'png'].indexOf(clickedImage.ext) !== -1) {
            const images = allFiles
                .filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1)
                .map(f => (f.file.substr(0, 4) !== 'http' ? this.url : '') + f.file + '?access_token=' + this.token);
            const dialogRef = this.dialog.open(PushpinPartImageViewerDialogComponent, {
                panelClass: 'image-viewer-dialog',
                data: {
                    images: images,
                    viewIndex: allFiles.filter(f => ['jpg', 'jpeg', 'png'].indexOf(f.ext) !== -1).indexOf(clickedImage)
                }
            });
            this.cordovaService.setBackbuttonAction(() => {
                dialogRef.close();
            });
            const subs = dialogRef.afterClosed().subscribe(() => {
                this.cordovaService.setBackbuttonAction(() => {
                    this.router.navigateByUrl('/repairs');
                });
                subs.unsubscribe();
            });
        } else {
            window.open(this.url + clickedImage.file + '?access_token=' + this.token);
        }
    }

    private saveRequest() {
        Utils.triggerValidationP(this.form).then(() => {
            this.request.date = this.form?.controls?.date.value;
            this.request.amount = this.form?.controls?.amount.value;
            this.request.comment = this.form?.controls?.comment.value;
            this.request.reason = this.form?.controls?.reason.value;
            this.request.status_id = this.form?.controls?.status_id.value;
            this.request.type_id = this.form?.controls?.type_id.value;
            this.request.manager_id = this.form?.controls?.manager_id.value;
            this.request.declaration_distances = this.form.controls.declaration_distances.value as any as DeclarationDistance[];
            this.subscriptions.add(this.declarationService.saveRequest(this.request).subscribe(() => {
                this.router.navigate([Routenames.declarationRequest]);
            }, error => {

            }));
        }, () => {
            this.form.controls.declaration_distances.markAllAsTouched();
        });
    }

    private uploadFiles(files: File[], object?: Declaration) {
        this.uploading = true;
        const uploadedFiles = [];
        const url = `${Settings.API_ENDPOINT}images`;
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const uploadFile = new UploadFile();
                uploadFile.filesize = Math.round(files[i].size / 1000);
                uploadFile.file = files[i].name;
                uploadFile.ext = files[i].type;
                const exts = ['jpg', 'jpeg', 'image/jpeg', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'image/png', 'png', 'application/pdf',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword'];
                if (exts.indexOf(uploadFile.ext.toLowerCase()) !== -1 && files[i].size < 110664300) {
                    this.makeFileRequest(url, files[i], uploadFile).subscribe(data => {
                        if (data['success']) {
                            this.addFile(data['file'], data['name'], data['ext'], object);
                            uploadFile['uploading'] = null;
                            uploadFile.ext = data['ext'];
                        } else {
                            let error = 'Een onbekende fout';
                            if (typeof data['error'] === 'object' && Array.isArray(data['error']['upload'])) {
                                error = data['error']['upload'][0];
                            } else if (typeof data['error'] === 'string') {
                                error = data['error'];
                            }
                            this.confirmDialog.confirm(
                                'Er is iets fout gegaan met uploaden',
                                error,
                                'Oké', null);
                        }
                        uploadedFiles.push(uploadFile);
                        if (uploadedFiles.length === files.length) {
                            this.uploading = false;
                        }
                    }, error => {
                        this.confirmDialog.confirm(
                            'Er is iets fout gegaan met uploaden',
                            'Een onbekende fout',
                            'Oké', null);
                        this.uploading = false;
                    });
                } else if (exts.indexOf(uploadFile.ext) === -1) {
                    this.uploading = false;
                    this.confirmDialog.confirm(
                        'U kunt alleen documenten uploaden',
                        'U kunt alleen jpg, doc(x), xls(x) en pdf bestanden uploaden',
                        'Oké', null);
                } else {
                    this.uploading = false;
                    this.confirmDialog.confirm(
                        'Het bestand is te groot',
                        'Het bestand is groter dan 100Mb',
                        'Oké', null);
                }
            }
        }
    }

    private makeFileRequest(url: string, file: File, uploadFile: UploadFile): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);
            formData.append('type', 'file');

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                uploadFile['uploading'] = Math.round(event.loaded / event.total * 100);

            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}

export interface FormGroupDeclaration {
    date: FormControl<Date>;
    reason: string;
    comment: string;
    amount: number;
    manager_id: number;
    type_id: number;
    status_id: number;
    declaration_distances: FormGroupDeclarationDistance[];
}

export interface FormGroupDeclarationDistance {
    id: number;
    date: FormControl<Date>;
    from: string;
    to: string;
    distance: number;
}
