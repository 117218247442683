<div class="mapandlistcontainer"
     [class.map-container]="view === 'map'"
     [class.show]="view === 'map' || view === 'list'">
    <div class="filterholder">

        <app-pushpin-selection
            (updatePushpinsOnMap)="reloadPushpins()"
            *ngIf="showPriceSelectionBar"
            (chooseInspection)="chooseInspection(customerArea)"
            #selection></app-pushpin-selection>
        <app-pushpin-move
            [customerArea]="customerArea"
            (pushpinsMoved)="reloadPushpins()"
            #move>
        </app-pushpin-move>

        <app-pushpin-map
            (addExtraCost)="selectionComponent.editExtraCost()"
            (addTailCharge)="selectionComponent.editTailCharge()"
            (pushpinCreated)="reloadPushpins()"
            (chooseInspection)="chooseInspection(customerArea)"
            [class.show]="view === 'map'"
            [filterComponent]="filterComponent"
            [manualAreasComponent]="manualAreasComponent"
            [pushpinMoveComponent]="pushpinMoveComponent"
            #pushpinMap [view]="view" [selectionComponent]="selectionComponent"></app-pushpin-map>


        <app-pushpin-list
            [class.show]="view === 'list'"
            [selectionComponent]="selectionComponent"
            [filterComponent]="filterComponent" #pushpinList></app-pushpin-list>

        <app-pushpin-filter #filter></app-pushpin-filter>
        <app-manual-areas #manualAreas></app-manual-areas>
    </div>
</div>
<div class="detail" [class.show]="view === 'map-detail' || view === 'list-detail'">
    <app-pushpin-detail #pushpinDetail
                        (pushpinChanged)="reloadPushpins()"
                        [customerArea]="customerArea"
                        [inspection]="inspection"
                        *ngIf="view === 'map-detail' || view === 'list-detail'"></app-pushpin-detail>
</div>
