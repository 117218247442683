import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {ManualArea} from '../../classes/manualarea.class';
import {AngularBingMapsComponent} from '../pushpin-map/angular-bing-maps/angular-bing-maps.component';
import {Region} from '../pushpin-map/angular-bing-maps/region.class';
import {Pushpin} from '../../classes/pushpin.class';
import {CustomerArea} from '../../classes/customerarea.class';
import {Inspection} from '../../classes/inspection';
import {InspectionService} from '../../services/inspection.service';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {PushpinPart} from '../../classes/pushpinpart.class';

@Component({
    selector: 'app-pushpin-move',
    templateUrl: './pushpin-move.component.html',
    styleUrls: ['./pushpin-move.component.scss']
})
export class PushpinMoveComponent implements OnInit {

    @HostBinding('class')
    classList = '';

    visible = false;

    @Input()
    customerArea!: CustomerArea;

    @Input()
    mapComponent: AngularBingMapsComponent;

    @Output()
    pushpinsMoved = new EventEmitter();

    pushpins: Pushpin[];
    selectionArea: ManualArea;
    selectedPushpins: Pushpin[];
    selectedPushpinParts: PushpinPart[];
    moveToInspection: Inspection;
    possibleInspectionIds: number[];

    moving = false;
    copying = false;

    inspections: Inspection[];

    constructor(private inspectionService: InspectionService,
                private confirmDialogService: ConfirmDialogService) {
    }

    ngOnInit(): void {
    }

    toggle() {
        this.visible = !this.visible;
        this.classList = this.visible ? 'visible' : '';
        this.inspections = this.customerArea.inspections;
    }

    inspectionChanged() {
        this.possibleInspectionIds = this.customerArea.inspections
            .filter(i => i.inspection_type_id === this.moveToInspection.inspection_type_id)
            .map(i => i.id);
        this.getIncludedParts();
    }

    clearSelection() {
        this.selectedPushpins = null;
        this.selectedPushpinParts = null;
        this.selectionArea = null;
        this.mapComponent.updateSelectionPolygons(this.selectionArea as any);
    }

    draw() {
        const manualArea = new ManualArea();
        manualArea.fillColor = 'rgba(64,90,154,0.05)';
        manualArea.name = 'selectie';
        manualArea.strokeColor = '#405a9a';
        manualArea.show_notification = false;
        manualArea.exteriorRing = [];
        manualArea['drawing'] = true;
        manualArea.isStreet = false;
        this.selectionArea = manualArea;

        this.mapComponent.updateSelectionPolygons(this.selectionArea as any);
    }

    setVisiblePushpins(pushpins: Pushpin[]) {
        this.pushpins = pushpins;
    }

    setPoint(e?: object) {
        if (e) {
            this.selectionArea.exteriorRing.push({
                lat: e ? e['lat'] : this.mapComponent.mapCenter.lat,
                lng: e ? e['lng'] : this.mapComponent.mapCenter.lng
            });
            this.mapComponent.updateSelectionPolygons(this.selectionArea as any);
        }
        this.getIncludedParts();
    }

    movePoints() {
        this.moving = true;
        this.confirmDialogService.confirm(
            'Punten in selectie verplaatsen naar schouwronde',
            `Weet u zeker dat alle ${this.selectedPushpins.length} punten in de selectie wilt verplaatsen naar schouwronde ${this.moveToInspection.name}?`,
            'Verplaatsen',
            'Annuleren').then(() => {
            this.inspectionService.changeInspectionOfParts(this.moveToInspection.id, this.selectedPushpinParts.map(p => p.id)).subscribe(() => {
                this.clearSelection();
                this.pushpinsMoved.emit();
                this.moving = false;
            }, () => {
                this.moving = false;
            });
        }, () => {
            this.moving = false;
        });
    }

    copyPoints() {
        this.copying = true;
        this.confirmDialogService.confirm(
            'Punten in selectie kopiëren naar schouwronde',
            `Weet u zeker dat alle ${this.selectedPushpins.length} punten in de selectie wilt kopiëren naar schouwronde ${this.moveToInspection.name}?`,
            'Kopieer',
            'Annuleren').then(() => {
            this.inspectionService.copyPartToInspection(this.moveToInspection.id, this.selectedPushpinParts.map(p => p.id)).subscribe(() => {
                this.clearSelection();
                this.pushpinsMoved.emit();
                this.copying = false;
            }, () => {
                this.copying = false;
            });
        }, () => {
            this.copying = false;
        });
    }

    private getIncludedParts() {
        if (this.selectionArea?.exteriorRing?.length > 2) {
            const manualRegion = new Region(this.selectionArea.exteriorRing);
            if (manualRegion) {
                this.selectedPushpins = this.pushpins?.filter(pushpin => manualRegion.insideTown({lat: pushpin.lat, lng: pushpin.lng}));
                this.selectedPushpinParts = [].concat.apply([], this.selectedPushpins.map(p => p.pushpin_parts))
                    .filter(pp => this.possibleInspectionIds.indexOf(pp.inspection_id) !== -1);
            }
        }
    }
}
