<div *ngIf="!touchDevice">
    <div class="tree" cdkDropList (cdkDropListDropped)="drop($event, libraryChapters)"
         [cdkDropListDisabled]="!editMode">
        <div *ngFor="let chapter of libraryChapters" cdkDrag>
            <div class="title" (click)="chapter['expanded'] = !chapter['expanded']" cdkDragHandle>
                <button mat-icon-button [disabled]="chapter.sub_items.length <1">
                    <mat-icon *ngIf="chapter.sub_items.length > 0"
                              class="mat-icon-rtl-mirror fas {{chapter['expanded'] ? 'fa-chevron-down' : 'fa-chevron-right'}}">
                    </mat-icon>
                    <mat-icon *ngIf="chapter.sub_items.length < 1"
                              class="mat-icon-rtl-mirror fas fa-folder-open">
                    </mat-icon>
                </button>
                {{chapter.name}}
                <ng-container *ngIf="editMode">
                    <button mat-icon-button (click)="renameItem($event, chapter)">
                        <mat-icon class="fas fa-pencil-alt"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="addNewItem($event, chapter, true)">
                        <mat-icon class="fas fa-plus"></mat-icon>
                    </button>
                    <button color="primary" mat-icon-button (click)="deleteItem($event, chapter)">
                        <mat-icon class="fas fa-trash"></mat-icon>
                    </button>
                </ng-container>
            </div>
            <div cdkDropList (cdkDropListDropped)="drop($event, chapter.sub_items)" [cdkDropListDisabled]="!editMode">
                <div *ngFor="let subChapter of chapter.sub_items" class="tree-content" cdkDrag [class.expanded]="chapter['expanded']">
                    <div class="title" *ngIf="!subChapter.file" cdkDragHandle>
                        <button mat-icon-button [disabled]="subChapter.sub_items.length <1" (click)="subChapter['expanded'] = !subChapter['expanded']">
                            <mat-icon *ngIf="subChapter.sub_items.length >0"
                                      class="mat-icon-rtl-mirror fas {{subChapter['expanded'] ? 'fa-chevron-down' : 'fa-chevron-right'}}">
                            </mat-icon>
                            <mat-icon *ngIf="subChapter.sub_items.length <1"
                                      class="mat-icon-rtl-mirror fas fa-folder-open">
                            </mat-icon>
                        </button>
                        <div (click)="subChapter['expanded'] = !subChapter['expanded']">{{subChapter.name}}</div>
                        <ng-container *ngIf="editMode">
                            <button mat-icon-button (click)="renameItem($event, subChapter)">
                                <mat-icon class="fas fa-pencil-alt"></mat-icon>
                            </button>
                            <button mat-icon-button (click)="addNewItem($event, subChapter)">
                                <mat-icon class="fas fa-upload"></mat-icon>
                            </button>
                            <button color="primary" mat-icon-button
                                    (click)="deleteItem($event, subChapter, chapter)">
                                <mat-icon class="fas fa-trash"></mat-icon>
                            </button>
                        </ng-container>
                    </div>
                    <div class="document" *ngIf="subChapter.file">
                        <button mat-button color="primary" (click)="download(subChapter.id)"><i
                            class="far fa-file-pdf pr-2"></i>{{subChapter.name}}</button>
                        <button color="primary" mat-icon-button (click)="deleteItem($event, subChapter, chapter)"
                                *ngIf="editMode">
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                    </div>
                    <div cdkDropList (cdkDropListDropped)="drop($event, subChapter.sub_items)"
                         [cdkDropListDisabled]="!editMode">
                        <div *ngFor="let subSubChapter of subChapter.sub_items" class="tree-content" cdkDrag
                             [class.expanded]="subChapter['expanded']">
                            <div class="document" cdkDragHandle>
                                <button (click)="download(subSubChapter.id)" mat-button color="primary"><i
                                    class="far fa-file-pdf pr-2"></i>{{subSubChapter.name}}</button>
                                <ng-container *ngIf="editMode">
                                    <button mat-icon-button (click)="renameItem($event, subSubChapter)">
                                        <mat-icon class="fas fa-pencil-alt"></mat-icon>
                                    </button>
                                    <button color="primary" mat-icon-button
                                            (click)="deleteItem($event, subSubChapter, subChapter)">
                                        <mat-icon class="fas fa-trash"></mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button mat-raised-button color="primary" (click)="addNewItem($event)" *ngIf="editMode">
        Hoofdstuk toevoegen
    </button>
</div>
<div *ngIf="touchDevice">
    <div class="tree">
        <div *ngFor="let chapter of libraryChapters">
            <div class="title" (click)="chapter['expanded'] = !chapter['expanded']">
                <button mat-icon-button [disabled]="chapter.sub_items.length <1">
                    <mat-icon *ngIf="chapter.sub_items.length >0"
                              class="mat-icon-rtl-mirror fas {{chapter['expanded'] ? 'fa-chevron-down' : 'fa-chevron-right'}}">
                    </mat-icon>
                    <mat-icon *ngIf="chapter.sub_items.length <1"
                              class="mat-icon-rtl-mirror fas fa-folder-open">
                    </mat-icon>
                </button>
                {{chapter.name}}
            </div>
            <div>
                <div *ngFor="let subChapter of chapter.sub_items" class="tree-content"
                     [class.expanded]="chapter['expanded']">
                    <div class="title" *ngIf="!subChapter.file">
                        <button mat-icon-button [disabled]="subChapter.sub_items.length <1"
                                (click)="subChapter['expanded'] = !subChapter['expanded']">
                            <mat-icon *ngIf="subChapter.sub_items.length >0"
                                      class="mat-icon-rtl-mirror fas {{subChapter['expanded'] ? 'fa-chevron-down' : 'fa-chevron-right'}}">
                            </mat-icon>
                            <mat-icon *ngIf="subChapter.sub_items.length <1"
                                      class="mat-icon-rtl-mirror fas fa-folder-open">
                            </mat-icon>
                        </button>
                        <div (click)="subChapter['expanded'] = !subChapter['expanded']">{{subChapter.name}}</div>
                    </div>
                    <div class="document" *ngIf="subChapter.file">
                        <button mat-button color="primary" (click)="download(subChapter.id)"><i
                            class="far fa-file-pdf pr-2"></i>{{subChapter.name}}</button>
                    </div>
                    <div>
                        <div *ngFor="let subSubChapter of subChapter.sub_items" class="tree-content"
                             [class.expanded]="subChapter['expanded']">
                            <div class="document">
                                <button (click)="download(subSubChapter.id)" mat-button color="primary"><i
                                    class="far fa-file-pdf pr-2"></i>{{subSubChapter.name}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
