import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LibraryService} from '../../services/library/library.service';
import {Library} from '../../classes/library.class';
import {Settings} from '../../settings.class';
import {Observable} from 'rxjs';
import {LocalStorage} from '../../storage.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';

@Component({
    selector: 'app-add-chapter',
    templateUrl: './add-chapter.component.html',
    styleUrls: ['./add-chapter.component.scss']
})
export class AddChapterComponent implements OnInit {

    public uploading;
    public dragOver = false;
    public saving = false;
    public file: string;
    public fileRequired = false;
    public noFilesAllowed = false;
    public parentChapter: Library;
    public libraryChapters: Library[];

    root = false;

    public form = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.maxLength(255)])
    });

    constructor(private libraryService: LibraryService,
                private confirmDialogService: ConfirmDialogService,
                public dialogRef: MatDialogRef<AddChapterComponent>,
                @Inject(MAT_DIALOG_DATA) {parentChapter, libraryChapters, root}) {
        this.parentChapter = parentChapter;
        this.libraryChapters = libraryChapters;
        this.root = root;
    }

    @HostListener('dragover', ['$event'])
    public ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.srcElement.classList.contains('dialog-container') || evt.srcElement.classList.contains('overlay')) {
            this.dragOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragOver = false;
        const files = evt.dataTransfer.files;
        if (this.noFilesAllowed) {
            this.confirmDialogService.confirm(
                'Geen hoofdstuk',
                `Je kunt alleen bestanden uploaden onder een hoofdstuk`,
                'Oké', null);
        } else {
            this.uploadFiles(files);
        }
    }

    ngOnInit() {
        if (!this.root && this.parentChapter && this.parentChapter.parent) {
            this.fileRequired = true;
        } else if (!this.parentChapter) {
            this.noFilesAllowed = true;
        }
    }

    save() {
        this.saving = true;
        const newChapter = new Library();
        let order = Math.max(...this.libraryChapters.map(p => p.order)) + 1;
        if (this.parentChapter) {
            order = Math.max(...this.parentChapter.sub_items.map(p => p.order)) + 1
        }
        newChapter.order = order;
        newChapter.name = this.form.value['name'];
        newChapter.parent = this.parentChapter ? this.parentChapter.id : null;
        newChapter.file = this.file;
        if (this.fileRequired && !this.file) {
            this.confirmDialogService.confirm(
                'Geen bestand',
                `Je moet een bestand toevoegen`,
                'Oké', null);
        } else {
            this.libraryService.save(newChapter).then(chapter => {
                this.saving = false;
                chapter.sub_items = [];
                this.dialogRef.close(chapter);
            }, error => {
                this.confirmDialogService.confirm(
                    'Error bij opslaan',
                    `Er is iets fout gegaan bij het opslaan`,
                    'Oké', null);
            });
        }
    }

    public uploadFiles(files: File[]) {
        const url = `${Settings.API_ENDPOINT}library/upload/${this.parentChapter.id}`;
        const uploadedChapters = [];
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                this.uploading = true;
                if (files[i].size < 110664300) { // ~100MB
                    if (files[i].type !== 'application/pdf') {
                        this.uploading = false;
                        this.confirmDialogService.confirm(
                            'Alleen PDF bestanden',
                            `${files[i].name} is geen PDF bestand. Je kunt alleen PDF bestanden uploaden.`,
                            'Oké', null);
                        return;
                    }
                    this.makeFileRequest(url, files[i]).subscribe(data => {
                        if (data['success'] && files.length === 1) {
                            this.uploading = false;
                            this.file = data['file'];

                            if (!this.form.value['name']) {
                                this.form.controls['name'].setValue(this.file);
                            }
                        } else if (data['success'] && files.length > 1) {
                            const newChapter = new Library();
                            newChapter.name = data['file'];
                            newChapter.parent = this.parentChapter ? this.parentChapter.id : null;
                            newChapter.file = data['file'];
                            this.libraryService.save(newChapter).then(chapter => {
                                chapter.sub_items = [];
                                uploadedChapters.push(chapter);
                                if (uploadedChapters.length === files.length) {
                                    this.dialogRef.close(uploadedChapters);
                                }
                            }, error => {
                                this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage']}`);
                            });
                        } else {
                            this.uploading = false;
                            this.uploadErrorMessage(data['error']['upload'][0]);
                        }
                    }, error => {
                        this.uploading = false;
                        this.uploadErrorMessage(`Bestand: ${files[i].name}<br>${error['errormessage']}`);
                    });
                } else {
                    if (files.length === 1) {
                        this.uploading = false;
                    }
                    this.confirmDialogService.confirm(
                        'Bestand te groot',
                        `${files[i].name} is te groot, de maximale grootte is 100MB.`,
                        'Oké', null);
                }
            }
        }
    }

    public uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        this.uploadFiles(files);
    }

    private uploadErrorMessage(message) {
        this.confirmDialogService.confirm(
            'Er is iets foutgegaan bij het uploaden',
            `${message}`,
            'Oké', null);
    }

    private makeFileRequest(url: string, file: File): Observable<number> {
        return Observable.create(observer => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('upload', file, file.name);

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {

                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + LocalStorage.getUserToken());
            xhr.send(formData);
        });
    }

}
