<app-vvr-icon [full]="false" [dark]="false" [overlay]="false" [shadow]="false"></app-vvr-icon>
<div class="container">
    <div class="topbar">
        <div class="title">
            <h1 class="h3 condensed">Inspecties</h1>
        </div>
        <div class="controls">
            <div id="btn-Exit" class="fab small" routerLink="/"
                 matTooltip="Terug naar overzicht"
                 matTooltipPosition="below">
                <i class="fas fa-sign-out-alt"></i>
            </div>
        </div>
    </div>
    <div class="row justify-content-center mb-3">
        <div class="col-12 col-lg-11">
            <div class="pb-3">
                Gebruik de VVR app voor het invullen van inspectielijsten.
            </div>
            <div>
                <button color="primary" mat-raised-button (click)="chooseChecklist()">
                    Nieuwe inspectie
                </button>
                <mat-checkbox *ngIf="AuthorisationService.hasFeature('checklistAnswersShowAll')"
                              class="ml-3"
                              [(ngModel)]="showAll"
                              (change)="getAnswers()">
                    Toon alle inspecties
                </mat-checkbox>
            </div>
            <div class="pt-3">
                <table class="table table-striped">
                    <tr>
                        <th (click)="sortList(adTypeSortFunction)" matRipple class="curpoint">
                            <div class="d-flex">
                                Checklist
                                <app-sorting-icon class="ml-1" [currentSort]="currentSort" [desc]="desc" [property]="adTypeSortFunction"></app-sorting-icon>
                            </div>
                        </th>
                        <th (click)="sortList('worknumber')" matRipple class="curpoint">
                            <div class="d-flex">
                                Werknummer
                                <app-sorting-icon class="ml-1" [currentSort]="currentSort" [desc]="desc" property="worknumber"></app-sorting-icon>
                            </div>
                        </th>
                        <th class="d-none d-md-table-cell curpoint" (click)="sortList(foremanSortFunction)" matRipple>
                            <div class="d-flex">
                                Voorman
                                <app-sorting-icon class="ml-1" [currentSort]="currentSort" [desc]="desc" [property]="foremanSortFunction"></app-sorting-icon>
                            </div>
                        </th>
                        <th class="d-none d-md-table-cell curpoint" (click)="sortList(inspectorSortFunction)" matRipple>
                            <div class="d-flex">
                                Inspecteur
                                <app-sorting-icon class="ml-1" [currentSort]="currentSort" [desc]="desc" [property]="inspectorSortFunction"></app-sorting-icon>
                            </div>
                        </th>
                        <th (click)="sortList('created_at')" matRipple class="curpoint">
                            <div class="d-flex">
                                Datum
                                <app-sorting-icon class="ml-1" [currentSort]="currentSort" [desc]="desc" property="created_at"></app-sorting-icon>
                            </div>
                        </th>
                        <th class="trash-column" *ngIf="AuthorisationService.hasFeature('checklistAnswerDelete')">

                        </th>
                    </tr>
                    <tr *ngFor="let checklist of list" (click)="edit(checklist)" class="curpoint">
                        <td>
                            {{checklist.checklist.name}} <b>{{checklist.final ? '' : '(concept)'}}</b>
                        </td>
                        <td>
                            {{checklist.worknumber}}
                        </td>
                        <td class="d-none d-md-table-cell">
                            {{checklist.foreman?.name}}
                        </td>
                        <td class="d-none d-md-table-cell">
                            {{checklist.inspector?.name}}
                        </td>
                        <td class="d-none d-md-table-cell">
                            {{checklist.created_at | date  : 'd MMM yyyy, H:mm'}}
                        </td>
                        <td class="d-md-none">
                            {{checklist.created_at | date  : 'd MMM'}}
                        </td>
                        <td *ngIf="AuthorisationService.hasFeature('checklistAnswerDelete')">
                            <button (click)="delete($event, checklist)"
                                    *ngIf="!checklist.final"
                                    color="warn"
                                    mat-icon-button>
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
