import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(private apiService: ApiService) {
    }

    public getQuotation(inspectionId): Promise<object> {
        return this.apiService.getCall(`document/quotation`, {'inspection_id': inspectionId});
    }

    public getQuotationPdf(inspectionId) {
        return this.apiService.getBlobCall(`document/quotationpdf`, {'inspection_id': inspectionId});
    }
}
