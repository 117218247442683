import {Component, OnInit} from '@angular/core';
import {ExtraCostCountType, ExtraCostType} from '../../classes/extracosttype.class';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ExtraCostsService} from '../../services/extraCosts/extra-costs.service';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-extra-cost-detail',
    templateUrl: './extra-cost-detail.component.html',
    styleUrls: ['./extra-cost-detail.component.scss']
})
export class ExtraCostDetailComponent implements OnInit {

    extraCostType: ExtraCostType;

    form = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.min(3), Validators.max(99)]),
        unit: new FormControl('', [Validators.required, Validators.min(1), Validators.max(99)]),
        count_based_on: new FormControl(null, [Validators.required]),
        night: new FormControl(false)
    });

    constructor(private router: Router,
                private route: ActivatedRoute,
                private extraCostsService: ExtraCostsService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {

            this.extraCostsService.getList().then(extraCostTypes => {
                extraCostTypes.forEach(extraCostType => {
                    if (extraCostType.id === +params['id']) {
                        this.extraCostType = extraCostType;
                    }
                });
                if (!this.extraCostType) {
                    this.extraCostType = new ExtraCostType();
                }
                Object.entries(this.form.controls).forEach(control => {
                    if (typeof this.extraCostType[control[0]] !== 'undefined') {
                        this.form.controls[control[0]].setValue(this.extraCostType[control[0]]);
                    }
                });
            });
        });
    }

    save() {
        Utils.triggerValidationP(this.form).then(() => {
            Object.assign(this.extraCostType, this.form.value);
            this.extraCostsService.saveType(this.extraCostType).then(extraCostType => {
                this.router.navigate([`extra-costs`]);
            }, error => {

            });
        }, () => {

        });

    }

    readonly ExtraCostCountType = ExtraCostCountType;
}
