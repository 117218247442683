import {Incident} from './incident.class';

export class IncidentComment {
    id: number;
    incident_id: number;
    user_id: number;
    comment: string;
    files: Array<any>;
    created_at: Date;
    incident: Incident;
    user: any;
}