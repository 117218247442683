import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as XLSX from 'xlsx';
import {PushpinService} from "../../services/pushpin/pushpin.service";
import {CustomerArea} from "../../classes/customerarea.class";
import {PushpinFilter} from "../../pushpin-filter";
import {PricesService} from "../../services/prices/prices.service";
import {StatusService} from "../../services/status/status.service";
import {MaterialService} from "../../services/material/material.service";
import {DecorationsService} from "../../services/decorations/decorations.service";
import {PaintColorService} from "../../services/paintColor/paint-color.service";
import {PushpinPart} from "../../classes/pushpinpart.class";
import {combineLatest} from "rxjs";
import {formatDate} from "@angular/common";
import {Settings} from "../../settings.class";
import {Inspection} from '../../classes/inspection';

@Component({
    selector: 'app-pushpin-export',
    templateUrl: './pushpin-export.component.html',
    styleUrls: ['./pushpin-export.component.scss']
})
export class PushpinExportComponent implements OnInit {

    customerArea: CustomerArea;
    inspection: Inspection;
    public exporting = false;
    includeArchived = false;
    includeCancelled = false;
    private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION = '.xlsx';

    constructor(private dialogRef: MatDialogRef<PushpinExportComponent>,
                private pushpinService: PushpinService,
                private pricesService: PricesService,
                private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService,
                @Inject(MAT_DIALOG_DATA) data: {
                    customerArea: CustomerArea,
                    inspection: Inspection
                }) {
        this.inspection = data.inspection;
        this.customerArea = data.customerArea;
    }

    ngOnInit() {
    }

    export() {
        this.exporting = true;

        const status$ = this.statusService.getKeyValMap();
        const decorations$ = this.decorationsService.getKeyValMap();
        const materials$ = this.materialService.getMaterialsAsKeyValueMap(true);
        const paintColor$ = this.paintColorService.getKeyValMap();
        combineLatest([status$, decorations$, materials$, paintColor$])
            .subscribe(([status, decoration, material, paintColor]) => {
                const keyValMap = {};
                keyValMap['status_id'] = status;
                keyValMap['decoration_id'] = decoration;
                keyValMap['material_id'] = material;
                keyValMap['paint_color_id'] = paintColor;
                this.makeSheet(keyValMap);
            });
    }

    onNoClick() {
        this.dialogRef.close();
    }

    private makeSheet(keyValMap: {}) {
        const filter = new PushpinFilter();
        filter.status_4 = true;
        filter.status_5 = true;
        filter.status_6 = true;
        filter.status_7 = this.includeCancelled;
        filter.status_8 = true;
        if (this.inspection) {
            filter.inspection_id = this.inspection.id;
            if (this.inspection.inspection_type_id > 2) {
                filter.status_14 = true;
                filter.status_15 = true;
            }
        }
        filter.status_9 = this.includeArchived;
        filter.status_10 = this.includeArchived;
        filter.extraInfo = true;
        this.pushpinService.getPushpins(this.customerArea.id, filter).then(pushpins => {
            this.pricesService.getItemsWithPrices([this.inspection.id]).subscribe(prices => {
                const materialPrices = prices['materialPrices'];
                const decorationPrices = prices['decorationPrices'];
                const sheetArray = [];
                pushpins.forEach(pushpin => {
                    if (pushpin.pushpin_parts && pushpin.pushpin_parts.length > 0) {
                        pushpin.pushpin_parts.forEach(pushpinPart => {
                            this.getAllData(pushpinPart, keyValMap);
                            if (!pushpinPart.decoration) {
                                return;
                            }
                            if (pushpinPart.decoration?.type === 'Vlak' || pushpinPart.decoration?.type === 'Diversen vervangen' || !pushpinPart.decoration?.specify_length) {
                                pushpinPart.surface = this.pricesService.calculateSurface(pushpinPart);
                            }
                            const price = this.pricesService.calculatePushpinPartPriceDetail(pushpinPart, materialPrices, decorationPrices);

                            sheetArray.push({
                                Nummer: pushpin.number,
                                Datum: new Date(pushpinPart.updated_at),
                                Uitgevoerd: pushpinPart.executedDate ? new Date(pushpinPart.executedDate) : '',
                                Postcode: pushpin.zipcode,
                                Plaats: pushpin.place,
                                Straat: pushpin.street,
                                Huisnummer: pushpin.housenumber,
                                Prijs: price.total,
                                'Prijs materiaal': price.material > 0 ? price.material : null,
                                'Code materiaal': price.materialCode,
                                'Prijs primer': price.primer > 0 ? price.primer : null,
                                'Code primer': price.primerCode,
                                'Prijs stralen': price.blast > 0 ? price.blast : null,
                                'Code stralen': price.blastCode,
                                'Prijs uitzetten': price.stake_out > 0 ? price.stake_out : null,
                                'Code uitzetten': price.stake_outCode,
                                'Datum uitgevoerd': pushpinPart.executedDate ? new Date(pushpinPart.executedDate) : null,
                                'Uitgevoerd door': pushpinPart.executedBy,
                                'Aangemaakt door': pushpinPart.created_by,
                                'In offerte': pushpinPart.inQuotation,
                                Status: pushpinPart.status.name,
                                Figuratie: pushpinPart.decoration.name,
                                Materiaal: pushpinPart.material.name,
                                Primer: this.friendlyBoolean(pushpinPart.primer),
                                Kleur: pushpinPart.paint_color.name,
                                Lengte: +pushpinPart.length,
                                Oppervlakte: pushpinPart.decoration.specify_length ? pushpinPart.length * pushpinPart.decoration.surface : +pushpinPart.surface,
                                Aantal: +pushpinPart.amount,
                                Crow: pushpinPart.crow,
                                Stralen: this.friendlyBoolean(pushpinPart.blast),
                                Uitzetten: this.friendlyBoolean(pushpinPart.stake_out),
                                Voorrangsfiguratie: this.friendlyBoolean(pushpinPart.decoration.priority_figuration),
                                'Turbo branden': this.friendlyBoolean(pushpinPart.turbo_burn),
                                'Handmatig branden': this.friendlyBoolean(pushpinPart.manual_burn),
                                Nacht: this.friendlyBoolean(pushpinPart.night),
                                latitude: pushpin.lat,
                                longitude: pushpin.lng,
                                Opmerking: pushpinPart.description,
                                Referentie: pushpin.reference,
                                'Opmerking Klant': pushpinPart.customer_note
                            });
                        });
                    }
                });
                const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetArray);
                for (let i = 2; i <= sheetArray.length + 1; i++) {
                    worksheet[`B${i}`].z = 'd mmmm yy h:mm';
                    worksheet[`C${i}`].z = 'd mmmm yy h:mm';
                    worksheet[`H${i}`].z = '_ € * #,##0.00';
                    worksheet[`I${i}`].z = '_ € * #,##0.00';
                    worksheet[`K${i}`].z = '_ € * #,##0.00';
                    worksheet[`M${i}`].z = '_ € * #,##0.00';
                    worksheet[`Q${i}`].z = 'd mmmm yy h:mm';
                }
                worksheet['!cols'] = [
                    {width: 8},
                    {width: 20},
                    {width: 20},
                    {width: 9},
                    {width: 15},
                    {width: 24},
                    {width: 7},
                    {width: 12},//Prijs
                    {width: 12},
                    {width: 12},
                    {width: 12},
                    {width: 12},
                    {width: 12},
                    {width: 12},
                    {width: 12},
                    {width: 12},
                    {width: 20},//datum uitgevoerd
                    {width: 16},
                    {width: 16},
                    {width: 14},
                    {width: 14},
                    {width: 22},
                    {width: 14},
                    {width: 8},//primer
                    {width: 8},
                    {width: 8},
                    {width: 11},
                    {width: 8},
                    {width: 7},
                    {width: 8},
                    {width: 8},
                    {width: 16},//voorran
                    {width: 13},
                    {width: 17},
                    {width: 8},
                    {width: 12},
                    {width: 12},
                    {width: 15},
                    {width: 30},
                    {width: 30}
                ];
                const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
                const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const filename = `VVR_${this.inspection?.name || this.customerArea.name}_${formatDate(new Date(), 'yyyy-MMM-d_H-mm-ss', 'nl')}`;
                Settings.saveAsExcelFile(excelBuffer, filename.replace('.', ''));
                this.dialogRef.close();
                this.exporting = false;
            });
        });
    }

    private friendlyBoolean(value) {
        return value ? 'Ja' : 'Nee';
    }

    private getAllData(pushpinPart: PushpinPart, keyValMap: {}) {
        pushpinPart.decoration = keyValMap['decoration_id'][pushpinPart.decoration_id];
        pushpinPart.material = keyValMap['material_id'][pushpinPart.material_id];
        pushpinPart.paint_color = keyValMap['paint_color_id'][pushpinPart.paint_color_id];
        pushpinPart.status = keyValMap['status_id'][pushpinPart.status_id];
    }


}
