import {Component, EventEmitter, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {StatusService} from '../../services/status/status.service';
import {Status} from '../../classes/status.class';
import {Settings} from '../../settings.class';
import {MaterialService} from '../../services/material/material.service';
import {Material} from '../../classes/material.class';
import {CordovaService} from '../../cordova.service';
import {Router} from '@angular/router';
import {PushpinFilter} from '../../pushpin-filter';
import {PaintColor} from '../../classes/paintcolor.class';
import {PaintColorService} from '../../services/paintColor/paint-color.service';
import {PricesService} from '../../services/prices/prices.service';
import {CustomerArea} from '../../classes/customerarea.class';
import {CodaltComponent} from '../../codalt.component';
import {Inspection} from '../../classes/inspection';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-pushpin-filter',
    templateUrl: './pushpin-filter.component.html',
    styleUrls: ['./pushpin-filter.component.scss']
})
export class PushpinFilterComponent extends CodaltComponent implements OnInit, OnDestroy {

    @HostBinding('class')
    classList = '';

    inspection: Inspection;
    customerArea: CustomerArea;
    priceSelectionOpened = false;
    filterVisible = false;
    statuses: Status[];
    paintColors: PaintColor[];
    materials: Material[];
    Settings = Settings;
    filterEmitter = new EventEmitter<PushpinFilter>();
    filter: PushpinFilter;

    date_end: Date;
    date_start: Date;

    constructor(private statusService: StatusService,
                private cordovaService: CordovaService,
                private pricesService: PricesService,
                private router: Router,
                private materialService: MaterialService,
                private paintColorService: PaintColorService) {
        super();
        this.filter = new PushpinFilter();
    }

    public setCustomerArea(customerArea: CustomerArea, inspection: Inspection): Promise<void> {
        return new Promise((resolve, reject) => {
            this.customerArea = customerArea;
            this.inspection = inspection;
            this.statusService.getStatuses(this.inspection?.inspection_type_id).then((statuses) => {
                const randId = (+new Date() + Math.random()) + '';
                statuses.forEach((status, i) => {
                    status['img'] = Settings.getMarkerForStatus(randId + i, status.color);
                });
                this.statuses = statuses;
                this.setStatusAvailability();
                resolve();
            }, () => {
                reject();
            });
            this.materialService.getMaterials().then((materials) => {
                this.materials = materials;
            });
            this.paintColorService.getList().then(paintColors => {
                this.paintColors = paintColors;
            });

            this.subscriptions.add(
                this.pricesService.priceSelectionOpened.subscribe(opened => {
                    this.priceSelectionOpened = opened;
                    if (opened) {
                        this.setStatusAvailability();
                        this.filterChange();
                    } else if (!opened && this.AuthorisationService.hasFeature('selectionClosedStatusNotDisabled')) {
                        this.statuses.forEach(status => {
                            this.filter['status_' + status.id + '_disable'] = false;
                        });
                    }
                }));
        });
    }

    resetStatus() {
        this.filter.status_1 = true;
        this.filter.status_2 = true;
        this.filter.status_3 = true;
        this.filter.status_4 = false;
        this.filter.status_5 = false;
        this.filter.status_6 = true;
        this.filter.status_7 = false;
        this.filterChange();
    }

    resetMaterial() {
        this.materials.forEach(material => {
            this.filter['material_' + material.id] = false;
        });
        this.filterChange();
    }

    resetPaintColor() {
        this.paintColors.forEach(paintColor => {
            this.filter['paintColor_' + paintColor.id] = false;
        });
        this.filterChange();
    }

    resetType() {
        this.filter.type_figuratie = false;
        this.filter.type_lengtemarkering = false;
        this.filter.type_vlak = false;
        this.filterChange();
    }

    resetCrow() {
        this.filter.crow = [];
        this.filterChange();
    }

    resetFilter(name) {
        this.filter[name] = false;
        this.filterChange();
    }


    filterChange() {
        this.filter.date_start = this.date_start ? formatDate(this.date_start, 'yyyy-MM-dd ', 'nl') : '';
        this.filter.date_end = this.date_end ? formatDate(this.date_end, 'yyyy-MM-dd ', 'nl') : '';
        this.filterEmitter.emit(this.filter);

        this.filter.filterTypeText = this.filter.getType();
        this.filter.filterMaterialText = this.getMaterial();
        this.filter.filterPaintColorText = this.getPaintColor();
        this.filter.filterCrowText = this.filter.getCrow();
        this.filter.enabledStatusText = this.filter.getEnabledStatuses();
        this.filter.disabledStatusText = this.filter.getDisabledStatuses();

        this.filter.filterCount = (this.filter.filterTypeText ? 1 : 0) +
            (this.filter.filterCrowText ? 1 : 0) +
            (this.filter.enabledStatusText ? 1 : 0) +
            (this.filter.disabledStatusText ? 1 : 0) +
            (this.filter.filterMaterialText ? 1 : 0) +
            (this.filter.primer ? 1 : 0) +
            (this.filter.priority_figuration ? 1 : 0) +
            (this.filter.stake_out ? 1 : 0) +
            (this.filter.always_check ? 1 : 0) +
            (this.filter.turbo_burn ? 1 : 0) +
            (this.filter.manual_burn ? 1 : 0) +
            (this.filter.night ? 1 : 0) +
            (this.filter.blast ? 1 : 0) +
            (this.filter.with_comment ? 1 : 0) +
            (this.filter.deleted ? 1 : 0) +
            (this.filter.traffic_controllers ? 1 : 0) +
            (this.filter.no_traffic_controllers ? 1 : 0) +
            (this.filter.filterPaintColorText ? 1 : 0);
        if (this.filter.filterCount === 0) {
            this.filter.filterCount = null;
        }

    }

    public getMaterial() {
        if (this.materials) {
            const selectedMaterials = this.materials.filter(p => this.filter['material_' + p.id]);
            if (selectedMaterials && selectedMaterials.length) {
                return 'Materiaal: ' + selectedMaterials.map(p => p.name).join(', ');
            }
        }
        return null;
    }

    public getPaintColor() {
        if (this.paintColors) {
            const selectedPaintColors = this.paintColors.filter(p => this.filter['paintColor_' + p.id]);
            if (selectedPaintColors && selectedPaintColors.length) {
                return 'Verfkleur: ' + selectedPaintColors.map(p => p.name).join(', ');
            }
        }
        return null;
    }

    ngOnInit() {

    }

    toggleFilter() {
        this.filterVisible = !this.filterVisible;
        this.classList = this.filterVisible ? 'visible' : '';
        if (this.filterVisible) {
            this.cordovaService.setBackbuttonAction(() => {
                this.toggleFilter();
            });
        } else {
            this.cordovaService.setBackbuttonAction(() => {
                this.router.navigate(['/']);
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private setStatusAvailability() {
        if (this.inspection?.inspection_status_id < 3 && this.AuthorisationService.hasFeature('pushpinPartCreateAsCustomerConcept')) {
            const statusIds = [2, 3, 6, 8, 4];
            this.statuses.forEach(status => {
                this.filter['status_' + status.id + '_disable'] = false;
                if (statusIds.indexOf(status.id) !== -1) {
                    this.filter['status_' + status.id] = true;
                } else {
                    this.filter['status_' + status.id] = false;
                }
            });
        } else if (this.inspection?.inspection_status_id === 1) {
            this.statuses.forEach(status => {
                const statusIds = [1, 2, 3, 4];
                if (AuthorisationService.hasAnyFeature(['allCustomerAreas', 'pushpinPartCreateAsCustomerConcept'])) {
                    statusIds.push(8);
                }
                if (statusIds.indexOf(status.id) !== -1) {
                    this.filter['status_' + status.id] = true;
                } else {
                    this.filter['status_' + status.id] = false;
                }
                statusIds.push(4);
                if (statusIds.indexOf(status.id) === -1 && !AuthorisationService.hasFeature('showVervallen')) {
                    this.filter['status_' + status.id + '_disable'] = true;
                } else {
                    this.filter['status_' + status.id + '_disable'] = false;
                }
            });
        } else if (this.inspection?.inspection_status_id === 2) {
            this.statuses.forEach(status => {
                if ([8, 1, 2].indexOf(status.id) !== -1) {
                    this.filter['status_' + status.id] = true;
                } else {
                    this.filter['status_' + status.id] = false;
                }
                this.filter['status_' + status.id + '_disable'] = false;
            });
        } else if (this.inspection?.inspection_status_id === 3) {
            this.statuses.forEach(status => {
                this.filter['status_' + status.id] = !(status.id !== 2 && status.id !== 3);
                this.filter['status_' + status.id + '_disable'] = (status.id !== 2 && status.id !== 3 && status.id !== 8);
            });
        } else if (this.inspection?.inspection_status_id === 4) {
            this.statuses.forEach(status => {
                this.filter['status_' + status.id] = (status.id === 3 || status.id === 8);
                this.filter['status_' + status.id + '_disable'] = (status.id !== 2 && status.id !== 3 && status.id !== 8);
            });
        } else if (this.inspection?.inspection_status_id === 5) {
            this.statuses.forEach(status => {
                const statusIds = [3, 4];
                if (AuthorisationService.hasAnyFeature(['allCustomerAreas', 'pushpinPartCreateAsCustomerConcept'])) {
                    statusIds.push(8);
                }
                if (statusIds.indexOf(status.id) !== -1) {
                    this.filter['status_' + status.id] = true;
                    this.filter['status_' + status.id + '_disable'] = false;
                } else {
                    this.filter['status_' + status.id] = false;
                    this.filter['status_' + status.id + '_disable'] = true;
                }
            });
            if (!AuthorisationService.hasFeature('statusFiltersAvailable')) {
                this.filter['status_8'] = false;
            }
        } else if (this.inspection?.inspection_status_id === 6) {
            this.statuses.forEach(status => {
                const statusIds = [3, 4];
                if (AuthorisationService.hasFeature('allCustomerAreas')) {
                    statusIds.push(...[4, 5, 6, 8, 10]);
                }
                if (statusIds.indexOf(status.id) !== -1) {
                    this.filter['status_' + status.id] = true;
                    this.filter['status_' + status.id + '_disable'] = false;
                } else {
                    this.filter['status_' + status.id] = false;
                    this.filter['status_' + status.id + '_disable'] = true;
                }
            });
        } else if (this.inspection?.inspection_status_id === 7) {
            this.statuses.forEach(status => {
                if ([4, 5, 6, 8].indexOf(status.id) !== -1) {
                    this.filter['status_' + status.id] = true;
                    this.filter['status_' + status.id + '_disable'] = false;
                } else {
                    this.filter['status_' + status.id] = false;
                    this.filter['status_' + status.id + '_disable'] = false;
                }
            });
        } else {
            const statusIds = [1, 2, 3, 6, 8, 11, 12, 13];
            this.statuses.forEach(status => {
                if (statusIds.indexOf(status.id) !== -1) {
                    this.filter['status_' + status.id] = true;
                }
                this.filter['status_' + status.id + '_disable'] = false;
            });
        }
        if (this.AuthorisationService.hasFeature('selectionFilteringAlwaysAvailable')) {
            this.statuses.forEach(status => {
                this.filter['status_' + status.id + '_disable'] = false;
            });
        }
    }
}
