<app-vvr-icon [dark]="true" [full]="true" [overlay]="true" [shadow]="false"></app-vvr-icon>
<div class="login-background">
    <div class="container">
        <div *ngIf="twoFactor" class="row justify-content-center">
            <div class="col-md-6 col-lg-4" class="login-form qr">
                <div>
                    <h5 class="h5 condensed">Instellen van 2FA</h5>
                    <div class="d-flex justify-content-center">
                        <qrcode [qrdata]="twoFactor.qr" [width]="256" errorCorrectionLevel="M"></qrcode>
                    </div>
                    <p>
                        Scan de QR code met je Authenticator-app en voer de code hieronder in.
                    </p>
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Code</mat-label>
                        <input (keyup.enter)="setup()" [(ngModel)]="twoFactorCode" matInput placeholder="000 000"
                               type="text">
                        <mat-error>Dit moet 6 tekens bevatten</mat-error>
                    </mat-form-field>
                    <div class="d-flex justify-content-between">
                        <button routerLink="/logout" color="secondary" mat-raised-button>
                            Uitloggen
                        </button>
                        <button (click)="setup()" color="primary" mat-raised-button>
                            Instellen
                        </button>
                    </div>
                </div>
                <div class="mt-2 howto">
                    <h5 class="h5 condensed">Toevoegen aan je app</h5>
                    <b>Microsoft authenticator</b>
                    <ol>
                        <li>Klik op de drie puntjes rechtsbovenin de app en kies Account toevoegen</li>
                        <li>Kies Ander account (Google, Facebook, etc.)</li>
                        <li>Scan de QR code</li>
                        <li>Klik op VVR App in de authenticator om de code te zien</li>
                    </ol>
                    <b>Google authenticator</b>
                    <ol>
                        <li>Klik op het plusje rechtsonderin</li>
                        <li>Kies "Scan een QR code"</li>
                        <li>Scan de QR code</li>
                        <li>De code staat onder VVR App in je overzicht</li>
                    </ol>
                </div>
            </div>
        </div>
        <div *ngIf="!twoFactor && !LocalStorage.user.hasOtp" class="row justify-content-center">
            <div class="col-md-6 col-lg-4" class="login-form">
                <h1 class="h2 condensed">Instellen van 2FA</h1>
                <p>
                    Open de link in de zojuist gestuurde email om tweestapsverificatie in te stellen.
                </p>
                <div class="d-flex justify-content-between mt-3">
                    <button routerLink="/logout" color="secondary" mat-raised-button>
                        Uitloggen
                    </button>
                    <button routerLink="/" color="primary" mat-raised-button>
                        Door naar applicatie
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="!twoFactor && LocalStorage.user.hasOtp" class="row justify-content-center">
            <div class="col-md-6 col-lg-4" class="login-form">
                <h1 class="h2 condensed">Instellen van 2FA</h1>
                <p>
                    Je hebt tweestapsverificatie al ingesteld!
                </p>
                <div class="d-flex justify-content-between mt-3">
                    <button routerLink="/logout" color="secondary" mat-raised-button>
                        Uitloggen
                    </button>
                    <button routerLink="/" color="primary" mat-raised-button>
                        Door naar applicatie
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
