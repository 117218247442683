import {Component, OnDestroy, OnInit} from '@angular/core';
import {StatusService} from '../services/status/status.service';
import {MaterialService} from '../services/material/material.service';
import {DecorationsService} from '../services/decorations/decorations.service';
import {PaintColorService} from '../services/paintColor/paint-color.service';
import {MaterialPricesGroup, PricesService} from '../services/prices/prices.service';
import {ExtraCostsService} from '../services/extraCosts/extra-costs.service';
import {DecorationPrice} from '../classes/decorationprice.class';
import {ExtraCost} from '../classes/extracost.class';
import {MaterialPrice} from '../classes/materialprice.class';
import {CustomerArea} from '../classes/customerarea.class';
import {first} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {LocalStorage} from '../storage.class';
import {DocumentService} from '../services/document/document.service';
import {UserService} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {Inspection} from '../classes/inspection';
import {InspectionStatusService} from '../services/inspection-status.service';
import {InspectionService} from '../services/inspection.service';
import {ChangesService, PushpinPartChange} from '../services/changes.service';
import {TailCharge} from '../classes/tail-charge';
import {TailChargeService} from '../services/tail-charge.service';
import {Material} from '../classes/material.class';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, OnDestroy {

    customerArea: CustomerArea;
    inspection: Inspection;
    user: User;

    materialSurfaces = new Map<number, number>();
    specificPriceDecorationLength = new Map<number, number>();
    specificPriceDecoration: DecorationPrice[] = [];
    totalPrice = 0;
    subTotalPrice = 0;
    extraCostsList: ExtraCost[];
    tailChargeList: TailCharge[];
    extraCostsPricesMap = {};
    extraCostsCodeMap = {};

    changedLines: PushpinPartChange[] = [];
    addedLines: PushpinPartChange[] = [];
    date = new Date();
    materialPrices: MaterialPrice[];
    materials: Material[];
    materialPricesGroups: MaterialPricesGroup[];

    private keyValMap = {};
    private decorationPrices: DecorationPrice[];
    private subscriptions = new Subscription();

    constructor(private customerAreaStatusService: InspectionStatusService,
                private statusService: StatusService,
                private materialService: MaterialService,
                private decorationsService: DecorationsService,
                private paintColorService: PaintColorService,
                private pricesService: PricesService,
                private activatedRoute: ActivatedRoute,
                private extraCostsService: ExtraCostsService,
                private tailChargeService: TailChargeService,
                private customerAreaService: CustomerAreaService,
                private inspectionService: InspectionService,
                private documentService: DocumentService,
                private changesService: ChangesService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.activatedRoute.params.pipe(first()).subscribe((params) => {
            if (params['token']) {
                LocalStorage.setUserToken(params['token']);
            }
            this.inspectionService.getInspection(+params['inspection']).subscribe(inspection => {
                this.inspection = inspection.data;
                this.customerArea = this.inspection.customer_area;
                this.pricesService.getItemsWithPrices([this.inspection.id]).subscribe(prices => {
                    prices['extraCostsPrices'].filter(_ecPrice => {
                        return _ecPrice.customer_area_id === this.customerArea.id;
                    }).forEach(price => {
                        this.extraCostsPricesMap[price.extra_cost_type_id] = price.price;
                        this.extraCostsCodeMap[price.extra_cost_type_id] = price.code;
                    });
                    this.materialPrices = prices['materialPrices'];
                    this.decorationPrices = prices['decorationPrices'];
                    this.getKeyValMapData();
                });
            });

            this.userService.getCurrentUser().then(user => {
                this.user = user;
            }, () => {
            });
        }));

    }

    public calculatePrices(waitFor = false) {
        this.specificPriceDecoration = [];
        this.specificPriceDecorationLength.clear();
        this.materialSurfaces.clear();
        this.totalPrice = 0;
        this.pricesService.selectedPushpinParts.forEach(pushpinPart => {
            this.calculate(pushpinPart);
        });

        this.specificPriceDecoration.sort((a, b) => {
            if (a.material_id < b.material_id) {
                return -1;
            }
            if (a.material_id > b.material_id) {
                return 1;
            }
            return 0;
        });
        const extraCostsTotalPrice = this.pricesService.calculateTotalPriceExtraCosts(this.extraCostsList, this.extraCostsPricesMap);
        this.subTotalPrice = this.totalPrice + extraCostsTotalPrice;
        const tailChargesTotal = this.pricesService.calculateTotalTailCharges(this.tailChargeList, this.subTotalPrice);
        this.totalPrice += extraCostsTotalPrice + tailChargesTotal;
        this.materialPricesGroups = this.pricesService.groupPrices(this.materials, this.materialPrices, this.materialSurfaces, this.specificPriceDecoration);
        if (waitFor) {
            setTimeout(() => {
                document.body.appendChild(document.createElement('readyforpuppeteer'));
            });
        }
    }


    public getPushpinPartsAndCalculatePrices() {
        if ([1, 2].indexOf(this.inspection.inspection_status_id) !== -1 || this.inspection.inspection_type_id > 1) {
            this.customerAreaStatusService.getWatchedPushpins(this.inspection.id).then(_pushpinParts => {
                this.pricesService.selectedPushpinParts = _pushpinParts;
                this.calculatePrices(true);
            });
        } else if ([3, 4].indexOf(this.inspection.inspection_status_id) !== -1 || this.inspection.inspection_type_id > 1) {
            this.customerAreaStatusService.getReleasedPushpins(this.inspection.id).then(_pushpinParts => {
                this.pricesService.selectedPushpinParts = _pushpinParts;
                this.calculatePrices(true);
            });
        } else {
            this.customerAreaStatusService.getFinishedPushpins(this.inspection.id).then(_pushpinParts => {
                this.pricesService.selectedPushpinParts = _pushpinParts;
                this.calculatePrices();
                this.changesService.getChanges(this.customerArea.id, this.inspection.id).then(changedLines => {
                    this.changedLines = changedLines.filter(c => !c.added);
                    this.addedLines = changedLines.filter(c => c.added);
                    setTimeout(() => {
                        document.body.appendChild(document.createElement('readyforpuppeteer'));
                    });
                }, () => {

                });
            });
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private calculate(pushpinPart) {
        pushpinPart.decoration = this.keyValMap['decoration_id'][pushpinPart.decoration_id];
        pushpinPart.material = this.keyValMap['material_id'][pushpinPart.material_id];
        pushpinPart.paint_color = this.keyValMap['paint_color_id'][pushpinPart.paint_color_id];
        pushpinPart.status = this.keyValMap['status_id'][pushpinPart.status_id];
        this.totalPrice += this.pricesService.calculatePushpinPartPrice(
            pushpinPart,
            this.materialPrices,
            this.decorationPrices,
            this.materialSurfaces,
            this.specificPriceDecorationLength,
            this.specificPriceDecoration
        );
    }

    private getKeyValMapData() {
        const statusMap$ = this.statusService.getKeyValMap();
        const decorationsMap$ = this.decorationsService.getKeyValMap();
        const materialsMap$ = this.materialService.getMaterialsAsKeyValueMap(true);
        const paintColorMap$ = this.paintColorService.getKeyValMap();
        const materials$ = this.materialService.getMaterials(true);
        combineLatest([statusMap$, decorationsMap$, materialsMap$, paintColorMap$, materials$])
            .subscribe(([status, decoration, material, paintColor, materials]) => {
                this.materials = materials;
                this.keyValMap['status_id'] = status;
                this.keyValMap['decoration_id'] = decoration;
                this.keyValMap['material_id'] = material;
                this.keyValMap['paint_color_id'] = paintColor;
                this.decorationPrices.forEach(_decorationPrice => {
                    _decorationPrice.decoration = this.keyValMap['decoration_id'][_decorationPrice.decoration_id];
                    _decorationPrice.material = this.keyValMap['material_id'][_decorationPrice.material_id];
                    _decorationPrice.paint_color = this.keyValMap['paint_color_id'][_decorationPrice.paint_color_id];
                });
                this.materialPrices.forEach(_materialPrice => {
                    _materialPrice.material = this.keyValMap['material_id'][_materialPrice.material_id];
                    _materialPrice.paint_color = this.keyValMap['paint_color_id'][_materialPrice.paint_color_id];
                });
                this.tailChargeService.get(this.inspection.id).subscribe(charges => {
                    this.tailChargeList = charges.data;
                    this.extraCostsService.getSavedExtraCosts(this.inspection.id).then(extraCosts => {
                        this.getPushpinPartsAndCalculatePrices();
                        this.extraCostsList = extraCosts;
                    });
                });
            });
    }

}
