import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomerArea} from '../../classes/customerarea.class';
import {CustomerAreaService} from '../../services/consumer-area/customer-area.service';
import {CordovaService} from "../../cordova.service";
import {ConfirmDialogService} from "../../services/confirm-dialog-service/confirm-dialog.service";

@Component({
    selector: 'app-customer-area-edit',
    templateUrl: './customer-area-edit.component.html',
    styleUrls: ['./customer-area-edit.component.scss']
})
export class CustomerAreaEditComponent implements OnInit {

    public saving = false;

    public customerArea: CustomerArea;

    form: FormGroup;
    private groupControl: FormControl;

    constructor(private route: ActivatedRoute,
                private cordovaService: CordovaService,
                private customerAreaService: CustomerAreaService,
                private confirmDialogService: ConfirmDialogService,
                private router: Router) {
    }

    ngOnInit() {
        this.cordovaService.setBackbuttonAction(() => {
            this.router.navigate(['/customer-areas']);
        });
        this.route.params.subscribe(params => {
            const customerAreaId = +params['customerAreaId'];
            if (customerAreaId) {
                this.getCustomerArea(customerAreaId);
            } else {
                this.customerArea = new CustomerArea();
                this.setFormGroup(this.customerArea);
            }
        });
    }

    save() {
        Object.assign(this.customerArea, this.form.value);
        this.saving = true;
        this.customerAreaService.save(this.customerArea).then(user => {
            this.saving = false;
            this.router.navigate([`customer-areas`]);

        }, error => {
            this.saving = false;
            this.confirmDialogService.confirm('Er ging iets fout', error['error']['error']['errormessage']);
        });
    }

    private getCustomerArea(userId) {
        this.customerAreaService.getList().then(customerAreas => {
            this.customerArea = customerAreas.find(p => p.id === userId);
            this.setFormGroup(this.customerArea);
        });
    }

    private setFormGroup(customerArea: CustomerArea) {
        this.form = new FormGroup({
            name: new FormControl(customerArea.name, [Validators.required, Validators.minLength(3)]),
            kind: new FormControl(customerArea.kind, Validators.required),
            street: new FormControl(customerArea.street),
            number: new FormControl(customerArea.number),
            zip: new FormControl(customerArea.zip),
            city: new FormControl(customerArea.city),
            phone: new FormControl(customerArea.phone),
            notifications: new FormControl(customerArea.notifications)
        });
    }

}
