import {Component, HostListener, OnInit} from '@angular/core';
import {PricesService} from '../services/prices/prices.service';
import {MaterialPrice} from '../classes/materialprice.class';
import {DecorationPrice} from '../classes/decorationprice.class';
import {MaterialService} from '../services/material/material.service';
import {DecorationsService} from '../services/decorations/decorations.service';
import {CustomerAreaService} from '../services/consumer-area/customer-area.service';
import {CustomerArea} from '../classes/customerarea.class';
import {ExtraCostPrice} from '../classes/extracostprice.class';
import {ExtraCostsService} from '../services/extraCosts/extra-costs.service';
import {MatDialog} from '@angular/material/dialog';
import {PaintColorService} from '../services/paintColor/paint-color.service';
import {CodaltComponent} from '../codalt.component';
import {PriceItemsGroup} from './price-items-group';
import {PriceIndexationDialogComponent} from './price-indexation-dialog/price-indexation-dialog.component';
import {FormControl} from '@ngneat/reactive-forms';
import {debounceTime} from 'rxjs/operators';
import {InspectionService} from '../services/inspection.service';
import {Inspection} from '../classes/inspection';
import {PushpinPart} from '../classes/pushpinpart.class';
import {ExtraCost} from '../classes/extracost.class';
import {ActivatedRoute} from '@angular/router';
import {Settings} from '../settings.class';

@Component({
    selector: 'app-prices',
    templateUrl: './prices.component.html',
    styleUrls: ['./prices.component.scss']
})
export class PricesComponent extends CodaltComponent implements OnInit {

    fcOnlyActiveItems = new FormControl<boolean>(true);
    fcCustomerAreas = new FormControl<number[]>();
    fcInspections = new FormControl<number[]>();

    priceMapExtraCosts;
    customerAreas: CustomerArea[];
    inspections: Inspection[];
    selectedInspections: Inspection[];
    lockedInspectionSelected = false;
    customerAreasKeyVal = {};
    paintColorKeyVal = {};
    selectedCustomerAreas: CustomerArea[];
    materialItemNames;
    decorationItemNames;
    extraCostNames;
    loading = false;
    saving = false;
    priceItems: PriceItemsGroup[];
    private extraCostPrices: ExtraCostPrice[];
    private materialPrices: MaterialPrice[];
    private decorationPrices: DecorationPrice[];
    private activePushpinPrices: PushpinPart[];
    private activeExtraCostPrices: ExtraCost[];

    constructor(public dialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                private pricesService: PricesService,
                private materialService: MaterialService,
                private paintColorService: PaintColorService,
                private decorationsService: DecorationsService,
                private extraCostsService: ExtraCostsService,
                private customerAreaService: CustomerAreaService,
                private inspectionService: InspectionService) {
        super();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (['ArrowDown', 'ArrowUp'].indexOf(event.code) === -1) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        const regex = /(?:panel-)([0-9]+)(?:[-]([0-9]+))?/g;
        const ids = document.activeElement.id.split(regex).filter(i => !!i);
        let panelToGo = +ids[0];
        let inputToGo = +ids[1];
        const nextInput = (panelSwith?: boolean) => {
            if (event.code === 'ArrowDown' && !panelSwith) {
                inputToGo++;
            }
            if (event.code === 'ArrowUp' && !panelSwith) {
                inputToGo--;
            }
            let selector = `#panel-${panelToGo}-${(inputToGo)}`;
            if (panelSwith && inputToGo === -1) {
                selector = `#panel-${panelToGo} .price-line:last-of-type input`;
            }
            return document.querySelector(selector) as HTMLInputElement;
        };
        const elem = nextInput();
        if (elem) {
            elem.focus();
        } else {
            if (event.code === 'ArrowDown') {
                panelToGo++;
                inputToGo = 0;
            }
            if (event.code === 'ArrowUp') {
                panelToGo--;
                inputToGo = -1;
            }
            const panelElem = document.querySelector(`#panel-${panelToGo} > mat-expansion-panel-header`) as HTMLElement;
            if (panelElem) {
                panelElem.click();
                setTimeout(() => {
                    const nextPanel = nextInput(true);
                    if (nextPanel) {
                        nextPanel.focus();
                    }
                }, 500);
            }
        }
        return false;
    }

    openAll() {
        const panelElemList = document.querySelectorAll(`mat-expansion-panel-header`);
        if (document.querySelectorAll(`mat-expansion-panel-header.mat-expanded`).length) {
            panelElemList.forEach((panelElem: HTMLElement) => {
                if (panelElem && panelElem.classList.contains('mat-expanded')) {
                    panelElem.click();
                }
            });
        } else {
            panelElemList.forEach((panelElem: HTMLElement) => {
                if (panelElem && !panelElem.classList.contains('mat-expanded')) {
                    panelElem.click();
                }
            });
        }
    }

    ngOnInit() {
        this.subscriptions.add(this.fcOnlyActiveItems.valueChanges.subscribe(() => {
            this.groupPrices();
        }));
        this.subscriptions.add(this.fcCustomerAreas.valueChanges.pipe(debounceTime(500)).subscribe(customerAreaIds => {
            this.inspectionService.getInspections(customerAreaIds).subscribe(inspections => {
                const lockInspectionRights = this.AuthorisationService.hasFeature('unlockInspection');
                const today = new Date();
                this.inspections = inspections.data.filter(i => {
                    const diffInHours = (today.getTime() - new Date(i.updated_at).getTime()) / 36e5;
                    return lockInspectionRights || !i.lock || diffInHours < Settings.inspectionLockGrace;
                }).map(inspection => {
                    inspection.customer_area = this.customerAreas.find(c => c.id === inspection.customer_area_id);
                    return inspection;
                });
                if (this.fcInspections?.value) {
                    this.getPrices();
                }
            });
        }));
        this.subscriptions.add(this.fcInspections.valueChanges.pipe(debounceTime(750)).subscribe(inspectionIds => {
            if (this.inspections) {
                this.getPrices();
            }
        }));

        this.customerAreaService.getList().then(customerAreas => {
            this.customerAreas = customerAreas;
            this.customerAreaService.getKeyValMap().then(customerAreaKeyVal => {
                this.customerAreasKeyVal = customerAreaKeyVal;
                this.paintColorService.getKeyValMap().then(map => {
                    this.paintColorKeyVal = map;
                });
            });
        });
        this.materialService.getMaterialsAsKeyValueMap(true).then(materialKeyValMap => {
            this.materialItemNames = materialKeyValMap;
        });
        this.decorationsService.getKeyValMap().then(decorationKeyValMap => {
            this.decorationItemNames = decorationKeyValMap;
        });
        this.extraCostsService.getKeyValMap().then(extraCostsKeyValMap => {
            this.extraCostNames = extraCostsKeyValMap;
        });
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { customerAreaIds: string, inspectionIds: string }) => {
            if (params.customerAreaIds) {
                this.fcCustomerAreas.setValue(params.customerAreaIds.split(',').map(id => +id));
            }
            if (params.inspectionIds) {
                this.fcInspections.setValue(params.inspectionIds.split(',').map(id => +id));
            }
        }));
    }

    indexation() {
        this.dialog.open(PriceIndexationDialogComponent, {
            width: '500px',
            maxHeight: '600px',
            panelClass: 'part-dialog',
            data: {
                materialPrices: this.materialPrices,
                decorationPrices: this.decorationPrices,
                extraCostPrices: this.extraCostPrices
            }
        });
    }

    save() {
        this.saving = true;
        this.pricesService.save(this.materialPrices, this.decorationPrices, this.extraCostPrices).then(result => {
            this.saving = false;
        }, error => {
            this.saving = false;
        });
    }


    private getPrices() {
        this.loading = true;
        this.pricesService.getItemsWithPrices(this.fcInspections.value).subscribe(prices => {
            this.activePushpinPrices = prices.activePushpinPrices;
            this.activeExtraCostPrices = prices.activeExtraCostPrices;
            this.materialPrices = prices.materialPrices;
            this.decorationPrices = prices.decorationPrices;
            this.extraCostPrices = prices.extraCostsPrices.filter(ecp => !!this.extraCostNames[ecp.extra_cost_type_id] && !this.extraCostNames[ecp.extra_cost_type_id].deleted_at);

            this.groupPrices();
            this.selectedInspections = this.inspections.filter(i => this.fcInspections.value.includes(i.id));
            this.lockedInspectionSelected = !!this.selectedInspections.find(si => si.lock);
            this.loading = false;
        }, error => {
            this.priceMapExtraCosts = [];
            this.selectedCustomerAreas = [];
            this.loading = false;
        });
    }

    private groupPrices() {
        if (this.fcInspections.value?.length) {
            this.priceItems = [];
            const pGroupExtraCost = new PriceItemsGroup();
            pGroupExtraCost.group = 'Extra kosten';
            pGroupExtraCost.type = 'extra';
            pGroupExtraCost.namesMap = this.extraCostNames;
            let extraCostItems = 0;
            this.fcInspections.value.forEach(inspectionId => {
                const inspection = this.inspections.find(i => i.id === inspectionId);
                if (this.fcOnlyActiveItems.value) {
                    const ecp = this.extraCostPrices.filter(mp =>
                        mp.inspection_id === inspectionId &&
                        !!this.activeExtraCostPrices.find(aep => aep.extra_cost_type_id === mp.extra_cost_type_id)
                    );
                    pGroupExtraCost.items.set(inspection, ecp);
                    extraCostItems += ecp.length;
                } else {
                    const ecp = this.extraCostPrices.filter(mp => mp.inspection_id === inspectionId);
                    pGroupExtraCost.items.set(inspection, ecp);
                    extraCostItems += ecp.length;
                }
            });
            if (extraCostItems) {
                this.priceItems.push(pGroupExtraCost);
            }
            const pGroup = new PriceItemsGroup();
            pGroup.group = 'Markeringen m2';
            pGroup.type = 'materials';
            pGroup.namesMap = this.materialItemNames;
            this.fcInspections.value.forEach(inspectionId => {
                const inspection = this.inspections.find(i => i.id === inspectionId);
                if (this.fcOnlyActiveItems.value) {
                    pGroup.items.set(
                        inspection,
                        this.materialPrices.filter(mp =>
                            mp.inspection_id === inspectionId &&
                            (
                                !!this.activePushpinPrices.find(app => app.material_id === mp.material_id && app.paint_color_id === mp.paint_color_id) ||
                                !this.materialItemNames[mp.material_id]['usable']
                            )
                        )
                    );
                } else {
                    pGroup.items.set(inspection, this.materialPrices.filter(mp => mp.inspection_id === inspectionId));
                }
            });
            this.priceItems.push(pGroup);
            this.materialService.getMaterials(false).then(materials => {
                materials.forEach(material => {
                    this.paintColorService.getList().then(paintColors => {
                        paintColors.forEach(paintColor => {
                            const pGroupDeco = new PriceItemsGroup();
                            pGroupDeco.group = `Figuratie ${material.name} ${paintColor.name}`;
                            pGroupDeco.type = 'decoration';
                            pGroupDeco.namesMap = this.decorationItemNames;
                            let hasContent = false;
                            this.fcInspections.value.forEach(inspectionId => {
                                const inspection = this.inspections.find(i => i.id === inspectionId);
                                let items = [];
                                if (this.fcOnlyActiveItems.value) {
                                    items = this.decorationPrices
                                        .filter(mp =>
                                                mp.inspection_id === inspectionId && mp.material_id === material.id && mp.paint_color_id === paintColor.id
                                                && !!this.activePushpinPrices.find(app =>
                                                    mp.material_id === app.material_id && mp.paint_color_id === app.paint_color_id && mp.decoration_id === app.decoration_id
                                                )
                                        );
                                } else {
                                    items = this.decorationPrices
                                        .filter(mp => mp.inspection_id === inspectionId && mp.material_id === material.id && mp.paint_color_id === paintColor.id);
                                }
                                pGroupDeco.items.set(inspection, items);
                                if (items.length > 0) {
                                    hasContent = true;
                                }
                            });
                            if (hasContent) {
                                this.priceItems.push(pGroupDeco);
                            }
                        });
                    });
                });
            });
        }
    }
}


